import { environment } from 'src/environments/environment';

export const serverUrl = environment.apiUrl;

export const GET_IP_PATIENTS_LIST = 'v1/mrd/getPatientsList';
export const FILES_UPLOAD = 'v1/mrd/patientFilesUpload'
export const GET_PATIENT_DOCUMENTS = 'v1/mrd/getPatientDocs'
export const PATIENT_DOCS_LOCATION = environment.hostUrl + 'assets/patient_docs/'

export const MRD_URL = {
    GET_PATIENTS_LIST : serverUrl + GET_IP_PATIENTS_LIST,
    FILES_UPLOAD: serverUrl + FILES_UPLOAD,
    GET_PATIENT_DOCUMENTS: serverUrl + GET_PATIENT_DOCUMENTS,
    PATIENT_DOCS_LOCATION: PATIENT_DOCS_LOCATION
}