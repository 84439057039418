import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { MRDComponent } from './mrd.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material/material.module';
import { NgxDropzoneModule } from 'ngx-dropzone';

const  mrdRoutes: Routes = [
  { path: 'mrd', component: MRDComponent }
]

@NgModule({
  declarations: [
    MRDComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxDropzoneModule,
    RouterModule.forChild(mrdRoutes)
  ],
  exports: [RouterModule]
})

export class MrdModule { }
