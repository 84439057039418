import { FacadeService } from './../../shared/facade.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterMng_URL } from './DoctorConstants';
import { Observable, forkJoin, throwError } from 'rxjs/index';

@Injectable({
    providedIn: 'root',
  })
  export class DoctorService {
    public headers: any = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
  
    constructor(private facadeService: FacadeService, private http: HttpClient) {}
  
      
    public doctorsList(): any{
      return this.http.post(MasterMng_URL.GET_DOCTOR_LIST, data);
    }
    public getServiceTypeList(): any{
      return this.http.get(MasterMng_URL.GET_SERVICE_TYPE_LIST)
    }
    public getServiceNameList(data): any{
      return this.http.post(MasterMng_URL.GET_SERVICE_NAME_LIST, data)
    }
    public gettemplatesList(data): any{
      return this.http.post(MasterMng_URL.GET_TEMPLATES_LIST, data)
    }
    public saveTemplatesData(data): any{
      return this.http.post(MasterMng_URL.POST_TEMPLATES_DATA, data)
    }
    public updateTemplatesData(data): any{
      return this.http.post(MasterMng_URL.UPDATE_TEMPLATES_DATA, data)
    }
    public getTemplateDataById(data): any{
      return this.http.post(MasterMng_URL.GET_TEMPLATE_BY_ID, data)
    }
    public submitReferralForm(data): any{
      return this.http.post(MasterMng_URL.ADD_REFERRAL_DOCTOR, data)
    }
}
function data(GET_DOCTOR_LIST: string, data: any): any {
    throw new Error('Function not implemented.');
}

