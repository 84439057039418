import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HimscontentComponent} from './landingpage/himscontent/himscontent.component';
import {BillingComponent} from './billing-module/billing/billing.component';
import {FrontOfficeComponent} from './doctors-workstation/front-office/front-office.component';
import {RegistrationFormComponent} from './regn-opdmanagement/registration-form/registration-form.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './shared/loginservices/auth.guard';
import {AppointmentComponent} from './appointments/appointment/appointment.component';
import {WardHeaderComponent} from './ward-management/ward-header/ward-header.component';
import {SampleCollectionComponent} from './lab-module/sample-collection/sample-collection.component';
import { FileuploadComponent } from './fileupload/fileupload.component';


const routes: Routes = [
  { path: '' , component: LoginComponent },
  { path: 'login' , component: LoginComponent },
  { path: 'home' , component: HimscontentComponent, canActivate: [AuthGuard]},
  { path: 'billing' , component: BillingComponent, canActivate: [AuthGuard]},
  { path: 'frontOffice' , component: FrontOfficeComponent, canActivate: [AuthGuard]},
  { path: 'registration' , component: RegistrationFormComponent, canActivate: [AuthGuard]},
  { path: 'appointment' , component: AppointmentComponent, canActivate: [AuthGuard]},
  { path: 'wardManagement' , component: WardHeaderComponent, canActivate: [AuthGuard]},
  { path: 'sampleCollection' , component: SampleCollectionComponent, canActivate: [AuthGuard]},
  { path: 'fileupload', component: FileuploadComponent },
  
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
