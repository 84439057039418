import { Component, OnInit, ViewChild, ElementRef, Type, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InpatientService } from '../inpatient.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgxPrinterService } from 'ngx-printer';
@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.css']
})
export class ViewBillComponent implements OnInit {
  public ipPatientNO = 0;
  public userInfo: any = {};
  patientURMNo: number;
  public rolesInfo: any = [];
  public patientInfoFinalBill: any;
  public finalBillServicesList: any;
  public finalBillReceiptsList: any;
  public finalBillFooterAcknowledgement: any;
  billReceiptsTotal: any = '';
  billServiceListTotal: any = 0;
  billReceiptsTotalWords: any = '';
  grossTotalWords: any = '';

  billServiceDiscountStatus = false;
  public printPrescription = false;
  constructor(private inpatientService: InpatientService,
              private router: Router,
              private route: ActivatedRoute,
              private printerService: NgxPrinterService,
              private tokenService: TokenStorageService,
              private commonService: CommonService) {

    if (this.tokenService.getToken()) {
      this.rolesInfo = this.tokenService.getUser().rolesInfo;
    }

    this.userInfo = this.tokenService.getUser();

    const id = this.route.snapshot.paramMap.get('id');
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;

      this.patientURMNo = parseInt(urmNo);

    });

  }

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
      sear_umr_no: this.patientURMNo,
      daywise_break_btn: 0,
      summary_bill_btn: 0
    };
    this.getFinalBillData(inputRequest);
  }
   // future scope
   getFinalBillData(inputRequest): void {

    this.inpatientService.getFinalBillCallsR(inputRequest).subscribe(result => {

      // Header
      if (result[0] !== null) {
        this.patientInfoFinalBill = result[0];
      }

      // Services
      if (result[1] !== null) {

        this.billServiceDiscountStatus = false;

        const totalCountArray = [];

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < result[1].length; i++) {

          if (!(this.patientInfoFinalBill.SummaryBillBtn)) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < result[1][i].SubCategory.length; j++) {

              totalCountArray.push(result[1][i].SubCategory[j]);
              if ( result[1][i].SubCategory[j].Discount !== '0.0') {
                this.billServiceDiscountStatus = true;
              }

            }
          } else { /** If it is summary bill, we will not be having sub catagories */

            totalCountArray.push(result[1][i]);
            if ( result[1][i].DiscountPercent !== '0') {
              this.billServiceDiscountStatus = true;
            }

          }

        }
        // console.log("getFinalBillServicesList", this.billServiceDiscountStatus);
        // tslint:disable-next-line:max-line-length
        this.billServiceListTotal = totalCountArray.map((t) => this.patientInfoFinalBill.SummaryBillBtn ?  parseInt(t.netAmount) : parseInt(t.Rate)).reduce((a: number, value: number) => a + value, 0);
        this.finalBillServicesList = result[1];

      }

      // Receipts
      if (result[2] !== null) {
     //   this.printPrescription = true;
        this.finalBillReceiptsList = result[2];
        if(this.finalBillReceiptsList[0].Message) {
          this.finalBillReceiptsList = []
           this.billReceiptsTotal = 0}
      else {
        this.billReceiptsTotal = this.finalBillReceiptsList
        .map((t) => parseInt(t.RecptAmt))
        .reduce((a: number, value: number) => a + value, 0);

      this.billReceiptsTotal = Math.round(this.billReceiptsTotal);

      this.grossTotalWords = this.commonService.toWords(
        this.billServiceListTotal
      );

      this.billReceiptsTotalWords = this.commonService.toWords(
        this.billReceiptsTotal
      );

      this.billReceiptsTotal = this.billReceiptsTotal
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      }

      // Footer
      if (result[3] !== null) {
        this.finalBillFooterAcknowledgement = result[3];
      }


    },
    error => {
      console.log('Unable to load');
    },
    () => {

      // All the API calls are completed here.
      // codes should be executed after the completion of all API call

    });

  }
  
  gotoNav(): void {
    const queryParam = { umrNumber: this.patientURMNo };
    this.router.navigate(['/BillSummary/' + this.ipPatientNO], { queryParams: queryParam });
  }
  printBill(){
    this.printPrescription = true;

    setTimeout(() => {
      this.printerService.printDiv('print-prescription-section');
    }, 2000);

    setTimeout(() => {
      this.printPrescription = false;
    }, 3000);

  }
}
