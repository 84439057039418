import { environment } from '../../../../src/environments/environment'

export const serverUrl = environment.apiUrl

export const GET_ICD_LIST = 'v1/ICD10/getIcdList'
export const SUBMIT_ICD_LIST = 'v1/ICD10/submitICDList'
export const GET_USER_ICD_LIST = 'v1/ICD10/getUserICDList'
export const UPDATE_USER_ICD = 'v1/ICD10/removeUserICDInfo'

export const ICD_URL = {
    GET_ICD_LIST : serverUrl + GET_ICD_LIST,
    SUBMIT_ICD_LIST: serverUrl + SUBMIT_ICD_LIST,
    GET_USER_ICD_LIST: serverUrl + GET_USER_ICD_LIST,
    UPDATE_USER_ICD: serverUrl + UPDATE_USER_ICD
}