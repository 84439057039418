<app-ward-header></app-ward-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-5 my-customsr bgbox pb-5 p-0 scroll-1 pt-4">
      <div class="row mt-1 px-2">
        <div class="col-sm-6 col-md-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input placeholder="Search by Mobile Number" 
                     type="number" 
                     id="searchMobile" 
                     name="patientNameSearch"
                     class="form-control" 
                     [(ngModel)]="mobileValue" 
                     #mobileNumber
                     [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10"
                     (keydown)="checkingMobileNumber($event)" 
                     (keyup)="keyUpEnterFunction($event, 'num')" />
              <div class="input-group-append">
                <span class="handCursor input-group-text"  (click)="searchByNumber(mobileNumber, 'num')"><i
                  class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <small class="searchLabel my-1"
                   [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10"
            >Search with full Mobile Number</small>
            <div *ngIf="mobileNumber.value.length > 0 && mobileNumber.value.length < 10" class="bg-warningtxt">Please enter 10-didgit Mobile Number 
             to search</div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input placeholder="Search by UMR" 
                     type="number" 
                     name="patientNameSearch" 
                     [(ngModel)]="UMRvalue" 
                     #umrId
                     class="form-control" 
                     (keydown)="checkingUMRNumber($event)" 
                     (keyup)="keyUpEnterFunction($event, 'id')" />
              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchByNumber(umrId, 'id')">
                  <i class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1">Search with full UMR Number</label>
         
              <div *ngIf="umrId.value.length < 3 && umrId.value.length > 0" class="bg-warningtxt">Please enter valid UMR Number to search
                </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0  inputRadius">
              <input placeholder="Search by Patient Name" type="text" #patientname name="patientNameSearch"
                [(ngModel)]="nameValue" class="form-control"
                [class.is-invalid-error]="patientname.value.length < 3 && patientname.value.length > 0"
                (keyup)="keyUpEnterFunction($event, 'name')" />
              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchByNumber(patientname, 'name')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1"
              [class.is-invalid-error]="patientname.value.length < 3 && patientname.value.length > 0">Search with
              minimum 3 characters</label>
              <div *ngIf="patientname.value.length < 3 && patientname.value.length > 0" class="bg-warningtxt">Please enter 
                minimum 3 characters of Patient Name to search</div>
          </div>
        </div>
        
        <div class="col-sm-6 col-md-6">
          <h6 class="cont btn btn-sm float-md-right btn-light" (click)="openRegistrationForm()">New Patient</h6>
        </div>
      </div>

      <div class="table-responsive">

        <!--Table-->
        <table class="table">

          <!--Table head-->
          <thead class="text-center">
          <tr>
            <th class=" th-lg">Name</th>
            <th class=" th-lg">Mobile Number</th>
            <th class=" th-lg">UMR Number</th>
            <!-- <th class=" th-lg">Gender</th> -->
            <th class=" th-lg">Options</th>
          </tr>
          </thead>

          <tbody class="text-center">
          <tr *ngFor="let patient of appointmentsData || patientsData, let i = index" >
            <td class="text-capitalize">{{patient.title}} {{patient.patientName}} </td>
            <td>{{patient.mobileNumber || patient.patientPhone}}</td>
            <td>{{patient.patientID}}</td>
            <td class="d-flex">
              <a href="javascript:void(0)"
                class="mr-1 font-small-icon"
                title="Add Admission"
                (click)="chekingPatientStatus(patient, i, false)"                                 
              > <i class="fa fa-plus"></i>                  
              </a> 
              <a href="javascript:void(0)"
              (click)="gettingPatientData()"
              class="mr-1 font-small-icon">
              <i class="fa fa-pencil-alt"></i>
            </a>
            </td>
          </tr>
          </tbody>
          <!--Table body-->

        </table>
        <div *ngIf="patientsData.length <=0 " class="text-info text-center font-weight-bold">
          Waiting for Patient Information
          </div>
        <!--Table-->

      </div>

    </div>

    <ng-container>
      <div class="col-7 py-5" *ngIf="visitOpExists">
        <div class="text-info text-center font-weight-bold">{{opExistText}}</div>
      </div>
      <div *ngIf="isAdmission" 
            class="col-7 py-5">
        <div>
          <fieldset class="legend-block mt-3">
            <legend class="legend-title mb-0">Patient Admission Details</legend>
            <div class="dropdown text-right">
              <button type="button" class="cont btn btn-sm float-md-right btn-light dropdown-toggle" data-toggle="dropdown">Options</button>
              <div id="myDropdown" class="dropdown-menu">
                <button class="btn btn-sm dropdown-item" (click)="actionRouting('admissionInformation', element)">Admission Information</button>
                <button class="btn btn-sm dropdown-item" (click)="actionRouting('updatePayee', element)">Update Payee/Sponsor Name</button>
                <button class="btn btn-sm dropdown-item" (click)="bedTransferPopUp(bedTransferPopup)">Bed Transfer</button>
                <button class="btn btn-sm dropdown-item" (click)="crossConsultantPopUp(crossConsultantPopup)">Add treating Doctor/Cross Consultant</button>
                <button class="btn btn-sm dropdown-item" (click)="actionRouting('addReferral', element)">Add Referral Doctor</button>
                <button class="btn btn-sm dropdown-item" (click)="actionRouting('updateExternal', element)">Update External Referral</button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Patient Name</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.TITLE}} {{patientDetails.PATIENT_NAME}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">UMR No</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.P_ID}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Address</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.ADDRESS}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Gender/Age</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.GENDER}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">DOB</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.DATE_OF_BIRTH}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Mobile number</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.MOBILE_NR}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Created By</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.IP_CREATED_BY}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">IP Number</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.IP_NR}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Admission date</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.IP_ADMISSION_DATE}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Admission Time</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.IP_ADMISSION_TIME}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Ward / Bed No</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.WM_WARD_NAME}} / {{patientDetails.WM_BED_NO}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Payee Name</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.PAYEE_NAME}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Admitting Doctor</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.ADMITTING_DOCTOR}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">External referral</label>
                  <div class="col-md-8 px-0">
                    <p class="2">{{patientDetails.REFER_NAME}}</p>
                  </div>
                </div>
              </div>

            </div>
          </fieldset>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="toggleOnOff === 'registration'">

      <div class="col-md-7 my-custom-scrollbar scroll-1 align">
        <fieldset class="legend-block mt-3">
          <legend class="legend-title mb-0">IP Admission</legend>
          <form (ngSubmit)="addAdmission.form.valid && submitAdmissionFormDetails()" 
                #addAdmission="ngForm"
                autocomplete="off"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-2 font-weight-bold">Patient Name</label>
                  <div class="col-md-10 px-0">
                    <p class="2">{{patientDetails.title+ '' + patientDetails.patientName}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group d-flex mb-1">
                  <label class="col-md-4 font-weight-bold">Gender /Age </label>
                  <div class="col-md-8 px-0"> 
                    <p class="2">{{patientDetails.gender + '/'}} {{patientDetails.dateOfBirth | ageCalculation}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group d-flex">
                  <label class="col-md-4 font-weight-bold mb-0">UMR No</label>
                  <div class="col-md-8 px-0">
                    {{patientDetails.patientID}}
                  </div>
                </div>
              </div>
            </div>

            <hr class="mt-0 mb-0">
            
            <div class="row mt-2">
              <div class="col-sm-6">
                <div class=" form-group row">
                  <div class="col-12 col-sm-5">
                    <label for="docname" class="font-weight-bold">Doctor Name: <span
                        class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    
                    <div>
                      <ng-autocomplete #doctorauto
                        [data]="filterDoctorList"
                        [searchKeyword]="doctorNameFilterKeyword"
                        placeholder="Enter the Doctor's Name"
                        (selected)='selectAutocompleteEvent($event, "doctorname")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.doctorName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <span *ngIf="addAdmission.submitted && saveAdmissionFormData.doctorId == '' " class="bg-warningtxt">Doctor Name is
                      required.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <select class="form-control ropdoun bb" 
                            name="dptName" 
                            [(ngModel)]="saveAdmissionFormData.departmentId"
                            #dpartName="ngModel" 
                            [ngClass]="{ 'is-invalid-error': addAdmission.submitted && dpartName.invalid }"
                            (change)="departmentChange(saveAdmissionFormData.departmentId)" 
                            id="dptName"                            
                            required
                    >
                      <option value="">-- Select Department --</option>
                      <option *ngFor="let department of departmentList; let i = index" [ngValue]="department.deptId">
                        {{department.deptName}}
                      </option>
                    </select>
                    <span *ngIf="addAdmission.submitted && dpartName.invalid" class="bg-warningtxt">Department is
                      required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Select Ward:  <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <select class="form-control ropdoun bb" 
                            name="wardList" 
                            [(ngModel)]="saveAdmissionFormData.wardListId"
                            #wardList="ngModel" 
                            [ngClass]="{ 'is-invalid-error': addAdmission.submitted && wardList.invalid }"
                            (change)="changeWard(saveAdmissionFormData.wardListId)"
                            id="wardList"
                            required
                    >
                      <option value="">-- Select Ward --</option>
                      <option *ngFor="let ward of wardsList; let i = index" 
                              [ngValue]="ward?.WM_WARD_M_ID">
                        {{ward?.WM_WARD_NAME}}
                      </option>
                    </select>
                    <span *ngIf="addAdmission.submitted && wardList.invalid" class="bg-warningtxt">
                      Ward is required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Select Bed: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <select class="form-control ropdoun bb" 
                            name="patientBed" 
                            [(ngModel)]="saveAdmissionFormData.patientBedId"
                            #patientBed="ngModel" 
                            [ngClass]="{ 'is-invalid-error': addAdmission.submitted && patientBed.invalid }"
                            id="patientBed"
                            required
                    >
                      <option value="">-- Select Bed --</option>
                      <option *ngFor="let bed of bedsList; let i = index" 
                              [ngValue]="bed?.WM_BED_M_ID">
                        {{bed?.WM_BED_NO}}
                      </option>
                    </select>
                    <span *ngIf="addAdmission.submitted && patientBed.invalid" class="bg-warningtxt">
                      Bed is required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Next of Kin/Guardian: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <input type="text" 
                           class="form-control ropdoun bb m-0" 
                           name="nextOfKin"
                           [(ngModel)]="saveAdmissionFormData.nextOfKin" 
                           #nextOfKin="ngModel"
                           [ngClass]="{ 'is-invalid-error': addAdmission.submitted && nextOfKin.invalid }" 
                           id="nextOfKin" required />
                    <span *ngIf="addAdmission.submitted && nextOfKin.invalid" class="bg-warningtxt">
                      Next of Kin is required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Relation: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <select class="form-control ropdoun bb" 
                            name="relation" 
                            [(ngModel)]="saveAdmissionFormData.relation"
                            #relation="ngModel" 
                            [ngClass]="{ 'is-invalid-error': addAdmission.submitted && relation.invalid }"
                            id="relation"
                            required
                    >
                      <option value="">-- Select Relation --</option>
                      <option *ngFor="let relation of patientRelationList" 
                              [ngValue]="relation.vlaue">{{relation.relationName}}</option>
                    </select>
                    <span *ngIf="addAdmission.submitted && relation.invalid" class="bg-warningtxt">
                      Relation is required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Mobile Number: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <input class="form-control ropdoun bb m-0"
                           type="number"
                           name="patientMobileNumber"
                           [(ngModel)]="saveAdmissionFormData.patientMobileNumber" 
                           #patientMobileNumber="ngModel"
                           pattern="^((\\+91-?)|0)?[0-9]{10}$"
                           [ngClass]="{ 'is-invalid-error': addAdmission.submitted && patientMobileNumber.invalid }" 
                           id="patientMobileNumber" 
                           required />
                    <span *ngIf="addAdmission.submitted && saveAdmissionFormData.patientMobileNumber === ''" 
                          class="bg-warningtxt">Mobile Number is required.</span>
                    <span *ngIf="patientMobileNumber.errors?.pattern" class="bg-warningtxt">Invalid Mobile number</span>
                  </div>
                </div>
              </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-12 col-sm-5">
                      <label class="font-weight-bold">External Referral: <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <div>
                       
                        <ng-autocomplete
                          [data]="extRefList"
                          [searchKeyword]="externalReferenceKeyword"
                          placeholder="Enter the Doctor's Name"
                          (selected)='selectAutocompleteEvent($event, "externalReferral")'
                          (inputChanged)='onChangeSearch($event)'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                          class="autocomplete-dropdown sm"
                        >
                        </ng-autocomplete>
                       
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.doctorName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                      <span *ngIf="addAdmission.submitted && saveAdmissionFormData.extReferralId == '' " class="bg-warningtxt">External Referral is
                        required.</span>
     
                    </div>
                  </div>
                </div>
            
              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Patient Type: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <div class="ml-3 pl-1">
                      <label class="radio-inline d-block" for="radio12">
                        <input type="radio" 
                               class="form-check-input" 
                               id="radio12" 
                               name="patientType"
                               [(ngModel)]="saveAdmissionFormData.patientTypeId" 
                               [value]="'inPatient'">In patient
                      </label>
                      <label class="radio-inline d-block" for="radio14">
                        <input type="radio" 
                               class="form-check-input" 
                               id="radio13" 
                               name="patientType"
                               [(ngModel)]="saveAdmissionFormData.patientTypeId" 
                               [value]="'dayCare'">Day Care
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Payee Type: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <div class="ml-3 pl-1">
                      <label class="radio-inline d-block" for="radio12">
                        <input type="radio" 
                               class="form-check-input" 
                               id="radio16" 
                               name="payeeType"
                               (change)="payeeTypeChange(1)"
                               [(ngModel)]="saveAdmissionFormData.payeeType" 
                               [value]="1">Self Pay
                      </label>
                      <label class="radio-inline d-block" for="radio13">
                        <input type="radio" 
                               class="form-check-input" 
                               id="radio17" 
                               name="payeeType"
                               (change)="payeeTypeChange(2)"
                               [(ngModel)]="saveAdmissionFormData.payeeType" 
                               [value]="2">TPA/Insurance/Corporate/Govt Scheme
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Payee Name: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <!-- <select class="form-control ropdoun bb"
                            name="payeeName" 
                            [(ngModel)]="saveAdmissionFormData.payeeNameId"
                            #payeeName="ngModel" 
                            [ngClass]="{ 'is-invalid-error': addAdmission.submitted && payeeName.invalid }"
                            id="payeeName"
                            required
                    >
                      <option value="">-- Select Payee Name --</option>
                      <option *ngFor="let payeeName of payeeNames" 
                              [ngValue]="payeeName.PAYEE_ID">{{payeeName.PIM_NAME}}</option>
                    </select> -->
                   <div> 
                    <ng-autocomplete #payeename
                    [data]="payeeNames"
                    [searchKeyword]="nameKeyword"
                    placeholder="Enter the Payee's Name"
                    (selected)='selectAutocompleteEvent($event, "payeeName")'
                    (inputChanged)='onChangeSearch($event)'
                    (inputFocused)='onFocused($event)'
                    [itemTemplate]="itemTemplate1"
                    [notFoundTemplate]="notFoundTemplate"
                    class="autocomplete-dropdown sm"
                  >
                  </ng-autocomplete> 
                  <ng-template #itemTemplate1 let-val>
                    <a [innerHTML]="val.PIM_NAME"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                   </div> 
                    <span *ngIf="addAdmission.submitted && saveAdmissionFormData.payeeNameId === ''" 
                          class="bg-warningtxt">Payee Name is required.</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Payee Info: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <input type="text" 
                           class="form-control ropdoun bb m-0"
                           name="payeeInfo"
                           [(ngModel)]="saveAdmissionFormData.payeeInfo" 
                           #payeeInfo="ngModel"
                           id="payeeInfo" required />
                           <span *ngIf="addAdmission.submitted && saveAdmissionFormData.payeeInfo === ''" 
                           class="bg-warningtxt">Payee Info is required.</span>
                  </div>
                </div>
              </div>
            </div>           
            
            <div class="row">
              <div class="col-sm-6" style="margin-top: 30px;">
                <button class="btn ward-btn" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>

    </ng-container>

    <ng-container *ngIf="toggleOnOff === 'patientForm'">
      <div class="col-md-7 my-custom-scrollbar scroll-1 align">
        <app-patient-registration-form [editOption]="editOption" [editButton]="editButton" [arrayIndex]=0
          [patientID]="patientsData[0]?.patientID"></app-patient-registration-form>
      </div>
    </ng-container>

    <ng-template #ipAdmissionInformationPopup let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Ip Admission Information</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-ipadmission-info (popupClose)="popUpClose()" [patientInfo]="patientDetails"></app-ipadmission-info>
      </div>
    </ng-template>

    <ng-template #updatePayeeSponsorPopup let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Update Payee/Sponsor Name</h5>
        <button type="button" class="close" aria-label="close" (click)="modal.dismiss('Corss click')">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <div class="modal-body">
        <app-update-payee (popupClose)="popUpClose()" [ipPatientNO]="ipPatientNO"></app-update-payee>
      </div>
    </ng-template>
  </div>

  <!-- bed transfer popup -->
  <ng-template #bedTransferPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Bed Transfer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="transferForm.form.valid && submitTransferFormDetails()" #transferForm="ngForm">
        <div class="form-group">
          <label class="font-weight-bold">Select Ward: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" 
                  name="wardList" 
                  [(ngModel)]="transferFormData.wardListId"
                  #wardList="ngModel" 
                  id="wardList" 
                  (change)="changeWard(transferFormData.wardListId)" 
                  required>
            <option value="">-- Select Ward --</option>
            <option *ngFor="let ward of completeWardsList; let i = index" [ngValue]="ward?.WM_WARD_M_ID">
              {{ward?.WM_WARD_NAME}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && wardList.invalid" class="bg-warningtxt">
            Ward is required
          </span>
        </div>

        <div class="form-group">
          <label class="font-weight-bold">Select Bed: <span class="requiredSymbol">*</span></label>
          <select class="form-control ropdoun bb" name="patientBed" [(ngModel)]="transferFormData.patientBedId"
            #patientBed="ngModel" id="patientBed" required>
            <option value="">-- Select Bed --</option>
            <option *ngFor="let bed of bedsList; let i = index" [ngValue]="bed?.WM_BED_M_ID">
              {{bed?.WM_BED_NO}}
            </option>
          </select>
          <span *ngIf="transferForm.submitted && patientBed.invalid" class="bg-warningtxt">
            Bed is required
          </span>
        </div>
        <span class="text-danger">{{transferBedError}}</span>
        <div class="float-left mr-2" *ngIf="bedTransferSubmitBtn">
          <button class="btn btn-primary">Submit</button>
        </div>
        <div *ngIf="!bedTransferSubmitBtn">
          <div>Are you sure really transfer the bed?</div>
          <button class="btn btn-success mr-2">Transfer Bed</button>
          <button class="btn btn-danger mr-2" type="button" (click)="closeBedTransferPopup()">Cancel</button>
        </div>
      </form>
    </div>
  </ng-template>
  <!-- ./END bed transfer popup -->

  <!-- Cross Consultant -->
  <ng-template #crossConsultantPopup let-modal class="consult-form">
  <div class="modal-header">
    <h4 class="modal-title">Add Treating Doctor / Cross Consultant</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
   <form  #consultantForm="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="row form-group">
          <div class="col-md-4">
            <label for="docname" class="font-weight-bold">Doctor Name: <span
              class="requiredSymbol">*</span></label>
          </div>
          <div class="col-md-8">
            <ng-autocomplete #doctorauto
                [data]="filterDoctorList"
                [searchKeyword]="doctorNameFilterKeyword"
                placeholder="Enter the Doctor's Name"
                (selected)='selectAutocompleteEvent($event, "doctorname")'
                (inputChanged)='onChangeSearch($event)'
                (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                class="autocomplete-dropdown bb"
              >
              </ng-autocomplete>          
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.doctorName"></a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
              <span *ngIf="consultantForm.submitted && consultantFormData.doctorId == '' " class="bg-warningtxt">Doctor Name is
                required.</span>
          </div>
        </div>        
      </div>
    
      <div class="col-md-6">
        <div class="row form-group">
          <div class="col-md-4">
            <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
          </div>
          <div class="col-md-8">
            <select class="form-control ropdoun bb" 
            name="dptName" 
            [(ngModel)]="consultantFormData.departmentId"
            #dpartName="ngModel" 
            [ngClass]="{ 'is-invalid-error': consultantForm.submitted && dpartName.invalid }"
            (change)="departmentChange(consultantFormData.departmentId)" 
            id="dptName"                            
            required>
            <option value="">-- Select Department --</option>
            <option *ngFor="let deprtment of departmentList; let i = index" [ngValue]="deprtment.deptId">
            {{deprtment.deptName}}
            </option>
            </select>
            <span *ngIf="consultantForm.submitted && dpartName.invalid" class="bg-warningtxt">Department is
            required</span>
          </div>
        </div>
      </div>
    </div><br>
    <div class="text-center">
      <button class="btn btn-primary mr-2 " (click)="submitConsultantFormDetails()"
    >Submit</button>
    <div class="float-left mr-2" *ngIf="consultFormErrorMessage"></div>
    </div>  
   </form>       
  </div>
  </ng-template>
  <!-- end Cross Consultant -->

  <!-- Add Referral Docotr -->
  <ng-template #addReferralPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Referral Doctor</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-referral (popupClose)="popUpClose()"></app-add-referral>
    </div>
  </ng-template>
  <!-- Add Referral Doctor -->

  <!-- External Referral -->
  <ng-template #externalReferralPopup let-modal class="consult-form">
  <div class="modal-header">
    <h4 class="modal-title">Update External Referral</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
   <app-update-external (popupClose)="popUpClose()" [ipPatientNO]="ipPatientNO"></app-update-external>       
  </div>
  </ng-template>
  <!--End External Referral -->
  
</div>
