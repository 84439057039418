<div class="col-md-12">
  <div class="card card-container">
    <form name="form"
            novalidate
            autocomplete="off"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm">
      <div class="form-group">
        <div><h4 class="text-center">Change Password</h4></div>
        <label for="oldpassowrd">Old Password</label>
        <input
            type="password"
            class="form-control"
            name="oldPassword"
            [(ngModel)]="form.oldPassword"
            #oldPassword="ngModel"
            required
        />
        <div
          class="alert alert-danger p-1 my-1"
          role="alert"
          *ngIf="f.submitted && oldPassword.invalid"
        >
            Old Password is required!
        </div>
      </div>
      <div class="form-group">
        <label for="newpassword">New Password</label>
        <input
            type="password"
            class="form-control"
            name="newpassword"
            [(ngModel)]="form.newPassword"
            #newPassword="ngModel"
            required
        />
        <div
          class="alert alert-danger p-1 my-1"
          role="alert"
          *ngIf="f.submitted && newPassword.invalid"
        >
            New Password is required!
        </div>
      </div>
      <div class="form-group">
        <label for="confirmpassword">Confirm Password</label>
        <input
            type="password"
            class="form-control"
            name="confirmpassword"
            [(ngModel)]="form.confirmPassword"
            #confirmPassword="ngModel"
            required
        />
        <div
          class="alert alert-danger p-1 my-1"
          role="alert"
          *ngIf="f.submitted && confirmPassword.invalid"
        >
            Confirm Password is required!
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
            Submit
        </button>
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="errorMessage !== null"
      >{{ errorMessage }}</div>
    </form>
  </div>
</div>
