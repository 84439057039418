<form [formGroup]="ExtReferalForm" (ngSubmit)="updateExternal.form.valid && extRefrl && updateExternalForm(ExtReferalForm)"
#updateExternal="ngForm"
autocomplete="off">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-12 col-sm-5">
            <label class="font-weight-bold">External Referral : <span class="requiredSymbol">*</span></label>
          </div>
          <div class="col-12 col-sm-7">
            <div>                       
                <ng-autocomplete
                  [data]="referralNames"
                  [searchKeyword]="searchNameKeyword"
                  placeholder="Enter the Doctor's Name"
                  (selected)='selectAutocompleteEvent($event, "referralname")'
                  (inputChanged)='onChangeSearch($event)'
                  (inputCleared)='onRemoveSelected($event, "referralname")'
                  (inputFocused)='onFocused($event)'
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                  class="autocomplete-dropdown sm" 
                  [(ngModel)]="externalReferralForm.referalName"
                  formControlName = "externalReferal">
                </ng-autocomplete>
               
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.referralName"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            <span *ngIf="updateExternal.submitted && !extRefrl && externalReferralForm.referalName == ''" class="bg-warningtxt">External Referral is
              required.</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-12 col-sm-5 text-center">
              <label class="font-weight-bold ">Remarks : </label>
          </div>
          <div class="col-12 col-sm-7">
              <input class="form-control-sm border border-primary" type="text" name="remarks" formControlName="remarks"/>
          </div>
        </div>
      </div>     
    </div><br>
    <div class="row form-group mb-3">      
      <label class="col-5 pb-2 pl-3 font-weight-bold">
        <input type="checkbox" name="edit" formControlName="edit" (change)="editReferralData()"> &nbsp; Click here to edit Referral details</label> <!-- (click)="editReferralData($event)" -->     
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Referral Name : </label>
          </div>
          <div class="col-12 col-sm-7">
              <input class="form-control-sm border border-primary" type="text" name="refrlName" formControlName="refrlName"
              [(ngModel)]="externalReferralForm.refrlName" [readOnly]="!editable"/>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-12 col-sm-5 text-right pr-4">
              <label class="font-weight-bold ">Referral Area : </label>
          </div>
          <div class="col-12 col-sm-7">
              <input class="form-control-sm border border-primary" type="text" name="area" formControlName="area"
              [(ngModel)]="externalReferralForm.area" [readOnly]="!editable"/>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Mobile No : </label>
          </div>
          <div class="col-12 col-sm-7">
              <input class="form-control-sm border border-primary" type="number" name="mobileNo"
              pattern="^((\\+91-?)|0)?[0-9]{10}$" maxlength="10"
              formControlName="mobileNo" [readOnly]="!editable" [(ngModel)]="externalReferralForm.mobileNo"/>
          </div>
        </div>
      </div>
    </div><br>
    <div class="text-center">
        <button class="btn btn-primary mr-2" [disabled]="submitbtn == true">Update</button>
        <!-- <div class="float-left mr-2" *ngIf="externalReferralErrorMessage"><span class="bg-warningtxt">{{errorInfo}}</span></div> -->
    </div>  
</form>
<div class="mt-3">
  <table class="table table-bordered table-hover">
    <thead>
      <tr>
        <th>S.NO</th>
        <th>Actual Value</th>
        <th>Updated Value</th>
        <th>Mobile No</th>
        <th>Area Name</th>
        <th>Updated On</th>
        <th>Updated By</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of dataLogList; let i = index;">
        <td>{{i+1}}</td>
        <td>{{list.actualValue}}</td>
        <td>{{list.updatedValue}}</td>
        <td>{{list.MobileNo}}</td>
        <td>{{list.areaTown}}</td>
        <td>{{list.updatedOn}}</td>
        <td>{{list.updateBy}}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- confirmation popup -->
<ng-template #areaConfirmPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Are you sure you want to edit this area name</h4>
  </div>
  <div class="modal-body">
    <button class="btn btn-success mr-2" (click)="areaConfirmation(area)">Yes</button>
    <button class="btn btn-danger" type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">No</button>
  </div>
</ng-template>
<ng-template #mobileConfirmPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Are you sure you want to edit this mobile number</h4>
  </div>
  <div class="modal-body">
    <button class="btn btn-success mr-2" (click)="mobileConfirmation(mobile)">Yes</button>
    <button class="btn btn-danger" type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">No</button>
  </div>
</ng-template>
<!-- confirmation end -->
    