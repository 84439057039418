import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontOfficeComponent } from './front-office/front-office.component';
import { VideoConsultationModule } from '../video-consultation/video-consultation.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentsModule} from '../appointments/appointments.module';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { DischargeListComponent } from '../doctors-workstation/discharge-list/discharge-list.component'
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/loginservices/auth.guard';

const officeRoutes: Routes = [
  {path: 'dischargelist', component: DischargeListComponent, canActivate: [AuthGuard]}  
];

@NgModule({
  declarations: [FrontOfficeComponent, DischargeListComponent],
  imports: [
    CommonModule,
    VideoConsultationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    AppointmentsModule,
    NgbModule,
    MaterialModule,
    SharedModule,
    RouterModule.forChild(officeRoutes)
  ]
})

export class DoctorsWorkstationModule { }
