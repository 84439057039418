import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-master-management-landing',
  templateUrl: './master-management-landing.component.html',
  styleUrls: ['./master-management-landing.component.css']
})
export class MasterManagementLandingComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  referralDoctorPopup(addReferralDoctor){
    this.modalService.open(addReferralDoctor);
  }
}
