<button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#patientRegistrationModal">
  Check in
</button>

<div class="modal" id="patientRegistrationModal">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" style="width:100%;height:100%;">
    <div class="modal-content " style="width:100%;height:100%">


      <div class="modal-header mb-3">
        <h4 class="modal-title text-primary " style="margin:0 auto;">Patient Registration</h4>

      </div>
      <div class="modal-body pt-0">
        <form autocomplete="off">
          <div class="row my-2">
            <div class="col-sm">
              <select class="form-control" name="titleName">
                <option value="Mr." selected>Mr.</option>
                <option value="Miss.">Miss</option>
                <option value="Mrs.">Mrs.</option>
              </select>
            </div>
            <div class="col-sm">
              <input type="text" class="form-control" placeholder="Patient Name" name="patientName">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
            <label>Gender</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="genderMale" value="male" checked>
              <label class="form-check-label" for="genderMale">Male</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="genderFemale" value="female">
              <label class="form-check-label" for="genderFemale">Female</label>
            </div>
            </div>

            <div class="col-sm">
<!--              <input type="date" class="form-control" placeholder="D.O.B" value="D.O.B" name="dateofbirth">-->

              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">D.O.B</div>
                </div>
                <input type="Date" class="form-control"  placeholder="D.O.B" name="dateofbirth">
              </div>

            </div>
            <div class="col-sm">
            <input type="text" class="form-control" placeholder="Age" name="ageofPatient">
          </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
              <input type="email" class="form-control" name="emailofPatient" placeholder="Email">
            </div>
            <div class="col-sm">
              <input class="form-control" type="number" name="mobilNo" placeholder="Mobile Number">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
              <textarea class="form-control" rows="3" name="addressofpatient" >Address</textarea>
            </div>
            <div class="col-sm">
              <input type="text" name="residingCity" class="form-control" placeholder="City">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
            <input  class="form-control" type="text" placeholder="State">
            </div>
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="Country">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="Name of Kin Name">
            </div>
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="Next of Kin1 Name">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="Next of Kin Phone">
            </div>
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="Existing UMR Number">
            </div>
          </div>

          <div class="row my-2">
            <div class="col-sm">
              <input  class="form-control" type="text" placeholder="AAdar Number">
            </div>
            <div class="col-sm">
              <textarea class="form-control" rows="2">Home Address</textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>

