import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  constructor(private router: Router,
              private location: Location) { }

  ngOnInit(): void {
  }

  navigateTo(ModuleRoute: string): any {

    this.router.navigate([ModuleRoute]);

  }

}
