<div class="d-flex">
    <div class="container-fluid total-page"> 
      <div  class="print-form mt-5"
    *ngIf="patientInfoFinalBill !== undefined">
    <div class="row no-gutters heading-block">
        <div class="col-md-2 logo">
            <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
        </div>
        <div class="col-md-9">
            <div class="text-center">
                <h2 class="main-title mb-0" [innerHtml]="patientInfoFinalBill?.HospitalName">
                </h2>
                <p>{{patientInfoFinalBill?.HospitalAddress}}</p>
                <h3 class="mb-1 font-weight-bold">
                    <u>{{patientInfoFinalBill?.InpatientFinalBIllLabel}}</u>
                </h3>
            </div>
        </div>
    </div>
    <!-- Patient information -->
    <div class="row mt-2">
        <div class="col-md-6">

            <div class="row" *ngIf="patientInfoFinalBill?.BillNo !== ''">
                <div class="col-md-3">
                    <b>Bill No.</b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.BillNo}}
                </div>
            </div>

            <div class="row" *ngIf="patientInfoFinalBill?.BillDate !== ''">
                <div class="col-md-3">
                    <b>Bill Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.BillDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    P. Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.PatientName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    DOA
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.DateOfAdmission}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Referral
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfo_finalbill?.Referral}}
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-3">
                    Consultant
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Consultant}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Department
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Department}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Address
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Address}}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-3">
                    <b>Admission No </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AdmissionNo}}
                </div>
            </div>
            <div class="row" *ngIf="patientInfoFinalBill?.DischargeDate !== ''">
                <div class="col-md-3">
                    <b>Discharge Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.DischargeDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    S-W-D-B/O
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.SWDBO}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    UMR No
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.UMRNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Age / Sex
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AgeSex}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Admitted Ward
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AdmittedWard}}
                </div>
            </div>
        </div>
    </div>
    <!-- ./END Patient information -->
    <div class="mt-2 text-center border border-dark p-2" *ngIf="!(patientInfoFinalBill?.daywiseBreakBtn)">
        <b>Hospitalisation Charges from
            <span class="color pl-2 text-danger">{{patientInfoFinalBill?.HospitalChargesFrom}}</span>
            <span class="pl-2">To</span>
            <span class="color pl-2 text-danger">{{patientInfoFinalBill?.HospitalChargesTo}}</span>
        </b>
    </div>
  <ng-container *ngIf="finalBillServicesList.length !==0 && patientInfoFinalBill?.SummaryBillBtn">
    <div class="row table1">
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
        <div class="col-md-2">
            Service TypeId
        </div>
        <div class="col-md-4">
            Service Type
        </div>
        <div class="col-md-2">
            Total
        </div>
        <div class="col-md-2">
            <span *ngIf="billServiceDiscountStatus">Discount(%)</span>
        </div>
        <div class="col-md-2">
            Net Amount
        </div>
    </div>
    <div class="row table1">
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
        <ng-container *ngFor="let service of finalBillServicesList;">
            <div class="col-md-2">
                {{service?.Service_typeid}}
            </div>
            <div class="col-md-4">
                {{service?.Service_typename}}
            </div>
            <div class="col-md-2">
                {{service?.totalAmount}}
            </div>
            <div class="col-md-2">
                <span *ngIf="billServiceDiscountStatus">{{service?.Discount}}</span>
            </div>
            <div class="col-md-2">
                {{service?.netAmount}}
            </div>
        </ng-container>
    </div>
  </ng-container> 
  <ng-container  *ngIf="!(patientInfoFinalBill?.SummaryBillBtn) && finalBillServicesList.length !== 0">
    <div class="row table1" >
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>

        <div class="col-md-1">
            Service Code
        </div>
        <div class="col-md-2" *ngIf="patientInfoFinalBill?.daywiseBreakBtn">
            Service Date
        </div>
        <div class="col-md-3">
            Services/Investigation
        </div>
        <div class="col-md-2">
            Qty.
        </div>
        <div class="col-md-2">
            Rate
        </div>
        <div class="col-md-1">
            <span *ngIf="billServiceDiscountStatus">Discount</span>
        </div>
        <div class="col-md-1">
            Amount
        </div>
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
    </div>
    <div *ngFor="let service of finalBillServicesList;">
        <!-- <div class="row pdf-blue-text font-weight-bold">
            <div class="col-md-6">
                {{service?.ServiceType}}
            </div>
            <div class="col pdf-blue-text text-right">
                <span>{{service?.ServiceTotalAmount}}</span>
            </div>
        </div> -->

        <div class="row table2">
            <div class="col-md-6 ">
                <b>{{service?.ServiceType}}</b>
            </div>
            <div class="offset-5 col-md-2">
                <b>{{service?.ServiceTotalAmount}}</b>
            </div>
        </div>

        <ng-container *ngIf="!(patientInfoFinalBill?.SummaryBillBtn)">
            <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                <div class="col-md-1">
                    {{subService?.ServiceCode}}
                </div>
                <div class="col-md-2" *ngIf="patientInfoFinalBill?.daywiseBreakBtn">
                    {{subService?.ServiceBillDate}}
                </div>
                <div class="col-md-3">
                    {{subService?.Services}}
                </div>
                <div class="col-md-1">
                    {{subService?.Qty}}
                </div>
                <div class="col-md-1">
                    *
                </div>
                <div class="col-md-2">
                    <span class="tablecol">{{subService?.Rate}}</span>
                </div>
                <div class="col-md-1">
                    <span *ngIf="billServiceDiscountStatus">{{subService?.Discount}}</span>
                </div>
                <div class="col-md-1">
                    <span class="tablecol">{{ subService?.Amount}}</span>
                </div>
            </div>
        </ng-container>
    </div>
  </ng-container>
   
    <br />
    <hr class="my-2 border-bottom border-dark" />

    <div class="row" *ngIf="billServiceListTotal !== 0">
        <div class="offset-6 col-3">
            <b>Gross Amount</b>
        </div>
        <div class="col-3 text-center">
            <b>{{billServiceListTotal}}</b>
        </div>
    </div>
    <div class="row" *ngIf="billReceiptsTotal !== 0">
        <div class="offset-6 col-3">
            <b>Receipt Amount</b>
        </div>
        <div class="col-3 text-center">
            <b>{{billReceiptsTotal}}</b>
        </div>
    </div><br />

    <div *ngIf="finalBillReceiptsList.length !== 0">
        <div>
            <b>Receipt / Payment Details</b>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row table1">
            <div class="col-md-1"><b>Recpt. No</b></div>
            <div class="col-md-2"><b>Recpt. Dt.</b></div>
            <div class="col-md-2"><b>Cash Amt</b></div>
            <div class="col-md-2"><b>Cheque Amt</b></div>
            <div class="col-md-1"><b>Card Amt</b></div>
            <div class="col-md-2"><b>Recpt. Amt.</b></div>
            <div class="col-md-1"><b>Remarks</b></div>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
            <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
            <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
            <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
            <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
            <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
            <div class="col-md-2">{{receptInfo?.RecptAmt}}</div>
            <div class="col-md-1">
                <span *ngIf="receptInfo?.Remarks !== ''">
                    {{receptInfo?.Remarks}}:</span>
            </div>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row" *ngIf="billReceiptsTotal !== 0">
            <div class="offset-6 col-3"><b>Total</b></div>
            <div class="col-3"><b>{{billReceiptsTotal}}</b></div>
        </div>
    </div>

    <hr class="my-2 border-bottom border-dark" />

    <div>
        <b *ngIf="billReceiptsTotalWords">Total Received Amount in Words: {{billReceiptsTotalWords}}</b>
    </div>
    <div>
        <b *ngIf="grossTotalWords">Gross Amount in Words: {{grossTotalWords}}
        </b>
    </div>
    <div class="text-right">
        <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared By</b>
                </div>
                <div class="col">
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 0">{{userInfo?.username}}</span>
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared Dt</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PreparedDt}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed By</b>
                </div>
                <div class="col">
                    {{userInfo?.username}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed On</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PrintedOn}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
        <div class="mt-5">
            <div class="text-center">
                <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-8"></div>
            <div class="col-4">
                <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
            </div>

        </div>

        <p class="text-center">I Recieve the bill for my patient <b>{{patientInfoFinalBill?.PatientName}} ..</b>
            and I agree
            and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
            i
            acknowledge the bill.</p>

        <div class="text-center mt-5">
            <div class="row justify-content-center">
                <div class="col-7">
                    <div class="row my-4">
                        <div class="col-3">Signature</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Name</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Ph No</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Date</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      </div>
      </div>
      <div  id="print-prescription-section" class="print-form mt-5"
      *ngIf="printPrescription && patientInfoFinalBill !== undefined">
    <div class="row no-gutters heading-block">
        <div class="col-md-2 logo">
            <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
        </div>
        <div class="col-md-9">
            <div class="text-center">
                <h2 class="main-title mb-0" [innerHtml]="patientInfoFinalBill?.HospitalName">
                </h2>
                <p>{{patientInfoFinalBill?.HospitalAddress}}</p>
                <h3 class="mb-1 font-weight-bold">
                    <u>{{patientInfoFinalBill?.InpatientFinalBIllLabel}}</u>
                </h3>
            </div>
        </div>
    </div>
    <!-- Patient information -->
    <div class="row mt-2">
        <div class="col-md-6">

            <div class="row" *ngIf="patientInfoFinalBill?.BillNo !== ''">
                <div class="col-md-3">
                    <b>Bill No.</b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.BillNo}}
                </div>
            </div>

            <div class="row" *ngIf="patientInfoFinalBill?.BillDate !== ''">
                <div class="col-md-3">
                    <b>Bill Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.BillDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    P. Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.PatientName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    DOA
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.DateOfAdmission}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Consultant
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Consultant}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Department
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Department}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Address
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.Address}}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-3">
                    <b>Admission No </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AdmissionNo}}
                </div>
            </div>
            <div class="row" *ngIf="patientInfoFinalBill?.DischargeDate !== ''">
                <div class="col-md-3">
                    <b>Discharge Date </b>
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.DischargeDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    S-W-D-B/O
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.SWDBO}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    UMR No
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.UMRNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Age / Sex
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AgeSex}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Admitted Ward
                </div>
                <div class="col-md-9">
                    <b>:</b> {{patientInfoFinalBill?.AdmittedWard}}
                </div>
            </div>
        </div>
    </div>
    <!-- ./END Patient information -->
    <div class="mt-2 text-center border-bottom border-dark p-2" *ngIf="!(patientInfoFinalBill?.daywiseBreakBtn)">
        <b>Hospitalisation Charges from
            <span class="color pl-2 text-danger">{{patientInfoFinalBill?.HospitalChargesFrom}}</span>
            <span class="pl-2">To</span>
            <span class="color pl-2 text-danger">{{patientInfoFinalBill?.HospitalChargesTo}}</span>
        </b>
    </div>
  <ng-container *ngIf="finalBillServicesList.length !==0 && patientInfoFinalBill?.SummaryBillBtn">
    <div class="row table1">
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
        <div class="col-md-2">
            Service TypeId
        </div>
        <div class="col-md-4">
            Service Type
        </div>
        <div class="col-md-2">
            Total
        </div>
        <div class="col-md-2">
            <span *ngIf="billServiceDiscountStatus">Discount(%)</span>
        </div>
        <div class="col-md-2">
            Net Amount
        </div>
    </div>
    <div class="row table1">
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
        <ng-container *ngFor="let service of finalBillServicesList;">
            <div class="col-md-2">
                {{service?.Service_typeid}}
            </div>
            <div class="col-md-4">
                {{service?.Service_typename}}
            </div>
            <div class="col-md-2">
                {{service?.totalAmount}}
            </div>
            <div class="col-md-2">
                <span *ngIf="billServiceDiscountStatus">{{service?.Discount}}</span>
            </div>
            <div class="col-md-2">
                {{service?.netAmount}}
            </div>
        </ng-container>
    </div>
  </ng-container> 
  <ng-container  *ngIf="!(patientInfoFinalBill?.SummaryBillBtn) && finalBillServicesList.length !== 0">
    <div class="row table1" >
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>

        <div class="col-md-1">
            Service Code
        </div>
        <div class="col-md-2" *ngIf="patientInfoFinalBill?.daywiseBreakBtn">
            Service Date
        </div>
        <div class="col-md-3">
            Services/Investigation
        </div>
        <div class="col-md-2">
            Qty.
        </div>
        <div class="col-md-2">
            Rate
        </div>
        <div class="col-md-1">
            <span *ngIf="billServiceDiscountStatus">Discount</span>
        </div>
        <div class="col-md-1">
            Amount
        </div>
        <div class="col-12">
            <hr class="my-2 border-bottom border-dark" />
        </div>
    </div>
    <div *ngFor="let service of finalBillServicesList;">
        <!-- <div class="row pdf-blue-text font-weight-bold">
            <div class="col-md-6">
                {{service?.ServiceType}}
            </div>
            <div class="col pdf-blue-text text-right">
                <span>{{service?.ServiceTotalAmount}}</span>
            </div>
        </div> -->

        <div class="row table2">
            <div class="col-md-6 ">
                <b>{{service?.ServiceType}}</b>
            </div>
            <div class="offset-5 col-md-2">
                <b>{{service?.ServiceTotalAmount}}</b>
            </div>
        </div>

        <ng-container *ngIf="!(patientInfoFinalBill?.SummaryBillBtn)">
            <div class="row table2 mt-2" *ngFor="let subService of service?.SubCategory">
                <div class="col-md-1">
                    {{subService?.ServiceCode}}
                </div>
                <div class="col-md-2" *ngIf="patientInfoFinalBill?.daywiseBreakBtn">
                    {{subService?.ServiceBillDate}}
                </div>
                <div class="col-md-3">
                    {{subService?.Services}}
                </div>
                <div class="col-md-1">
                    {{subService?.Qty}}
                </div>
                <div class="col-md-1">
                    *
                </div>
                <div class="col-md-2">
                    <span class="tablecol">{{subService?.Rate}}</span>
                </div>
                <div class="col-md-1">
                    <span *ngIf="billServiceDiscountStatus">{{subService?.Discount}}</span>
                </div>
                <div class="col-md-1">
                    <span class="tablecol">{{ subService?.Amount}}</span>
                </div>
            </div>
        </ng-container>
    </div>
  </ng-container>
   
    <br />
    <hr class="my-2 border-bottom border-dark" />

    <div class="row" *ngIf="billServiceListTotal !== 0">
        <div class="offset-6 col-3">
            <b>Gross Amount</b>
        </div>
        <div class="col-3 text-center">
            <b>{{billServiceListTotal}}</b>
        </div>
    </div>
    <div class="row" *ngIf="billReceiptsTotal !== 0">
        <div class="offset-6 col-3">
            <b>Receipt Amount</b>
        </div>
        <div class="col-3 text-center">
            <b>{{billReceiptsTotal}}</b>
        </div>
    </div><br />

    <div *ngIf="finalBillReceiptsList.length !== 0">
        <div>
            <b>Receipt / Payment Details</b>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row table1">
            <div class="col-md-1"><b>Recpt. No</b></div>
            <div class="col-md-2"><b>Recpt. Dt.</b></div>
            <div class="col-md-2"><b>Cash Amt</b></div>
            <div class="col-md-2"><b>Cheque Amt</b></div>
            <div class="col-md-1"><b>Card Amt</b></div>
            <div class="col-md-2"><b>Recpt. Amt.</b></div>
            <div class="col-md-1"><b>Remarks</b></div>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row" *ngFor="let receptInfo of finalBillReceiptsList">
            <div class="col-md-1">{{receptInfo?.RecptNo}}</div>
            <div class="col-md-2">{{receptInfo?.RecptDt}}</div>
            <div class="col-md-2">{{receptInfo?.CashAmt}}</div>
            <div class="col-md-2">{{receptInfo?.ChequeAmt}}</div>
            <div class="col-md-1">{{receptInfo?.CardAmt}}</div>
            <div class="col-md-2">{{receptInfo?.RecptAmt}}</div>
            <div class="col-md-1">
                <span *ngIf="receptInfo?.Remarks !== ''">
                    {{receptInfo?.Remarks}}:</span>
            </div>
        </div>
        <hr class="my-2 border-bottom border-dark" />
        <div class="row" *ngIf="billReceiptsTotal !== 0">
            <div class="offset-6 col-3"><b>Total</b></div>
            <div class="col-3"><b>{{billReceiptsTotal}}</b></div>
        </div>
    </div>

    <hr class="my-2 border-bottom border-dark" />

    <div>
        <b *ngIf="billReceiptsTotalWords">Total Received Amount in Words: rupees {{billReceiptsTotalWords}}</b>
    </div>
    <div>
        <b *ngIf="grossTotalWords">Gross Amount in Words: rupees {{grossTotalWords}}
        </b>
    </div>
    <div class="text-right">
        <h6 class="mt-5 mb-3"><b>(Authorized Signatory)</b></h6>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared By</b>
                </div>
                <div class="col">
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 0">{{userInfo?.username}}</span>
                    <span
                        *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">{{finalBillFooterAcknowledgement?.PreparedBy}}</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Prepared Dt</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PreparedDt}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed By</b>
                </div>
                <div class="col">
                    {{userInfo?.username}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    <b>Printed On</b>
                </div>
                <div class="col">
                    {{finalBillFooterAcknowledgement?.PrintedOn}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="finalBillFooterAcknowledgement?.Acknowlegement_staus === 1">
        <div class="mt-5">
            <div class="text-center">
                <h4 class="font-weight-bold"><u>Acknowledgement</u></h4>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-8"></div>
            <div class="col-4">
                <div>Admission No : {{finalBillFooterAcknowledgement?.AdmissionNo}}</div>
                <div>Discharge Dt : {{finalBillFooterAcknowledgement?.DischargeDt}}</div>
            </div>

        </div>

        <p class="text-center">I Recieve the bill for my patient <b>{{patientInfoFinalBill?.PatientName}} ..</b>
            and I agree
            and satisfied with the hospital tariff and charges generated against the services provided to us, Hence
            i
            acknowledge the bill.</p>

        <div class="text-center mt-5">
            <div class="row justify-content-center">
                <div class="col-7">
                    <div class="row my-4">
                        <div class="col-3">Signature</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Name</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Ph No</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                    <div class="row my-4">
                        <div class="col-3">Date</div>
                        <div class="col-9" style="border-bottom:2px solid #000;padding-top:20px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    
