<!--<div class="land">
  <div class="container-fluid ">
     <div class="row justify-content-md-center">
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_view_outpatient">
        <button type="button" routerLink="/OutPatient" class="btn btn-sm btnn btn-light">View Outpatients</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_view_inpatient">
        <button type="button" routerLink="/Inpatient" class="btn btn-sm btnn btn-light">View Inpatients</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_search_view_op_billing">
        <button type="button" class="btn btn-sm btnn btn-light">Search/View OP Bills</button>
      </div>
      <div class="m-3" *ngIf="rolesInfo?.subFunctionalityLevel?.billing_reports">
        <button type="button" routerLink="/reports" class="btn btn-sm btnn btn-light">Reports</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/Enquiry" class="btn btn-sm btnn btn-light">Enquiry</button>
      </div>
      <div class="m-3">
        <button type="button" routerLink="/registration" class="btn btn-sm btnn btn-light" >Registration</button>
      </div>
    </div>
  </div>
</div>-->
<div  class="container-fluid">
  <div class="row">
    <div class="col-md-5 bgbox p-0">
      <div class="table-responsive">
        <table class="table tableBodyScroll enquirytbl">
          <thead>
            <tr>
              <th scope="col fweight600">Name Of Service</th>
              <th scope="col fweight600">Service Type Name / Category Name</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th scope="col fweight600">
                <input type="text" class="searchbox" [(ngModel)]="searchServiceNameVal" (ngModelChange)="servicenameSearch()" name="searchName" id="searchName"/>
              </th>
              <th scope="col fweight600">
                <input type="text" class="searchbox" [(ngModel)]="searchServiceType" (ngModelChange)="serviceTypeSearch()" name="searchService" id="searchService"/>
              </th>
            </tr>            
          </thead>
          <tbody class="mt-5">
            <tr *ngFor="let service of selectedAllServices" (click)="addService(service)">
              <td class="services">{{service.serviceName}}</td>
              <td class="services">{{service.serviceTypeName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-container *ngIf="show">

      <div class="col-md-7 datPage align pt-2 pl-2">

        <div class="table-responsive table-bordered">
          <table class="table">
            <thead>
              <tr>
                <th scope="col fweight600">SM ID</th>
                <th scope="col fweight600">Service Name</th>
                <th scope="col fweight600">OP Price</th>
                <th scope="col fweight600">Four Sharing</th>
                <th scope="col fweight600">Twin Sharing</th>
                <th scope="col fweight600">Single Sharing</th>
                <th scope="col fweight600">Delux Sharing</th>
                <th scope="col fweight600">Suite Sharing</th>
              </tr>

            </thead>
            <tbody class="mt-2" >
              <tr *ngFor="let service of doctorServicesList">
                <td>{{service.smID}}</td>
                <td>{{service.serviceName}}</td>
                <td class='alignCenter'>{{service.opdPrice}}</td>
                <td>{{service.fourSharing}}</td>
                <td>{{service.twinSharing}}</td>
                <td>{{service.singleRoom}}</td>
                <td>{{service.deluxRoom}}</td>
                <td>{{service.suiteRoom}}</td>
              </tr>
            </tbody>
          </table>
        <!-- </div>
        <div class="table-responsive table-bordered"> -->
          <table class="table w-50">
            <thead>
              <tr>
                <th scope="col fweight600">ICU </th>
                <!-- <th scope="col fweight600">Covid Isolation Single Room</th>
                <th scope="col fweight600">Covid Isolation ICU Bed</th> -->
                <th scope="col fweight600">Service Category </th>
                <th scope="col fweight600">Status </th>
                <th scope="col fweight600">Variable Price </th>
                <th scope="col fweight600">Suvarna Code </th>
              </tr>
            </thead>
            <tbody class="mt-2" >
              <tr *ngFor="let service of doctorServicesList">
                <td>{{service.isolationPrice}}</td>
                <!-- <td>{{service.covidSingleRoom}}</td>
                <td>{{service.covidICUBed}}</td> -->
                <td>{{service.serviceTypeName}}</td>
                <td *ngIf = "service.serviceStatus ==1">Active</td>
                <td *ngIf = "service.serviceStatus ==0">InActive</td>
                <td>{{service.priceEdit}}</td>
                <td>{{service.suvarnaCode}}</td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <!--<div class="row">
          <div class="col-md-10"></div>
          <div class="col-md-2 text-center" style="line-height: 32px;">
            <button type="button" (click)="gotoBilling()" class="bkbn">Back</button>
          </div>
        </div>-->
      </div>
    </ng-container>
   
  </div>
</div>
