import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Icd10Service} from './icd-10.service'
import { ToastrService } from 'ngx-toastr'
import { TokenStorageService } from '../loginservices/token-storage.service';

interface Isuccess {
  message: string
  status: string
}

interface IsubmitInfo {
  IP_NR: string | null
  ICD10_CODE: string | null
  DISEASE_NAME: string | null
  REMARKS: string | null
  CREATE_ID: string | null
}

@Component({
  selector: 'app-icd',
  templateUrl: './icd.component.html',
  styleUrls: ['./icd.component.css']
})
export class IcdComponent implements OnInit {
  @Input() patientDetails
  @Output() ICDpopupClose = new EventEmitter(null);

  userInfo: any = '';
  userICDList: any = '';
  remarksContent: any = []
  constructor(private icd10Service:Icd10Service, 
    private toastr: ToastrService,
    private tokenService: TokenStorageService ) {
    this.userInfo = this.tokenService.getUser();
   }

  searchICDCodeValue: string = ''
  searchDiseaseValue: string = ''
  icdSelectedList: any = [];
  icdList: any = []

  ngOnInit(): void {
    this.getUserICDList()
  }

  getUserICDList()
  {
    let inputRequest = {
      ipNr: this.patientDetails.IP_NR
    }
    this.icd10Service.getuserICDList(inputRequest)
      .subscribe(
        (response) => 
        {
          this.userICDList = response
          console.log("user ICD list", response)
        },
        (err) =>
        {

        })
  }

  icdSearch()
  {
    if(this.searchICDCodeValue.length > 1 || this.searchDiseaseValue.length > 2)
    {
      let inputRequest = {
        code: this.searchICDCodeValue,
        diseaseName: this.searchDiseaseValue  
      }
      this.icd10Service.getICDList(inputRequest)
        .subscribe(
          (response) =>
          {
            this.icdList = response
          },
          (err) =>
          {
            this.toastr.error(err.error.message)
          }
          )
    }
  }

  addICDCheck(icdData)
  {
    let data = icdData
    data.isSelected = true
    let filter = this.icdSelectedList.filter((list) =>
    {
        if(list.ICD_ID === icdData.ICD_ID)
          return list
    })
    if(filter.length === 0)
    {
      this.icdSelectedList.push(data)
    }    
  }

  onSelectICD(icdData, index)
  {
    this.icdSelectedList.splice(index, 1)
  }

  onSubmit()
  {

    let inputData:IsubmitInfo[] = [];
    this.icdSelectedList.map((icdData, i) =>
    {
      let remarks = null
      if(icdData.REMARKS === '')
      {
        if(this.remarksContent[i])
          remarks = this.remarksContent[i]
        else
          remarks = null
      }
      else
        remarks = icdData.REMARKS

      let data: IsubmitInfo = {
        IP_NR: this.patientDetails.IP_NR,
        ICD10_CODE: icdData.DIAGNOSIS_CODE,
        DISEASE_NAME: icdData.DESCRIPTION,
        REMARKS: remarks,
        CREATE_ID: this.userInfo.username
      }      
      inputData.push(data)
    })
    this.icd10Service.submitICDList(inputData)
        .subscribe(
          (response: Isuccess) =>
          {
            this.toastr.success(response.message)
            this.ICDpopupClose.emit();
          },
          (err) =>
          {
            this.toastr.error(err.error.message)
          }
          )
  }

  removeICD(rowId, index)
  {
    let inputRequest = {
      rowId : rowId,
      savedBy: this.userInfo.username
    }
    this.icd10Service.updateUserICD(inputRequest)
      .subscribe(
        (response) => 
        {
          this.getUserICDList()
        },
        (err) =>
        {
          this.toastr.error(err.error.message)
        }
      )
      
  }
}
