<div class="bg-white">
  <div class="container-fluid p-0 resppage">
    
    <div class="dayDefiner ttop">

      <hr>
      <ul class="nav nav-pills nav-justified mx-auto" style="transform: translateY(8%);"
        [ngClass]="{'d-none': days.length === 0}">
        <li class="nav-item">
          <a [className]="tabname === 'Yesterday' ? 'nav-link active' : 'nav-link' " class="btn rounded-0"
            data-toggle="pill" (click)="activeDay('Yesterday', yesterdayDate)">{{days[0]}}</a>
        </li>
        <li class="nav-item">
          <a [className]="tabname === 'Today' ? 'nav-link active' : 'nav-link' " class="btn" data-toggle="pill"
            (click)="activeDay('Today', todayDate)">{{days[1]}}</a>
        </li>
        <li class="nav-item call">
          <a [className]="tabname === 'Tomorrow' ? 'nav-link active' : 'nav-link' " data-toggle="pill"
            (click)="activeDay('Tomorrow', tomorrowDate)">{{days[2]}}</a>
        </li>
      </ul>
      <div class="row date-top">
        <div class="col-10">
          
        </div>

        <div class="col-2">
          <p class="text-dark  fileter_view tex ml-5" (click)="filter = !filter">
            <i class="fa fa-filter"></i>Filters
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
        </div>
        <div class="col-2 filter0">
          <ng-container *ngIf="filter">
            <div class="mb-1 position-absolute">
              <input placeholder="Search by Speciality" type="text" name="specialitySearch" class="form-control droup"
                [(ngModel)]="formSpeciality" [ngbTypeahead]="SearchBySpeciality"
                (selectItem)="gettingAppointmentsBySpeciality($event)"
                (focus)="searchBySpeciality$.next($any($event).target.value)"
                (click)="searchBySpecialityClick$.next($any($event).target.value)" #instance="ngbTypeahead" />
            </div>
            <div class="mb-1 filter1">
              <input placeholder="Search by Doctors" type="text" name="doctorsSearch" class="form-control droup"
                [(ngModel)]="formDoctor" [ngbTypeahead]="searchByDoctorName"
                (focus)="searchByDoctor$.next($any($event).target.value)"
                (click)="searchByDoctorClick$.next($any($event).target.value)" #instance="ngbTypeahead"
                (selectItem)="gettingDataByDoctor($event)" (change)="checkingEmptyFields()">
            </div>
          </ng-container>
        </div>
      </div>
      <!--<div class="panel-heading">
        <div class="float-left">
							<span class="clickable filter">
							Appointment List
							</span>
        </div>
        <div class="float-right">
							<span class="clickable filter" (click)="filter = !filter" data-toggle="tooltip" title="Search By Doctors and Speciality" >
								<i class="fa fa-filter"></i>
							</span>
        </div>
      </div>
      <div class="panel panel-primary">
        <div class="panel-heading">
          <div class="pull-right ml-3">
<span class="clickable filter ml-3" (click)="filter = !filter" data-toggle="tooltip" title="Search By Doctors and Speciality" >
								<i class="fa fa-filter"></i>
							</span>
          </div>
        </div>-->


      <div class="tab-content view_app_resp">

        <div class="tab-pane  active" id="yesterday">
          <div class="list_dr scroll-1">
            <!--<ng-container *ngIf="filter">
              <div class="row justify-content-center tabl-align">
                <div class="col-4">
                  <div class="mb-1">
                    <input
                      placeholder="Search by Speciality"
                      type="text"
                      name="specialitySearch"
                      class="form-control droup"
                      [(ngModel)]="formSpeciality"
                      [ngbTypeahead]="SearchBySpeciality"
                      (selectItem)="gettingAppointmentsBySpeciality($event)"
                      (focus)="searchBySpeciality$.next($any($event).target.value)"
                      (click)="searchBySpecialityClick$.next($any($event).target.value)"
                      #instance="ngbTypeahead"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-1">
                    <input
                      placeholder="Search by Doctors"
                      type="text"
                      name="doctorsSearch"
                      class="form-control droup"
                      [(ngModel)]="formDoctor"
                      [ngbTypeahead]="searchByDoctorName"
                      (focus)="searchByDoctor$.next($any($event).target.value)"
                      (click)="searchByDoctorClick$.next($any($event).target.value)"
                      #instance="ngbTypeahead"
                      (selectItem)="gettingDataByDoctor($event)"
                      (change)="checkingEmptyFields()"
                    >
                  </div>
                </div>
              </div>
            </ng-container>-->

            <table class="table" *ngIf="appointmentsData">

              <thead>
                <tr class="text-center">
                  <!--  <th [ngStyle]="{display: (appointmentDate >= formDate.toJSON().substr(0, 10)) ? 'table-cell' : 'none'}" >
                  <div class="form-check">
                     <input type="checkbox" class="form-check-input">
                   </div>
                 </th>-->
                  <th class="table-bordered">Patient Name</th>
                  <th class="table-bordered">Mobile No</th>
                  <th class="table-bordered">UMR No</th>
                  <th class="table-bordered">Appointment Time</th>
                  <th class="table-bordered">Doctor Name</th>
                  <th class="table-bordered">Appointment Type</th>
                  <th class="table-bordered">Appointment Status</th>
                  <th [ngStyle]="{display: chooseDay === 'Today' ? 'table-cell' : 'none'} ">Action</th>
                  
                  <!--            <th [ngStyle]="{display: chooseDay === 'Today' ? 'table-cell' : 'none'}">Triage</th>-->
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let patient of appointmentsData | slice: (page-1) * 7 : (page-1) * 7 + 7"
                  class="bg-white table-bordered text-dark   tablee-row rounded-0">
                  <!-- <td  [ngStyle]="{display: (appointmentDate >= formDate.toJSON().substr(0, 10)) ? 'table-cell' : 'none'}" >
                   <div class="form-check">
                     <input type="checkbox" class="form-check-input">
                   </div>
                 </td>-->
                  <td class="rounded-0">{{patient?.patientName}} {{patient.patientName}}</td>
                  <td> {{patient.patientPhone}} </td>
                  <td class="tabwidth"> {{patient.patientID}} </td>
                  <td class="tabwidth"> {{intoTweleve(patient.patientAppointmentTime)}} </td>

                  <td>{{getDoctorTitle(patient.doctorName)}} {{patient.doctorName}} </td>
                  <td> {{patient.patientAppointmentType}} </td>
                  <td class="pl-1"
                    [ngStyle]="{display: (!(checkinTimeSlot(patient.patientAppointmentTime)) && (patient.patientAppointmentStatus === 'pending' || patient.patientAppointmentStatus === 'Pending')) ? 'table-cell' : 'none'}">

                    <a class="btn bn text-primary btn-sm   m-1 " href="#changeAppoitnment" data-backdrop="static"
                      (click)="selectedAppointmentForChange = patient"
                      data-toggle="modal">{{patient.patientAppointmentStatus}}</a></td>
                  <!--                <td style="width: 1px;" [ngStyle]="{display: chooseDay !== 'Yesterday' ? 'table-cell' : 'none'}"><a href="#changeAppoitnment" data-backdrop="static" (click)="selectedAppointmentForChange = patient"  data-toggle="modal">{{patient.patientAppointmentStatus}}</a></td>-->
                  <td class="text-success pl-1"
                    [ngStyle]="{display: (checkinTimeSlot(patient.patientAppointmentTime)) ? 'table-cell' : 'none'}">
                    <button class="btn btn-sm bn m-1  pl-1 text-success "
                      [ngStyle]="{display: (checkinTimeSlot(patient.patientAppointmentTime)) ? 'table-cell' : 'none'}">
                      Completed</button>
                    <!-- <button
                    class="btn btn-sm bn m-1  pl-1 text-success "
                    (click)="gettingAllPatientData(content, patient.patientID, patient)" [ngStyle]="{display: (checkinTimeSlot(patient.patientAppointmentTime)) ? 'table-cell' : 'none'}"> Completed</button>-->
                  </td>
                  <!--                <td style="width: 1px;" [ngStyle]="{display: chooseDay === 'Yesterday' ? 'table-cell' : 'none'}">  Completed  </td>-->
                  <td
                    [ngStyle]="{display: (!(checkinTimeSlot(patient.patientAppointmentTime)) && (patient.patientAppointmentStatus === 'Cancelled')) ? 'table-cell' : 'none'}">
                    <button class="btn btn-sm bn m-1  pl-1 text-danger">Cancelled</button> </td>
                  <td class="rounded-0" [ngStyle]="{display: chooseDay === 'Today' ? 'table-cell' : 'none'}">
                    <button
                      [disabled]="(checkinTimeSlot(patient.patientAppointmentTime)) || (!(checkinTimeSlot(patient.patientAppointmentTime)) && (patient.patientAppointmentStatus === 'Cancelled'))"
                      class="btn cont btn-sm btn-secondary m-1 "
                      (click)="gettingAllPatientData(content, patient.patientID, patient)">Check in</button>
                    <button 
                      class="cont btn btn-secondary btn-sm m-1" 
                      (click)="openPresectiptionPopup(presectiptionPopup, patient)"
                    >Prescription</button> 
                  </td>
                </tr>
              </tbody>
              <th></th>
              <th></th>
              <th></th>
              <th></th>

              <th>
                <div *ngIf="!appointmentsData || appointmentsData.length === 0"
                  class="text-info  font-weight-bold ml-5"> No Appointments Found</div>
                <br>
                <div class="position-relative">
                  <ngb-pagination [collectionSize]=appointmentsData.length [pageSize]="7" [(page)]="page"
                    aria-label="Default pagination"></ngb-pagination>
                </div>
              </th>

              <th></th>
              <th></th>


            </table>
            <!--<div class="text-center">

              <div class="position-relative">
                <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
              </div>
            </div>-->
          </div>
        </div>
        <!-- Modal for the Patient Registration-->
        <ng-template #content let-modal>
          <div class="modal-dialog modal-xl my-0">
            <div class="modal-content p-3 pt-0">
              <div class="modal-header text-center p-0 m-0 ">
                <h3 class="mt-1" style="left: 50%; transform: translateX(-50%); position: relative">Patient Registration
                </h3>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form (ngSubmit)="check.form.valid && onSubmit(forMale, forFemale)" #check="ngForm" autocomplete="off">
                  <div class="row">
                    <!-- title field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <div class="col-4 ">
                          <label class="col-form-label" for="title1">Title: <span
                              class="requiredSymbol">*</span></label></div>
                        <div class="col-7">
                          <select class="form-control  rounded-0 bb" (change)="changingGenderFields()" name="title"
                            [(ngModel)]="model.title" #title="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && title.invalid }" id="title1" required>
                            <option value="B/O." title="0 to 1 year boy and girl">B/O.</option>
                            <option value="Master." title="1 to 12 years Boys">Master. </option>
                            <option value="Miss." title="1 to 12 years Girls">Miss.</option>
                            <option value="Mr." title="Above 13 years Boys">Mr.</option>
                            <option value="Ms." title="Above 13 years Girls and Unmarried">Ms.</option>
                            <option value="Mrs." title="For married women">Mrs.</option>
                          </select>
                          <span *ngIf="check.submitted && title.invalid" class="bg-warningtxt">Title Is Required</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END title field -->
                    <!-- Patient name field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="patname">Patient Name:<span
                              class="requiredSymbol">*</span></label></div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0 bb " name="ptname"
                            [(ngModel)]="model.patientName" #ptname="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && ptname.invalid }"
                            [readOnly]="receivedData" id="patname" required>
                          <span *ngIf="check.submitted && ptname.invalid" class="bg-warningtxt">patient Name Is
                            Required</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END Patient name field -->
                    <!-- Patient age field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="age">Age(Calculated):<span class="requiredSymbol">*</span>
                          </label>
                        </div>
                        <div class="col-7">
                          <input type="text" class=" form-control rounded-0 bb" name="age" id="age" [value]="patientAge"
                            required [readOnly]="receivedData" />
                        </div>
                      </div>
                    </div>
                    <!-- ./END Patient age field -->
                    <!-- Gender field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <label class="col-4 col-form-label">Gender:<span class="requiredSymbol">*</span></label>
                        <div class="col-7">
                          <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" id="radio122" value="MALE" name="gender"
                              #forMale #gender="ngModel" required="required" [ngModel]="model.gender"
                              [readOnly]="receivedData">
                            <label class="form-check-label" for="radio122">Male</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" id="radio222" value="FEMALE" name="gender"
                              #forFemale #gender="ngModel" required="required" [ngModel]="model.gender"
                              [readOnly]="receivedData">
                            <label class="form-check-label" for="radio122">Female</label>
                          </div>
                          <div *ngIf="gender.invalid && check.submitted" class="text-danger">
                            Gender Required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ./END  Gender field -->
                    <!-- DOB field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <div class="col-4 ">
                          <label class="col-form-label" for="dob1">D.O.B: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="date" class=" form-control rounded-0 bb" placeholder="mm/dd/yyyy"
                            name="dateofbirth" #dateofbirth="ngModel" [(ngModel)]="model.dateOfBirth"
                            (input)="gettingAge(model.dateOfBirth)"
                            [ngClass]="{'is-invalid--error':check.submitted && dateofbirth.invalid}" id="dob1" required
                            [readOnly]="receivedData">
                          <span *ngIf="check.submitted &&dateofbirth.invalid" class="bg-warningtxt">Date Of Birth Is
                            Required</span>
                        </div>
                      </div>
                    </div>
                    <!-- DOB field -->
                    <!-- Email field -->
                    <div class="col-sm-6 col-lg-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label for="patientmobEmailrff" class=" col-form-label">Email: <span
                              class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="email" class="form-control rounded-0  bb" name="email"
                            [(ngModel)]="model.emailId" #email="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && email.invalid }"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" id="patientmobEmailrff"
                            [readOnly]="receivedData">
                          <!--<span *ngIf="check.submitted && model.emailId === ''"
                                class="bg-warningtxt">Email Is Required.</span>-->
                          <span *ngIf="email.errors?.pattern" class="bg-warningtxt">Invalid Email Format</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END Email field -->
                    <!-- Mobile number field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="patientmobNumberf">Mobile Number: <span
                              class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0  bb" name="mobile"
                            [(ngModel)]="model.mobileNumber" #mobile="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && mobile.invalid }"
                            pattern="^((\\+91-?)|0)?[0-9]{10}$" id="patientmobNumberf" required
                            [readOnly]="receivedData">
                          <span *ngIf="check.submitted && model.mobileNumber === ''" class="bg-warningtxt">Mobile Number
                            Is Required.</span>
                          <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Invalid Mobile Number</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END Mobile number field -->
                    <!-- City field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="city1">City: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <select class="form-control rounded-0 bb" name="city" [(ngModel)]="model.city" #city="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && city.invalid }"
                            (change)="gettingCityInfo()" id="city1" *ngFor="let cityObj of patientCityModel" required>
                            <option>{{model.city || cityObj.cityName}}</option>
                            <span *ngIf="check.submitted && city.invalid" class="bg-warningtxt">City Is Required</span>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- ./END City field -->
                    <!-- state field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4 ">
                          <label class="col-form-label" for="ste">State: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="text" class="form-control rounded-0 bb" name="state123"
                            [(ngModel)]="model.stateCountry" #state123="ngModel" id="ste" required
                            [readOnly]="receivedData">
                          <!--<input type="text" class="form-control rounded-0 bb"
                                name="state123" [(ngModel)]="model.stateCountry" #state123="ngModel"
                                [ngClass]="{ 'is-invalid-error': check.submitted && state123.invalid }"
                                id="ste" required>
                          <span *ngIf="check.submitted &&state123.invalid" class="bg-warningtxt">State Is Required</span>-->
                        </div>
                      </div>
                    </div>
                    <!-- ./END state field -->
                    <!-- Country field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class="col-form-label" for="cont">Country: <span
                              class="requiredSymbol">*</span></label></div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0 bb" name="countryyy"
                            [(ngModel)]="model.country" #countryyy="ngModel" id="cont" required
                            [readOnly]="receivedData" />
                          <!--<input type="text" class="form-control  rounded-0 bb"
                                name="countryyy" [(ngModel)]="model.country" #countryyy="ngModel"
                                [ngClass]="{ 'is-invalid-error': check.submitted && countryyy.invalid }"
                                id="cont" required />
                          <span *ngIf="check.submitted &&countryyy.invalid" class="bg-warningtxt">Country Is Required</span>-->
                        </div>
                      </div>
                    </div>
                    <!-- ./END Country field -->
                    <!-- Pincode field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class="col-form-label" for="pin">Pincode: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0 bb" name="pincode"
                            [(ngModel)]="model.pincode" #pincode="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && pincode.invalid }"
                            pattern="^\d{3}\d{3}?$" id="pin" required>
                          <span *ngIf="check.submitted && model.pincode === ''" class="bg-warningtxt">Pincode Is
                            Required</span>
                          <span *ngIf="pincode.errors?.pattern" class="bg-warningtxt">Invalid Pincode</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END Pincode field -->
                    <!-- Address fields -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class="col-form-label" for="add">Address:
                            <span class="requiredSymbol">*</span>
                          </label>
                        </div>
                        <div class="col-7">
                          <textarea type="text" class="form-control  rounded-0 bb" rows="3" cols="55" name="address"
                            [(ngModel)]="model.address" #address="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && address.invalid }" id="add" required>
                        </textarea>
                          <span *ngIf="check.submitted &&address.invalid" class="bg-warningtxt">Address Is
                            Required</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="kin1">Next of kin1 :
                            <span class="requiredSymbol">*</span>
                          </label></div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0 bb" name="Nextofkin1"
                            [(ngModel)]="model.nextOfKin1Name" #Nextofkin1="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1.invalid }"
                            pattern="^[a-zA-Z][a-zA-Z\. ]*$" id="kin1">
                          <!--<span *ngIf="check.submitted && model.nextOfKin1Name === ''" class="bg-warningtxt">Patient Name is required</span>-->
                          <span *ngIf="Nextofkin1.errors?.pattern" class="bg-warningtxt">Only Alphabets Allowed</span>
                          <!--<input type="text" class="form-control rounded-0 bb"
                                 name="Nextofkin1" [(ngModel)]="model.nextOfKin1Name" #Nextofkin1="ngModel"
                                 pattern="[a-zA-Z][a-zA-Z ]+"
                                 [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1.invalid }"
                                 id="kin1" required>
                          <div [hidden]="Nextofkin1.valid || Nextofkin1.pristine"
                               class="bg-warningtxt">
                            <div [hidden]="!Nextofkin1.hasError('required')">Next Of Kin1 Is Required</div>
                            <div [hidden]="!Nextofkin1.hasError('pattern')">Only Alphabets Allowed</div>
                          </div>-->
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class="col-form-label" for="Rkin1">Relation: <span
                              class="requiredSymbol">*</span></label></div>
                        <div class="col-7">
                          <select class="form-control rounded-0 bb" name="Nextofkin1relation"
                            [(ngModel)]="model.nextOfKin1Relation" #Nextofkin1relation="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1relation.invalid }"
                            id="Rkin1">
                            <option>Father</option>
                            <option>Mother</option>
                            <option>Brother</option>
                            <option>Sister</option>
                            <option>Spouse</option>
                            <option>Son</option>
                            <option>Daughter </option>
                            <option>Son-in-law</option>
                            <option>Daughter-in-law</option>
                            <option>In-laws</option>
                            <option>Guardian </option>
                            <option>Friends </option>
                          </select>
                          <!--<span *ngIf="check.submitted && Nextofkin1relation.invalid" class="bg-warningtxt">Relation Is Required</span>-->
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class="col-form-label" for="kin1mobNumber">Mobile Number:
                            <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0 bb" name="kinmobNumber122"
                            [(ngModel)]="model.nextOfKin1Mobilenr" #kinmobNumber122="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumber122.invalid }"
                            (keypress)="numberOnly($event)" pattern="^((\\+91-?)|0)?[0-9]{10}$" id="kin1mobNumber">
                          <!--<span *ngIf="check.submitted && kinmobNumber122.untouched&& model.nextOfKin1Mobilenr === ''" class="bg-warningtxt">Mobile Number Is Required</span>-->
                          <span *ngIf="kinmobNumber122.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                          <!--<input type="number" class="form-control  rounded-0  bb"
                                 name="kinmobNumber122" [(ngModel)]="model.nextOfKin1Mobilenr" #kinmobNumber122="ngModel"
                                 [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumber122.invalid }"
                                 pattern="^((\\+91-?)|0)?[0-9]{10}$"
                                 id="kin1mobNumber" required />
                          <span *ngIf="check.submitted && kinmobNumber122.touched && model.nextOfKin1Mobilenr === ''" class="bg-warningtxt">Mobile Number Is Required</span>
                          <span *ngIf="kinmobNumber122.errors?.pattern" class="bg-warningtxt">Invalid Mobile Number</span>-->
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label">Next of kin2:</label>
                        </div>
                        <div class="col-7">
                          <input type="text" class="form-control rounded-0 bb" name="Nextofkin111" required /></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label">Relation:</label>
                        </div>
                        <div class="col-7">
                          <select class="form-control  rounded-0 bb" name="Nextofkin2relation1123" required>
                            <option>Select Relation</option>
                            <option>Father</option>
                            <option>Mother</option>
                            <option>Brother</option>
                            <option>Sister</option>
                            <option>Spouse</option>
                            <option>Son</option>
                            <option>Daughter </option>
                            <option>Son-in-law</option>
                            <option>Daughter-in-law</option>
                            <option>In-laws</option>
                            <option>Guardian </option>
                            <option>Friends </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4 ">
                          <label class="col-form-label">Mobile Number: </label></div>
                        <div class="col-7">
                          <input type="text" class="form-control  rounded-0  bb" required /></div>
                      </div>
                    </div>
                    <!-- ./END Address field -->
                    <!-- identity type field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4 ">
                          <label class="col-form-label" for="IDTYPE">Identity Type: <span
                              class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-7">
                          <select class="form-control  rounded-0 bb" (change)="selectChangeHandler($event)"
                            name="IDTYPE" [(ngModel)]="model.identityType" #IDTYPE="ngModel"
                            [ngClass]="{ 'is-invalid-error': check.submitted && IDTYPE.invalid }" id="IDTYPE" required>

                            <option value="select one">select one</option>
                            <option value="Aadhar">Aadhar</option>
                            <option value="Driving license">Driving license</option>
                            <option value="Voter ID">Voter ID</option>
                            <option value="Pan Card">Pan card</option>
                            <option value="Passport">Passport</option>
                            <option value="Employee Id">Employee ID </option>
                          </select>
                          <span *ngIf="check.submitted && IDTYPE.invalid" class="bg-warningtxt">Identity Type Is
                            Required</span>
                        </div>
                      </div>
                    </div>
                    <!-- ./END identity type field -->
                    <!-- identity number field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label">Identity Number:
                            <span class="requiredSymbol">*</span>
                          </label>
                        </div>
                        <div class=" col-7">
                          <input
                            [ngClass]="{ 'is-invalid-error':identityNumber.touched && identityNumber.hasError('required')}"
                            type="text" class="form-control rounded-0 bb" [formControl]="identityNumber"
                            (keyup)="onKeyUp()">
                          <span class="bg-warningtxt"
                            *ngIf="identityNumber.touched && identityNumber.hasError('required')">Identity Number is
                            required</span>
                          <span class="bg-warningtxt"
                            *ngIf="selectedId==='Aadhar' && identityNumber.hasError('pattern')">Invalid Aadhar
                            Number</span>
                          <span class="bg-warningtxt"
                            *ngIf="selectedId==='Driving license' && identityNumber.hasError('pattern')">Invalid Driving
                            license</span>
                          <span class="bg-warningtxt"
                            *ngIf="selectedId==='Voter ID' && identityNumber.hasError('pattern')">Invalid Voter
                            ID</span>
                          <span class="bg-warningtxt"
                            *ngIf="selectedId==='Pan Card' && identityNumber.hasError('pattern')">Invalid Pan
                            Card</span>
                          <span class="bg-warningtxt"
                            *ngIf="selectedId==='Passport' && identityNumber.hasError('pattern')">Invalid
                            Passport</span>
                          <!--                    <span *ngIf="check.submitted && model.identityIdNr === ''" class="bg-warningtxt">Identity Number is required.</span>-->
                          <!--                    <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Identity Number number</span>-->
                        </div>
                      </div>
                    </div>
                    <!-- ./END identity number field -->
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-4">
                          <label class=" col-form-label" for="existing">Existing UMR Number: <span
                              class="requiredSymbol">*</span></label></div>
                        <div class="col-7">
                          <input type="text" class="form-control rounded-0 bb" name="existingnumber"
                            [(ngModel)]="model.patientID" #existingnumber="ngModel" id="existing" required>
                          <!--                  <span *ngIf="check.submitted &&existingnumber.invalid" class="bg-warningtxt">Existing UMR Number is required</span>-->
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-right mr-4 pr-2">
                        <button type="button" data-dismiss="modal" aria-label="Close"
                          (click)="modal.dismiss('Cross click')" class="btn btn-info mr-2">Cancel</button>
                        <button class="btn btn-primary">Submit</button>
                        <!-- <span class="btn" data-dismiss = "modal" #modalForRegistration></span> -->
                      </div>
                    </div>
                  </div>


                  <!--<div class="row">
                    <div class="col-sm-6">
                      <div class="form-group twoInputssameLine mx-2 ">
                        <div class="form-group">
                          <label >Identity Type:  <span class="requiredSymbol">*</span></label>
                          <select  class="form-control rounded-0 border border-primary"  (change)="selectChangeHandler($event);
                                  name="IDTYE" required>
                            <option value="Aadhar">Aadhar</option>
                            <option value="Driving license">Driving license</option>
                            <option>Voter ID</option>
                            <option> Pan card</option>
                            <option>Passport</option>
                            <option>Employee ID</option>
                          </select>
                          <select *ngIf="check.submitted && IDTYPE.invalid" class="bg-warningtxt">Identity Type is required</select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group twoInputssameLine mx-2 ">
                        <div class="form-group">
                          <label for="idnum">Identity Number :
                            <span class="requiredSymbol">*</span>
                          </label>
                          <input type="text" class="form-control rounded-0 border border-primary"
                                 name="idnumb" [(ngModel)]="model.identityIdNr" #idnumb="ngModel"
                                 [ngClass]="{ 'is-invalid-error': check.submitted && idnumb.invalid }"
                                 [pattern] ="patternvalidation"
                                 id="idnum" required>
                          <span *ngIf="check.submitted && model.identityIdNr === ''" class="bg-warningtxt">Identity Number is required.</span>
                          <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Identity Number number</span>
                        </div>
                      </div>
                    </div>
                  </div>-->
                </form>
              </div>
            </div>
          </div>
        </ng-template>



        <!--      <div class="tab-pane container " id="today" [ngClass]="{active: chooseDay=='Today'}"> this is the compolicated</div>-->
        <!--      <div class="tab-pane container" id="tomorrow" [ngClass]="{active: chooseDay=='Tomorrow'}" >aohdoha bjjjn </div>-->
      </div>
    </div>
  </div>

  <!--Modal for Rescheduling Appointment-->
  <div class="modal fade" id="changeAppoitnment">
    <div class="modal-dialog modal modal-dialog-centered">
      <div class="modal-content ">
        <div class="modal-header">
          <h4 class="modal-title text-dark text-center">Modify Appointment</h4>
          <a class="btn btn-sm btn-danger" data-dismiss="modal">
            <span class="p-0"><i class="fa fa-times" aria-hidden="true"></i></span>
          </a>
        </div>
        <div class="modal-body w-75 d-flex justify-content-around mx-auto my-md-5">
          <button class="btn btn-success m-2" data-dismiss="modal" (click)="navigationForReschedule()">Reschedule
            Appointment</button>
          <button class="btn btn-danger m-2" data-dismiss="modal" data-toggle="modal" data-target="#decideModal">Cancel
            Appointment</button>

        </div>
      </div>
    </div>
  </div>

  <!--    Modal for the Cancel Confirmation-->

  <div id="decideModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <div class="icon-box">
            <i aria-hidden="true" class="fa fa-times"></i>

          </div>
          <h4 class="modal-title w-100">Are you sure?</h4>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
          <p>Do you want to cancel the appointment.</p>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          <button type="button" class="btn btn-danger" (click)="cancellingAppointment()" data-toggle="modal"
            data-dismiss="modal" data-target="#cancelMessage">Yes</button>
        </div>
      </div>
    </div>
  </div>



  <!--<div id="decideModal" class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-sm  modal-dialog-centered" role="document">
      <div class="modal-content bg-light">
        <div class="modal-header">
          <h3 class="modal-title" id="my-modal-title" style="text-align: center !important; margin: 0 auto;">Cancel Appointment</h3>
        </div>
        <div class="modal-body">
          <div class="my-5">
            <p class=" h5 text-center mx-auto">Do you want to cancel the appointment ?</p>
            <div class="d-flex justify-content-center">
              <div class="d-flex btnGroup justify-content-around w-75 mt-2">
                <button  class="btn btn-outline-danger p-1 w-50" data-dismiss="modal"><span class="h4">NO</span></button>&nbsp; &nbsp;
                <button  type="button" class="btn btn-outline-success p-1 w-50 " (click)="cancellingAppointment()" data-toggle="modal" data-dismiss="modal" data-target="#cancelMessage"><span class="h4">YES</span></button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>-->
  <!--    Modal for Loading Data-->
  <div class="modal" [class]="viewAppLoaded ? 'forModalDisplay' : 'd-none'">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content" style="background-color: #cbd8db">
        <div class="modal-body px-0 font-weight-bold text-center">
          <div class="font-weight-bold text-dark">{{modalInfo}}</div>
          <div class="spinner-border text-primary"></div>
        </div>
      </div>
    </div>
  </div>
  <!--    Modal For Cancellation-->
  <!--<div class="modal" id="cancelMessage" data-backdrop="static">
    <div class="modal-dialog modal-sm modal-dialog-centered" >
      <div class="modal-content">
        <div class="modal-body px-0 font-weight-bold text-center">
          <div class="font-weight-bold text-dark">{{cancellationMessage}}</div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
        </div>
      </div>

    </div>
  </div>-->
  <div id="cancelMessage" data-backdrop="static" class="modal fade">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header">
          <div class="icon-box">
            <i aria-hidden="true" class="fa fa-times"></i>
          </div>
        </div>
        <div class="modal-body">
          <!-- <p class="text-center">{{cancellationMessage}}</p>-->

          <p class="text-center">{{cancellationMessage}}</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger btn-block" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal for Prescription -->
  <ng-template #presectiptionPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Patient Prescription</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">�</span>
      </button>
    </div>
    <div class="modal-body">
      <app-prescription [patientInfo]="patientInfo"></app-prescription>
    </div>
  </ng-template>
</div>


<!--<app-patientregistration></app-patientregistration>-->