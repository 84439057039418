export const AppointmentSearchLocations = '/appointment/getCities';
export const AppointmentSearchHospitals = '/appointment/getHospital/';
export const AppointmentSearchDoctor = '/appointment/getDoctorList/';
export const AppointmentDoctorProfiles = '/appointment/getDoctor';
export const AppointmentDoctorSchedule = '/appointment/getDoctorSchedule';
export const AppointmentPatientSearch = '/patient/searchPatient';
export const AppointmentViewAppointments = '/appointment/searchAppointmentsByHospital/1';
export const AppointmentBookingAppointment = '/appointment/bookAppointment';
export const AppointmentDataBYDoctor = 'appointment/searchAppointmentsByDoctor/';
export const AppointmentPatientRegistration = '/patient/registerPatient';
export const AppointmentAppointmentsByDate = '/appointment/searchAppointmentsByDate/';
export const AppointmentDepartmentsList = '/appointment/getDepartmentList/';
export const AppointmentOnlineDoctorSchedule = '/appointment/getDoctorScheduleOnline';
export const AppointmentCancellingAppointment = '/appointment/cancelAppointment';
export const AppointmentPatientAllCities = '/appointment/getAllCities';
export const AppointmentAppointmentsByNameNumberUmr = '/appointment/searchAppointment';
export const ExternalReferralList = '/appointment/getExternalReferralList';
export const VisitTypeCategories = '/doctorVisitCategory/getVisitCategories/';
export const GettingCityInfoById = '/appointment/getCityInfo';
export const PayeeType = 'payment/getPayeeType';
// export const SavePatientVisitDetails = 'payment/submitPaymentDetails';
export const SavePatientVisitDetails = 'patient/saveVisitPatient';
export const AppointmentSingleDoctorInfo = '/appointment/getDoctorinfo/';
export const AddDoctors = 'payment/addDoctorsToVisit';
export const AddVisitStatus = 'patient/addPatientVisitStatus/';
export const PatientPreviousVisits = 'patient/patientPreviousVisits';
