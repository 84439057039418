import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacadeService } from '../shared/facade.service';
import { Outpatient_URL } from './billingconstants';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  public headers: any = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  constructor(private facadeService: FacadeService, private http: HttpClient) { }

  public getVaccinesList( data: any): any{
    return this.http.post(Outpatient_URL.vaccinesList, data);
  }
  
  public submitFormData(data: any): any {
    return this.http.post(Outpatient_URL.vaccineFormData, data);
  }

  public getSelectedVaccineInfo(data: any): any{
    return this.http.post(Outpatient_URL.selectedVaccineInfo, data);
  }

  public vaccinationDataPrint(data: any): any{
    return this.http.post(Outpatient_URL.vaccinationFormPrint, data);
  }

  public getVaccinisedPatientsList(data: any): any{
    return this.http.post(Outpatient_URL.vaccinisedPatientsList, data);
  }

  public getExistingRecords(data: any): any{
    return this.http.post(Outpatient_URL.existingRecords, data);
  }

  public getOutpatientsByVisitId(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getDoctorsInfo(paramaters: string): any {
    return this.facadeService.getService(paramaters);
  }

  public getPaymentModes(paramaters: string): any {
    return this.facadeService.getService(paramaters);
  }

  public getBillingId(paramaters: string): any {
    return this.facadeService.getService(paramaters);
  }

  public getPayeeName(paramaters: string): any {
    return this.facadeService.getServiceText(paramaters);
  }

  public getPatientDetails(paramaters: string): any {
    return this.facadeService.getService(paramaters);
  }

  public getDoctorsServiceList(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveServicesBillingInfo(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveServicesInfo(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveOpBillDetails(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveOpPaymentDetails(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getAllServices(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getPrintDetails(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getServiceDetail(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public prescriptionDoctorsInfo(data: any, parameters: string): any {
    return this.facadeService.postService(data, parameters);
  }
  
  public setPatientVisitSatus(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getPatientVisitInfo(paramaters: string): any {
    return this.facadeService.getService(paramaters);
  }

  public getPayeeNames(paramaters): any
  {
    return this.facadeService.getService(paramaters);
  }

}
