import { environment } from "src/environments/environment";

export const PatientRecordsByVisitID = '/patient/searchPatientOrByVisitId';
export const SaveServicesBillingInfo = 'payment/opServicesBillingInfo';
export const SaveServicesInfo = 'payment/opServicesOrderInfo';
export const SaveOpBillDetails = 'payment/postOPBillDetails';
export const SaveOpPaymentDetails = 'payment/postOPPaymentDetails';
export const GetDoctorsList = 'patient/getDoctorNames/';
export const DoctorServicesList = 'patient/getVisitServiceOrderDetails';
export const PatientDetails = 'patient/getPatientRecord/';
export const BillingId = 'patient/getVisitBillingID/';
export const PaymentModes = 'payment/getPaymentMode/';
export const PayeeName = 'patient/getPayeeName/';
export const LoadServices = 'payment/searchPaymentPrescriptions';
export const SingleService = 'payment/getserviceInfo';
export const ServiceInfo = 'payment/serviceAddPrescription';
export const BillPayment = 'payment/billPaymentSubmit';
export const printBill = 'billing/printBill';
export const prescriptionService = 'prescription/printPrescription';
export const cancelBilling = 'billing/CancellationBilling';
export const visitStatus = 'patient/setPatientVisitStatus';
export const patientVisitStatus = 'patient/getPatientVisitStatus/';

export const serverUrl = environment.apiUrl;

export const vaccinesList = 'Vaccine/getVaccinesList';
export const vaccineFormData = 'Vaccine/vaccinationForm';
export const selectedVaccineInfo = 'Vaccine/getVaccinesList';
export const vaccinationFormPrint = 'Vaccine/PatientvaccinationPrint';
export const vaccinisedPatientsList = 'Vaccine/getPatientsVaccinationList';
export const existingRecords = 'Vaccine/vaccinationForm';

export const payeeNamesUrl = 'Billing/getPayeeInsuranceNames'

export const Outpatient_URL = {
    vaccinesList: serverUrl + vaccinesList,
    vaccineFormData: serverUrl + vaccineFormData,
    selectedVaccineInfo: serverUrl + selectedVaccineInfo,
    vaccinationFormPrint: serverUrl + vaccinationFormPrint,
    vaccinisedPatientsList: serverUrl + vaccinisedPatientsList,
    existingRecords: serverUrl + existingRecords,
    payeeNamesUrl: serverUrl + payeeNamesUrl
}