<div class="container-md border-secondary mt-3">
<div class="row mb-1">
  <div class="col-md-3 mbl-mr">
    <input  type="text" placeholder="Search by UMR/UHID No " class="form-control searchfont"
           name="searchUHID" [(ngModel)]="searchForm.UHIDNo" #UHIDNo="ngModel" #UHIDSearch
           [ngClass]="{ 'is-invalid-error': isemptySearch }"
           (keyup)="enterKeyTriggerFunction($event, UHIDSearch, '', '')">
  </div>
  <div class="col-md-4 mbl-mr">
    <input type="email" placeholder="Search by Email " class="form-control searchfont" name="searchEmail"
           [(ngModel)]="searchForm.email" #searchEmail="ngModel" #emailSearch
           [ngClass]="{ 'is-invalid-error': isemptySearch }"
           pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
           (keyup)="enterKeyTriggerFunction($event, '', emailSearch, '')">
    <span *ngIf="searchEmail.errors?.pattern" class="bg-warningtxt">Invalid email format</span>
  </div>
  <div class="col-md-4 mbl-mr">
    <input  type="number" class="form-control searchfont" #mobileSearch
           placeholder="Search by Mobile No" [ngClass]="{ 'is-invalid-error': isemptySearch }"
            pattern="^((\\+91-?)|0)?[0-9]{10}$" #searchMobile="ngModel" name="mobilesearch"
           [(ngModel)]="searchForm.mobileNumber" (keypress)="numberOnly($event, searchForm.mobileNumber)"
           aria-label="Recipient's username" aria-describedby="emailMobileSearch"
           (keyup)="enterKeyTriggerFunction($event, '', '', mobileSearch)" required>
    <span *ngIf="searchMobile.errors?.pattern" class="bg-warningtxt">Invalid phone format</span>
  </div>
  <div class="col-md-1">
    <div class="input-group-append mbl-search">
            <span class="input-group-text searchBtn"
                  (click)="assigningPatientProfile(UHIDSearch, emailSearch, mobileSearch)" id="emailMobileSearch"><i
              class="fas fa-search text-primary faic"></i></span>
    </div>
  </div>
</div>
  <hr>
  <div class="row pl-1">
    <div class="col-12">
      <form name="form" (ngSubmit)="f.form.valid && formFillEmitter()" #f="ngForm" novalidate autocomplete="off">
<div class="row">
  <div class="col-md-6">
    <div class="form-group row">
      <div class="col-4">
        <label for="umrorUhidNo" class="text-dark">UMR/UHID No :
        </label>
      </div>
      <div class="col-8">
        <input type="text" name="umrorUhidNo" class="form-control  rounded-0 bb"
               id="umrorUhidNo" [(ngModel)]="selectedPatient.patientID" readonly>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group row">
      <div class="col-4">
        <label for="title">Title : <span class="requiredSymbol">*</span></label>
      </div>
      <div class="col-8">
        <select class="form-control rounded-0 bb" (change)="changingGenderFields()"
                name="title" [(ngModel)]="selectedPatient.title" #title="ngModel"
                [ngClass]="{ 'is-invalid-error': f.submitted && title.invalid }" id="title" required>
          <option value="B/O." selected title="0 to 1 year boy and girl">B/O.</option>
          <option value="Master." title="1 to 12 years Boys">Master. </option>
          <option value="Miss." title="1 to 12 years Girls">Miss.</option>
          <option value="Mr." title="Above 13 years Boys">Mr.</option>
          <option value="Ms." title="Above 13 years Girls and Unmarried">Ms.</option>
          <option value="Mrs." title="For married women">Mrs.</option>
        </select>
        <span *ngIf="f.submitted && title.invalid" class="bg-warningtxt">Title is required</span>
      </div>
    </div>
  </div>
</div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-4">
                <label for="patientName" class="text-dark">Name :
                  <span class="requiredSymbol">*</span></label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control rounded-0 bb" name="patientName"
                       [(ngModel)]="selectedPatient.patientName" #patientName="ngModel" pattern="[a-zA-Z.(\s)]{5,}"
                       [ngClass]="{ 'is-invalid-error': f.submitted && patientName.invalid }" id="patientName" required>
                <div [hidden]="patientName.valid || patientName.pristine" class="bg-warningtxt">
                  <span *ngIf="!patientName.hasError('required')" class="bg-warningtxt">Name is required</span>
                  <span *ngIf="!patientName.hasError('pattern')" class="bg-warningtxt">Only alphabets are allowed</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <div class="col-4">
                <label for="patientmobNumberff" class="text-dark">Mobile No :
                  <span class="requiredSymbol">*</span>
                </label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control rounded-0  bb" name="mobile"
                       [(ngModel)]="selectedPatient.mobileNumber" #mobile="ngModel" #patMobile
                       (keypress)="numberOnly($event, selectedPatient.mobileNumber)"
                       (focusout)="searchingMultiplePatientRecords(patMobile, 'num')"
                       [ngClass]="{ 'is-invalid-error': f.submitted && mobile.invalid }" pattern="^((\\+91-?)|0)?[0-9]{10}$"
                       id="patientmobNumberff" required>
                <span *ngIf="f.submitted && selectedPatient.mobileNumber === ''" class="bg-warningtxt">Mobile is
                required.</span>
                <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Invalid mobile number</span>
              </div>
            </div>
          </div>
        </div>

<div class="row">
  <div class="col-md-6">
    <div class="form-group row">
    <div class="col-4">
      <label for="patientmobEmailrff" class="text-dark">Email :
      </label>
    </div>
    <div class="col-8">
      <input type="email" class="form-control rounded-0  bb" name="email" #patEmail
             [(ngModel)]="selectedPatient.emailId" #email="ngModel" (focusout)= "searchingMultiplePatientRecords(patEmail, 'email')"
             [ngClass]="{ 'is-invalid-error': f.submitted && email.invalid }"
             pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="patientmobEmailrff" >
      <span *ngIf="email.errors?.pattern" class="bg-warningtxt">Invalid email format</span>
    </div>
  </div>
  </div>
  <div class="col-md-6">
    <div class="form-group row">
    <div class="col-4">
      <label for="patientDobff">DOB :
      <span class="requiredSymbol">*</span>
    </label>
    </div>
    <div class="col-8">
      <input type="date" class="form-control rounded-0 bb"
             (input)="gettingAge(selectedPatient.dateOfBirth)" name="dateOfBirth"
             [(ngModel)]="selectedPatient.dateOfBirth" #dateOfBirth="ngModel" [max]="maxDate" [min]="minDate"
             [ngClass]="{ 'is-invalid-error': f.submitted && dateOfBirth.invalid }" placeholder="mm/dd/yyyy"
             (keyup)="checkInputDate($event)" id="patientDobff"  (click)="checkInputDate($event)" required>
      <span *ngIf="f.submitted && dateOfBirth.invalid" class="bg-warningtxt">DOB is required</span>
    </div>
  </div>
  </div>
</div>

        <!--<div class="row">
          <div class="col-md-3">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="umrorUhidNo" class="text-dark">UMR/UHID No :
              </label>
              <input type="text" name="umrorUhidNo" class="form-control  rounded-0 bb"
                     id="umrorUhidNo" [(ngModel)]="selectedPatient.patientID" readonly>

            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="title">Title : <span class="requiredSymbol">*</span></label>
              <select class="form-control rounded-0 bb" (change)="changingGenderFields()"
                      name="title" [(ngModel)]="selectedPatient.title" #title="ngModel"
                      [ngClass]="{ 'is-invalid-error': f.submitted && title.invalid }" id="title" required>
                <option value="B/O." selected title="0 to 1 year boy and girl">B/O.</option>
                <option value="Master." title="1 to 12 years Boys">Master. </option>
                <option value="Miss." title="1 to 12 years Girls">Miss.</option>
                <option value="Mr." title="Above 13 years Boys">Mr.</option>
                <option value="Ms." title="Above 13 years Girls and Unmarried">Ms.</option>
                <option value="Mrs." title="For married women">Mrs.</option>
              </select>
              <span *ngIf="f.submitted && title.invalid" class="bg-warningtxt">Title is required</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="patientName" class="text-dark">Name :
                <span class="requiredSymbol">*</span>
              </label>
              <input type="text" class="form-control rounded-0 bb" name="patientName"
                     [(ngModel)]="selectedPatient.patientName" #patientName="ngModel" pattern="[a-zA-Z][a-zA-Z ]+"
                     [ngClass]="{ 'is-invalid-error': f.submitted && patientName.invalid }" id="patientName" required>
              <div [hidden]="patientName.valid || patientName.pristine" class="bg-warningtxt">
                <span *ngIf="!patientName.hasError('required')" class="bg-warningtxt">Name is required</span>
                <span *ngIf="!patientName.hasError('pattern')" class="bg-warningtxt">Only alphabets are allowed</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="patientmobNumberff" class="text-dark">Mobile No:
                <span class="requiredSymbol">*</span>
              </label>
              <input type="text" class="form-control rounded-0  bb" name="mobile"
                [(ngModel)]="selectedPatient.mobileNumber" #mobile="ngModel"
                (keypress)="numberOnly($event, selectedPatient.mobileNumber)"
                [ngClass]="{ 'is-invalid-error': f.submitted && mobile.invalid }" pattern="^((\\+91-?)|0)?[0-9]{10}$"
                id="patientmobNumberff" required>
              <span *ngIf="f.submitted && selectedPatient.mobileNumber === ''" class="bg-warningtxt">Mobile is
                required.</span>
              <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Invalid mobile number</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="patientmobEmailrff" class="text-dark">Email :
              </label>
              <input type="email" class="form-control rounded-0  bb" name="email"
                [(ngModel)]="selectedPatient.emailId" #email="ngModel"
                [ngClass]="{ 'is-invalid-error': f.submitted && email.invalid }"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="patientmobEmailrff" >
              <span *ngIf="email.errors?.pattern" class="bg-warningtxt">Invalid email format</span>
            </div>
          </div>
      </div>-->

<!--      <div class="d-md-flex justify-content-between">-->
<!--        <div class="form-group twoInputssameLine w-50 mx-2 ">-->
<!--          <label for="patientfirstNameff" class="text-dark">First Name :-->
<!--            <span class="requiredSymbol">*</span>-->
<!--          </label>-->
<!--          <input type="text" class="form-control rounded-0 border border-primary" -->
<!--          name="firstName" [(ngModel)]="selectedPatient.firstName" #firstname="ngModel"-->
<!--            [ngClass]="{ 'is-invalid-error': f.submitted && firstname.invalid }"-->
<!--          id="patientfirstNameff" required>-->
<!--          <span *ngIf="f.submitted && firstname.invalid" class="bg-warningtxt">First Name is required</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="d-md-flex justify-content-between">-->
<!--        <div class="form-group twoInputssameLine w-50 mx-2 ">-->
<!--          <label for="patientLastNameff" class="text-dark">Last Name :-->
<!--            <span class="requiredSymbol">*</span>-->
<!--          </label>-->
<!--          <input type="text" class="form-control rounded-0 border border-primary"-->
<!--          name="lastName" [(ngModel)]="selectedPatient.lastName" #lastname="ngModel"-->
<!--            [ngClass]="{ 'is-invalid-error': f.submitted && lastname.invalid }"-->
<!--          id="patientLastNameff" required>-->
<!--          <span *ngIf="f.submitted && lastname.invalid" class="bg-warningtxt">Last Name is required</span>-->
<!--        </div>-->
<!--        <div class="form-group twoInputssameLine w-50 mx-2 ">-->
<!--          <label for="patientLastNameff" class="text-dark">Last Name :</label>-->
<!--          <input type="text" class="form-control rounded-0 border border-primary" id="patientLastNameff"  [value]="selectedPatient.patientName">-->
<!--        </div>-->
<!--      </div>-->

        <!--<div class="row">
          <div class="col-md-6">
        <div class="form-group twoInputssameLine mx-2 ">
            <label for="patientDobff">DOB:
              <span class="requiredSymbol">*</span>
            </label>
            <input type="date" class="form-control rounded-0  border border-primary" (input)="gettingAge(selectedPatient.dateOfBirth)"
            name="dateOfBirth" [(ngModel)]="selectedPatient.dateOfBirth" #dateOfBirth="ngModel" [max]="maxDate" [min]="minDate"
                [ngClass]="{ 'is-invalid-error': f.submitted && dateOfBirth.invalid }"
                placeholder="yyyy-mm-dd" (keyup)="checkInputDate($event)"
            id="patientDobff" required>
            <span *ngIf="f.submitted && dateOfBirth.invalid" class="bg-warningtxt">DOB is required</span>
        </div>
      </div>


     </div>-->
 <!-- <div class="row">
      <div class="col-md-6">
        <div class="form-group twoInputssameLine mx-2 ">
            <label for="patientDobff">Gender:
              <span class="requiredSymbol">*</span>
            </label>
        </div>
        <div class="form-group twoInputssameLine mx-2 mt15">
          <label class="radio-inline ml20" for="radio1">
            <input type="radio" class="form-check-input" id="radio1" name="gender" #forMale  [checked]="selectedPatient.gender === 'MALE'">Male
          </label>
          <label class="radio-inline ml70" for="radio2">
            <input type="radio" class="form-check-input" id="radio2" name="gender" #forFemale   [checked]="selectedPatient.gender === 'FEMALE'">Female
          </label>
        </div>
      </div>
    </div>-->

<div class="row">
  <div class="col-md-6">
    <div class="form-group row">
      <div class="col-4">
        <label for="patientDobff">Gender :
          <span class="requiredSymbol">*</span>
        </label>
      </div>
      <div class="col-8">
        <label class="radio-inline ml20" for="radio1">
          <input type="radio" class="form-check-input" id="radio1" value="MALE" name="gender"
                 (change)="changeRadioGender($event)" [ngModel]="selectedPatient.gender">Male
        </label>
        <label class="radio-inline ml70" for="radio2">
          <input type="radio" class="form-check-input" id="radio2" value="FEMALE" name="gender"
                 (change)="changeRadioGender($event)" [disabled]="selectedPatient.gender !== '' && selectedPatient.title !== 'B/O.'" [ngModel]="selectedPatient.gender">Female
        </label>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row">
      <div class="col-3">
        <label for="patientageff">Age :
          <span class="requiredSymbol">*</span>
        </label>
      </div>
      <div class="col-9">
        <div class="form-group twoInputssameLine mx-2 ">

          <div id="patientageff" class="form-row">
            <div class="form-group col-sm-4 d-flex">
              <div class="input-group-append width30">
                <span class="input-group-text borderrightnone" for="forYears">Y</span>
              </div>
              <input type="number" class="form-control " (input)="assigningYears($event)" placeholder="Years"
                     (keyup)="checkingByYear($event)" [min]="minYear" [max]="maxYear" width="2" size="4" id="forYears"
                     [value]="patientYears" [readOnly]="patientAvailable">
            </div>
            <!-- <div class="form-group col-sm-4">

               <label for="forYears"> :Y </label>
             </div>-->
            <div class="form-group col-sm-4 d-flex">
              <div class="input-group-append width30">
                <span class="input-group-text borderrightnone" for="forMonths">M</span>
              </div>
              <input type="number" class="form-control" (input)="assigningMonths($event)" placeholder="Months"
                     (keyup)="checkingByMonth($event)" [min]="minMonth" [max]="maxMonth" width="2" size="4"
                     id="forMonths" [value]="patientMonths" [readOnly]="patientAvailable">

            </div>
            <div class="form-group col-sm-4 d-flex">
              <div class="input-group-append width30">
                <span class="input-group-text borderrightnone" for="forMonths">D</span>
              </div>
              <input type="number" class="form-control" (input)="assigningDate($event)" placeholder="Days"
                     (keyup)="checkingByDay($event)" [min]="minDay" [max]="maxDay" width="2" size="4" id="forDays"
                     [value]="patientDays" [readOnly]="patientAvailable">
            </div>
          </div>
          <!--              <span *ngIf="f.submitted && dateOfBirth.invalid" class="bg-warningtxt">Age is required</span>-->
          <span *ngIf="dateError || monthError || yearError" class="bg-warningtxt">Select correct Title or DOB/Age</span>
        </div>
      </div>
    </div>

  </div>
</div>








        <div class="row">
          <!--<div class="col-md-3">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="patientDobff">DOB:
                <span class="requiredSymbol">*</span>
              </label>
              <input type="date" class="form-control rounded-0 bb"
                     (input)="gettingAge(selectedPatient.dateOfBirth)" name="dateOfBirth"
                     [(ngModel)]="selectedPatient.dateOfBirth" #dateOfBirth="ngModel" [max]="maxDate" [min]="minDate"
                     [ngClass]="{ 'is-invalid-error': f.submitted && dateOfBirth.invalid }" placeholder="mm/dd/yyyy"
                     (keyup)="checkInputDate($event)" id="patientDobff"  (click)="checkInputDate($event)" required>
              <span *ngIf="f.submitted && dateOfBirth.invalid" class="bg-warningtxt">DOB is required</span>
            </div>
          </div>-->
         <!-- <div class="col-md-3">
            <div class="form-group twoInputssameLine mx-2 ">
              <label for="patientDobff">Gender:
                <span class="requiredSymbol">*</span>
              </label>
            </div>
            <div class="form-group twoInputssameLine mx-2 mt15">
              <label class="radio-inline ml20" for="radio1">
                <input type="radio" class="form-check-input" id="radio1" value="MALE" name="gender"
                       (change)="changeRadioGender($event)" [ngModel]="selectedPatient.gender">Male
              </label>
              <label class="radio-inline ml70" for="radio2">
                <input type="radio" class="form-check-input" id="radio2" value="FEMALE" name="gender"
                       (change)="changeRadioGender($event)" [ngModel]="selectedPatient.gender">Female
              </label>
            </div>
          </div>-->

        </div>
      <!-- <div class="form-check-inline">
        <label class="form-check-label mr-5">Gender:
          <span class="requiredSymbol">*</span>
        </label>
        <label class="form-check-label" for="radio1">
          <input type="radio" class="form-check-input" id="radio1" name="gender" #forMale  [checked]="selectedPatient.gender === 'MALE'">Male
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="radio2">
          <input type="radio" class="form-check-input" id="radio2" name="gender" #forFemale   [checked]="selectedPatient.gender === 'FEMALE'">Female
        </label>
      </div> -->

      <div class="float-right">
        <!-- <a (click)="formFillEmitter()" class="btn btn-lg btn-primary w-50" style="border-radius: 24px">Proceed</a> -->
        <button class="btn btn-md cont m-2"  type="reset" (click)="resetData()">Reset</button>

        <button type="submit" class="btn btn-md cont  contt1" name="proceedInfo" id="proceedInfo">
            Proceed
        </button>
      </div>
    </form>
    </div>

<!--    Modal for Data not found-->

    <!--<div class="modal fade" id="dataNotFound">
      <div class="modal-dialog modal-sm modal-dialog-centered" >
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-content">

          <div class="modal-header text-primary d-flex font-weight-bold">Data Not Found</div>

          <div class="modal-body px-0 d-flex font-weight-bold">
            <div>Please fill all the fields and proceed</div>
          </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger btn-sm" data-dismiss="modal">Close</button>
        </div>
        </div>
      </div>
    </div>-->
    <button class="btn d-none" data-toggle="modal" data-target="#dataNotFound" #datanotFound></button>
    <div class="modal fade" id="dataNotFound">
      <div class="modal-dialog modal-md modal-confirm">
        <div class="modal-content">
          <div class="modal-header">
            <div class="icon-box">
              <i class="material-icons"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i></i>

            </div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
          </div>
          <div class="modal-body text-center">
            <h4>Something went wrong</h4>
            <p>Please fill all the fields and proceed</p>
            <button class="btn btn-primary" data-dismiss="modal">Go Back</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal" [class]="formDataLoaded ? 'forModalDisplay' : 'd-none'" >
      <div class="modal-dialog modal-sm modal-dialog-centered" >
        <div class="modal-content" style="background-color: #cbd8db">
          <div class="modal-body px-0 font-weight-bold text-center">
            <div class="font-weight-bold text-dark">Loading...</div>
            <div class="spinner-border text-primary"></div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #multipleRecordsModal let-modal>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-center">
            <h4 class="text-info">Multiple Records Found</h4>
          </div>
          <div class="modal-body p-0">
            <table class="table my-0 table-hover table-striped">
              <thead><tr class="text-center">
                <th>Name</th>
                <th>Gender</th>
                <th>D.O.B</th>
                <th>UMR No</th>
                <th>Mobile No</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              <tr class="handCursor text-center" *ngFor = "let patient of multipleRecords, let  i = index" (click)="selectPatientFromMultiple(patient, dataFillModal)">
                <td>{{patient.title}} {{patient.patientName}}</td>
                <td>{{patient.gender}}</td>
                <td>{{patient.dateOfBirth.split('-').reverse().join('-')}}</td>
                <td>{{patient.patientID}}</td>
                <td>{{patient.mobileNumber}}</td>
                <td>{{patient.emailId}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-sm btn-danger" type="button" (click)="modal.dismiss()">Close</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #dataFillModal let-modal>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-0">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="modal-title text-info">
              Confirmation
            </h3>
          </div>
          <div class="modal-body text-dark">
            Do you want to fill the form with Selected Patient Data?
          </div>
          <div class="modal-footer d-flex justify-content-around">
            <button class="btn btn-outline-success" (click)="assignSelectedPatient()">Yes, Fill it</button>
            <button class="btn btn-outline-danger" (click)="modal.dismiss()">No</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #duplicateRecordsMod let-modal>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content p-0 w-100">
          <div class="modal-header d-flex justify-content-center">
            <h4 class="modal-title text-info">Duplicate Records Found</h4>
          </div>
          <div class="modal-body p-0">
            <table class="table my-0 table-hover table-striped">
              <thead><tr class="text-center">
                <th>Name</th>
                <th>Gender</th>
                <th>D.O.B</th>
                <th>UMR No</th>
                <th>Mobile No</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              <tr class="handCursor text-center" *ngFor = "let patient of duplicateRecords, let  i = index">
                <td>{{patient.title}} {{patient.patientName}}</td>
                <td>{{patient.gender}}</td>
                <td>{{patient.dateOfBirth.split('-').reverse().join('-')}}</td>
                <td>{{patient.patientID}}</td>
                <td>{{patient.mobileNumber}}</td>
                <td>{{patient.emailId}}</td>
              </tr>
              </tbody>
            </table>
            <p class="text-danger mt-3"><strong>Note:</strong> Please select existing record or register with new mobile number</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-danger btn-sm" (click)="modal.dismiss('click')">Close</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

