<form [formGroup]="addReferralForm" (ngSubmit)="addReferral.form.valid && submitData()" #addReferral="ngForm">
    <div style="font-size: 16px;">

      <div class="row mt-3 mb-3">
          <label for="pname" class="col-md-5 font-weight-bold font">Referral Name
              <span class="requiredSymbol">*</span></label>
          <div class="col-md-7">
            <input type="text" class="form-control-sm form-control border border-primary" 
            name="rfname"
            formControlName="referralname"
            [ngClass]="{ 'is-invalid': addReferral.submitted && f.referralname.errors }"
              id="rfname" required>
            
            <div *ngIf="addReferral.submitted && f.referralname.errors" class="invalid-feedback">
              <div *ngIf="f.referralname.errors.required">Referral Name is required</div>
            </div>
          </div>
      </div>
      
      <div class="row mt-3 mb-3">
          <label class="col-md-5 font-weight-bold font">Qualification</label>
          <div class="col-md-7">
              <input type="text" class="form-control-sm form-control border border-primary" 
              name="qualification"
              id="qualification" 
              formControlName="qualification">
          </div>
      </div>
      
      <div class="row mt-3 mb-3">
        <label for="pname" class="col-md-5 font-weight-bold font">Area/HospitalName
            <span class="requiredSymbol">*</span></label>
        <div class="col-md-7">
          <input type="text" class="form-control-sm form-control border border-primary" 
          name="areaname"
          formControlName="areaname"
          [ngClass]="{ 'is-invalid': addReferral.submitted && f.areaname.errors }"
            id="areaname" required>
          
          <div *ngIf="addReferral.submitted && f.areaname.errors" class="invalid-feedback">
            <div *ngIf="f.areaname.errors.required">Area Name is required</div>
          </div>
        </div>
      </div>      

      <div class="row mt-3 mb-3">
        <label for="pname" class="col-md-5 font-weight-bold font">Referral Type
        <span class="requiredSymbol">*</span></label>
        <div class="col-md-7">
          <select class="form-control-sm form-control border border-primary" name="referralType"
            formControlName="referralType" 
            [ngClass]="{ 'is-invalid': addReferral.submitted && f.referralType.errors }" id="referralType">                    
            <option value="">Select Referral Type</option>
            <option *ngFor="let type of referralTypeList; let i = index" [ngValue]="type.referralTypeId">
              {{type.referralName}}
            </option>
          </select>
          <div *ngIf="addReferral.submitted && f.referralType.errors" class="invalid-feedback">
            <div *ngIf="f.referralType.errors.required">Referral Type is required</div>
          </div>
        </div>                                
      </div>
       
      <div class="row mt-3 mb-3">
        <label class="col-md-5 font-weight-bold font">Mobile Number
          <span class="requiredSymbol">*</span></label>
        <div class="col-md-7">
          <input type="number" class="form-control-sm form-control  border border-primary"
            name="mobileNo" formControlName="mobileNo" 
            [ngClass]="{ 'is-invalid': addReferral.submitted && f.mobileNo.errors }"
            id="mobileNo" maxlength="10" required pattern="^((\\+91-?)|0)?[0-9]{10}$">
            <div *ngIf="addReferral.submitted && f.mobileNo.touched && f.mobileNo.errors" class="invalid-feedback">
              <div *ngIf="f.mobileNo.errors.required">Mobile Number is required</div>
              <div *ngIf="f.mobileNo.errors.pattern">Mobile Number must be 10 characters</div>
              <div *ngIf="f.mobileNo.errors.maxlength">Mobile Number must be 10 characters</div>
            </div>
        </div>
      </div>

    </div>

    <div class="text-right mt-5 mb-1 mr-5">
      <button (click)="setValuestoEmpty()" type="button"
        class="btn mx-sm-2 resetSub mb-2 p-2 text-white"
        style="background-color: #173267; width: 80px">Reset</button>
      <button type="submit" class="btn mx-sm-2 resetSub mb-2 p-2 text-white"
        style="background-color: #173267; width: 80px" [disabled]="!addReferral.valid">Submit</button>
    </div>      
  </form>
