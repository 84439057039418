import { Injectable } from '@angular/core';
import {serverUrl} from '../constants/commonConstants';

@Injectable({
  providedIn: 'root'
})
export class UrlsettingService {
  public server: string;
  public loggedInUserName: string;
  public urlConstants: Map<string, string>;
  constructor() {
    this.server = serverUrl;
    this.urlConstants = new Map<string, string>();
  }

  // tslint:disable-next-line:typedef
  public setServerURL(url: string) {
    this.server = url;
    this.setURLConstants();
  }

  // tslint:disable-next-line:typedef
  public setLoggedInUserDN(username: string) {
    this.loggedInUserName = username;
  }

  // tslint:disable:max-line-length
  // tslint:disable-next-line:typedef
  public setURLConstants() {
    this.urlConstants.set('serverUrl', this.server);
    this.urlConstants.set('restServerUrl', this.server + '/igzolthealth/v1/' );
  }

  public getLoggedInUserDN(): string {
    return this.loggedInUserName;
  }

  public getURLConstants(): Map<string, string> {
    return this.urlConstants;
  }

  public getServerURL(): string {
    return this.server;
  }

}
