<div class="row">
  <div class="col-12">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">Patient Info</legend>
      <div class="row">
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Name </div>
            <div class="col"> {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> IP Number </div>
            <div class="col"> {{patientInfo?.IP_NR}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
            <div class="col"> {{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></div>
          </div>
        </div>

        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Gender/Age </div>
            <div class="col"> {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Admitting Doctor </div>
            <div class="col"> {{patientInfo?.DM_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Payee Name </div>
            <div class="col"> {{patientInfo?.PIM_NAME}} </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Referred By </div>
            <div class="col">{{patientInfo?.REFERRED_BY}} </div>
          </div>
        </div>
      </div>
    </fieldset>

  </div>
  <div class="col-12 mt-3">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">Pre Auth Form Details</legend>
      <form class="row" [formGroup]="preAuthForm" (ngSubmit)="onSubmit()">
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Pre Auth Sent On </div>
            <div class="col">
              <input type="datetime-local" class="form-control-sm form-control" placeholder="dd/mm/yyyy HH:mm"
                name="sentOn" formControlName="preauthSentOn" (change)="onChangeSentON($event)" [min]="sentONMin" [max]="sentONMax" id="sentOn">
              <span class="helpnote">
                Please make sure that date entered is not before admission date time and post current date time
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Pre Auth Sent To </div>
            <div class="col">
              <input type="text" id="sentTo" class="form-control form-control-sm" name="sentTo"
                formControlName="preauthSentTo">
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Estimation Submitted For PreAuth</div>
            <div class="col">
              <input type="text" id="estimationsubmittedFor" class="form-control form-control-sm"
                name="estimationsubmittedFor" formControlName="preauthEstimationSubmittedFor">
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Amount Approved </div>
            <div class="col">
              <input type="number" id="amtApproved" class="form-control form-control-sm" name="amtApproved"
                formControlName="amountApproved">
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Approval Status </div>
            <div class="col">

              <input type="radio" value="Approved" id="approvedStatus" name="approvedStatus"
                formControlName="approvedStatus"> Approved
              <input type="radio" value="Rejected" id="approvedStatus" name="approvedStatus"
                formControlName="approvedStatus"> Rejected
              <input type="radio" value="Query" id="approvedStatus" name="approvedStatus"
                formControlName="approvedStatus"> Query
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Information Received On </div>
            <div class="col">
              <input type="datetime-local" 
                     class="form-control-sm form-control" 
                     placeholder="dd/mm/yyyy HH:mm"
                     name="receivedon" 
                     formControlName="informationReceivedOn" 
                     [min]="receivedONMin" 
                     [max]="receivedONMax" 
                     id="receivedon">
              <span class="helpnote">
                Please make sure that date entered is not before Sent ON date time and post current date time
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">Payee Name</div>
            <div class="col">
              <select class="form-control rounded-0 bb"
                      id="payeeName"
                      name="payeeName" 
                      formControlName="payeeName" >
                <option value="">-- Select Payee Name --</option>
                <option *ngFor="let payeeName of insurancePayeeNames"
                        [ngValue]="payeeName.mpm_id">{{payeeName.main_payee_name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold"> Date of Discharge </div>
            <div class="col">
              <input type="datetime-local" class="form-control-sm form-control" placeholder="dd/mm/yyyy HH:mm"
                name="sentOn" formControlName="dateofdischarge" (change)="onChangeSentON($event)" [min]="sentONMin" [max]="sentONMax" id="sentOn">
              <span class="helpnote">
                Please make sure that date entered is not before admission date time and post current date time
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">Approval type</div>
            <div class="col">
              <select class="form-control rounded-0 bb"
                      id="approvalType"
                      name="approvalType" 
                      formControlName="approvalType" >
                <option value="">-- Select Approval type --</option>
                <option value="intialApproval"> Initial Approval </option>
                <option value="intrimApproval"> Intrim Approval </option>
                <option value="finalApproval"> Final Approval </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-3">
          <div class="row">
            <div class="col-5 font-weight-bold">Claim Number</div>
            <div class="col">
              <input type="text" id="claimNumber" class="form-control form-control-sm"
                name="claimNumber" formControlName="claimNumber">
            </div>
          </div>
        </div>
        <div class="col-12 mt-2 text-right">
          <button type="submit" class="btn btn-primary mr-2" [disabled]="!preAuthForm.valid">Submit</button>
        </div>

      </form>
    </fieldset>
  </div>
  <div *ngIf="preAuthList.length !== 0" class="col-12 mt-3">
    <fieldset class="legend-block">
      <legend class="legend-title mb-0 font-16">PreAuth Details List</legend>
      <div class="row border-bottom">
        <div class="col-md-2">
          <p class="font-weight-bold">Pre Auth Sent On</p>
        </div>
        <div class="col-md-2">
          <p class="font-weight-bold">Pre Auth Sent To</p>
        </div>
        <div class="col-md-2">
          <p class="font-weight-bold"> Estimation Submitted For PreAuth</p>
        </div>
        <div class="col-md-2">
          <p class="font-weight-bold">Amount Approved</p>
        </div>
        <div class="col-md-2">
          <p class="font-weight-bold">Approval Status</p>
        </div>
        <div class="col-md-2">
          <p class="font-weight-bold">Information Received On</p>
        </div>
      </div>
      <div class="row p-1 border-bottom" *ngFor="let item of preAuthList">
        <div class="col-md-2">
          {{item?.PRE_AUTH_SENT_ON}}
        </div>
        <div class="col-md-2">
          {{item?.PRE_AUTH_SENT_TO}}
        </div>
        <div class="col-md-2">
          {{item?.ESTIMATION_SUBMITTED_FOR_PRE_AUTH}}
        </div>
        <div class="col-md-2">
          {{item?.AMOUNT_APPROVED}}
        </div>
        <div class="col-md-2">
          {{item?.APPROVAL_STATUS}}
        </div>
        <div class="col-md-2">
          {{item?.INFORMATION_RECEIVED}}
        </div>
      </div>
    </fieldset>
  </div>

</div>