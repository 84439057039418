import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WARD_API_URLS } from './wardConstants';

@Injectable({
    providedIn: 'root'
})

export class WardService {

    public headers: any = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    constructor(private http: HttpClient) { }

    public getWardsList(): any {
        // const queryStr = 'https://igzolthealth.in/igzolt_dev_api/Api/ward_list';
        return this.http.post(WARD_API_URLS.WARD_LIST, '');
    }

    public getBedsList(id: number): any {
        const data = {
            ward_id: id
        };
        // const queryStr = 'https://igzolthealth.in/igzolt_dev_api/Api/ward_beds_list';
        return this.http.post(WARD_API_URLS.WARD_BEDS_LIST, data);
    }
    /** get the payee list by id */
    public getPayeeTypes(id, value): any {
        const data = {
            ptm_id: id,
            IS_OP_IP: value
        };
        // const queryStr = 'https://igzolthealth.in/igzolt_dev_api/Api/payeenameslist';
        return this.http.post(WARD_API_URLS.PAYEE_NAME_LIST, data);
    }

    public saveAdmisionDetails(inputRequest): any {
        // const queryStr = 'https://igzolthealth.in/igzolt_dev_api/Api/patient_addmission';
        return this.http.post(WARD_API_URLS.PATIENT_ADMISSION, inputRequest);
    }

    gettingPatientData(inputRequest): any {
        // const queryStr = 'https://igzolthealth.in/igzolt_dev_api/Api/ip_pateint_infodata';
        return this.http.post(WARD_API_URLS.IPPATIENT_INFO_DATA, inputRequest);
    }

    public insertIPAdmissionDetails(inputRequest) 
    {
        return this.http.post(WARD_API_URLS.INSERT_IP_ADMISSION_DETAILS, inputRequest);
    }

    public getAdmissionInformation(inputRequest)
    {
        return this.http.post(WARD_API_URLS.GET_ADMISSION_INFORMATION, inputRequest)
    }
    public existingPayeeInfo(data: any)
    {
        return this.http.post(WARD_API_URLS.GET_INPATIENT_PAYEE_INFO, data);
    }
    public existingPayeeNamesList(data: any)
    {
        return this.http.post(WARD_API_URLS.GET_EXISTING_PAYEENAMES_LIST, data);
    }
    public updatePayeeInfo(data: any){
        return this.http.post(WARD_API_URLS.UPDATE_PAYEE_NAMETO_PATIENT, data);
    }
    public getIpLabServiceList(data: any){
        return this.http.post(WARD_API_URLS.GET_IPLAB_SERVICE_LIST, data);
    }
    public getSmapleIdsByIpNr(data: any){
        return this.http.post(WARD_API_URLS.SAMPLEIDS_BY_IPNR, data);
    }    
    public getSubtestsByService(data: any){
        return this.http.post(WARD_API_URLS.GET_SUBTEST_BY_SERVICE, data);
    }
    public getExternalReferralsList(data: any){
        return this.http.post(WARD_API_URLS.GET_REFERRALS_LIST, data);
    }
    public updateExternalReferral(data: any){
        return this.http.post(WARD_API_URLS.EXTERNAL_REFERRAL_UPDATE, data);
    }
    public getPatientsList(data: any){
        return this.http.post(WARD_API_URLS.IP_PATIENTS_LIST, data);
    }
    public getPatientDataLogList(data: any){
        return this.http.post(WARD_API_URLS.DATA_LOG_LIST, data);
    }
}
