import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AdmissionFormInfo } from './types'
import { WardService } from '../../ward-management/ward.service';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-ipadmission-info',
  templateUrl: './ipadmission-info.component.html',
  styleUrls: ['./ipadmission-info.component.css']
})

export class IpadmissionInfoComponent implements OnInit {

  @Output() popupClose = new EventEmitter();
  @Input() patientInfo;
  public admissionFormValues:AdmissionFormInfo = {
    mlc                 : "Y",
    mlcNumber           : '',
    initialAssessment   : '',
    provisionDiagnosis  : '',
    surgicalMedical     : "medical",
    surgeryPostedOn     : "",
    surgeon             : "",
    surgeonAsst         : "",
    anesthetist         : "",
    technician          : "",
    plannedDischargeDate: "",
    createId            : "",
    createDatetime      : "",
    lastUpdatedOn       : "",
    lastUpdatedBy       : "",
    ipNr                : "",
    admissionDate       : ""
  };
  userInfo: any = {};
  public isFormReadonlyMode: boolean = false;

  constructor(private wardService: WardService,
              private toastrService: ToastrService,
              private tokenService: TokenStorageService,
              private commonService: CommonService) { 
                this.userInfo = this.tokenService.getUser();
              }

  ngOnInit(): void 
  {
    this.getAdmissionInformation(this.patientInfo.P_ID || this.patientInfo.UMRNO )
    this.admissionFormValues.admissionDate = this.dateFormat(this.patientInfo.IP_ADMISSION_DATE)
  }

  getAdmissionInformation(ipNrNumber)
  {
    const inputRequest = {
      ipNrNumber : ipNrNumber
    }
    this.wardService.getAdmissionInformation(inputRequest)
      .subscribe(
        (response: AdmissionFormInfo) =>
        {
          console.log("AdmissionFormInfo", response);
          this.isFormReadonlyMode = true;
          this.admissionFormValues = response
          this.admissionFormValues.surgeryPostedOn = this.dateFormat(response.surgeryPostedOn)
          this.admissionFormValues.plannedDischargeDate = this.dateFormat(response.plannedDischargeDate)
          this.admissionFormValues.admissionDate = this.dateFormat(this.patientInfo.IP_ADMISSION_DATE)
        },
        (err) =>
        {
          console.log(err.error, "getAdmissionInformation, ipadmission-info.component.ts")
        }
      )
  }

  dateFormat(date)
  {
    var formatedDate:string = ''
    if(date.split(',')){
      var splitDate = date.split(',')
      formatedDate = this.commonService.dateFormatForDatepicker(splitDate[0])
    }
    else {
      formatedDate = this.commonService.dateFormatForDatepicker(date)
    }

    return formatedDate + 'T00:00'
    
  }

  submitAdmissionFormDetails(): void 
  {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit',
      minute: '2-digit'
    })
    const currentDate = dateFormatter.format()
    const surgeryPostedOnDate: any = dateFormatter.format(new Date(this.admissionFormValues.surgeryPostedOn))
    const plannedDischargeDate: any = dateFormatter.format(new Date(this.admissionFormValues.plannedDischargeDate))
    this.admissionFormValues.ipNr = this.patientInfo.P_ID;
    this.admissionFormValues.surgeryPostedOn = surgeryPostedOnDate
    this.admissionFormValues.plannedDischargeDate = plannedDischargeDate    
    this.admissionFormValues.lastUpdatedOn = currentDate
    if(this.admissionFormValues.admissionInfoId)
    {
      this.admissionFormValues.lastUpdatedBy = this.userInfo.username      
    }
    else 
    {
      this.admissionFormValues.createId = this.userInfo.username
      this.admissionFormValues.createDatetime = currentDate
    }
    this.wardService.insertIPAdmissionDetails(this.admissionFormValues)
      .subscribe(
          (response) => {
            console.log(response);
            this.toastrService.success("Data submitted successfully");
            this.closePopup();
          },
          (err) => {
            this.toastrService.error("Something went wrong");
          }
      )
  }

  closePopup(): void
  {
    this.popupClose.emit();
  }

}
