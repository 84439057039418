<!--multisteps-form-->
<div *ngIf="isFormBlockActive" class="multisteps-form mt-4">
  <!--progress bar-->
  <div class="row">
    <div class="col-12 col-lg-8 ml-auto mr-auto mb-2">
      <div class="multisteps-form__progress">
        <button 
          [ngClass]="[widzetTabIndex == 0?'js-active':'']" 
          class="multisteps-form__progress-btn vitalSigns" 
          type="button" 
          title="Vital signs"
          (click)="tabAction($event, 0)">Vital signs</button>
        <button 
          [ngClass]="[widzetTabIndex == 1?'js-active':'']" 
          class="multisteps-form__progress-btn anthro" 
          type="button" 
          title="Anthro"
          (click)="tabAction($event, 1)">Anthro</button>
        <button 
          [ngClass]="[widzetTabIndex == 2?'js-active':'']" 
          class="multisteps-form__progress-btn chiefComplaint" 
          type="button" 
          title="Chief complaints and Past history"
          (click)="tabAction($event, 2)">Chief complaints and Past history</button>
        <button 
          [ngClass]="[widzetTabIndex == 3?'js-active':'']"
          class="multisteps-form__progress-btn treatmentAdvise" 
          type="button" 
          title="Treatment Advised"
          (click)="tabAction($event, 3)">Treatment Advised</button>
      </div>
    </div>
  </div>
  <!--form panels-->
  <div class="row mb-4">
    <div class="col-10 col-lg-8 m-auto multisteps-main__content">
      <form class="multisteps-form__form mb-4" (submit)="prescriptionFornSubmit()" autocomplete="off">
        <!-- Vital signs block -->
        <div 
          [ngClass]="[widzetTabIndex == 0?'js-active':'']" 
          class="multisteps-form__panel px-4" 
          data-animation="scaleIn">
          <div class="multisteps-form__content">
            <div class="form-row mt-4">
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Pulse : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Pulse"
                    name="patientPulst"
                    [(ngModel)]="patientPrescriptionInfo.pulse" />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Temp : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Temp"
                    name="temp"
                    [(ngModel)]="patientPrescriptionInfo.temp"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">B.P : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="B.P"
                    name="bp"
                    [(ngModel)]="patientPrescriptionInfo.bp"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">RR : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="RR"
                    name="rr"
                    [(ngModel)]="patientPrescriptionInfo.rr"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">SPO2 : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="SPO2"
                    name="spo2"
                    [(ngModel)]="patientPrescriptionInfo.spo2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Anthro block -->
        <div 
          [ngClass]="[widzetTabIndex == 1?'js-active':'']"
          class="multisteps-form__panel px-4" 
          data-animation="scaleIn">
          <div class="multisteps-form__content">
            <div class="form-row">
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">HC : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="HC"
                    name="hc"
                    [(ngModel)]="patientPrescriptionInfo.hc"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Weight : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Weight"
                    name="weight"
                    [(ngModel)]="patientPrescriptionInfo.weight"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Height : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Height"
                    name="height"
                    [(ngModel)]="patientPrescriptionInfo.height"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Pain score : 
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Pain score"
                    name="painScore"
                    [(ngModel)]="patientPrescriptionInfo.painScore"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- chief complaint block -->
        <div 
          [ngClass]="[widzetTabIndex == 2?'js-active':'']"
          class="multisteps-form__panel px-4" data-animation="scaleIn">
          <div class="multisteps-form__content">
            <div class="form-row">
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">O/E:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="O/E"
                    name="oe"
                    [(ngModel)]="patientPrescriptionInfo.oe" />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Provisional Diagnosis:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Provisional Diagnosis"
                    name="provisionalDiagnosis"
                    [(ngModel)]="patientPrescriptionInfo.provisionalDiagnosis"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Investigations Advised:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Investigations Advised" 
                    name="investigationsAdvised"
                    [(ngModel)]="patientPrescriptionInfo.investigationsAdvised"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Treatment advised block -->
        <div 
          [ngClass]="[widzetTabIndex == 3?'js-active':'']"
          class="multisteps-form__panel px-4" data-animation="scaleIn">
          <div class="multisteps-form__content">
            <div class="form-row">
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Dose:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Dose" 
                    name="dose"
                    [(ngModel)]="patientPrescriptionInfo.dose"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group mb-4 px-2">
                  <label for="" class="text-dark">Frequency:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Frequency" 
                    name="frequency"
                    [(ngModel)]="patientPrescriptionInfo.frequency"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Duration:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="Duration" 
                    name="duration"
                    [(ngModel)]="patientPrescriptionInfo.duration"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Patient Education and Nutritional Advise:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="" 
                    name="nutritionalAdvise"
                    [(ngModel)]="patientPrescriptionInfo.nutritionalAdvise"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group mb-2 px-2">
                  <label for="" class="text-dark">Next follow-up Date:
                    <span class="requiredSymbol">*</span>
                  </label>
                  <input 
                    class="multisteps-form__input form-control" 
                    type="text" 
                    placeholder="" 
                    name="nextFollowUpDate"
                    [(ngModel)]="patientPrescriptionInfo.nextFollowUpDate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Action buttons -->
        <div class="button-row d-flex justify-content-end mr-4 mt-2">
          <button 
            *ngIf="widzetTabIndex == 1 || widzetTabIndex == 2 || widzetTabIndex == 3" 
            class="btn btn-brand mr-2 px-3 py-1"
            type="button" 
            title="Prev"
            (click)="wizardPrev($event, widzetTabIndex)">Prev</button>
          <button 
            *ngIf="widzetTabIndex == 0 || widzetTabIndex == 1 || widzetTabIndex == 2" 
            class="btn btn-brand mr-2 px-3 py-1"
            type="button" 
            title="Next"
            (click)="wizardNext($event, widzetTabIndex)">Next</button>
          <button 
            *ngIf="widzetTabIndex == 3" 
            class="btn btn-success mr-2 px-3 py-1"
            type="submit" 
            title="Save">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="!isFormBlockActive">
  <div class="row">
    <div class="col-12 col-md-6 mx-auto prescription-info">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">Prescription Information</h3>
        </div>
        <div class="panel-body">
          <table class="table table-user-information">
            <tbody>
              <tr>
                <td> <b>Patient Information: </b></td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2">Patient Name: <span class="text-capitalize">{{patientInfo?.title}} {{patientInfo?.patientName}}</span></td>                
              </tr>
              <tr>                
                <td colspan="2">Doctor Name: Dr. {{patientInfo?.doctorName}}</td>
              </tr>
              <tr>
                <td colspan="2">Appointment Type: {{patientInfo?.patientAppointmentType}}</td>
              </tr>
              <tr>
                <td>UMR No: {{patientInfo?.patientID}}</td>
                <td></td>
              </tr>              
              <tr>
                <td><b>Vital Signs: </b></td>
                <td></td>
              </tr>
              <tr>
                <td>Pulse: {{patientPrescriptionInfo?.pulse}}</td>
                <td>Temp: {{patientPrescriptionInfo?.temp}}</td>
              </tr>
              <tr>
                <td>B.P: {{patientPrescriptionInfo?.bp}}</td>
                <td>RR: {{patientPrescriptionInfo?.rr}}</td>
              </tr>
              <tr>
                <td>SPO2: {{patientPrescriptionInfo?.spo2}}</td>
                <td></td>
              </tr>
              <tr>
                <td><b>Anthro: </b></td>
                <td></td>
              </tr>
              <tr>
                <td>HC: {{patientPrescriptionInfo?.hc}}</td>
                <td>Weight: {{patientPrescriptionInfo?.weight}}</td>
              </tr>
              <tr>
                <td>Height: {{patientPrescriptionInfo?.height}}</td>
                <td>Pain score: {{patientPrescriptionInfo?.painScore}}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Chief complaints and Past history: </b></td>
              </tr>
              <tr>
                <td>O/E: {{patientPrescriptionInfo?.oe}}</td>
                <td>Provisional Diagnosis: {{patientPrescriptionInfo?.provisionalDiagnosis}}</td>
              </tr>
              <tr>
                <td>Investigations Advised: {{patientPrescriptionInfo?.investigationsAdvised}}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2"><b>Treatment Advised: </b></td>
              </tr>
              <tr>
                <td>Dose: {{patientPrescriptionInfo?.dose}}</td>
                <td>Frequency: {{patientPrescriptionInfo?.frequency}}</td>
              </tr>
              <tr>
                <td>Duration: {{patientPrescriptionInfo?.duration}}</td>
                <td>Next follow-up Date: {{patientPrescriptionInfo?.nextFollowUpDate}}</td>
              </tr>
              <tr>
                <td colspan="2">Patient Education and Nutritional Advise: {{patientPrescriptionInfo?.nutritionalAdvise}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="my-4 text-right">
        <button 
          class="btn btn-info mr-2"
          (click)="formEdit()">
          <span><i class="fa fa-pencil-alt"></i></span> Edit
        </button>
        <button class="btn btn-success">
          <span><i class="fa fa-check"></i></span> Approve
        </button>
      </div>
    </div>
  </div>
</div>