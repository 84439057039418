import { Component, Input, OnInit } from '@angular/core';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { TokenStorageService } from '../loginservices/token-storage.service';

@Component({
  selector: 'app-patient-room-info',
  templateUrl: './patient-room-info.component.html',
  styleUrls: ['./patient-room-info.component.css']
})
export class PatientRoomInfoComponent implements OnInit {

  @Input() ipPatientNO: number;
  @Input() patientURMNo: number;

  userInfo: any = {};
  patientInfo: any = [];
  patientBedInfo: any;
  walkin = false;
  
  constructor(
    private inpatientService: InpatientService,
    private tokenService: TokenStorageService) { 
      this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    this.getPatientInfo();
    this.getBedInfo();
    // console.log("IPNO",this.ipPatientNO);
    // console.log("UMR", this.patientURMNo);
  }

  ngOnChanges():void {
    //this.getPatientInfo();
  }

  getPatientInfo(): void {

    const inputRequest = {

      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };
    this.inpatientService.ipPatientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        if(response.REFERRED_BY == "Walk-In")
        {
          this.walkin = true;
        }
        console.log("patient info", response );
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  getBedInfo(){
    const request = {
      hospitalId: this.userInfo.hospitalID,
      IpNo: this.ipPatientNO
    }
    this.inpatientService.getPatientBedInfo(request).subscribe( data => {
      this.patientBedInfo = data;
    });
  }
}
