
<div class="container-fluid p-0" style="overflow-x: hidden">
  <div class="w-100 m-0"  style="background-color: #173267">
    <app-searchbar (searchBarResultsEmit)="receivedSearchBarResults($event)"></app-searchbar>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4 float-left list_dr  scroll-1">
      <div class="frontside mt-3 ml-2" *ngFor="let doctor of docProfiles, let i = index">
        <div class="card" (click)="doctorSelection(i)"
          [ngClass]="{'doctor-active' : doctor.activeClass == true}">
          <div class="card-body p-3 text-center doctor-list">
            <p><img class=" img-fluid float-left list_img" src="assets/{{doctor.doctorName}}.png" alt="card image"></p>
            <h4 class="card-title mt-2 ">{{doctor.doctorTitle}} {{doctor["doctorName"]}}</h4>
            <p class="card-text" style="font-size: 15px; margin-bottom: 5px;">Experience:{{doctor["doctorExperience"]}}
              Years</p>
            <p class="card-text" style="font-size: 12px;">{{doctor["doctorSpecilization"]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-8 col-xl-8 col-sm-8  rightBoxContent">
      <div class="list_dr scroll-1" style="overflow-x: hidden;">
        <!--      <p>[ngClass]="{'d-none': !(displpayComponent==='formFilling')}"</p>-->
        <!--<app-homepage *ngIf="!displpayComponent"></app-homepage>-->
        <app-doctorinfo *ngIf="selectedDoctor && (displpayComponent==='doctorInfo')"
          [selectedDoctorProfile]="selectedDoctor" (doctorEmit)=" displpayComponent = 'formFilling'"></app-doctorinfo>
        <app-formfilling (formEmitter)="displpayComponent = 'bookingAppointment'"
          *ngIf="(displpayComponent==='formFilling')"></app-formfilling>
        <app-bookingappointment (detailsEmitter)="displpayComponent = 'formFilling'"
          (bookingEmit)="displpayComponent= 'confirmationMessage' " *ngIf="(displpayComponent==='bookingAppointment')">
        </app-bookingappointment>
        <app-confirmationmessage (confirmationEmitter)="displpayComponent = 'doctorInfo' "
          *ngIf="(displpayComponent==='confirmationMessage')"></app-confirmationmessage>
      </div>
    </div>
  </div>
</div>
