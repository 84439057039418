import { Component, ElementRef, OnInit, EventEmitter, QueryList, ViewChild, ViewChildren, Input, Output, OnChanges } from '@angular/core';
import { BillingService } from '../../billing-module/billing.service';
import { PaymentModes } from '../../billing-module/billingconstants';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxPrinterService } from 'ngx-printer';
import { CommonService } from '../services/common.service';

import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '../../print-management/preview/preview.component';

@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css'],
  providers: [DatePipe]
})

export class PaymentProcessComponent implements OnInit {
  myDate = new Date();
  public paymentModeList: any = [];
  public userInfo: any = {};
  // patientURMNo: number;
  // ipPatientNO: number;
  public patientInfo: any;
  cashMode: any;
  Creditdebit: any;
  gpayUPI: any;
  cheque: any;
  umrorUhidNo: any;
  bankName: any;
  mobileNumber: any;
  date: any;
  showErrorMessage = false;
  ipReceiptDetailsList;
  printPrescription;
  ipReceiptAmountInWords;

  isSubmitted = false;

  @ViewChildren('searchInput') searchInput: QueryList<ElementRef>;
  @ViewChild('transactionID') transactionID: ElementRef;
  @ViewChild('bank_Name') bank_Name: ElementRef;
  @ViewChild('mobileNum') mobileNum: ElementRef;

  @Input() ipPatientNO: number;
  @Input() patientURMNo: number;

  @Output() closePopup = new EventEmitter();

  constructor(private billingService: BillingService,
              private tokenService: TokenStorageService,
              private inpatientService: InpatientService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private toastrService: ToastrService,
              private printerService: NgxPrinterService,
              private commonService: CommonService,
              public dialog: MatDialog
  )
 {
    this.userInfo = this.tokenService.getUser();

    // const id = this.route.snapshot.paramMap.get('id');
    // this.route.queryParams.subscribe(params => {
    //   const urmNo = params.umrNumber;
    //   this.patientURMNo = parseInt(urmNo);
    // });

    // this.ipPatientNO = parseInt(id);
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }
  selectedValue: number;
  disableifCash = false;
  paymentModeType = '';

  paymentModes = [
    { id: 1, name: 'Cash', value: '', isSelected: false },
    { id: 2, name: 'Credit/Debit', value: '', isSelected: false },
    { id: 3, name: 'Google Pay/Phonepe/UPI', value: '', isSelected: false },
    { id: 4, name: 'Cheque/ECS/DD/NEFT/RTGS', value: '', isSelected: false }
  ];

  ngOnInit(): void {
    this.getPatientInfo();
  }

  closeModalPopup(): void {
    this.closePopup.emit(true);
  }

  getPatientInfo(): void {
    const inputRequest = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };
    console.log(inputRequest);
    this.inpatientService.patientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
     //   console.log("patient info", response)
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  selectedradioButton(data): void {

    this.paymentModes.forEach((e) => {
      e.isSelected = false;
      e.value = '';
    });

    data.isSelected = true;
    this.paymentModeType = data.name;

    if (data.id === 1) {
      this.transactionID.nativeElement.disabled  = true;
      this.bank_Name.nativeElement.disabled  = true;
      this.mobileNum.nativeElement.disabled  = true;
    } else {
      this.transactionID.nativeElement.disabled  = false;
      this.bank_Name.nativeElement.disabled  = false;
      this.mobileNum.nativeElement.disabled  = false;
    }

  }

  selectedInputValue(data): void {

    this.paymentModeType = data.name;
    this.selectedValue = data.value;
    if (data.id === 1) {
      this.cashMode = this.selectedValue;
      this.umrorUhidNo = '';
      this.bankName = '';
      this.mobileNumber = '';
      this.Creditdebit = '';
      this.gpayUPI = '';
      this.cheque = '';
    } else if (data.id === 2) {
      this.Creditdebit = this.selectedValue;
      this.cashMode = '';
      this.gpayUPI = '';
      this.cheque = '';
    } else if (data.id === 3) {
      this.gpayUPI = this.selectedValue;
      this.cashMode = '';
      this.Creditdebit = '';
      this.cheque = '';
    } else {
      this.cheque = this.selectedValue;
      this.cashMode = '';
      this.gpayUPI = '';
      this.Creditdebit = '';
    }

  }

  submit(): void {

    if (this.paymentModeType !== 'Cash' && (this.transactionID.nativeElement.value === ''
      || this.bank_Name.nativeElement.value === '' ||  this.mobileNum.nativeElement.value === '')) {
      this.showErrorMessage = true;
      this.toastrService.error('Please fill all required fields');
      return;
    }

    this.isSubmitted = true;

    let transactionReferenceId = '';
    if (this.cheque !== '') {
      transactionReferenceId = this.umrorUhidNo;
    }

    this.showErrorMessage = false;
    const inputRequest1 = {
      sear_ip_no: this.ipPatientNO,
      sear_h_id: this.userInfo.hospitalID,
      cash_amount: this.cashMode,
      card_amount: this.Creditdebit,
      wallets_amount: this.gpayUPI,
      banking_amount: this.cheque,
      bank_name: this.bankName,
      transaction_reference_id: transactionReferenceId,
      transaction_id: this.umrorUhidNo,
      banking_date: this.date,
      transaction_mobile_nr: this.mobileNumber,
      payment_mode_type: this.paymentModeType,
      payment_type: '1',
      create_name: this.userInfo.username
    };

    this.inpatientService.receiptList(inputRequest1).subscribe(
      (response) => {
        console.log('patient info', response);
        this.toastrService.success(response.message);

        // const input = {
        //   IpNr: this.ipPatientNO,
        //   HospitalId: this.userInfo.hospitalID,
        //   ReceiptNo : response.ReceiptNo
        // };
        // this.getIpReceiptDetails(input);
        this.printReceipt(response.ReceiptNo);

        this.closeModalPopup();

      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  printReceipt(receiptno): void {

    const ipnoC = this.ipPatientNO;
    const receiptnoC = receiptno;
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'receipt',
        ipno: ipnoC,
        receiptno: receiptnoC
      }
    });

    // const queryParam = { mode: 'receipt', ipno: this.ipPatientNO, receiptno: receipt.RECEIPT_NO };
    // this.router.navigate(['/' + 'printpreview'], { queryParams: queryParam });
    // this.router.navigate(['/' + 'printpreview'], { queryParams: queryParam }).then(result => {  window.open(result, '_blank'); });

    // const input = {
    //   IpNr: this.ipPatientNO,
    //   HospitalId: this.userInfo.hospitalID,
    //   ReceiptNo : receipt.RECEIPT_NO
    // };
    // this.getIpReceiptDetails(input);

  }

  /** get the pdf footer details */
  getIpReceiptDetails(inputRequest): void {

    this.inpatientService.ipReceiptDetails(inputRequest).subscribe((response) => {

      if (response !== null) {
        this.ipReceiptDetailsList = response;

        this.ipReceiptAmountInWords = this.commonService.toWords(
          Math.round(this.ipReceiptDetailsList.ReceiptAmt)
        );
         }

      this.printPrescription = true;

      setTimeout(() => {
        this.printerService.printDiv('print-prescription-section');
        this.closeModalPopup();
      }, 2000);

      setTimeout(() => {
        this.printPrescription = false;
      }, 3000);

    });

  }
}
