import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxPrinterService } from 'ngx-printer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { InpatientService } from '../../viewinpatient-module/inpatient.service';

import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { CommonService } from '../../shared/services/common.service';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  mode = 'empty';
  ipno = 0;
  receiptno = 0;
  umrno = 0;
  typeofbill = 'Summary';

  public userInfo: any = {};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private inpatientService: InpatientService,
              private tokenService: TokenStorageService,
              private printerService: NgxPrinterService,
              public dialogRef: MatDialogRef<PreviewComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public params: any) {

    this.userInfo = this.tokenService.getUser();

    this.mode = params.mode;

    this.ipno = (params.ipno) ? params.ipno : 0;
    this.receiptno = (params.receiptno) ? params.receiptno : 0;
    this.umrno = (params.umrno) ? params.umrno : 0;
    this.typeofbill = (params.typeofbill) ? params.typeofbill : 'Summary';

  }

  ngOnInit(): void {

    // this.printerService.printDiv('print-section');

  }

  printMe(): void {

    const shortName = this.userInfo.hospitalShortName;
    if (shortName === 'DPMTA' || shortName === 'KPMTA' || shortName === 'CPMTA' || shortName === 'JPMTA') {
      if (this.mode === 'summarybill') {
        if (this.typeofbill === 'Full Break') {
          const sheet = document.createElement('style');
          sheet.innerHTML = '@page { margin-top: 2in; margin-bottom: 0.8in}';
          document.getElementById('print-section').appendChild(sheet);
        }
      }
    }

    this.printerService.printDiv('print-section');

    if (this.mode === 'receipt') {
      this.updateReceiptPrintStatus();
    }

    if (this.mode === 'summarybill') {
      if (this.typeofbill === 'Full Break') {
        this.updateFullBreakPrintStatus();
      }
    }

  }

  updateFullBreakPrintStatus(): void {

    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
      IpNr: this.ipno
    };
    this.inpatientService.updateFullBreakPrintLabelStatus(inputRequest).subscribe((response) => {
      console.log(response);
    });

  }

  updateReceiptPrintStatus(): void {

    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
      IpNr: this.ipno,
      ReceiptPrintId: this.receiptno
    };
    this.inpatientService.updateReceiptPrintLabelStatus(inputRequest).subscribe((response) => {
      console.log(response);
    });

  }

  closeMe(): void {

    this.dialogRef.close();

  }

}
