import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WardHeaderComponent } from './ward-header/ward-header.component';
import { InpatientlistComponent } from './inpatientlist/inpatientlist.component';
import { IpadmissionComponent } from './ipadmission/ipadmission.component';
import {RouterModule, Routes} from '@angular/router';
import { NursingstaffComponent } from './nursingstaff/nursingstaff.component';
import { NursestationsComponent } from './nursestations/nursestations.component';
import { WardBedManagementComponent } from './ward-bed-management/ward-bed-management.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SharedModule } from '../shared/shared.module';
import { NgxEditorModule } from 'ngx-editor';
import { DischargeSummaryComponent } from './discharge-summary/discharge-summary.component';
import {AuthGuard} from '../shared/loginservices/auth.guard';
import { CKEditorModule } from 'ckeditor4-angular';
import { MasterManagementModule } from '../master-management/master-management.module';

const wardRoutes: Routes = [
  {path: 'ipAdmission', component: IpadmissionComponent, canActivate: [AuthGuard]},
  {path: 'inPatients-sample', component: InpatientlistComponent},
  {path: 'nursing-staff-mangement', component: NursingstaffComponent},
  {path: 'nursestation', component: NursestationsComponent, canActivate: [AuthGuard]},
  {path: 'dischargeSummary', component: DischargeSummaryComponent, canActivate: [AuthGuard]},
  {path: 'ward-bed-management', component: WardBedManagementComponent, canActivate: [AuthGuard]},
];

@NgModule({
  declarations: [
    WardHeaderComponent, 
    InpatientlistComponent, 
    IpadmissionComponent, 
    NursingstaffComponent, 
    NursestationsComponent, 
    WardBedManagementComponent, DischargeSummaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    SharedModule,
    CKEditorModule,
    MasterManagementModule,
    NgxEditorModule.forRoot({
      locals: {
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        url: 'false',
      }
    }),
    RouterModule.forChild(wardRoutes)
  ]
})
export class WardManagementModule { }
