<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">  
      <div class="row page-header">
          <div class="col-md-11 text-center p-0">
           <div>
            <h4 class="text-uppercase bold font-weight-bold orange-color">
            Inventory Management
            </h4>
           </div>
          </div> 
      </div>
      </div>
      </div>
