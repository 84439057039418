import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decideappointment',
  templateUrl: './decideappointment.component.html',
  styleUrls: ['./decideappointment.component.css']
})
export class DecideappointmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
