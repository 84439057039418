<div class="bg-light p-2 d-flex align-items-center" style="height: 100vh">

<div class="container bg-white h-75 pt-5 ">


    <p class="display-4 text-danger text-center mt-2 mb-md-5 mx-md-4 ">Cancel Appointment</p>


  <div class=" text-center">
    <p class="h3 text-dark"><span class="font-weight-normal">Patient Name: </span> Chandu</p>
    <p class="h3 text-dark "><span class="font-weight-normal">Mobile Number: </span> 9999999999</p>
  </div>


            <div class="my-lg-5 my-2 my-md-5">"
             <p class="text-secondary h5 text-center">Do you want to cancel the appointment ?</p>
              <div class="d-flex justify-content-center">
              <div class="d-flex btnGroup justify-content-around w-75 my-4">
                <a routerLink="/confirmed" class="btn btn-outline-danger btn-lg p-3"><h4>NO</h4></a>
                <a routerLink="/canceled" class="btn btn-outline-success btn-lg p-3"><h4>YES</h4></a>
              </div>
              </div>
            </div>
  </div>
</div>
