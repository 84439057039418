<app-ward-header></app-ward-header>
<div class="container-fluid">
  <div class="row justify-content-center text-center">
    <div class="col-md-3">
      <button class="ward-btn">
        Floor & Block Configuration
      </button>
    </div>
    <div class="col-md-3">
      <button class="ward-btn">
        Ward Configuration
      </button>
    </div>
    <div class="col-md-3">
      <button class="ward-btn">
        Room Configuration
      </button>

    </div>
    <div class="col-md-3">
      <button class="ward-btn">Bed Configuration</button>
    </div>
  </div>
</div>
