/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { NursestationsService } from '../nursestations.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { WardService } from '../ward.service';
import { Editor } from 'ngx-editor';
import {AppointmentService} from '../../appointments/appointment.service';
import { DOCTORS_LIST, DEPARTMENTS_LIST } from './../wardConstants';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import { SearchDepartments } from 'src/app/shared/appointmentsModels/searchDepartments';
import { ViewChild } from '@angular/core';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nursestations',
  templateUrl: './nursestations.component.html',
  styleUrls: ['./nursestations.component.css']
})
export class NursestationsComponent implements OnInit, OnDestroy {
  @ViewChild('doctorauto') doctorauto;

  editor: Editor;
  html: '';


  public show = false;
  public show1 = false;
  public show2 = false;
  public semiprivate = false;
  public privateward = false;
  public deluxward = false;
  public semiprivateform = false;
  public privatewardform = false;
  public deluxwardform = false;
  public nicu = false;
  public picu = false;
  public nicuform = false;
  public picuform = false;
  public  icu = false;
  public bmt = false;
  public icuform = false;
  public bmtform = false;
  public selectform1 = false;
  public selectform2 = false;
// Start here
  public userInfo: any = {};
  public floorType = 0;
  public wardType = false;
  public patientData = false;
  public wardsList: any = [];
  public roomBedsList: any = [];
  public inputPropertiesList: any;

  public selectedBed: any = {};
  public completeWardsList: any = [];
  public bedsList: any = [];
  public transferBedError: string = '';
  public bedTransferSubmitBtn:boolean = true;
  public bedCountInfo:any = [];  
  public departmentList:any = [];
  public addDoctorSubmitBtn:boolean = true;
  public doctorIdsList: any = [];
  doctorNameFilterKeyword = 'doctorName';
  floorsList: any = [];
  objectKeys = Object.keys;
  
  consultantFormData: any = {
    doctorId: '',
    departmentId: ''
  }
  itemExref: boolean = false;
  doctorList: any = [];
  filterDoctorList: any = [];
  doctorData: any = [];
  itemDoctor: boolean;
  patientIPNo: number = 0;
  patientUMRNo: number = 0;
  

  public transferFormData: any = {
    wardListId: '',
    patientBedId: ''
  }

  public dischargeSummaryFormData: any = {
    summaryInfo: ''
  }
  consultFormErrorMessage: boolean = false;
  doctorSbmtBtn: boolean;
  patientName: any;
  labreportsbtn: boolean = false;
  labreports: boolean = false;
  
  constructor(
    private router: Router,
    private tokenService: TokenStorageService,
    private appointmentService: AppointmentService,
    private nursestationsService: NursestationsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private wardService: WardService,
    private toastrService: ToastrService,
   ) {

    this.userInfo = this.tokenService.getUser();

  }

  ngOnInit(): void {
    const inutReq = {
      HospitalId: this.userInfo.hospitalID,
    };
    this.getFloorsList(inutReq);
    this.getAvailableBedsCount();    
    this.editor = new Editor();
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  getAvailableBedsCount() {
    const inutReq = {
      HospitalID: this.userInfo.hospitalID,
    };
    this.nursestationsService.getAvailableBedsCount(inutReq)
      .subscribe((response) => {
        this.bedCountInfo = response;
      });
  }

 /** get Floors list from B-end */
  getFloorsList(inutReq): void {
    this.nursestationsService.floorsList(inutReq).subscribe((response) => {
      this.floorsList = response;
    });
  }

   /** get Wards list from B-end */

  showWardsList(floorId): void {
    const wardReq = {
      HospitalId: this.userInfo.hospitalID,
      FloorId: floorId
    };
    this.nursestationsService.floorWardList(wardReq).subscribe((response) => {
      this.wardsList = response;
      this.floorType = floorId;
      this.wardType = false;
      this.patientData = false;
      this.labreports = false;
    });
  }

 /** get Ward Rooms list from B-end */
  showWardRoomsList(floorId, wardId): void {
    const roomReq = {
      HospitalId: this.userInfo.hospitalID,
      WardId: wardId
    };
    this.nursestationsService.wardRoomBedsList(roomReq).subscribe((response) => {
      this.roomBedsList = response;
      this.floorType = floorId;
      this.wardType = true;
      this.patientData = false;
      this.labreportsbtn = false;
    });
  }

  showPatientInfo(bed: any): void {
    if(bed.BedStatus !== 'ENABLED') {
      this.wardType = !this.wardType;
      this.patientData = !this.patientData;
      this.selectedBed = bed;
      this.patientName = this.selectedBed.PatientName;
      this.patientIPNo = bed.IpNr;
      this.patientUMRNo = bed.UMRNo;
    }
  }

  getLabReports(event){
    if(event){
    this.labreportsbtn = true;
    }else{
      this.labreportsbtn = false;
    }
     

  }
  // tslint:disable-next-line:typedef

  toggle1(showParms): any{
    this.wardType = !this.wardType;
    this.patientData = !this.patientData;
    this.labreportsbtn = false;
  }

  toggle4(showParms): any{
    this.wardType = !this.wardType;
    this.patientData = !this.patientData;
  }

  toggle7(showpars): any {
    if (showpars === 'selectform1'){
      this.selectform1 = true;
      this.selectform2 = false;
      this.privateward = false;
      this.semiprivate = false;
      this.semiprivateform = false;
      this.deluxward = false;
      this.nicu = false;
      this.nicuform = false;
      this.picu = false;
      this.picuform = false;
      this.icu = false;
      this.icuform = false;
      this.bmt = false;
      this.bmtform = false;
      this.privatewardform = false;
      this.deluxwardform = false;
    }
    else if ( showpars === 'selectform2'){
      this.selectform2 = true;
      this.selectform1 = false;
      this.privateward = false;
      this.semiprivate = false;
      this.semiprivateform = false;
      this.deluxward = false;
      this.nicu = false;
      this.nicuform = false;
      this.picu = false;
      this.picuform = false;
      this.icu = false;
      this.icuform = false;
      this.bmt = false;
      this.bmtform = false;
      this.privatewardform = false;
      this.deluxwardform = false;
    }
  }

  actionRouting(IpNr, UMRNo, routing) {
    const queryParam = { umrNumber: UMRNo };
    this.router.navigate(['/' + routing + '/' + IpNr], { queryParams: queryParam });
  }

  navigation(selectedBedData) {
    console.log(selectedBedData);
    this.nursestationsService.setSelectedBedUserInfo(selectedBedData);    
    this.router.navigate(['/dischargeSummary']);
  }  

  /**
   * open bed confirmaiton popup
   * @param bedConfirmPopup 
   */
  bedConfirmationPopUp(bedConfirmPopup) {
    this.modalService.open(bedConfirmPopup);
  }
  /**
   * open cross consultant popup
   * @param crsConsultantPopUp 
   */
   crossConsultantPopUp(crsConsultantPopUp,bedIplist: any) {
    this.addDoctorSubmitBtn = true;
    this.consultantFormData.departmentId = "";
    this.loadDoctorList(bedIplist);
    this.loadDepartmetList();
    this.modalService.open(crsConsultantPopUp,{ size: 'lg', backdrop: 'static' });
  }
  loadDoctorList(bedIplist) {
    const docInput = {
        "IpNr":bedIplist.IpNr, 
      "HospitalID": this.userInfo.hospitalID
      }
      console.log(bedIplist.IpNr);
      console.log(this.selectedBed.ConsultDoctorId);
      this.doctorData = [];
    this.nursestationsService.doctorsList(docInput)
    .subscribe((doctors: SearchDoctors[]) => {
      this.doctorList = doctors;
      console.log(doctors);
      this.doctorList.forEach(element => {
        this.doctorIdsList[element.doctorId]=element.doctorName;
        
      });
      this.filterDoctorList = this.doctorList.filter(
        obj => obj.doctorId !== this.selectedBed.ConsultDoctorId);
    });
  }

  loadDepartmetList(){
    const dpInput = {
      "HospitalID": this.userInfo.hospitalID
      }
      this.departmentList = [];         
      this.nursestationsService.departmentsList(dpInput).subscribe((departments: SearchDepartments[]) => {
      const departmentId: number[] = [];
      departments.forEach( dept => {
        if (departmentId.indexOf(dept.deptId) < 0) {
          this.departmentList.push(dept);
        }
        departmentId.push(dept.deptId);
      });
    });
  }
  doctorChange(doctorId: any): any {

    if (doctorId === '') {
      this.filterDoctorList = this.doctorList;
    } else {
      const docObj = this.doctorList.filter(obj => obj.doctorId === doctorId);
      if (docObj.length !== 0) {
        this.consultantFormData.departmentId = docObj[0].departmentId;
      }
    }
  }

  departmentChange(departmentId: any): any {

    if (departmentId === '') {
      this.filterDoctorList = this.doctorList;
    } else {
      this.filterDoctorList = this.doctorList.filter(obj => obj.departmentId === departmentId);
      this.consultantFormData.doctorId = '';
      this.doctorauto.clear();
    }
  }
  selectAutocompleteEvent(item: any, field: string): void {
    console.log('item', item);
    if (field === 'doctorname') {
      this.consultantFormData.doctorId = item.doctorId;
      this.doctorChange(item.doctorId);
    }
  }

  onRemoveSelected(item: any, field: string): void {
    if(field === 'doctorname') {
      this.consultantFormData.doctorId = null ;
      this.consultantFormData.departmentId = " ";
      this.itemDoctor = false;
    }    
  }
  submitConsultantFormDetails() {
    if(this.consultantFormData.doctorId){
      const inputRequest = {

        "IPNr": this.selectedBed.IpNr,
        "DeptId": this.consultantFormData.departmentId,
        "DmId": this.consultantFormData.doctorId,
        "DoctorName": this.doctorIdsList[this.consultantFormData.doctorId],
        "CreateName": this.userInfo.username,
        "ReferralId":0,
        "ReferralName":0
      }
      this.nursestationsService.crossConsultFormDetails(inputRequest)
        .subscribe((response) => {
          
            //console.log(this.consultantFormData.doctorId);
            this.modalService.dismissAll();
            this.consultantFormData = {
              doctorId: '',
              departmentId: ''
            }       
          });
          
        }else{
          this.consultFormErrorMessage = true;
        }
        
    }
    
    onChangeSearch(val: string): void {
      // return this.doctorsList.filter(option => option.DM_NAME.toLowerCase().includes(val));
    }
  
    onFocused(e): void {
      // do something when input is focused
    }  
    closeCrosConsultantPopup() {
      this.addDoctorSubmitBtn = true;
      this.consultantFormData.doctorId = "";
      this.consultantFormData.departmentId= "";
      this.modalService.dismissAll();
    }

  /**
   * bed confirmation
   * @param bedInfo 
   */
  bedConfirmation(bedInfo) {
    this.modalService.dismissAll();
    let inputRequest = {
      HospitalId: this.userInfo.hospitalID,
      IpNr: bedInfo.IpNr,
      BedConfirmationBtn: "Y",
      BedTransferBtn: "N",
      WmWardMId: bedInfo.WardId,
      WmWardRoomMId: "",
      WmBedMId: bedInfo.BedId
    }
    this.nursestationsService.bedConfirmation(inputRequest)
      .subscribe((response) => {
        this.showWardRoomsList(this.floorType, bedInfo.WardId);
      })
  }

  /**
   * 
   * @param bedTransferPopup 
   */
  bedTransferPopUp(bedTransferPopup) {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = "";
    this.transferFormData.patientBedId = "";
    this.getWardsList();
    this.modalService.open(bedTransferPopup);
  }
  /**
   * 
   */
  getWardsList() {
    this.wardService.getWardsList().subscribe((response) => {
      this.completeWardsList = response;
    });
  }

  changeWard(wardId): void {
    /* if(wardId === '') {
      this.bedsList = [];  
    }
    else {
      this.wardService.getBedsList(wardId).subscribe((response) => {
        if(response !=0){
        this.bedsList = response;
      });
    } */
    this.bedsList = [];
    this.wardService.getBedsList(wardId).subscribe((response) => {
      if(response !=0 ){
      this.bedsList = response;
      }else{
        this.toastrService.error("All beds are occupied in this ward");
      }
    });
  }
  /**
   * submitTransferFormDetails
   */
  submitTransferFormDetails() {
    if(this.bedTransferSubmitBtn) {
      this.bedTransferSubmitBtn = false
    }
    else {
      let roomId = this.bedsList.find((data) => {
        if(data.WM_BED_M_ID === this.transferFormData.patientBedId) {
          return data;
        }
      })
      let inputRequest = {
        HospitalId: this.userInfo.hospitalID,
        IpNr: this.selectedBed.IpNr,
        BedConfirmationBtn: "N",
        BedTransferBtn: "Y",
        CreateName: this.userInfo.username,
        WmWardMId: this.transferFormData.wardListId,
        WmWardRoomMId: roomId.WM_WARD_ROOM_M_ID,
        WmBedMId: this.transferFormData.patientBedId
      }
      this.nursestationsService.transferBed(inputRequest)
        .subscribe(
          (response) => {
            this.transferBedError = '';
            this.modalService.dismissAll();
            this.transferFormData = {
              wardListId: '',
              patientBedId: ''
            }
            this.showWardRoomsList(this.floorType, this.selectedBed.WardId);
          },
          (error) => {
            this.transferBedError = error.error.message;
            console.log("error123458", error.error.message)
          }
        )
    }
  }  

  closeBedTransferPopup() {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = "";
    this.transferFormData.patientBedId = "";
    this.modalService.dismissAll();
  }

  labReportsPopup(viewLabReportsPopup){
    this.modalService.open(viewLabReportsPopup, { size: 'xl', backdrop: 'static' });
  }
}
function bedIplist(bedIplist: any) {
  throw new Error('Function not implemented.');
}

