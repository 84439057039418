<div class=" bg-light container">
<div class="row p-3">
<div class=" docImg  col-md-4 ">
  <img src="assets/Dr.%20Mehul%20A%20Shah.jpg" class="rounded-circle border border-primary" >
</div>
  <div class="col-md-7 mt-lg-4 docDetails">

  <div class=" docData">
    <h3 class=" text-primary ml-3"> Dr Mehul A Shah</h3>
    <p class="h5 text-dark border-"> <span class="text-primary h3">&gt; </span> Qualification: M.D. PEDIATRICS (USA),DABPN - PEDIATRIC NEPHROLOGY (USA)</p>
    <p class="h5 text-dark"><span class="text-primary h3">&gt; </span> Speciality: Consultant - Pediatric Nephrology</p>
    <p class="h5 text-dark"><span class="text-primary h3">&gt; </span> Experience: 29 years </p>
    <p class="h5 text-dark"><span class="text-primary h3">&gt; </span> Languages: English, Hindi, Telugu and Gujarathi</p>

<!--  <div class="col-md-4 text-center mt-md-2">-->
<!--    <h4 class="text-primary"><i class="far fa-clock"></i> TIMING</h4>-->
<!--    <h5 class="text-danger"> (8:00 AM to 1:00 PM, 2:00 PM to 6:00 PM)</h5>-->
<!--    <button class="btn btn-danger mt-4" type="button" style="border-radius: 19px">BOOK APPOINTMENT</button>-->
<!--  </div>-->
</div>
  </div>
</div>


  <div class="mt-lg-4  px-0">
    <p class="text-center text-danger d-md-none h2">July</p>
    <div class="d-flex justify-content-between align-items-center calendarDate  mx-0 W-100" >
      <a href="#"  style="color: orangered !important; text-decoration: underline"> <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">25</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">SAT</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
      <a href="#"  > <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">26</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">SUN</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
      <a href="#"  > <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">27</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">MON</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
      <a href="#"  > <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">28</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">TUE</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
      <a href="#"  > <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">29</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">WED</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
      <a href="#"  > <span class="h5 font-weight-normal m-0 float-left" style="font-size: 50px">30</span>
        <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 20px"><span class="d-block mt-0">THU</span>
          <span class="d-block mt-0">JULY</span>
        </span>
      </a>
<!--      <a href="#" class="row "><span class="h5 font-weight-normal  col-6 m-0 " style="font-size: 60px">26</span>-->
<!--        <span class="col-6 m-0 h6 p-2"><span class="d-block mt-0">Sun</span>-->
<!--          <span class="d-block mt-0">july</span>-->
<!--        </span>-->
<!--      </a>-->
<!--      <a href="#" class="row font-weight-bold"> <span class="h5 font-weight-normal  col-6 m-0 " style="font-size: 60px">27</span>-->
<!--        <span class="col-6 m-0 h6 p-2"><span class="d-block mt-0">Mon</span>-->
<!--          <span class="d-block mt-0">july</span>-->
<!--        </span>-->
<!--      </a>-->
<!--      <a href="#" class="row font-weight-bold"> <span class="h5 font-weight-normal col-6 m-0 " style="font-size: 60px">28</span>-->
<!--        <span class="col-6 m-0 h6 p-2"><span class="d-block mt-0">Tue</span>-->
<!--          <span class="d-block mt-0">july</span>-->
<!--        </span>-->
<!--      </a>-->
<!--      <a href="#" class="row font-weight-bold"> <span class="h5 font-weight-normal col-6 m-0 " style="font-size: 60px">29</span>-->
<!--        <span class="col-6 m-0 h6 p-2"><span class="d-block mt-0">Wed</span>-->
<!--          <span class="d-block mt-0">july</span>-->
<!--        </span>-->
<!--      </a>-->
<!--      <a href="#" class="row font-weight-bold"> <span class="h5 font-weight-normal col-6 m-0 " style="font-size: 60px">30</span>-->
<!--        <span class="col-6 m-0 h6 p-2"><span class="d-block mt-0">Thu</span>-->
<!--          <span class="d-block mt-0">july</span>-->
<!--        </span>-->
<!--      </a>-->
      <button class="btn bg-light rounded-circle d-flex  align-items-center" style="height: 60px; text-align: center;" ><span class="font-weight-bold " style="font-size: 40px; color: #e0e0a6" >&gt;</span></button>
    </div>


    <div>
    <div class="row p-3">
      <div class="col-md-2 text-secondary d-flex align-items-center justify-content-center h5 ">Morning</div>
      <div class="col-md-12 d-inline-block">
        <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">10:30 - 10:45</a>
        <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">10:45 - 11:00</a>
        <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:00 - 11:15</a>
        <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:15 - 11:30</a>
        <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:30 - 11:45</a>
      </div>
    </div>
      <hr>
      <div class="row p-3">
        <div class="col-md-2 text-secondary h5 d-flex align-items-center justify-content-center">Afternoon</div>
        <div class="col-md-10 d-inline-block">
          <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">10:30 - 10:45</a>
          <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">10:45 - 11:00</a>
          <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:00 - 11:15</a>
          <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:15 - 11:30</a>
          <a routerLink="/patient_profile" class="btn btn-outline-primary m-3" style="border-radius: 10px">11:30 - 11:45</a>
        </div>
      </div>

      <hr>

      <div class="row p-5">
        <div class="col-md-2 text-secondary h5 d-flex align-items-center justify-content-center">Evening</div>
        <div class="col-md-10 d-inline-block">
         <span class="h5"> No slots Available </span>
        </div>
      </div>

    </div>


  </div>
</div>
