import { Component, OnInit, ViewChild, ElementRef, Type, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InpatientService } from '../inpatient.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
interface IsericePopupInfo {
  title: string | null;
  patient: any | null;
}
@Component({
  selector: 'app-services-charged',
  templateUrl: './services-charged.component.html',
  styleUrls: ['./services-charged.component.css']
})
export class ServicesChargedComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // columnsToDisplay = ['SNO', 'CREATED_BY', 'SERVICE_NAME', 'UNIT', 'SERVICE_TYPE_NAME', 'SM_ID', 'SERVICE_PRESCRIBED_BY_DOCTOR', 'REMARKS', 'PRIORITY', 'BILL_PROCESSED'];
  columnsToDisplay = ['SNO', 'CREATED_BY', 'SERVICE_NAME', 'UNIT', 'SERVICE_TYPE_NAME', 'SM_ID', 'SERVICE_PRESCRIBED_BY_DOCTOR', 'BILL_PROCESSED'];

  patientServiceList: any = [];
  patientServicesData = new MatTableDataSource(this.patientServiceList);

  public patientInfo: any;
  public ipPatientNO = 0;
  public userInfo: any = {};
  dataArray: any = [];
  patientURMNo: number;

  sericePopupInfo: IsericePopupInfo = {
    title: null,
    patient: null
  };

  holdServiceStatus = 'N';
  finalBillStatus = 'N';

  public rolesInfo: any = [];

  returnMode = '';

  constructor(private inpatientService: InpatientService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private toastrService: ToastrService,
              private tokenService: TokenStorageService,
              private confirmDialogService: ConfirmDialogService) {

    if (this.tokenService.getToken()) {
      this.rolesInfo = this.tokenService.getUser().rolesInfo;
    }

    this.userInfo = this.tokenService.getUser();

    const id = this.route.snapshot.paramMap.get('id');
    // tslint:disable-next-line:radix
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;
      // tslint:disable-next-line:radix
      this.patientURMNo = parseInt(urmNo);

      this.returnMode = params.mode;

    });

  }

  ngOnInit(): void {
    this.getPatientInfo();
    this.getDateWiseServiceChargedList();
    this.holdServiceBillingStatus();
  }

  /**
   * get patient info
   */
  getPatientInfo(): void {

    const search = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.patientInfo(search).subscribe((response) => {
      this.patientInfo = response;
    });

  }
  /**
   * get patient service list
   */
  getDateWiseServiceChargedList(): void {

    const search = {
      IpNr: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID
    };

    this.inpatientService.dateWiseServiceChargedList(search).subscribe((response) => {
      this.patientServiceList = response;
      this.patientServiceList.forEach((index) => {
       this.dataArray.push(index.DateWiseServices);
      });

      this.patientServicesData = new MatTableDataSource(this.dataArray);
      this.patientServicesData.sort = this.sort;
      this.patientServicesData.paginator = this.paginator;
    });
  }

  holdServiceBillingStatus(): void {

    const search = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.holdServiceBillingStatus(search).subscribe((response) => {
      this.holdServiceStatus = response.HoldServiceStatus;
      this.finalBillStatus = response.FinalBillStatus;
    });

  }
  navigate() {
    const queryParam = { mode: 'dischargelist' };
    if (this.returnMode === 'iplist') {
      this.router.navigate(['/Inpatient']);
    } else if(this.returnMode == 'dischargelist'){     
      this.router.navigate(['/Inpatient'], {  queryParams: queryParam });
    } else{
      this.router.navigate(['/nursestation']);
    }
  }
  gotoNav(): void {
    const queryParam = { umrNumber: this.patientURMNo, mode: this.returnMode };
    this.router.navigate(['/BillSummary/' + this.patientInfo.IP_NR], { queryParams: queryParam });

  }

}
