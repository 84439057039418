<div class="cmBody  d-flex align-items-center" >
  <div class="container-md  h-75">
    <p class="h3 text-danger mb-5 text-center mt-2">YOUR APPOINTMENT IS CANCELLED !</p>
    <div class="row align-content-center mt-md-5">
      <div class="col-sm-5 col-md-4 text-center mt-md-5">
        <img src="./assets/calendar1.PNG" class="img-fluid cmIcon mw-100 text-danger" alt="Cancellation Icon">
       </div>
      <div class="col-md-8 col-sm-7 mt-md-5">
        <p class="h4 text-dark font-weight-bolder mt-5" >Your Appointment ID <span class="h4 font-weight-bolder"> 123456 </span> has been cancelled </p>
        <div class="text-center">
           <a routerLink="/" class="btn btn-primary btn-lg text-center my-5"><i class="fas fa-home mx-2"></i>Home</a>
        </div>
      </div>
    </div>

  </div>
</div>
