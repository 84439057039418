import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialisation',
  templateUrl: './specialisation.component.html',
  styleUrls: ['./specialisation.component.css']
})
export class SpecialisationComponent implements OnInit {
  docProfiles: {}[] = [
    {
      name: 'Dr. Satish Ghanta',
      specialisation: 'Pediatric',
      experience: 26,
      description: 'Dr. Satish Ghanta is vastly experienced with a demonstrated history of working in the hospital & health care industry for the last 26 years. He is skilled in Pediatric Advanced Life Support (PALS), Pediatric Intensive Care (Sydney Childrens Hospital), Neonatal Intensive Care (Royal Hospital for Women, Sydney) and Developmental & General Pediatrics. He is also the CEO & Director of Choice Foundation for the last 8 years, which is focused on curative and preventive medicine for children between 0-18 years.',
      imagepath: 'assets/Dr.%20Satish%20Ghanta.png'
    },
    {
      name: 'Dr. Dr Venkata Ramana Dandamudi',
      specialisation: 'Pediatric',
      experience: 36,
      description: 'Full time Consultant and Head of The Department Pediatric Haemato-Oncology, Little Stars Children’s Hospital, Hyderabad. Worked in Pediatric Hematology, Oncology and Bone Marrow Transplant units in various internationally reputed hospitals (U.K). Worked in Pediatric Oncology in Royal Manchester Children’s hospital and Manchester University for three years.Returned to India in 1998 and established practice in Pediatric Hematology and Oncology at Hyderabad . Has established facilities to treat childhood cancers and blood disorders over the last 20 years. Treated the largest number children with cancers with excellent results. Has done lot of community work in areas of Thalassemia and Haemophilia. Has been instrumental in lifting quality of life of children with chronic blood disorders like thalassemia, sickle cell anemia and haemophilia.',
      imagepath: 'assets/Dr.%20Ramana%20Dandamudi.png'
    },
    {
      name: 'Dr Mehul A Shah',
      specialisation: 'Pediatric Nephrology',
      experience: 29,
      description: 'He is one of the few trained Pediatric Nephrologists in the country. He has been practicing Pediatric Nephrology exclusively for the past 15 years in Hyderabad.He is a Full-time Chief Consultant Pediatric Nephrologist at Little Stars Children’s Hospital., he has managed a number of challenging young children with tubular disorders, performed hemodialysis in young children weighing ~10 kg, and performed many pediatric kidney transplants.',
      imagepath: 'assets/Dr.%20Mehul%20A%20Shah.png'
    },
    {
      name: 'Dr. Ankush Singh',
      specialisation: 'Pediatric Neurology',
      experience: 16,
      description: 'Dr. Ankush Singh leads the Department of Pediatric Neurology and Neuro - Rehabilitative Services. He did his MD in Pediatrics in 2008 and then his DM in Pediatric Neurology from the prestigious AIIMS, New Delhi. He has undertaken advanced training and has experience in evaluating and managing a wide range of neurological disorders in children.',
      imagepath: 'assets/Dr.%20Ankush%20Singh1.png'
    },
    {
      name: 'Dr. M.Rajesh Reddy',
      specialisation: 'Pediatrics & Neonatology',
      experience: 10,
      description: 'Dr. M. Rajesh Reddy is a Consultant Pediatrician & Neonatologist in Little Star Children’s Hospital, Panjagutta, Hyderabad. He has an experience of 10 years in this field. He had completed MBBS from Dr.NTR University of Health Sciences in 2008 and Diploma in Child Health (DCH) in 2013. He had also completed Fellowship in Neonatolgy(NNF) in 2015. He underwent training in NRP & PALS. He was also trained in Bayley Scales of Infant and Toddler Development. He has been Practicing as Consultant Pediatrician and Intensivist in Little Star Children’s Hospital for past 5 years. He also worked as Intensivist in AIG hospitals, looked after Liver Transplant Children. His areas of interest are General Pediatrics, Growth and Development, Behavioural problems, Allergy and Atopy in children.',
      imagepath: 'assets/Rajeshreddy.png'
    },
    {
      name: 'Maya Chandrashekaran',
      specialisation: 'Psychology',
      experience: 3.5,
      description: 'Ms. Maya skills include working with children and their families, to help them to understand and identify different psychological conditions including – cognitive, learning, emotional and behavioral aspects; help them to cope and overcome these using techniques and treatment plans designed for them. In other areas, to help them have a good intra-personal and interpersonal relationships and in developing adaptation skills, life skills, social skill, and motivational techniques that are appropriate.',
      imagepath: 'assets/Ms.%20Maya%20Chandrashekaran1.jpg'
    },
    {
      name: 'Basudha Bandyopadhyay',
      specialisation: 'Psychology',
      experience: 4.5,
      description: 'Ms. Basudha skilled at working with children and adoloscents mental health and well-being, this includes children with developmental disabilities & their family, behavioral issues, anxiety, depression, adjustmental problems, identity & personality issues, stress due to academics and other specific areas, dependency over electronic gadgets etc, and planning out appropriate psychological interventions to help the individual to function to their fullest potential. She is also experienced in working with adults having problems in their personal life, occupational stress and lifestyle.',
      imagepath: 'assets/Ms.%20Basudha.png'
    },
    {
      name: 'Ms. Keturah David',
      specialisation: 'Psychology',
      experience: 5.7,
      description: 'Keturah David has been working with children for the last 5 years, specifically trained in diagnosing and managing Neuro-Developmental Disorders like Autism, ADHD, Learning Disabilities, etc., She is also trained for parental counseling and has a keen interest is helping the children suffering and their parent have a better quality of life. She easily establishes rapport with children.',
      imagepath: 'assets/Ms.%20Keturah.png'
    },
    {
      name: 'Dr. Soumyakanta Sahu',
      specialisation: 'Occupational Therapy',
      experience: 7,
      description: 'In last 7 years he  is skilled and experienced in paediatric and adult neuro-rehabilitation, early intervention, social/school integration, industrial/ office ergonomics, enviormental safety management skills, assistive/suportive devices and technology.  He has 5 research publications in national and international journals.',
      imagepath: 'assets/Mr.%20S%20Soumyakanta%20sahu.png'
    },
    {
      name: 'Ms. Debajani Mohapatra',
      specialisation: 'Occupational Therapy',
      experience: 1.7,
      description: 'In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, .',
      imagepath: 'assets/Ms.%20Debjani.png'
    },
    {
      name: 'Bavani Thonukunoori',
      specialisation: 'Remedial Therapy',
      experience: 12,
      description: '"Bhavani is Remedial Therapist & Psychological Counselor with over 12 years of experience spanning across various schools, Non-Profit organizations and Hospitals.',
      imagepath: 'assets/Ms.%20Bhavani.png'
    },
    {
      name: 'Dr.Omkari Preethi ',
      specialisation: 'Physiotherapy',
      experience: 4,
      description: 'Mrs. Preeti wants to help children to achieve their optimal physical development. She has specialized knowledge in the movement, development and conditions that are likely to affect the baby and growing child and treat from 1-day-old babies to adolescents.',
      imagepath: 'assets/Dr.%20Preethi.png'
    },
    {
      name: 'A. Naveen Goud',
      specialisation: 'Speech Therapy',
      experience: 4,
      description: 'About.... Speech-language work to prevent assess diagnose and treat speech, language ,social communication, cognitive communication   disorders articulation, fluency, resonance, receptive, expressive disorder in children and adults. when a patient has trouble with the social use of verbal and nonverbal communication and aphasia brain disorders and dysarthria there are several speech and language disorders assessing and treating individual with various communication disorders developing speech programs for disabled youth of various ages.',
      imagepath: 'assets/Mr.%20S%20Soumyakanta%20sahu.png'
    },
    {
      name: 'K V Narayana',
      specialisation: 'Speech & Audiology',
      experience: 8,
      description: '1.Providing customized and effective communication skills instruction to patients in a variety of settings—including schools, hospitals, and mental health locations—while educating both patients and caregivers in successful home communication strategies.2.Assessing and treating individuals with various communication disorders caused by traumas such as such as aphasia, mild brain injury, and right hemisphere stroke.3.Monitoring individuals’ progress and determining appropriate future treatment plans to ensure optimal learning experiences, rehabilitation, and recovery.4.Demonstrating superior organizational and communication skills while delivering outstanding levels of compassion and care to patients.5.Developing Speech Programs for Disabled Youth of Various Ages.6. Conducting Parent Workshops.',
      imagepath: 'assets/Mr.%20KV%20Narayana.png'
    },

  ];
  constructor() { }

  ngOnInit(): void {
  }

}
