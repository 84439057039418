import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nursingstaff',
  templateUrl: './nursingstaff.component.html',
  styleUrls: ['./nursingstaff.component.css']
})
export class NursingstaffComponent implements OnInit {
  public show = false;
  public buttonName = 'Select';
  constructor() { }

  ngOnInit(): void {
  }
  toggle(): any {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Select';
    }
    else{
      this.buttonName = 'Select';
    }
  }
}
