import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-rule',
  templateUrl: './billing-rule.component.html',
  styleUrls: ['./billing-rule.component.css']
})
export class BillingRuleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
