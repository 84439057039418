/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-dupe-else-if */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ViewAppointmentsModel} from '../../shared/appointmentsModels/viewAppointmentsModel';
import { ToastNoAnimation, ToastrService } from 'ngx-toastr';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {AppointmentService} from '../../appointments/appointment.service';
import { AppointmentPatientSearch,
         AppointmentSearchDoctor,
         AppointmentDepartmentsList,
         ExternalReferralList } from '../../appointments/appointmentconstants';
import {BillingService} from '../../billing-module/billing.service';
import { PatientRecordsByVisitID} from '../../billing-module/billingconstants';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import {SearchDepartments} from '../../shared/appointmentsModels/searchDepartments';
import { WardService } from '../ward.service';
import {ActivatedRoute, Router} from '@angular/router';
import { CommonService } from '../../shared/services/common.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NursestationsService } from '../nursestations.service';
import { DOCTORS_LIST, DEPARTMENTS_LIST } from './../wardConstants';

@Component({
  selector: 'app-ipadmission',
  templateUrl: './ipadmission.component.html',
  styleUrls: ['./ipadmission.component.css']
})
export class IpadmissionComponent implements OnInit {

  @ViewChild('doctorauto') doctorauto;
  @ViewChild('payeename') payeename;
  @ViewChild('ipAdmissionInformationPopup') private ipAdmissionInformationPopup: ElementRef;
  @ViewChild('updatePayeeSponsorPopup') private updatePayeeSponsorPopup: ElementRef;
  @ViewChild('addReferralPopup') private addReferralPopup: ElementRef;
  @ViewChild('externalReferralPopup') private externalReferralPopup: ElementRef;
  public ipPatientNO : number;
  public show = true;
  public buttonName = 'Admission';
  public editOption = false;
  public editButton = false;
  public patientID = '';
  public arrayIndex = 0;
  public completeWardsList: any = [];
  public bedTransferSubmitBtn:boolean = true;
  public selectedBed: any = {};
  public transferBedError: string = '';
  public floorType = 0;
  public wardType = false;
  public roomBedsList: any = [];
  public patientData = false;

  public addDoctorSubmitBtn:boolean = true;
  public doctorIdsList: any = [];

  UMRvalue: number = null;
  nameValue: string = null;
  mobileValue: string = null;
  toggleOnOff = 'nothing';
  dataLoaded = false;
  selectedVisitId: number;
  addVisitAvailable = false;
  appointmentsData: ViewAppointmentsModel[];
  patientsData: PatientProfile[] = [];
  selectedVisitIdArray: any = {};
  addVisitArray: boolean[] = [];
  toDayDate = new Date();
  filterDoctorList: any = [];
  doctorData: any = null;
  userInfo: any = {};
  doctorList: any = [];
  departmentList: SearchDepartments[];
  wardsList: any = null;
  bedsList: any = null;
  doctorNdepAvailable = false;
  payeeNames: any = [];
  extRefList: any = [];
  extReferalList: string[] = [];
  patientDetails: any = null;
  doctorNameFilterKeyword = 'doctorName';
  externalReferenceKeyword = 'doctorName';
  nameKeyword = 'PIM_NAME';

  itemDoctor: boolean;
  consultFormErrorMessage: boolean = false;

  public transferFormData: any = {
    wardListId: '',
    patientBedId: ''
  }

  public consultantFormData = {
    doctorId: '',
    departmentId: ''
  }
  
  /** get Ward Rooms list from B-end */
  showWardRoomsList(floorId, wardId): void {
    const roomReq = {
      HospitalId: this.userInfo.hospitalID,
      WardId: wardId
    };
    this.nursestationsService.wardRoomBedsList(roomReq).subscribe((response) => {

      this.roomBedsList = response;
      this.floorType = floorId;
      this.wardType = true;
      this.patientData = false;
    });
  }

  public patientRelationList = [
                          {
                            vlaue: 'Father',
                            relationName: 'Father'
                          },
                          {
                            vlaue: 'Mother',
                            relationName: 'Mother'
                          },
                          {
                            vlaue: 'Brother',
                            relationName: 'Brother'
                          },
                          {
                            vlaue: 'Sister',
                            relationName: 'Sister'
                          },
                          {
                            vlaue: 'Wife',
                            relationName: 'Wife'
                          },
                          {
                            vlaue: 'Husband',
                            relationName: 'Husband'
                          },
                          {
                            vlaue: 'Son',
                            relationName: 'Son'
                          },
                          {
                            vlaue: 'Daughter',
                            relationName: 'Daughter'
                          },
                          {
                            vlaue: 'Son-in-law',
                            relationName: 'Son-in-law'
                          },
                          {
                            vlaue: 'Daughter-in-law',
                            relationName: 'Daughter-in-law'
                          },
                          {
                            vlaue: 'In-laws',
                            relationName: 'In-laws'
                          },
                          {
                            vlaue: 'Guardian',
                            relationName: 'Guardian'
                          },
                          {
                            vlaue: 'Friends',
                            relationName: 'Friends'
                          },
                        ];

  public saveAdmissionFormData: any = {
    doctorId: '',
    departmentId: '',
    wardListId: '',
    patientBedId: '',
    nextOfKin: '',
    relation: '',
    patientMobileNumber: '',
    patientTypeId: 'inPatient',
    payeeType: 1,
    payeeNameId: '',
    payeeInfo: '',
    extReferralId: '',
    patientID: '',
  };
  showRegistrationForm = false;
  visitID: any;
  public opExistText = 'Please checkout the OP Visit to proceed with IP Admission';
  public visitOpExists: boolean = false
  public isAdmission: boolean = false
  public patientAdmissionDetails: any = {}
  
  constructor(private appointmentService: AppointmentService,
              private  billingService: BillingService,
              private tokenService: TokenStorageService,
              private wardService: WardService,
              private toastrService: ToastrService,
              private router: Router,
              private service: CommonService,
              private nursestationsService: NursestationsService,
              private modalService: NgbModal,) {
                this.userInfo = this.tokenService.getUser();      
               }

  ngOnInit(): void {
    this.loadDoctorList();
    this.loadDepartmentList();
    this.loadWardsList();
    this.loadExternalReferal();
    this.payeeTypeChange(1);
    this.service.events$.forEach((event) => {
      this.gettingSinglepatient(event);
  })   
  }
 
  /** get the doctors list based on the hospital id */
  loadDoctorList(): any {
    this.doctorData = [];
    this.appointmentService.getAppointmentSearchDoctors(AppointmentSearchDoctor + this.userInfo.hospitalID)
      .subscribe( (doctors: SearchDoctors[]) => {
        this.doctorList = doctors;
        this.filterDoctorList = doctors;
      });
  }
  /** get the departments list based on the hospital id */
  loadDepartmentList(): any {
    this.departmentList = [];
    this.appointmentService.getAppointmentDepartmentList(AppointmentDepartmentsList + this.userInfo.hospitalID)
      .subscribe( (departments: SearchDepartments[]) => {
        const departmentId: number[] = [];
        departments.forEach( dept => {
          if (departmentId.indexOf(dept.deptId) < 0) {
            this.departmentList.push(dept);
          }
          departmentId.push(dept.deptId);
        });
      });
  }
  /** get the ward details  */
  loadWardsList(): void {
    this.wardService.getWardsList().subscribe((response) => {
      this.wardsList = response;
    });
  }
  /** load the doctors external referal */
  loadExternalReferal(): any {
    this.appointmentService.getExternalReferralList(ExternalReferralList)
      .subscribe( referals => {
        this.extRefList = referals;        
        this.extReferalList = [];
        this.extRefList.forEach( refList => {
          this.extReferalList.push(refList.doctorName);
        });
      });
  }
  openRegistrationForm() {
    this.isAdmission = false;
    this.UMRvalue = null;
    this.editOption = false;
    this.editButton = false;
    this.toggleOnOff = 'patientForm';
  }
  gettingPatientData() {
  this.editOption = true;
  this.editButton = true;
  this.toggleOnOff = 'patientForm';
  this.isAdmission = false;
}

  chekingPatientStatus(patientInfo, i?, isView?): any {

    const searchObj = {
      patientID: patientInfo.patientID,
      visitID: 0
    };

    this.billingService.getOutpatientsByVisitId(PatientRecordsByVisitID , searchObj)
    .subscribe(response => {
    console.log("response response", response.ipInfo[0]);
      this.patientDetails = response.ipInfo[0];
      this.ipPatientNO = this.patientDetails.IP_NR;
      this.patientDetails.payeeName = patientInfo.payeeInfo;
    });
    // const receivedParam = {
    //   patientID: patientInfo.patientID
    // }
    // this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, receivedParam)
    //   .subscribe( patients => {
    //     this.patientDetails = patients[0];
    //   })
    if(patientInfo.ipAdmissionVisitCheck.hasAdmission == "Y") {
      this.isAdmission = true;
      this.toggleOnOff = 'nothing';
    }
    else if(patientInfo.ipAdmissionVisitCheck.hasVisit == "Y") {
      this.visitOpExists = true;
      this.toggleOnOff = 'nothing';
    }
    else if(patientInfo.ipAdmissionVisitCheck.hasAdmission == "Y" || patientInfo.ipAdmissionVisitCheck.hasVisit == "Y") {
      this.toggleOnOff = 'nothing';
    }
    else {
      this.isAdmission = false;
      this.visitOpExists = false;
      this.toggleOnOff = 'registration';
    }
    // this.patientDetails = patientInfo;
    this.saveAdmissionFormData.patientId = patientInfo.patientID;
    console.log("this.patientDetails", this.saveAdmissionFormData);
  }

  changeWard(wardId): void {
    this.saveAdmissionFormData.patientBedId = '';
    this.bedsList = [];
    this.wardService.getBedsList(wardId).subscribe((response) => {
      if(response !=0 ){
      this.bedsList = response;
      }else{
        this.toastrService.error("All beds are occupied in this ward");
      }
    });
  }

  keyUpEnterFunction(event, idRec): any {
    if (idRec === 'num') {
      this.UMRvalue = null;
      this.nameValue = '';
    }
    else if (idRec === 'id') {
      this.nameValue = '';
      this.mobileValue = '';
    }
    else if (idRec === 'name') {
      this.mobileValue = '';
      this.UMRvalue = null;
    }
    if (event.keyCode === 13) {
    this.searchByNumber(event.target, idRec);
    }
  }
  searchByNumber(recNumber, type): any {
    if (type === 'num' && recNumber.value.length < 10) {return recNumber}
    else if(type === 'id' && recNumber.value.length < 3) {return recNumber }
    else if(type === 'name' && recNumber.value.length < 3) { return recNumber}
    else{
      this.toggleOnOff = 'nothing';
    this.isAdmission = false;
    this.visitOpExists = false;
    let patientObj = {};
    if (type === 'num' && recNumber.value.length === 10) {
      this.dataLoaded = true;
      patientObj = {
        patientMobile: recNumber.value
      };
      this.gettingSinglepatient(patientObj);
    }
    else if (type === 'id') {
      this.dataLoaded = true;
      patientObj = {
        patientID: recNumber.value
      };
      this.gettingSinglepatient(patientObj);
    }
    else if (type === 'name' && recNumber.value.length >= 3) {
      this.dataLoaded = true;
      patientObj = {
        patientName: recNumber.value
      };
      this.selectedVisitId = null;
      this.addVisitAvailable = false;
      this.gettingSinglepatient(patientObj);
    }
    }
    
  }
  gettingSinglepatient(receivedParam): any {
    this.appointmentsData = undefined;
    this.patientsData = [];
    this.selectedVisitIdArray = [];
    this.addVisitArray = [];
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, receivedParam)
      .subscribe( 
        (patients) => {
          
          this.dataLoaded = false;
          if (patients.length > 0) {
            this.patientDetails = patients[0];
            patients.forEach( eachPatient => {
              this.patientsData.push(eachPatient);
              this.gettingVisitStatus(eachPatient);
              this.saveAdmissionFormData.patientId = eachPatient.patientID;
              //this.chekingPatientStatus(this.patientsData[0], 1, false)
              if(eachPatient.ipAdmissionVisitCheck.hasAdmission == "Y") {
                this.isAdmission = true;
                this.toggleOnOff = 'nothing';
                this.chekingPatientStatus(this.patientDetails);
              }
              else if(eachPatient.ipAdmissionVisitCheck.hasVisit == "Y") {
                this.visitOpExists = true;
                this.toggleOnOff = 'nothing';
              }
              else if(eachPatient.ipAdmissionVisitCheck.hasAdmission == "Y" || eachPatient.ipAdmissionVisitCheck.hasVisit == "Y") {
                this.toggleOnOff = 'nothing';
              }
              else {
                this.isAdmission = false;
                this.visitOpExists = false;
                this.toggleOnOff = 'registration';
              }
            });
          } else {
            this.dataLoaded = false;
            this.toastrService.error('No results found');
          }
        },
        (err) => {
          this.dataLoaded = false;
          console.log("there is no record with this result");
        }
      )
  }
  gettingVisitStatus(recPatient): any {
    const searchObj = {
      patientID: recPatient.patientID,
      visitID: 0
    };
    const toDayVisitDate = this.appointmentService.requiredDateFormat(this.toDayDate);
    let visitArray = [];
    let visitpatient;
    this.billingService.getOutpatientsByVisitId(PatientRecordsByVisitID , searchObj)
      .subscribe(response => {
      if(response.ipInfo.length !== 0) {
        visitArray = response.filter( eachVisit => {
          return eachVisit.visitDate === toDayVisitDate;
        });
        visitpatient = visitArray[0];
        if (visitArray.length > 0) {
          // this.selectedVisitIdArray.push(visitArray[0].visitId);
          this.selectedVisitIdArray[visitpatient.patientName + visitpatient.patientId] = visitpatient.visitId;
        }
        else {
          this.selectedVisitIdArray[recPatient.patientName + recPatient.patientID] = 0;
        }
        // this.addVisitArray.push(visitArray.length > 0);
        // this.selectedVisitIdArray[visitpatient.patientName + visitpatient.patientId][1] = visitArray.length > 0;
        // this.addVisitAvailable = visitArray.length > 0;
        // return visitArray;
      }
      });
  }
  checkingUMRNumber(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    }
    if (event.target.value.toString().length >= 6 || event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }
  checkingMobileNumber(event): boolean {
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    }
    if (event.target.value.toString().length >= 10 || event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }

  toggle(): any {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Admission';
    }
    else{
      this.buttonName = 'Admission';
    }
  }

  doctorChange(doctorId: any): any {

    if (doctorId === '') {
      this.filterDoctorList = this.doctorList;
    } else {
      const docObj = this.doctorList.filter(obj => obj.doctorId === doctorId);
      if (docObj.length !== 0) {
        this.saveAdmissionFormData.departmentId = docObj[0].departmentId;
        this.consultantFormData.departmentId = docObj[0].departmentId;
      }
    }

  }

  departmentChange(departmentId: any): any {

    console.log(this.doctorList);
    console.log(departmentId);

    if (departmentId === '') {
      this.filterDoctorList = this.doctorList;
    } else {
      this.filterDoctorList = this.doctorList.filter(obj => obj.departmentId === departmentId);
      this.saveAdmissionFormData.doctorId = '';
      this.consultantFormData.doctorId = '';
      this.doctorauto.clear();
    }

  }
  /** get the payee list */
  payeeTypeChange(id): void {
    this.saveAdmissionFormData.payeeInfo = '';
    this.wardService.getPayeeTypes(id, 'IP').subscribe((response) => {
      this.payeeNames = response;
     if( this.saveAdmissionFormData.payeeNameId == ''){
       //
     } else{
      this.saveAdmissionFormData.payeeNameId = '';
      this.payeename.clear();
     }
     
      if (id === 1) {
        this.saveAdmissionFormData.payeeInfo = this.payeeNames[0].PIM_NAME;
      }

    });

  }

  submitAdmissionFormDetails(): any {

    if (this.saveAdmissionFormData.doctorId === '' || this.saveAdmissionFormData.extReferralId === '') {
      // this.toastrService.error('Please select Doctor name');
      return;
    }
    console.log(this.patientDetails);

    const inputRequest = {
      H_ID : this.userInfo.hospitalID,
      P_ID : this.saveAdmissionFormData.patientId,
      DOCTOR_ID : this.saveAdmissionFormData.doctorId,
      DEPT_ID : this.saveAdmissionFormData.departmentId,
      WM_WARD_M_ID : this.saveAdmissionFormData.wardListId,
      WM_BED_M_ID : this.saveAdmissionFormData.patientBedId,
      NEXT_OF_KIN : this.saveAdmissionFormData.nextOfKin,
      RELATION : this.saveAdmissionFormData.relation,
      MOBILE_NO : this.saveAdmissionFormData.patientMobileNumber,
      PATIENT_TYPE : this.saveAdmissionFormData.patientTypeId,
      PTM_ID : this.saveAdmissionFormData.payeeType,
      PAYEE_ID : this.saveAdmissionFormData.payeeNameId,
      PAYEE_INFO : this.saveAdmissionFormData.payeeInfo,
      EXT_REF_ID : this.saveAdmissionFormData.extReferralId,
      CREATED_NAME: this.userInfo.username
    };

    this.wardService.saveAdmisionDetails(inputRequest).subscribe((response) => {
      this.router.navigateByUrl('/Inpatient');
    });

  }

  selectAutocompleteEvent(item: any, field: string): void {
    console.log('item', item);
    if (field === 'doctorname') {
      this.saveAdmissionFormData.doctorId = item.doctorId;
      this.consultantFormData.doctorId = item.doctorId;
      this.doctorChange(item.doctorId);
    }
    else if (field === 'externalReferral') {
      this.saveAdmissionFormData.extReferralId = item.referralId;
    }
    else if (field === 'payeeName') {
  //    this.payeeNames.filter(option => option.PIM_NAME.toLowerCase().includes(item.PIM_NAME));
      this.saveAdmissionFormData.payeeNameId = item.PAYEE_ID;
     // this.payeeTypeChange(item.PAYEE_ID);
    }
  }

  onChangeSearch(val: string): void {
     return this.payeeNames.filter(option => option.PIM_NAME.toLowerCase().includes(val));
  }

  onFocused(e): void {
    // do something when input is focused
  }
  clearSearch(){
    this.nameValue = '';
    this.mobileValue = '';
    this.UMRvalue = null;
    this.patientsData = [];
    this.visitID =  undefined;
    
  }

  actionRouting(value: any, umrno: any): void{
    const val = value;

    if (val === 'admissionInformation') {
      this.openIpAdmissionInfoPopup(this.ipAdmissionInformationPopup);
    } else if (val === 'updatePayee') {
      this.openUpdatePayeeSponsorPopup(this.updatePayeeSponsorPopup);
    } else if (val === 'addReferral'){
      this.openAddReferralPopup(this.addReferralPopup);
    } else if (val === 'updateExternal'){
      this.openUpdateExternalPopup(this.externalReferralPopup);
    }  
  }

  openIpAdmissionInfoPopup(ipAdmissionInformationPopup){
    this.modalService.open(ipAdmissionInformationPopup, { size: 'lg'});
  }

  openUpdatePayeeSponsorPopup(updatePayeeSponsorPopup){
    this.modalService.open(updatePayeeSponsorPopup, { size: 'lg'});
  }

  openAddReferralPopup(addReferralPopup) {
    this.modalService.open(addReferralPopup);
  }

  openUpdateExternalPopup(externalReferralPopup){
    this.modalService.open(externalReferralPopup, { size: 'lg'});
  }
  /* admissionInfoPopClose()
  {
    this.modalService.dismissAll();
  }
  
  updatePayeePopupClose()
  {
    this.modalService.dismissAll();
  } */
  
  popUpClose(){
    this.modalService.dismissAll();
  }

  /**
   * 
   * @param bedTransferPopup 
   */
   bedTransferPopUp(bedTransferPopup) {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = "";
    this.transferFormData.patientBedId = "";
    this.getWardsList();
    this.modalService.open(bedTransferPopup);
  }
  /**
   * 
   */
  getWardsList() {
    this.wardService.getWardsList().subscribe((response) => {
      this.completeWardsList = response;
    });
  }
  /**
   * submitTransferFormDetails
   */
   submitTransferFormDetails() {
    if(this.bedTransferSubmitBtn) {
      this.bedTransferSubmitBtn = false
    }
    else {
      const roomId = this.bedsList.find((data) => {
        if(data.WM_BED_M_ID === this.transferFormData.patientBedId) {
          return data;
        }
      })
      const inputRequest = {
        HospitalId: this.userInfo.hospitalID,
        IpNr: this.patientDetails.IP_NR,
        BedConfirmationBtn: "N",
        BedTransferBtn: "Y",
        CreateName: this.userInfo.username,
        WmWardMId: this.transferFormData.wardListId,
        WmWardRoomMId: roomId.WM_WARD_ROOM_M_ID,
        WmBedMId: this.transferFormData.patientBedId
      }
      this.nursestationsService.transferBed(inputRequest)
        .subscribe(
          (response) => {
            this.transferBedError = '';
            this.modalService.dismissAll();
            this.transferFormData = {
              wardListId: '',
              patientBedId: ''
            }
            this.showWardRoomsList(this.floorType, this.selectedBed.WardId);
          },
          (error) => {
            this.transferBedError = error.error.message;
            console.log("error123458", error.error.message)
          }
        )
    }
  
  }
  closeBedTransferPopup() {
    this.bedTransferSubmitBtn = true;
    this.transferFormData.wardListId = "";
    this.transferFormData.patientBedId = "";
    this.modalService.dismissAll();
  }

  /**
   * open cross consultant popup
   * @param crsConsultantPopUp 
   */
  
   crossConsultantPopUp(crsConsultantPopUp) {
    this.addDoctorSubmitBtn = true;
    this.consultantFormData.departmentId = "";
    this.loadDoctorLists();
    this.loadDepartmetList();
    this.modalService.open(crsConsultantPopUp,{ size: 'lg', backdrop: 'static' });
  }
  loadDoctorLists() {
    const docInput = {
        "IpNr":this.patientDetails.IP_NR, 
      "HospitalID": this.userInfo.hospitalID
      }
     /* console.log(bedIplist.IpNr);*/
      console.log(this.selectedBed.ConsultDoctorId);
      this.doctorData = [];
    this.nursestationsService.doctorsList(docInput)
    .subscribe((doctors: SearchDoctors[]) => {
      this.doctorList = doctors;
      console.log(doctors);
      this.doctorList.forEach(element => {
        this.doctorIdsList[element.doctorId]=element.doctorName;        
      });
      this.filterDoctorList = this.doctorList.filter(
        obj => obj.doctorId !== this.selectedBed.ConsultDoctorId);
    });
  }
  
  loadDepartmetList(){
    const dpInput = {
      "HospitalID": this.userInfo.hospitalID
      }
      this.departmentList = [];         
      this.nursestationsService.departmentsList(dpInput).subscribe((departments: SearchDepartments[]) => {
      const departmentId: number[] = [];
      departments.forEach( dept => {
        if (departmentId.indexOf(dept.deptId) < 0) {
          this.departmentList.push(dept);
        }
        departmentId.push(dept.deptId);
      });
    });
  }
  onRemoveSelected(item: any, field: string): void {
    if(field === 'doctorname') {
      this.consultantFormData.doctorId = null ;
      this.consultantFormData.departmentId = " ";
      this.itemDoctor = false;
    }    
  }
  submitConsultantFormDetails() {
    if(this.consultantFormData.doctorId){
      const inputRequest = {

        "IPNr": this.patientDetails.IP_NR,
        "DeptId": this.consultantFormData.departmentId,
        "DmId": this.consultantFormData.doctorId,
        "DoctorName": this.doctorIdsList[this.consultantFormData.doctorId],
        "CreateName": this.userInfo.username,
        "ReferralId":0,
      "ReferralName":0
      }
          this.nursestationsService.crossConsultFormDetails(inputRequest)
        .subscribe((response) => {
          
            //console.log(this.consultantFormData.doctorId);
            this.modalService.dismissAll();
            this.consultantFormData = {
              doctorId: '',
              departmentId: ''
            }       
          });
          
        }else{
          this.consultFormErrorMessage = true;
        }
        
    }
    closeCrosConsultantPopup() {
      this.addDoctorSubmitBtn = true;
      this.consultantFormData.doctorId = "";
      this.consultantFormData.departmentId= "";
      this.modalService.dismissAll();
    }

  
}
function bedIplist(bedIplist: any) {
  throw new Error('Function not implemented.');
}

