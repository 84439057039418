<div class="p-0" >

<!--  <div class=" iconsSearch mt-2 mt-md-0 ">-->
<!--    <div class=" row">-->
<!--      <div class="col-12 col-md-6  d-flex justify-content-around px-0 text-center mx-0">-->
<!--        <div> <a routerLink="/specialisation"   class=" btn  bg-white rounded-circle " ><img src="assets/svg/icon6.svg" alt="Neurology"> </a>-->
<!--          <p class="text-white">Neurology</p>-->
<!--        </div>-->
<!--        <div > <a routerLink="/specialisation"   class=" btn  bg-white rounded-circle "><img src="assets/svg/icon2.svg" alt="Pulmonary"> </a>-->
<!--          <p class="text-white">Pulmonary</p>-->
<!--        </div>-->
<!--        <div> <a routerLink="/specialisation"  class=" btn  bg-white rounded-circle "><img src="assets/svg/icon3.svg" alt="Cardiology"> </a>-->
<!--          <p class="text-white">Cardiology</p>-->
<!--        </div>-->
<!--        <div> <a routerLink="/specialisation"  class=" btn  bg-white rounded-circle "><img src="assets/svg/icon4.svg" alt="Nephrology"> </a>-->
<!--          <p class="text-white">Nephrology</p>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-12 col-md-6 d-flex justify-content-around px-0 text-center mx-0">-->
<!--        <div> <a routerLink="/specialisation"  class=" btn  bg-white rounded-circle "><img src="assets/svg/icon5.svg" alt="Orthopedics"> </a>-->
<!--          <p class="text-white">Orthopedics</p>-->
<!--        </div>-->
<!--        <div > <a routerLink="/specialisation" (click)="sendingSearchData('','','Pediatric')"  class=" btn  bg-white rounded-circle "><img src="assets/kid.png" alt="Birth"></a>-->
<!--          <p class="text-white">Pediatric</p>-->
<!--        </div>-->
<!--        <div > <a routerLink="/specialisation"  target="_self" class=" btn  bg-white rounded-circle "><img src="assets/svg/icon7.svg" alt="Dental"> </a>-->
<!--          <p class="text-white">Dental</p>-->
<!--        </div>-->
<!--        <div> <a routerLink="/specialisation"  target="_self" class=" btn  bg-white rounded-circle "><img src="assets/svg/icon8.svg" alt="Pregnancy"> </a>-->
<!--          <p class="text-white" >Gynecology</p>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->


  <div class="mysearchBox p-2">
    <form class="d-flex justify-content-around align-items-center text-center row">

<div class="col-md-2">

  <div class="btn btnn btn-light "  routerLink="/viewappointments"><i _ngcontent-vgn-c109="" class="fa fa-arrow-left fa-1x"></i>&nbsp;View Appointments</div>
</div>
      <div class="col-sm-6 col-md-2">
        <input
                  placeholder="Search Location"
                  name="location"
                  type="text"
                  required="required"
                  class="form-control doubleInput my-1 my-md-0"
                  [(ngModel)]="location"
                  [ngbTypeahead]="locationSearch"
                  (focus)="place$.next($any($event).target.value)"
                  (click)="placeClick$.next($any($event).target.value)"
                  #instance="ngbTypeahead"
                  (selectItem)="hospitalList($event)"
                  #selectedLocation
                  [disabled]="disableLocation"
                  [readOnly]="showModeratorBoard"
                />
      </div>


      <div class=" col-sm-5 col-md-3">

        <input placeholder="Search Hospital" type="text" name="hospitalName" class="form-control my-1 my-md-0 doubleInput"
               [(ngModel)]="hospital"
               [ngbTypeahead]="hospitalSearch"
               (focus)="allhospitals$.next($any($event).target.value)"
               (click)="hospitalClick$.next($any($event).target.value)"
               (selectItem)="doctorList($event)"
               #selectedHospital
               [disabled]="!selectedLocation.value || disableHospital"
               [readOnly]="showModeratorBoard"
                />
      </div>

      <div class="col-md-4 col-sm-6 ">

              <input
                placeholder="Search by Doctors, specialities"
                type="text"
                name="docspecialities"
                class="form-control my-1 my-md-0 singleInput"
                [(ngModel)]="docnspecialisation"
                [ngbTypeahead]="docSpecialitySearch"
                (focus)="specialisationDoc$.next($any($event).target.value)"
                (click)="specialisationDocClick1$.next($any($event).target.value)"
                #instance="ngbTypeahead"
                #selectedspecialisationRdoctor
                [disabled]="!selectedHospital.value"
              />

      </div>

      <div class="col-md-1 col-6 col-sm-5">
        <button  type="Submit"  class="btn bg-white" (click)="sendingSearchData(selectedLocation, selectedHospital, selectedspecialisationRdoctor)"
                  >
          <i class="fas fa-search text-whit faic"></i></button>
      </div>

    </form>
  </div>
  <div class="modal" [class]="searchDataLoaded ? 'forModalDisplay' : 'd-none'" >
    <div class="modal-dialog modal-sm modal-dialog-centered" >
      <div class="modal-content" style="background-color: #cbd8db">
        <div class="modal-body px-0 font-weight-bold text-center">
          <div class="font-weight-bold text-dark">Loading...</div>
          <div class="spinner-border text-primary"></div>
        </div>
      </div>
    </div>
  </div>
</div>
