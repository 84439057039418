import { Injectable } from '@angular/core';
import { FacadeService } from '../shared/facade.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Account_URL } from './accountConstants';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class AccountService {

  constructor(private facadeService: FacadeService, private http: HttpClient) {}

  /**
   * get the patient info by search fields
   */
  public searchPatientById(data: any, parameters: any): any {
    return this.facadeService.postService(data, parameters);
  }

  /**
   * change user password
   * @param data
   * @author Sankar
   */
  public changePassword(data: any): Observable<any> {
    return this.http.post(Account_URL.CHANGE_PASSWORD, data, httpOptions);
  }

}
