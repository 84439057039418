<div class="row justify-content-center mb-3 summary-info">
    <div class="col-12">
        <div class="row mt-5">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-5"><label>Patient Name</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.TITLE + " " + patientInfo?.PATIENT_NAME}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Age / Gender</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.AGE}} / {{patientInfo?.GENDER}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Mobile No</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.MOBILE}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Next Of Kin / Guardian Name</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.NEXT_OF_KIN_AND_REALTION}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Address</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.ADDRESS}}</span>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-5"><label>UMR No</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.UMRNO}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>IP/ Admission No</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.IP_NR}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Admitted Doctor</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.DM_NAME}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Admitting Date</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.IP_ADMISSION_DATE}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Admission Time</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.IP_ADMISSION_TIME}}</span>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-md-5"><label>Referred By</label></div>
                    <div class="col-md-7">
                        <span>{{patientInfo?.REFERRED_BY}}</span>
                    </div>
                </div> -->
            </div>

            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-5"><label>Ward/Bed No</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.WM_WARD_NAME}}/ {{patientInfo?.WM_BED_NO}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>External Referral</label></div>
                    <div class="col-md-7">
                        <div *ngIf="walkin == true">
                            <span>: {{patientInfo?.REFERRED_BY}}</span>
                        </div>
                        <div *ngIf="walkin == false">
                            <span>: {{patientInfo?.REFERRED_BY}}({{patientInfo?.REFERRAL_CITY}})</span>
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Treating Doctor/ Cross Consultant</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.TREATING_DOCTOR?.DM_NAME}}</span>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5"><label>Payment</label></div>
                    <div class="col-md-7">
                        <span>: {{patientInfo?.PAYMENT_TYPE}}</span>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-5"><label>Payee Name</label></div>
                    <div class="col-md-7">
                        <span>{{patientInfo?.PIM_NAME}}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

