<div class="row">
    <div class="col-12">
      <fieldset class="legend-block">
        <legend class="legend-title mb-0 font-16">Patient Info</legend>
        <div class="row">
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Name </div>
                <div class="col">{{patientInfo?.PatientName}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> IP Number </div>
                <div class="col"> {{patientInfo?.IpNo}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold">UMR No. </div>
                <div class="col"> {{patientInfo?.UmrNo}} </div>
              </div>
            </div>
    
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Bill Date </div>
                <div class="col"> {{patientInfo?.BillDate}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Bill No. </div>
                <div class="col"> {{patientInfo?.BillNo}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Payee Name </div>
                <div class="col"> {{patientInfo?.PayeeName}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Total Bill Value </div>
                <div class="col">{{patientInfo?.TotalBill}} </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 pt-3">
              <div class="row">
                <div class="col-5 font-weight-bold"> Credit Voucher Value </div>
                <div class="col">{{patientInfo?.CreditVoucherAmount}} </div>
              </div>
            </div>
          </div>
      </fieldset>
  
    </div>
    <div class="col-md-6 mt-3">
      <fieldset class="legend-block">
        <legend class="legend-title mb-0 font-16">Credit Bill Form Details</legend>
        <form class="column" [formGroup]="creditBillSubmissionForm" (ngSubmit)="onSubmit()">
          <div class="col-12 pt-3">
            <div class="">
              <div class="col-md-12 font-weight-bold"> Credit Bill Submitted On </div>
              <div class="col-md-12">
                <input type="datetime-local" class="form-control-sm form-control" placeholder="dd/mm/yyyy HH:mm"
                  name="sentOn" formControlName="billSubmittedOn" (change)="onChangeSentON($event)" [min]="sentONMin" [max]="sentONMax" id="sentOn">
                <span class="helpnote">
                  Please make sure that date entered is not before admission date time and post current date time
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-3">
            <div class="">
              <div class="col-5 font-weight-bold"> Credit Bill Submitted To </div>
              <div class="col-md-12">
                <input type="text" id="sentTo" class="form-control form-control-sm" name="sentTo"
                  formControlName="billSubmittedTo">
              </div>
            </div>
          </div>
          <div class="col-12 pt-3">
            <div class="">
              <div class="col-5 font-weight-bold"> Credit Bill Submitted By </div>
              <div class="col-md-12">
                <input type="text" id="sentTo" class="form-control form-control-sm" name="sentTo"
                  formControlName="billSubmittedBy">
              </div>
            </div>
          </div>
     
          <div class="col-12 mt-2 text-right">
            <button type="submit" class="btn btn-primary mr-2" [disabled]="!creditBillSubmissionForm.valid">Submit</button>
          </div>
  
        </form>
      </fieldset>
    </div>
    <div class="col-md-6 mt-3">
        <fieldset class="legend-block">
            <legend class="legend-title mb-0 font-16">Remarks Form</legend>
        
        <form (ngSubmit)="addRemark.form.valid && submitRemarks()" #addRemark="ngForm" autocomplete="off">
            <div class="col-md-12">
                <div class="col d-flex align-items-center">
                    <label>Remarks <span style="color: #ccc;">(at least 10 characters)</span></label>
                </div>
                <div class="col-12">
                    <textarea class="form-control" [(ngModel)]="remarkFormData.remark" name="remark"
                        #remark="ngModel" required></textarea>
                </div>
            </div>
          <div class="col-12 mt-2 text-right">
            <button type="submit" [disabled]="remarkFormData.remark === ''" class="btn btn-primary mr-2">Submit
                Remarks</button>
          </div>
        </form>
        </fieldset>
    </div>
    
    
    <div *ngIf="remarksList.length !== 0" class="col-12 mt-3">
        <fieldset class="legend-block">
          <legend class="legend-title mb-0 font-16">Remarks List</legend>
                 <div class="row border-bottom">
            <div class="col-md-2">
              <p class="font-weight-bold">S.No</p>
            </div>
            <div class="col-md-6">
              <p class="font-weight-bold">Remarks</p>
            </div>
            <div class="col-md-2">
              <p class="font-weight-bold">Created By Name</p>
            </div>
            <div class="col-md-2">
              <p class="font-weight-bold">Created Time</p>
            </div>
          </div>
          <div class="row p-1 border-bottom" *ngFor="let remark of remarksList; let i = index">
            <div class="col-md-2">
                {{i+1}}
            </div>
            <div class="col-md-6">
                {{remark.REMARKS}}
            </div>
            <div class="col-md-2">
                {{remark.CREATED_ID}}
            </div>
            <div class="col-md-2">
                {{remark.CREATED_DATE_TIME}}
            </div>
            
          </div>
        </fieldset>
      </div>
    
  </div>