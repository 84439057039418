import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from '../../shared/loginservices/token-storage.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  public searchJson = {
    umrNumber:'',
    visitNumber:'',
    patientName: ''
  }
  public rolesInfo: any = [];

  public patientVistList:any = [];
  public bodyDisplay = 'viewoutPatient';/*serviceEnquiry  */
  visitID: string;
  userInfo: any = {};
  constructor(private tokenStorageService: TokenStorageService) {
    if (this.tokenStorageService.getToken()) {
      this.rolesInfo = this.tokenStorageService.getUser().rolesInfo;
    }
    this.userInfo = this.tokenStorageService.getUser();
   }

  ngOnInit(): void {
  }

  searchPatientData(): void {
    this.searchJson;
    this.patientVistList = [
      {
        patientName:'A1',
        umrNumber:'111',
        visitNumber:'12333'
      }
    ]
  }


  gettingPatientData(): void {

  }

}
