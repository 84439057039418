import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ward-bed-management',
  templateUrl: './ward-bed-management.component.html',
  styleUrls: ['./ward-bed-management.component.css']
})
export class WardBedManagementComponent implements OnInit {
  public show = false;
  constructor() { }
  toggle(): any {
    this.show = !this.show;
  }
  ngOnInit(): void {
  }

}
