import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryComponent } from './inventory/inventory.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material/material.module';
import { NgxEditorModule } from 'ngx-editor';
import { RouterModule, Routes } from '@angular/router';
const inventoryRoutes: Routes = [
  { path : 'inventory-management',component : InventoryComponent},
];

@NgModule({
  declarations: [InventoryComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    MaterialModule,
    NgxEditorModule,

    RouterModule.forChild(inventoryRoutes),
  ]
})
export class InventoryManagementModule { }
