import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPrinterService } from 'ngx-printer';
import { CommonService } from '../../shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '../../print-management/preview/preview.component';
import { forEachChild } from 'typescript';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-view-receipts',
  templateUrl: './view-receipts.component.html',
  styleUrls: ['./view-receipts.component.css']
})
export class ViewReceiptsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

/*   @ViewChildren('searchInput') searchInput: QueryList<ElementRef>;
  @ViewChild('transactionID') transactionID: ElementRef;
  @ViewChild('bank_Name') bank_Name: ElementRef;
  @ViewChild('mobile_Numbr') mobile_Numbr: ElementRef; */

  public patientInfo: any;
  public ipPatientNO = 0;
  public userInfo: any = {};

  public transactionId: any = '';
  public bankName: any = '';
  public mobileNumber: any = '';
  public issueDate: any = '';
  cashMode: any = '';
  Creditdebit: any = '';
  gpayUPI: any = '';
  cheque: any = '';
  date: any;
  showErrorMessage = false;
  isSubmitted = false;
  selectedValue: number;
  disableifCash = false;
  paymentModeType = '';
  patientURMNo;

  receiptList: any = [];
  ipReceiptDetailsList;
  ipReceiptAmountInWords;
  printReceiptDiv = false;
  listTitle = 'View Receipts';
  columnsToDisplay = ['RECEIPT_NUMBER', 'AMOUNT', 'CREATEDBY', 'CREATEDDATE', 'PAYMENTTYPE', 'PAYMENTMODE', 'PAYMENTSTATUS', 'ACTION'];
  receiptListData = new MatTableDataSource(this.receiptList);

  returnMode = 'iplist';
  sericePopupInfo: any;
  paymentModeList: any = [];
  paymentModesList: any = [];
  paymentOptionsList: any = [];
  billingSummaryDetails: any = [];
  receiptInfo: any = [];
  selectedReceiptArr: any = [];
  receiptAmount: number;
  inputAmount: number;
  

  constructor(
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService,
    private confirmDialogService: ConfirmDialogService,
    private toastrService: ToastrService,
    private printerService: NgxPrinterService,
    private commonService: CommonService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.userInfo = this.tokenService.getUser();

    const id = this.route.snapshot.paramMap.get('id');
    // tslint:disable-next-line:radix
    this.ipPatientNO = parseInt(id);

    this.route.queryParams.subscribe(params => {

      const urmNo = params.umrNumber;
      // tslint:disable-next-line:radix
      this.patientURMNo = parseInt(urmNo);

      this.returnMode = params.mode;

    });

  }

  returnToMainPage(): void {
    const queryParam = { umrNumber: this.patientURMNo, mode: this.returnMode };
    this.router.navigate(['/' + 'BillSummary' + '/' +  this.ipPatientNO], { queryParams: queryParam });
  }

  ngOnInit(): void {
    this.getPatientInfo();
    this.viewReceipt();
    this.getPaymentModeList();
    this.getPaymentModesList();
    this.getPaymentOptionsList();
  }

  /**
   * get patient info
   */
   getPatientInfo(): void {
    const inputRequest = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };
    this.inpatientService.patientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        // console.log("patient info", response )
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  viewReceipt(): void {

    const inputRequest = {
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };

    this.inpatientService.viewReceipts(inputRequest).subscribe(
      (response) => {
        this.receiptList = response;
        console.log(this.receiptList);
        this.receiptListData = new MatTableDataSource(this.receiptList);
        this.receiptListData.sort = this.sort;
        this.receiptListData.paginator = this.paginator;
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  printReceipt(receipt): void {

    const ipnoC = this.ipPatientNO;
    const receiptnoC = receipt.RECEIPT_NO;
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '80%',
      height: '95%',
      data: {
        mode: 'receipt',
        ipno: ipnoC,
        receiptno: receiptnoC
      }
    });

    // const queryParam = { mode: 'receipt', ipno: this.ipPatientNO, receiptno: receipt.RECEIPT_NO };
    // this.router.navigate(['/' + 'printpreview'], { queryParams: queryParam });
    // this.router.navigate(['/' + 'printpreview'], { queryParams: queryParam }).then(result => {  window.open(result, '_blank'); });

    // const input = {
    //   IpNr: this.ipPatientNO,
    //   HospitalId: this.userInfo.hospitalID,
    //   ReceiptNo : receipt.RECEIPT_NO
    // };
    // this.getIpReceiptDetails(input);

  }

  /** get the pdf footer details */
  getIpReceiptDetails(inputRequest): void {

    this.inpatientService.ipReceiptDetails(inputRequest).subscribe((response) => {
       
      if (response !== null) {
        this.ipReceiptDetailsList = response;
        
        this.ipReceiptAmountInWords = this.commonService.toWords(
          Math.round(this.ipReceiptDetailsList.ReceiptAmt)
        );
      }

      this.printReceiptDiv = true;

      setTimeout(() => {
        this.printerService.printDiv('print-receipt-section');
        // this.closeModalPopup();
      }, 2000);

      setTimeout(() => {
        this.printReceiptDiv = false;
      }, 3000);

    });

  }

  paymentDone(): void {

    const inputRequest = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.ipPatientNO
    };
    this.inpatientService.paymentDone(inputRequest).subscribe((response) => {
      this.toastrService.success(response.msg);
    });
  }

  refundPopup(refund, element): void {
      this.resetRefundPopup();
      this.selectedReceiptInfo(element);
      this.modalService.open(refund, { size: 'xl', backdrop: 'static' });
  }
  public isPaymentSelected(): any {
    const hasObj = this.paymentModeList.filter(
      (obj) => obj.paymentModeID !== 1 && obj.paymentModeID !== 5 && obj.paymentModeID !== 6 && obj.selectedPaymentType
    );
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }
  getPaymentModeList(): any {

    const modes = [
      { id: 1,  name: 'Cash', type: 1 },
      { id: 2, name: 'Credit/Debit Card', type: 1 },
      { id: 3, name: 'Google Pay/Phonee/UPI', type: 1 },
      { id: 4, name: 'Cheque/ECS/DD/NEFT/RTGS', type: 1 },
      { id: 5, name: 'Refund Later', type: 1 }
    ];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < modes.length; i++) {
      const data = {
        hopitalID: 1,
        paymentModeID: modes[i].id,
        paymentModeName: modes[i].name,
        isDisable: false,
        collectedAmount: '',
        selectedPaymentType: false,
        paymentType: modes[i].type,
      };
      this.paymentModeList.push(data);
    }
  }
  selectedReceiptInfo(element):void{
    this.selectedReceiptArr = element;
    const ipRequest = {
      "IpNr": this.ipPatientNO,
      "ReceiptRowId": element.IP_PAYMENT_ID,
      "ReceiptPaymentNo": element.RECEIPT_NO,
      "ReceiptActionName":"RefundForm",
      "hospitalId":this.userInfo.hospitalID
    }
    this.inpatientService.receiptsOperationInfo(ipRequest).subscribe((response) => {
      console.log(response);
      this.receiptInfo = response;      
    } 
    );   
  }

  resetRefundPopup(): void {

    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.issueDate = '';

    this.paymentModeList.forEach((element) => {
      element.isDisable = true;
      element.collectedAmount = '';
      element.selectedPaymentType = false;
    });

  }
  
  public isPaymentChequeSelected(): any {
    const hasObj = this.paymentModeList.filter(
      (obj) => obj.paymentType === 1 && obj.paymentModeID === 4 && obj.selectedPaymentType
    );
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }
  
  numberOnlyVal(event: any, data: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public checkCollectAmount(): any {
    let collectAmount = 0;
    let balanceAmount = 0;

    
    if (Number(this.receiptInfo.Amount) < 0) {
      balanceAmount = Number(this.receiptInfo.Amount) * -1;
    }
    if (Number(this.receiptInfo.Amount) > 0) {
      balanceAmount = Number(this.receiptInfo.Amount);
    }

    const hasObj = this.paymentModeList.find((obj) => obj.selectedPaymentType === true);
    if (hasObj) {

      if (hasObj.paymentType === 1) {
        collectAmount = Number(hasObj.collectedAmount);
      }

      if (hasObj.paymentType === 3) {
        collectAmount = Number(this.receiptInfo.Amount);
      }

      if (hasObj.paymentType === 2) {
        collectAmount = Number(this.receiptInfo.Amount) * -1;
      }

    }
    if (collectAmount <= balanceAmount) {
      return false;
    } else {
      return true;
    } 

  }

  changePaymentMode(event, paymentMode): void {

    this.paymentModeList.forEach((element) => {
      element.isDisable = true;
      element.collectedAmount = '';
      element.selectedPaymentType = false;
      this.transactionId = '';
      this.bankName = '';
      this.mobileNumber = '';
      this.issueDate = '';
    });

    if (event.target.checked) {
      paymentMode.selectedPaymentType = true;
      paymentMode.isDisable = false;
    }
  }

  public clearAmount(paymentType: any): void {
    paymentType.collectedAmount = '';
    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.issueDate = '';
  }

  submitRefundForm():any {
  
    let refundLaterCheckbox = '';
    let paymentType = '';
    let cashAmount = '';
    let cardAmount = '';
    let walletsAmount = '';
    let bankingAmount = '';
    let refundAmount = '';

    let transactionReferenceId = '';
    let paymentModeName = '';
    let error = 0;

    const hasObj = this.paymentModeList.find((obj) => obj.selectedPaymentType === true);
    if (hasObj) {

      paymentType = hasObj.paymentType;

      paymentModeName = hasObj.paymentModeName;

      if (hasObj.paymentModeID === 1) {
        cashAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 2) {
        cardAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 3) {
        walletsAmount = hasObj.collectedAmount;
      }

      if (hasObj.paymentModeID === 4) {
        bankingAmount = hasObj.collectedAmount;
        transactionReferenceId = this.transactionId;
      }

      if (hasObj.paymentModeID === 5) {
        refundAmount = hasObj.collectedAmount;
        refundLaterCheckbox = '1';
      }

      if (hasObj.paymentModeID === 2 || hasObj.paymentModeID === 3 || hasObj.paymentModeID === 4) {

        if (this.transactionId === '') { error++; }
        if (this.bankName === '') { error++; }
        if (this.mobileNumber === '') { error++; }
        if (this.issueDate === '' && hasObj.paymentModeID === 4) { error++; }

      }
    }
    if (error > 0) {
      this.toastrService.error('Please fill all required fields');
      return;
    }
    //console.log(this.selectedReceiptArr);
    const inputRequest = {
      "IpNr": this.ipPatientNO,
      "ReceiptRowId": this.selectedReceiptArr.IP_PAYMENT_ID,
      "ReceiptPaymentNo": this.selectedReceiptArr.RECEIPT_NO,
      "ReceiptActionName":"RefundForm",
      "hospitalId":this.userInfo.hospitalID,
      "payment_type": 2, // For refund transaction
      "cash_amount": cashAmount,
      "card_amount": cardAmount,
      "wallets_amount": walletsAmount,
      "banking_amount": bankingAmount,
      "bank_name": this.bankName,
      "banking_date": this.issueDate,
      "transaction_reference_id": transactionReferenceId,
      "transaction_id": (hasObj.paymentModeID === 4)?this.transactionId = '':this.transactionId,
      "transaction_mobile_nr": this.mobileNumber,
      "payment_mode_type": (hasObj.paymentModeID === 5)?paymentModeName ="Refund Pending":paymentModeName,
      "refund_later_checkbox": refundLaterCheckbox,
      "refund_later_amount": refundAmount,
      "create_name": this.userInfo.username,
    };

    this.inpatientService.receiptRefundOrCreditAction(inputRequest).subscribe(
      (response) => {
        console.log('Refund data', response);
        this.toastrService.success(response.message);
        this.refundPopupClose();
        this.viewReceipt();
      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
        this.refundPopupClose();        
      }
    );    
  }
  
  refundPopupClose(): void{
      this.modalService.dismissAll();
    }
/* Process pending Refund Form */
getPaymentModesList(): void{  
  const paymentModes = [
    { id: 1, name: 'Cash', type: 2},
    { id: 2, name: 'Credit/Debit', type: 2},
    { id: 3, name: 'Google Pay/Phonepe/UPI',type: 2 },
    { id: 4, name: 'Cheque/ECS/DD/NEFT/RTGS', type: 2 }
  ];
  
  for(let i=0; i < paymentModes.length; i++){
      const data = {
        hopitalID: 1,
        paymentModeID: paymentModes[i].id,
        paymentModeName: paymentModes[i].name,
        isSelected: false,
        collectedAmount: '',
        selectedPaymentType: false,
        paymentType: paymentModes[i].type,
      };
      this.paymentModesList.push(data);
    }
  }
    pendingRefundPopup(pendingRefund, element): void{
      this.selectedPendingReceiptInfo(element);
      this.modalService.open(pendingRefund,{ size: 'xl', backdrop: 'static' });
      this.resetPendingRefundPopup();
    }
  
    selectedPendingReceiptInfo(element):void{
      this.selectedReceiptArr = element;
      const ipRequest = {
        "IpNr": this.ipPatientNO,
        "ReceiptRowId": element.IP_PAYMENT_ID,
        "ReceiptPaymentNo": '',
        "ReceiptActionName":"ProcessPendingRefundForm",
        "hospitalId":this.userInfo.hospitalID
      }
      this.inpatientService.receiptsOperationInfo(ipRequest).subscribe((response) => {
        this.receiptInfo = response;
        console.log(this.receiptInfo);      
      } 
      );   
    }
    
    resetPendingRefundPopup(): void {

      this.transactionId = '';
      this.bankName = '';
      this.mobileNumber = '';
      this.issueDate = '';
  
      this.paymentModesList.forEach((element) => {
        element.isSelected = false;
        element.collectedAmount = '';
        element.selectedPaymentType = false;
      });
  
    }

    public isCashSelected(): any {
      const hasObj = this.paymentModesList.filter(
        (obj) => obj.paymentModeID === 1 &&  obj.selectedPaymentType);
       if (hasObj.length !== 0) {
         return false;
        }else{
         return true;
        }
      }

    selectedradioButton(paymode, event): void {
  
      this.paymentModesList.forEach((e) => {
        e.isSelected = false;
        e.collectedAmount = '';
        e.selectedPaymentType = false;
        this.transactionId = '';
        this.bankName = '';
        this.mobileNumber = '';
        this.issueDate = '';
      });
      if (event.target.checked) {
        paymode.selectedPaymentType = true;
        paymode.isSelected = true;
      }
  
      this.paymentModeType = paymode.paymentModeName;
  
      /* if (paymode.paymentModeID === 1) {
        this.transactionID.nativeElement.disabled  = true;
        this.bank_Name.nativeElement.disabled  = true;
        this.mobile_Numbr.nativeElement.disabled  = true;
      } else {
        this.transactionID.nativeElement.disabled  = false;
        this.bank_Name.nativeElement.disabled  = false;
        this.mobile_Numbr.nativeElement.disabled  = false;
      } */
  
    }
    /* selectedInputValue(data): void {
  
      this.paymentModeType = data.paymentModeName;
      console.log(this.paymentModeType);
      this.selectedValue = data.collectedAmount;
      console.log(this.selectedValue);
      if (data.paymentModeID === 1) {
        this.cashMode = this.selectedValue;
        this.transactionId = '';
        this.bankName = '';
        this.mobileNumber = '';
        this.Creditdebit = '';
        this.gpayUPI = '';
        this.cheque = '';
      } else if (data.paymentModeID === 2) {
        this.Creditdebit = this.selectedValue;
        this.cashMode = '';
        this.gpayUPI = '';
        this.cheque = '';
      } else if (data.paymentModeID=== 3) {
        this.gpayUPI = this.selectedValue;
        this.cashMode = '';
        this.Creditdebit = '';
        this.cheque = '';
      } else {
        this.cheque = this.selectedValue;
        this.cashMode = '';
        this.gpayUPI = '';
        this.Creditdebit = '';
      }
  
    } */
    public collectedAmountCheck(): any {
      let collectAmount = 0;
      let receiptAmount = 0;
  
       /* if (Number(this.receiptInfo.Amount) < 0) {
        balanceAmount = Number(this.receiptInfo.Amount) * -1;
      }  */
      if (Number(this.receiptInfo.Amount) > 0) {
        receiptAmount = Number(this.receiptInfo.Amount);
      }
      const Obj = this.paymentModesList.find((data) => data.selectedPaymentType === true);
      if(Obj)
        {
          if (Obj.paymentModeID === 1) {
            collectAmount= Number(Obj.collectedAmount);
          } else if (Obj.paymentModeID === 2) {
            collectAmount = Number(Obj.collectedAmount);
          } else if (Obj.paymentModeID === 3) {
            collectAmount= Number(Obj.collectedAmount);
          } else {
            collectAmount = Number(Obj.collectedAmount);      
          }
        }
        if (collectAmount === receiptAmount ) {
          return false;
          //this.paymentModeType =='' || this.isSubmitted 
        } else {
          return true;
        } 
    } 

    submitPendingRefundForm(){
    
    /* if (this.paymentModeType !== 'Cash' && (this.transactionID.nativeElement.value === ''
        && this.bank_Name.nativeElement.value === '' &&  this.mobile_Numbr.nativeElement.value === '')) {
        this.showErrorMessage = true;
        this.toastrService.error('Please fill all required fields');
        return;
      } */
      let error = 0;
      this.isSubmitted = true;
      let transactionReferenceId = '';
      
      const Obj = this.paymentModesList.find((data) => data.selectedPaymentType === true);
      if(Obj)
        {
          if (Obj.paymentModeID === 1) {
            this.cashMode = Number(Obj.collectedAmount);
            console.log(this.cashMode);
          } else if (Obj.paymentModeID === 2) {
            this.Creditdebit = Number(Obj.collectedAmount);
            console.log(this.Creditdebit);
          } else if (Obj.paymentModeID === 3) {
            this.gpayUPI = Number(Obj.collectedAmount);
            console.log(this.gpayUPI);
          } else {
            this.cheque = Number(Obj.collectedAmount);
            transactionReferenceId = this.transactionId;
            console.log(this.cheque);      
          }
          if (Obj.paymentModeID === 2 || Obj.paymentModeID === 3 || Obj.paymentModeID === 4 ) {

            if (this.transactionId === '') { error++; }
            if (this.bankName === '') { error++; }
            if (this.mobileNumber === '') { error++; }    
          }
        }
        if (error > 0) {
          this.toastrService.error('Please fill all required fields');
          return;
        }
  
      this.showErrorMessage = false;
  
      const inputRequest1 = {
        "IpNr": this.ipPatientNO,
        "ReceiptRowId": this.selectedReceiptArr.IP_PAYMENT_ID,
        "ReceiptPaymentNo": '',
        "ReceiptActionName":"ProcessPendingRefundForm",
        "hospitalId":this.userInfo.hospitalID,
        "payment_type": 2, // For refund transaction
        "cash_amount": this.cashMode,
        "card_amount": this.Creditdebit,
        "wallets_amount": this.gpayUPI,
        "banking_amount": this.cheque,
        "bank_name": this.bankName,
        "banking_date": '',
        "transaction_reference_id": transactionReferenceId,
        "transaction_id": this.transactionId,
        "transaction_mobile_nr": this.mobileNumber,
        "payment_mode_type": this.paymentModeType,
        "refund_later_checkbox": '',
        "refund_later_amount":'',
        "create_name": this.userInfo.username,      
        };
  
        this.inpatientService.receiptRefundOrCreditAction(inputRequest1).subscribe(
        (response) => {
          console.log('patient info', response);
          this.toastrService.success(response.message);
          this.pendingRefundPopupClose();
          this.viewReceipt();
        },
        (err) => {
          console.log('HTTP Error', err);
          this.toastrService.error('Something went wrong..!');
          this.pendingRefundPopupClose();
        }
      );
  
    }
  
    pendingRefundPopupClose(){
      this.modalService.dismissAll();
      this.resetPendingRefundPopup();
    }

    /* Due Pending Form */
  getPaymentOptionsList(): void{  
    const paymentOptions = [
      { id: 1,  name: 'Cash', type: 1 },
      { id: 2, name: 'Credit/Debit Card', type: 1 },
      { id: 3, name: 'Google Pay/Phonee/UPI', type: 1 },
      { id: 4, name: 'Cheque/ECS/DD/NEFT/RTGS', type: 1 }
    ];
      
    for(let i=0; i < paymentOptions.length; i++){
      const data = {
        hopitalID: 1,
        paymentModeID: paymentOptions[i].id,
        paymentModeName: paymentOptions[i].name,
        isSelected: false,
        collectedAmount: '',
        selectedPaymentType: false,
        paymentType: paymentOptions[i].type,
      };
      this.paymentOptionsList.push(data);
    }
  }

  pendingDuePopup(pendingDue, element): void{      
    this.selectedReceiptDue(element);
    this.modalService.open(pendingDue, { size: 'xl', backdrop: 'static' });
    this.resetPendingDuePopup();
  }
      
  selectedReceiptDue(element): void{

      this.selectedReceiptArr = element;
      const dueRequest = {
        "IpNr": this.ipPatientNO,
        "ReceiptRowId": element.IP_PAYMENT_ID,
        "ReceiptPaymentNo": element.RECEIPT_NO,
        "ReceiptActionName": "CollectDuesForm",
        "hospitalId": this.userInfo.hospitalID
      }
      this.inpatientService.receiptsOperationInfo(dueRequest).subscribe((response) => {
      console.log(response);
      this.receiptInfo = response;
      //console.log(this.receiptInfo);      
      } 
    );   
  }

  resetPendingDuePopup(): void {

    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.issueDate = '';

    this.paymentOptionsList.forEach((element) => {
      element.isSelected = false;
      element.collectedAmount = '';
      element.selectedPaymentType = false;
    });

  }

  public isCashOptionSelected(): any {
    const hasObj = this.paymentOptionsList.filter(
      (obj) => obj.paymentModeID === 1 &&  obj.selectedPaymentType);
     if (hasObj.length !== 0) {
       return false;
      }else{
       return true;
      }
    }
      
  selectedradioOption(paymode, event): void {
      
    this.paymentOptionsList.forEach((e) => {
      e.isSelected = false;
      e.collectedAmount = '';
      e.selectedPaymentType = false;
      this.transactionId = '';
      this.bankName = '';
      this.mobileNumber = '';
      this.issueDate = '';
    });
     
    if (event.target.checked) {
      paymode.selectedPaymentType = true;
      paymode.isSelected = true;
    }
      
    this.paymentModeType = paymode.paymentModeName;
      
    /* if (paymode.paymentModeID === 1) {
      this.transactionID.nativeElement.disabled  = true;
      this.bank_Name.nativeElement.disabled  = true;
      this.mobile_Numbr.nativeElement.disabled  = true;
    } else {
      this.transactionID.nativeElement.disabled  = false;
      this.bank_Name.nativeElement.disabled  = false;
      this.mobile_Numbr.nativeElement.disabled  = false;
    } */
  }
        
  public collectedDueAmountCheck(): any {
    let collectAmount = 0;
    let receiptAmount = 0;
      
    /* if (Number(this.receiptInfo.Amount) < 0) {
        balanceAmount = Number(this.receiptInfo.Amount) * -1;
       }  */
    if (Number(this.receiptInfo.Amount) > 0) {
      receiptAmount = Number(this.receiptInfo.Amount);
    }
    const Obj = this.paymentOptionsList.find((data) => data.selectedPaymentType === true);
    if(Obj)
    {
      if (Obj.paymentModeID === 1) {
        collectAmount= Number(Obj.collectedAmount);
      } else if (Obj.paymentModeID === 2) {
        collectAmount = Number(Obj.collectedAmount);
      } else if (Obj.paymentModeID === 3) {
        collectAmount= Number(Obj.collectedAmount);
      } else {
        collectAmount = Number(Obj.collectedAmount);      
      }
    }
    if (collectAmount <= receiptAmount ) {
    return false; 
    } else {
    return true;
    } 
  } 
  
  submitPendingDueForm(){
        
    /* if (this.paymentModeType !== 'Cash' && (this.transactionID.nativeElement.value === ''
      && this.bank_Name.nativeElement.value === '' &&  this.mobile_Numbr.nativeElement.value === '')) {
      this.showErrorMessage = true;
      this.toastrService.error('Please fill all required fields');
      return;
    } */
    let refundLaterCheckbox = '';
    let paymentType = '';
    let cashAmount = '';
    let cardAmount = '';
    let walletsAmount = '';
    let bankingAmount = '';
    let refundAmount = '';

    let transactionReferenceId = '';
    let paymentModeName = '';
    let error = 0;
    this.isSubmitted = true;
  
          
    const Obj = this.paymentOptionsList.find((data) => data.selectedPaymentType === true);
    if(Obj)
    {
      if (Obj.paymentModeID === 1) {
      cashAmount = Obj.collectedAmount;
      console.log(cashAmount);
      } else if (Obj.paymentModeID === 2) {
      cardAmount = Obj.collectedAmount;
      console.log(cardAmount);
      } else if (Obj.paymentModeID === 3) {
      walletsAmount = Obj.collectedAmount;
      console.log(walletsAmount);
      } else {
      bankingAmount = Obj.collectedAmount;
      transactionReferenceId = this.transactionId;
      console.log(bankingAmount);      
      }
      
      if (Obj.paymentModeID === 2 || Obj.paymentModeID === 3 || Obj.paymentModeID === 4 ) {
    
        if (this.transactionId === '') { error++; }
        if (this.bankName === '') { error++; }
        if (this.mobileNumber === '') { error++; }    
      }
    }
    if (error > 0) {
      this.toastrService.error('Please fill all required fields');
      return;
    }
      
    this.showErrorMessage = false;
      
    const  dueFormData= {
      "IpNr": this.ipPatientNO,
      "ReceiptRowId": this.selectedReceiptArr.IP_PAYMENT_ID,
      "ReceiptPaymentNo": this.selectedReceiptArr.RECEIPT_NO,
      "ReceiptActionName":"CollectDuesForm",
      "hospitalId":this.userInfo.hospitalID,
      "payment_type": 3, // For Due Pending transaction
      "cash_amount": cashAmount,
      "card_amount": cardAmount,
      "wallets_amount": walletsAmount,
      "banking_amount": bankingAmount,
      "bank_name": this.bankName,
      "banking_date": '',
      "transaction_reference_id": transactionReferenceId,
      "transaction_id": (Obj.paymentModeID == 4)?this.transactionId = '':this.transactionId,
      "transaction_mobile_nr": this.mobileNumber,
      "payment_mode_type": this.paymentModeType,
      "refund_later_checkbox": '',
      "refund_later_amount":'',
      "create_name": this.userInfo.username,      
    };
      
    this.inpatientService.receiptRefundOrCreditAction(dueFormData).subscribe(
      (response) => {
        console.log('patient info', response);
        this.toastrService.success(response.message);
        this.pendingDuePopupClose();        
        this.viewReceipt();
      },
      (err) => {
        console.log('HTTP Error', err);
        this.toastrService.error('Something went wrong..!');
        this.pendingDuePopupClose();
      }
    );      
  }          
  pendingDuePopupClose(){
    this.modalService.dismissAll();
    this.resetPendingDuePopup();
  }
}
