import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../shared/material/material.module';
// import { SampleAuthorizationComponent } from './sample-authorization/sample-authorization.component';
// import { ResultentryComponent } from './resultentry/resultentry.component';
// import { SampledataComponent } from './sampledata/sampledata.component';
// import { ResultAuthorizationComponent } from './result-authorization/result-authorization.component';
// import { ResultAuthorizedComponent } from './result-authorized/result-authorized.component';
// import { ResultauthorizedpartialComponent } from './resultauthorizedpartial/resultauthorizedpartial.component';
import { SharedModule } from '../shared/shared.module';
import { SampleCollectionComponent } from './sample-collection/sample-collection.component';
import { NgxEditorModule } from 'ngx-editor';
const labRouting: Routes = [
  {
    path : 'sampleCollection',
    component : SampleCollectionComponent
  }
    // {
    //   path: 'result', 
    //   component: ResultentryComponent
    // },
    // {
    //   path: 'sample', 
    //   component: SampleAuthorizationComponent
    // }
];

@NgModule({
  // declarations: [SampleAuthorizationComponent, ResultentryComponent, SampledataComponent, ResultAuthorizationComponent, ResultAuthorizedComponent, ResultauthorizedpartialComponent, SampleCollectionComponent],
  declarations: [
    SampleCollectionComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    MaterialModule,  
    NgxEditorModule,
    RouterModule.forRoot(labRouting),
  ]
})
export class LabModuleModule { }
