<div class="container-fluid">
  <div class=" text-center mt-3">
    <p class=" text-success card-title ">YOUR APPOINTMENT IS CONFIRMED !</p>
    <!--    <div class="text-center d-inline-block float-right">-->
    <!--      <a routerLink="/" class="btn btn-success btn-lg text-center my-5"><i class="fas fa-home mx-2"></i>Home</a>-->
    <!--    </div>-->
  </div>
  <div class="container">
    <p class="text-success text-center mt-3 card-title2"><i class="far fa-thumbs-up" class="con-font"></i></p>
    <p class=" text-dark con_doc_name text-center card-title1" >{{selectedDate.toUpperCase()}} at {{selectedSlot}} </p>

    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="frontside mt-3">
          <div class="card ">
            <div class="card-body p-3 text-center doctor-list">
              <p><img class=" img-fluid float-left list_img" src="assets/{{conSelectedDoctor.doctorName}}.png" alt="card image"></p>
              <h4 class="card-title mt-2" >{{conSelectedDoctor.doctorTitle}} {{conSelectedDoctor.doctorName}}</h4>
              <p class="card-text mb-1" style="font-size: 15px;">Experience:{{conSelectedDoctor.doctorExperience}}Years</p>
              <p class="card-text" style="font-size: 12px;">{{conSelectedDoctor.doctorSpecilization}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="frontside mt-3">
          <div class="card ">
            <div class="card-body p-3 text-center doctor-list">
              <i class="fas fa-hospital-symbol text-center  conform-img float-left text-success"></i>
              <h4 class="card-text hos-text" >{{receivedHospital.hospitalName}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">
          <p class="h6 text text-dark "><span class="con_doc_name font1 "> Appointment ID :&nbsp;</span>
            <span class="text-primary small-text1">{{receivedAppointmentId}}</span> </p>
        </th>
        <th scope="col">
          <p class="h6 text text-dark "><span class="con_doc_name font1 "> UMR No :&nbsp;</span>
            <span class="text-primary small-text1">{{conSelectedpatient.patientID}}</span> </p>
        </th>
        <th scope="col">
          <p class="h6 text-dark "><span class="con_doc_name font1 ">Patient Name : &nbsp;</span> 
            <span class="text-primary small-text1 text-capitalize">
              {{conSelectedpatient?.patientName}} {{conSelectedpatient.patientName}}</span></p>
        </th>
        <th scope="col"><p class="h6 text-dark  "><span class="con_doc_name font1 " >Mobile No :&nbsp; </span><span class="text-primary small-text1">{{conSelectedpatient.mobileNumber}}</span></p></th>
      </tr>
      </thead>
    </table>
    <!--  <hr>-->
    <div class=" patientInfo mt-2">

      <!--    <p class="h3 font-weight-bolder mt-4 text-center text-dark">Having second thoughts ?</p>-->
      <div class="d-flex justify-content-center">
        <div class="d-flex justify-content-around my-4 w-75 buttonGroup">

          <button  class="btn btn-outline-danger mx-1 py-2"  data-toggle="modal" data-target="#decideModal"><span class="h5">Cancel Appointment</span></button>
          <button (click)="confirmationMessageEmitter()" class=" py-2 btn btn-outline-success mx-1"><span class="h5">Reschedule Appointment</span></button>






          <div id="decideModal" class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
            <div class="modal-dialog modal-md  modal-dialog-centered" role="document">
              <div class="modal-content bg-light">
                <div class="modal-header">
                  <h3 class="modal-title" id="my-modal-title" style="text-align: center !important; margin: 0 auto;">Cancel Appointment</h3>
                  <!--              <button class="close" data-dismiss="modal" (click)="showModalCancel=false" aria-label="Close">-->
                  <!--                <span aria-hidden="true">&times;</span>-->
                  <!--              </button>-->
                </div>
                <div class="modal-body">
                  <ul>
                    <li class="m-1"><span class="font-weight-normal font1 text-dark" >Patient Name: </span> <span class="font text-capitalize">{{conSelectedpatient?.title}} {{conSelectedpatient.patientName}}</span></li>
                    <li class="m-1"><span class="font-weight-normal font1 text-dark" >Mobile Number: </span><span class="font">{{conSelectedpatient.mobileNumber}}</span></li>
                    <li class="m-1"><span class="font-weight-normal font1 text-dark" >Appointment Date: </span> <span class="font">{{selectedDate}}</span></li>
                    <li class="m-1"><span class="font-weight-normal font1 text-dark" >Appointment Time: </span> <span class="font">{{selectedSlot}}</span></li>
                  </ul>

                    <p class=" h5 text-center mx-auto">Do you want to cancel the appointment ?</p>
                    <div class="d-flex justify-content-center">
                      <div class="d-flex btnGroup justify-content-around w-75 mt-2">
                        <button  class="btn btn-outline-danger p-1 w-50" data-dismiss="modal"><span class="h4">NO</span></button>
                        <!--                    <a routerLink="/canceled" class="btn btn-outline-success btn-lg p-3"><h4>YES</h4></a>--> &nbsp; &nbsp;
                        <button  type="button" class="btn btn-outline-success p-1 w-50 " (click)="cancellingAppointment()" data-toggle="modal" data-dismiss="modal" data-target="#cancelMessage"><span class="h4">YES</span></button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade"  id="cancelSpinner" [ngStyle]="{'d-block': (modalString !== undefined && modalString !== 'cancellingAppointment')}">
            <div class="modal-dialog modal-dialog-centered modal-sm">
              <div class="modal-content bg-light" style="border-radius: 30px;">
                <div class="modal-body">
                  <h5 class="text-dark">Caancelling Appointment</h5>
                  <div class="spinner-border text-info"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade"  id="cancelMessage" [ngClass]="{'d-none': !(modalString === 'cancellingAppointment')}">
              <div class="modal-dialog modal-confirm">
                <div class="modal-content">
                  <div class="modal-header flex-column">
                    <div class="icon-box">
                      <i class="material-icons"><i class="fa fa-times" aria-hidden="true"></i></i>
                    </div>
                    <h4 class="modal-title w-100">YOUR APPOINTMENT IS CANCELLED</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div>
                  <div class="modal-footer justify-content-center">

                    <button type="button" class="btn btn-success" data-dismiss="modal" routerLink="/appointment">Ok</button>
                  </div>
                </div>
              </div>

            <!--<div class="modal-dialog modal-dialog-centered">
              <div class="modal-content bg-light" style="border-radius: 30px;">
                <div class="modal-body P-5 text-center">
                  <p class="text-center"><i class="fas fa-times-circle text-danger" style="font-size: 3rem"></i></p>
                  <p class="text-danger text font-weight-bold"  > YOUR APPOINTMENT IS CANCELLED</p>
                  <button class="btn btn btn-primary" data-dismiss="modal" routerLink="/appointment">ok</button>

                </div>
              </div>
            </div>-->
          </div>





        </div>
      </div>

    </div>
  </div>
</div>

