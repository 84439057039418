<ng-container class="row ml-1">
  <!-- Patient Info -->
  <div class="row col-12 patient-info">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-5"><label>Age / Gender</label></div>
            <div class="col-md-7">
                <span>{{patientInfo?.AGE}} / {{patientInfo?.GENDER}}</span>
            </div>
        </div>            
    </div>

    <div class="col-md-4">
        <div class="row">
            <div class="col-md-5"><label>UMR No</label></div>
            <div class="col-md-7">
                <span>{{patientInfo?.UMRNO}}</span>
            </div>
        </div>                       
    </div>

    <div class="col-md-4">                        
        <div class="row">
          <div class="col-md-6"><label>IP/ Admission No</label></div>
          <div class="col-md-6">
              <span>{{patientInfo?.IP_NR}}</span>
          </div>
        </div> 
    </div>
  </div>
  <hr class="m-0"/>
<!-- ./END Patient Info -->
  <div class="row ml-1">
      <div class="col-3 p-1">
        <fieldset class="legend-block mt-2">
          <legend class="legend-title blue-title mb-0">Test Name</legend>
          <div class="table-body-scroll">
            <table class="table table-hover service-table">
            <thead>
                <tr>
                  <th scope="col fweight600">Name Of Test</th>
                </tr>
              </thead>            
              <tbody class="mt-2">
                <tr *ngFor="let service of selectedAllServices"
                [ngClass]="{ 'selectedService': service.isSelected }" 
                (click)="getSampleIds(service)">                  
                  <td class="services line-break-anywhere">{{service?.serviceName}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
      <div class="col-9 p-1">
        <fieldset class="legend-block mt-2">
          <legend class="legend-title blue-title mb-0">Selected Test Info</legend>
          <div>
            <div class="col-md-12" *ngIf="sampleIdsList !== undefined">
              <div class="row">
                <table class="table table-hover">
                  <thead>
                    <tr colspan="col-12">
                      <th class="col-6">SampleId</th>
                      <th class="col-6">Date</th>
                    </tr>                    
                  </thead>
                  <tbody>
                    <tr colspan="col-12" *ngFor="let sequenceNo of sampleIdsList;let i = index">
                      <td [ngClass]="{'selService':resultIndex == i}" (click)="resultIndex = i;getSampleIdResults(sampleIdsPopup,sequenceNo);">
                        Sample Id: {{sequenceNo.sampleId}}
                      </td>
                      <td>{{sequenceNo.resultDate}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
          </div>          
        </fieldset>
      </div>    
  </div>
</ng-container>
<!-- View Lab Reports Popup -->
  <ng-template #sampleIdsPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">View Lab Reports of {{patientInfo?.TITLE + " " + patientInfo?.PATIENT_NAME}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="labServiceResult.length != 0 && labServiceType != 'subservices' && labServiceType != 'textEditor'">
        <div class="row col-md-12 col-sm-12 ml-0 mr-2 mb-3 font-weight-bold backgrnd">
            <div class="col-md-4 mt-1 pl-3 font-18 bgrnd"> Test Name </div>
            <div class="col-md-2 mt-1 pl-3 font-18 bgrnd"> Result </div>
            <div class="col-md-2 mt-1 pl-3 font-18 bgrnd"> Units </div>
            <div class="col-md-4 mt-1 pl-3 font-18"> Reference Range </div>
<!--        <div class="col-md-2 mt-1 font-18"> Remarks </div> -->
            <!-- <div class="col-md-2 mt-1 font-18"> Methodology</div> -->
        </div>
        <div class="row" *ngFor="let service of labServiceResult; let i= index">
            
            <ng-container *ngIf="service['SUB_TESTS'].length != 0 && service['SUB_PACKAGE_TESTS']">
            
                <div class="col-md-12" style="font-size: 21px;" *ngIf="service['SUB_TESTS'].length != 0">
                    <span style="font-weight: bolder;">{{service['NAME']}}</span></div>
            
                <div class="row pl-4" *ngFor="let subTest of service['SUB_TESTS'];let j=index;">
                    <div class="col-md-10 pl-3 mt-2 resultLabel" ><span class="resultLabel" style="font-size: 20px; font-weight: bold;"> {{subTest['NAME']}}</span></div><br>
                    <ng-container  *ngIf="subTest['SUB_TEST_SERVICES'].length != 0">
                        <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                            <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                            <div class="col-md-3 mt-1 font-18"> Result </div>
                            <div class="col-md-3 mt-1 font-18"> Units </div>
                            <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                        </div> -->
                        <div class="row col-md-12 pl-3 ml-1" *ngFor="let subTestService of subTest['SUB_TEST_SERVICES'];let l= index;">
                            <div class="col-md-4 mt-1 p-1 pl-2 font-19"> {{subTestService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                                <span class="fontsize" *ngIf="subTestService.RESULT_METHOD">({{subTestService.RESULT_METHOD}})</span></div>
                            <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{subTestService.RESULT_NUMERIC_VALUE}} </div>
                            <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{subTestService.RESULT_UNITS}} </div>
                            <div class="col-md-4 mt-1 p-1 pl-2 font-19"> {{subTestService.RESULT_NORMAL_RANGES}} </div>
                            <!-- <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{subTestService.RESULT_REMARKS}} </div> -->
                            <!-- <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{subTestService.RESULT_METHOD}} </div> -->
                        </div>
                    </ng-container><br><br>
                    <ng-container *ngIf="subTest['SUB_TEST_INNER_SERVICES'].length != 0">
                        <div class="col-md-12 pl-4" *ngFor="let innerHeading of subTest['SUB_TEST_INNER_SERVICES'] | keyvalue">
                            <div class="row col-md-12 pl-3 "><span class="resultLabel" style="font-size: 19px; font-weight: bold;">{{subTest.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</span></div><br>
                            <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                                <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                                <div class="col-md-3 mt-1 font-18"> Result </div>
                                <div class="col-md-3 mt-1 font-18"> Units </div>
                                <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                            </div> -->
                            <ng-container>
                                <div class="row m-0 col-md-12 text-left" *ngFor="let innerService of subTest['SUB_TEST_INNER_SERVICES'][innerHeading.key];let k = index;">
                                    <div class="col-md-4 mt-1 p-1 font-19"> {{innerService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                                        <span class="fontsize" *ngIf="innerService.RESULT_METHOD">({{innerService.RESULT_METHOD}})</span></div>
                                    <div class="col-md-2 mt-1 p-1 font-19"> {{innerService.RESULT_NUMERIC_VALUE}} </div>
                                    <div class="col-md-2 mt-1 p-1 font-19"> {{innerService.RESULT_UNITS}} </div>
                                    <div class="col-md-4 mt-1 p-1 font-19"> {{innerService.RESULT_NORMAL_RANGES}} </div>
                                    <!-- <div class="col-md-2 mt-1 p-1 font-19"> {{innerService.RESULT_REMARKS}} </div> -->
                                    <!-- <div class="col-md-2 mt-1 p-1 font-19"> {{innerService.RESULT_METHOD}} </div> -->
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>                    
                </div>
            </ng-container>
    
            <!-- serive itself -->
            <ng-container *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length == 0  ">
                <!-- <div class="col-md-10 resultLabel"><h6>{{service['NAME']}}</h6></div><br> -->
                <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                    <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                    <div class="col-md-3 mt-1 font-18"> Result </div>
                    <div class="col-md-3 mt-1 font-18"> Units </div>
                    <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                </div> -->
                <div class="row mb-2 mt-1 ml-1 col-md-12 text-left">
                    <div class="col-md-4 p-1 font-19">{{service.NAME}}<br><!--resultLabel-->
                        <span class="fontsize" *ngIf="service.RESULT_METHOD">({{service.RESULT_METHOD}})</span></div>
                    <div class="col-md-2 p-1 pl-4 font-19"> {{service.RESULT_NUMERIC_VALUE}} </div>
                    <div class="col-md-2 p-1 pl-4 font-19"> {{service.RESULT_UNITS}} </div>
                    <div class="col-md-4 p-1 pl-3 font-19"> {{service.RESULT_NORMAL_RANGES}} </div>
                    <!-- <div class="col-md-2 p-1 pl-2 font-19"> {{service.RESULT_REMARKS}} </div> -->
                    <!-- <div class="col-md-2 p-1 font-19"> {{service.RESULT_METHOD}} </div> -->
                </div>
            </ng-container>
        <!-- sub sub service -->
            <!-- <br><br><br> -->
            <ng-container *ngIf="service['SUB_TESTS'].length == 0 && !service['SUB_PACKAGE_TESTS']  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                <div class="col-md-12 pl-3 mt-2 p-1"><span class="resultLabel" style="font-size: 21px; font-weight: bolder;">{{service['NAME']}}</span></div>
                <br><br>
                <ng-container *ngIf="service['SUB_TESTS'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                    <ng-container>
                        <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                            <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                            <div class="col-md-3 mt-1 font-18"> Result </div>
                            <div class="col-md-3 mt-1 font-18"> Units </div>
                            <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                        </div> -->
                        <div class="col-md-12 mt-1 mb-1" *ngFor="let testService of service['SUB_TEST_SERVICES'];let p = index;">
                            <div class="row col-md-12 ml-1 mb-1 text-left">
                                <div class="col-md-4 p-1 font-19"> {{testService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                                    <span class="fontsize" *ngIf="testService.RESULT_METHOD">({{testService.RESULT_METHOD}})</span></div>
                                <div class="col-md-2 p-1 pl-3 font-19"> {{testService.RESULT_NUMERIC_VALUE}} </div>
                                <div class="col-md-2 p-1 pl-3 font-19"> {{testService.RESULT_UNITS}} </div>
                                <div class="col-md-4 p-1 pl-3 font-19"> {{testService.RESULT_NORMAL_RANGES}} </div>
                                <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{testService.RESULT_REMARKS}} </div> -->
                                <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{testService.RESULT_METHOD}} </div> -->
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-12 pl-4" *ngFor="let innerHeading of service['SUB_TEST_INNER_SERVICES'] | keyvalue">
                        <div class="row col-md-12" ><span class="resultLabel" style="font-size: 19px; font-weight: bolder;">{{service.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</span></div><br>
                        <ng-container>
                            <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                                <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                                <div class="col-md-3 mt-1 font-18"> Result </div>
                                <div class="col-md-3 mt-1 font-18"> Units </div>
                                <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                            </div> -->
                            <div class="col-md-12 mt-1" *ngFor="let innerService of service['SUB_TEST_INNER_SERVICES'][innerHeading.key];let m = index;">
                                <div class="row col-md-12 mb-2 text-left"><!-- need to remove ml-1 -->
                                    <div class="col-md-4 p-1 font-19"> {{innerService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                                        <span class="fontsize" *ngIf="innerService.RESULT_METHOD">({{innerService.RESULT_METHOD}})</span></div>
                                    <div class="col-md-2 p-1 pl-3 font-19"> {{innerService.RESULT_NUMERIC_VALUE}} </div>
                                    <div class="col-md-2 p-1 pl-4 font-19"> {{innerService.RESULT_UNITS}} </div>
                                    <div class="col-md-4 p-1 pl-4 font-19"> {{innerService.RESULT_NORMAL_RANGES}} </div>
                                    <!-- <div class="col-md-2 p-1 pl-5 font-19"> {{innerService.RESULT_REMARKS}} </div> -->
                                    <!-- <div class="col-md-2 p-1 pl-5 font-19"> {{innerService.RESULT_METHOD}} </div> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>                    
                </ng-container>        
            </ng-container>
            <br>
            <ng-container  *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length != 0 && service['SUB_TEST_INNER_SERVICES'].length == 0">
                <div class="col-md-12 text-left"><span class="resultLabel" style="font-size: 19px; font-weight: bolder;">{{service['NAME']}}</span></div>
                <!-- <div class="row m-0 col-md-12 p-4 pt-3">
                    <div class="col-md-3 mt-1 resultLabel font-18"> Test Name </div>
                    <div class="col-md-3 mt-1 font-18"> Result </div>
                    <div class="col-md-3 mt-1 font-18"> Units </div>
                    <div class="col-md-3 mt-1 font-18"> Reference Range </div>
                </div> -->
                <div class="col-md-12 " *ngFor="let subTestService of service['SUB_TEST_SERVICES'];let n = index;">
                    
                    <div class="row col-md-12 p-1 ml-1 text-left"><!-- need to remove ml-1 -->
                        <div class="col-md-4 mt-1 font-19"> {{subTestService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                            <span class="fontsize" *ngIf="subTestService.RESULT_METHOD"><b>({{subTestService.RESULT_METHOD}})</b></span></div>
                        <div class="col-md-2 mt-1 pl-4 font-19"> {{subTestService.RESULT_NUMERIC_VALUE}} </div>
                        <div class="col-md-2 mt-1 pl-3 font-19"> {{subTestService.RESULT_UNITS}} </div>
                        <div class="col-md-4 mt-1 pl-3 font-19"> {{subTestService.RESULT_NORMAL_RANGES}} </div>
                        <!-- <div class="col-md-2 mt-1 pl-2 font-19"> {{subTestService.RESULT_REMARKS}} </div> -->
                        <!-- <div class="col-md-2 mt-1 pl-1 font-19"> {{subTestService.RESULT_METHOD}} </div> -->
                    </div>
                       
                </div>
            </ng-container>
        </div>
    </ng-container>
    <!-- Renal Profile Test -->
    <ng-container *ngIf="labServiceResult.length != 0 && labServiceType == 'subservices'">
      <div class="row col-md-12 mb-3 ml-1 text-left font-weight-bold">
          <div class="col-md-4 mt-1 font-18"> Test Name </div>
          <div class="col-md-2 mt-1 font-18"> Result </div>
          <div class="col-md-2 mt-1 font-18"> Units </div>
          <div class="col-md-4 mt-1 font-18"> Reference Range </div>
          <!-- <div class="col-md-2 mt-1 font-18"> Remarks </div> -->
          <!-- <div class="col-md-2 mt-1 font-18"> Methodology</div> -->
      </div>
      <div class="row">
           <!-- Individual subTest Start -->
          <div class="col-md-12" *ngIf="labServiceResult.single.length != 0"><span class="resultLabel" style="font-size: 21px; font-weight: bold;">{{labServiceResult.single[0].SERVICE_NAME}}</span></div>

          <div class="row col-md-12 pl-3 ml-1" *ngFor="let sigleService of labServiceResult.single; let i= index">
              <div class="col-md-4 mt-1 p-1 pl-2 font-19"> {{sigleService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
              <span class="fontsize" *ngIf="sigleService.RESULT_METHOD">({{sigleService.RESULT_METHOD}})</span></div>
              <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{sigleService.RESULT_NUMERIC_VALUE}} </div>
              <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{sigleService.RESULT_UNITS}} </div>
              <div class="col-md-4 mt-1 p-1 pl-2 font-19"> {{sigleService.RESULT_NORMAL_RANGES}} </div>
              <!-- <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{sigleService.RESULT_REMARKS}} </div> -->
              <!-- <div class="col-md-2 mt-1 p-1 pl-2 font-19"> {{sigleService.RESULT_METHOD}} </div> -->
          </div>

          <ng-container *ngFor="let sigleSubService of labServiceResult.subService | keyvalue; let l= index">
              <div class="col-md-12 pl-3 mt-2 p-1" ><span class="resultLabel" style="font-size: 19px; font-weight: bold;">{{sigleSubService.key}}</span></div>
              <br><br>
              <ng-container>
                  <div class="col-md-12 mt-1 mb-1" *ngFor="let subService of labServiceResult.subService[sigleSubService.key]['single']; let k= index">
                      <div class="row col-md-12 ml-1 mb-1 text-left">
                          <div class="col-md-4 p-1 font-19"> {{subService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                          <span class="fontsize" *ngIf="subService.RESULT_METHOD">({{subService.RESULT_METHOD}})</span></div>
                          <div class="col-md-2 p-1 pl-3 font-19"> {{subService.RESULT_NUMERIC_VALUE}} </div>
                          <div class="col-md-2 p-1 pl-3 font-19"> {{subService.RESULT_UNITS}} </div>
                          <div class="col-md-4 p-1 pl-3 font-19"> {{subService.RESULT_NORMAL_RANGES}} </div>
                          <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{subService.RESULT_REMARKS}} </div> -->
                          <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{subService.RESULT_METHOD}} </div> -->
                      </div>
                  </div>
                  <ng-container *ngIf="labServiceResult.subService[sigleSubService.key]['innerHead'] != undefined">
                      <ng-container *ngFor="let subInnerService of labServiceResult.subService[sigleSubService.key]['innerHead'] | keyvalue; let t= index">
                          <div class="col-md-12 pl-3 mt-2 p-1" ><span class="resultLabel" style="font-size: 19px; font-weight: bold;">{{subInnerService.key}}</span></div>
                          <ng-container>
                              <div class="col-md-12" *ngFor="let subInnerHeadService of labServiceResult.subService[sigleSubService.key]['innerHead'][subInnerService.key]; let t= index">
                                  <div class="row col-md-12 ml-1 mb-1 text-left">
                                      <div class="col-md-4 p-1 font-19"> {{subInnerHeadService.TEST_PROFILE_NAME}}<br><!--resultLabel-->
                                      <span class="fontsize" *ngIf="subInnerHeadService.RESULT_METHOD">({{subInnerHeadService.RESULT_METHOD}})</span></div>
                                      <div class="col-md-2 p-1 pl-3 font-19"> {{subInnerHeadService.RESULT_NUMERIC_VALUE}} </div>
                                      <div class="col-md-2 p-1 pl-3 font-19"> {{subInnerHeadService.RESULT_UNITS}} </div>
                                      <div class="col-md-4 p-1 pl-3 font-19"> {{subInnerHeadService.RESULT_NORMAL_RANGES}} </div>
                                      <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{subInnerHeadService.RESULT_REMARKS}} </div> -->
                                      <!-- <div class="col-md-2 p-1 pl-3 font-19"> {{subInnerHeadService.RESULT_METHOD}} </div> -->
                                  </div>
                              </div>
                          </ng-container>
                      </ng-container>
                  </ng-container>
              </ng-container>
          </ng-container>
      </div>

  </ng-container>
  <ng-container *ngIf="labServiceType == 'textEditor'">
    <div class="row">
      No Results Found.
    </div>
  </ng-container>
    </div>  
  </ng-template>  
<!--  /End view Lab Reports Popup-->


