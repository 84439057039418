
<div class="container-fluid bg-white bgImage p-3">
  <div class="bg-light container  ">
    <div id="docCards" class="card my-3 d-inline-flex border border-secondary rounded-0" style="height: 450px" *ngFor="let doctor of docProfiles">
      <img class="card-img-top mt-4 rounded border border-primary" [src]="doctor.imagepath"  style="width: 40%; max-height: 150px; margin: 0 auto" [alt]="doctor.name">
      <div class="card-body">
        <h4 class="card-title  text-dark">{{doctor.name}}</h4>
        <p class="card-text text-primary font-weight-bold">{{doctor.specialisation}} | {{doctor.experience}} Years</p>
        <p class="card-text text-secondary" style="height: 80px; overflow-y: auto;">{{doctor.description}}</p>
        <a routerLink="/doctor_info" class="btn btn-primary w-75" style="border-radius: 19px">Select</a>
      </div>
    </div>

  </div>


</div>

