<div class="land">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="m-3">
        <button type="button" (click)="bodyDisplay = 'registration';clearAllData()" class="btn btn-sm  btnn btn-light text-dark"
          [class.active]="bodyDisplay === 'registration'">Registration</button>
      </div>
      <div class="m-3">
        <button type="button" (click)="bodyDisplay = 'viewoutPatient';clearAllData()" class="btn btn-sm btnn btn-light"
          [class.active]="bodyDisplay === 'viewoutPatient'">View Outpatients</button>
      </div>
      <div class="m-3">
        <button type="button" (click)="bodyDisplay = 'viewoutPatientBill';clearAllData()" class="btn btn-sm btnn btn-light"
          [class.active]="bodyDisplay === 'viewoutPatientBill'">Search/View OP Bills</button>
      </div>
      <!-- <div class="m-3">
        <button type="button" class="btn btn-sm btnn btn-light text-dark">Triage Examination</button>
      </div>
      <div class="m-3">
        <button type="button" class="btn btn-sm btnn btn-light text-dark">Queue Management</button>
      </div> -->
      <div class="m-3">
        <!-- <button type="button" routerLink="/reports" class="btn btn-sm  btnn btn-light text-dark">Reports</button> -->
        <button type="button" (click)="bodyDisplay = 'reports';clearAllData()" class="btn btn-sm btnn btn-light"
          [class.active]="bodyDisplay === 'reports'">Reports</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="bodyDisplay === 'registration'" class="container-fluid">
  <div class="row ">

    <div class="col-md-5 my-customsr bgbox pb-5 p-0 scroll-1 pt-4">
      <div class="row mt-1 px-2">
        <div class="col-sm-6 col-md-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input placeholder="Search by Mobile Number" type="number" id="searchMobile" name="patientNameSearch"
                class="form-control" [(ngModel)]="mobileValue" #mobileNumber 
                [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10"
                (keydown)="checkingMobileNumber($event)" (keyup)="keyUpEnterFunction($event, 'num')">
                <span class="input-group-text text-size">{{textCount}}</span>
                 
              <div class="input-group-append">                
                <span class="handCursor input-group-text" (click)="searchByNumber(mobileNumber, 'num')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <small class="searchLabel my-1"
              [class.is-invalid-error]="mobileNumber.value.length > 0 && mobileNumber.value.length < 10">Search with
              10-digit Mobile Number</small>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input placeholder="Search by UMR" 
                     type="number" 
                     name="patientNameSearch" 
                     [(ngModel)]="UMRvalue" 
                     #umrId
                     class="form-control" 
                     (keyup)="keyUpEnterFunction($event, 'id')" />
              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchByNumber(umrId, 'id')">
                  <i class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1">Search with full UMR Number</label>
          </div>
        </div>
      </div>
      <div class="row px-2">
        <div class="col-sm-6 col-md-6">
          <div class="form-group search_align">
            <div class="input-group input-group-sm mb-0  inputRadius">
              <input placeholder="Search by Patient Name" type="text" #patientname name="patientNameSearch"
                [(ngModel)]="nameValue" class="form-control"
                [class.is-invalid-error]="patientname.value.length < 3 && patientname.value.length > 0"
                (keyup)="keyUpEnterFunction($event, 'name')" />
              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchByNumber(patientname, 'name')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1"
              [class.is-invalid-error]="patientname.value.length < 3 && patientname.value.length > 0">Search with
              minimum 3 characters</label>
          </div>

        </div>
        <div class="col-sm-6 col-md-6">
          <h6 (click)="setValuestoEmpty(); clearSearch()" class="cont btn btn-sm float-md-right my-sm-2 my-1 my-md-0 btn-light">
            New Patient
          </h6>
        </div>
      </div>
      <div class="responsive" style="overflow-x: auto">
        <table class="table table-hover table-striped">

          <!--Table head-->
          <thead>
            <tr>
              <th class=" fweight600 " style="min-width: 160px">Patient Name </th>
              <th class=" fweight600 wid120">Mobile No</th>
              <th class=" fweight600  wid120">UMR No</th>
              <th class="fweight600 wid120">Action</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let patient of appointmentsData || patientsData, let i = index" class="handCursor">
              <td class="text-capitalize">{{patient.title}} {{patient.patientName}} </td>
              <td>{{patient.mobileNumber || patient.patientPhone}}</td>
              <td>{{patient.patientID}}</td>
              <td>
                <div>
                  <a href="javascript:void(0)"
                    (click)="chekingPatientStatus(patient, i,false)"
                    *ngIf="!(selectedVisitIdArray[patient.patientName + patient.patientID] > 0) && !visitIpExists"
                    class="mr-1 font-small-icon"
                    title="Add Visit">
                    <i class="fa fa-plus"></i>                   
                  </a>

                  <a href="javascript:void(0)"
                    (click)="gettingPatientData(patient.patientID, i)"
                    class="mr-1 font-small-icon">
                    <i class="fa fa-pencil-alt"></i>
                  </a>
                  <a href="javascript:void(0)" 
                    *ngIf="selectedVisitIdArray[patient.patientName + patient.patientID] > 0 || visitIpExists"
                    (click)="chekingPatientStatus(patient, i, true);viewVisitFunction(patient)"
                    title="View Visit" class="font-small-icon">
                    <i class="fa fa-eye"></i>
                  </a>                    
                </div>                
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="patientsData.length <=0 " class="text-info text-center font-weight-bold">
          Waiting for Patient Information
        </div>
      </div>
    </div>

    <ng-container *ngIf="toggleOnOff === 'registration'">
      <div class="col-md-7 my-custom-scrollbar float-right scroll-1">
        <fieldset class="legend-block mt-3">
          <legend class="legend-title mb-0" *ngIf="!editButton">New Patient</legend>
          <legend class="legend-title mb-0" *ngIf="editButton">Patient Information</legend>
        
          <div class="text-center mt-1 clearfix">
            <i class="fas fa-edit close handCursor" (click)="editOption = false" *ngIf="editButton" style="color: #173267"
              title="click here to edit form"></i>
          </div>

          <form class="form-horizontal" (ngSubmit)=" check.form.valid && itemCity && onSubmit()  " #check="ngForm" autocomplete="off">
            <div class="row">
              <div class="col-md-5 row form-group">
                <label for="title" class="control-label col-sm-4">Title
                  <span class="requiredSymbol">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control-sm form-control border border-primary"
                    (change)="changingGenderFields()" name="title" [(ngModel)]="model.title" #title="ngModel"
                    [ngClass]="{ 'is-invalid-error': check.submitted && title.invalid }" id="title"
                    [attr.disabled]="(editOption) ? '' : null" required>
                    <option [ngValue]="''">-- Select Title --</option>
                    <option value="Baby" selected title="0 to 1 year boy and girl">Baby </option>
                    <option value="B/O." selected title="0 to 1 year boy and girl">B/O. </option>
                    <option value="Master." title="1 to 12 years Boys">Master. </option>
                    <option value="Miss." title="1 to 12 years Girls">Miss. </option>
                    <option value="Mr." title="Above 13 years Boys">Mr. </option>
                    <option value="Ms." title="Above 13 years Girls and Unmarried">Ms. </option>
                    <option value="Mrs." title="For married women">Mrs. </option>
                  </select>
                  <span *ngIf="check.submitted && title.invalid" class="bg-warningtxt">Title is required</span>
                </div>
              </div>
              <div class="form-group col-md-7 row">
                <label for="patname" class="col-sm-4">Name
                  <span class="requiredSymbol">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control-sm form-control border border-primary" name="ptname"
                    [(ngModel)]="model.patientName" #ptname="ngModel" [readOnly]="editOption"
                    [ngClass]="{ 'is-invalid-error': check.submitted && ptname.invalid }" pattern="^[a-zA-Z][a-zA-Z\. ]*$"
                    id="patname" required>
                  <span *ngIf="check.submitted && model.patientName === ''" class="bg-warningtxt">Name is required</span>
                  <span *ngIf="ptname.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-5 form-group row mb-0">
                <label class="col-sm-4">Gender
                  <span class="requiredSymbol">*</span></label>
                <div class="form-group col-sm-8">
                  <label class="radio-inline" for="radio122">
                    <input type="radio" class="mx-1" id="radio122" value="MALE" name="gender"
                      (change)="changeRadioGender($event)" #gender="ngModel" required="required"
                      [ngModel]="model.gender">Male
                  </label>
                  <label class="radio-inline" for="radio222">
                    <input type="radio" class="mx-1" id="radio222" value="FEMALE" name="gender"
                      (change)="changeRadioGender($event)" #gender="ngModel" required="required"
                      [disabled]="model.gender !== '' && model.title !== 'B/O.' && model.title !== 'Baby'" [ngModel]="model.gender">Female
                  </label>
                  <div *ngIf="check.submitted && gender.invalid" class="bg-warningtxt width100">
                    Gender is required
                  </div>
                </div>
              </div>

              <div class="col-md-7 form-group row">
                <label for="patientmobNumberf" class="col-sm-4">Mobile No
                  <span class="requiredSymbol">*</span></label>
                <div class="col-sm-8">
                  <input type="number" 
                         class="form-control-sm form-control border border-primary" 
                         #patMobile
                         name="mobile" 
                         [(ngModel)]="model.mobileNumber" 
                         #mobile="ngModel"
                         [readOnly]="editOption && model.mobileNumber"
                         [ngClass]="{ 'is-invalid-error': check.submitted && mobile.invalid }"
                         pattern="^((\\+91-?)|0)?[0-9]{10}$"
                         (focusout)="existingPatientsCheck(patMobile, 'num', existingPatientsModal)" id="patientmobNumberf"
                         (keydown)="checkingMobileNumber($event)" required>
                  <span *ngIf="check.submitted && model.mobileNumber === ''" class="bg-warningtxt">Mobile Number is
                    required.</span>
                  <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Invalid Mobile number</span>
                </div>
              </div>
            </div>


            <div class="row my-0 py-0">
              <div class="col-md-5 form-group row py-0">
                <label for="dob1" class="col-sm-4 py-0 my-0">D.O.B
                  <span class="requiredSymbol">*</span></label>
                <div class="col-sm-8 my-0 py-0">
                  <input type="date" class="form-control-sm form-control border border-primary"
                    placeholder="dd/mm/yyyy" (click)="checkInputDate($event)" (keyup)="checkInputDate($event)"
                    [max]="maxDate" [min]="minDate" name="dateofbirth" #dob #dateofbirth="ngModel"
                    [(ngModel)]="model.dateOfBirth " (input)="gettingAge(model.dateOfBirth)"
                    [ngClass]="{'is-invalid--error':check.submitted && dateofbirth.invalid}" id="dob1"
                    [readOnly]="editOption" required>
                  <span *ngIf="check.submitted && dateofbirth.invalid" class="bg-warningtxt">D.O.B is required</span>
                  <span *ngIf="yearError || monthError || dateError" class="bg-warningtxt">Select correct Title or Date/
                    Age</span>
                </div>
              </div>

              <div class="col-md-7 form-group row py-0">
                <label for="patientageff" class="col-sm-4">Age
                  <span class="requiredSymbol">*</span></label>
                <div id="patientageff" class="row ml-1 col-sm-8">
                  <div class="form-group col-4 px-1">
                    <div class="input-group-sm input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Y</span>
                      </div>
                      <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                        [readOnly]="editOption" (keyup)="checkingByYear($event)" (input)="assigningYears($event)"
                        placeholder="Years" [min]="minYear" [max]="maxYear" width="2" size="4" id="forYears"
                        [value]="patientDobYear">

                    </div>

                  </div>
                  <div class="form-group col-4 px-1">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">M</span>
                      </div>
                      <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                        [readOnly]="editOption" (keyup)="checkingByMonth($event)" (input)="assigningMonths($event)"
                        placeholder="Months" [min]="minMonth" [max]="maxMonth" width="2" size="4" id="forMonths"
                        [value]="patientDobMonth">
                    </div>
                  </div>
                  <div class="form-group col-4 px-1">
                    <div class="input-group input-group-sm">
                      <div class="input-group-append ">
                        <span class="input-group-text borderrightnone">D</span>
                      </div>
                      <input type="number" class="form-control-sm form-control mx-1 borderleftnone"
                        [readOnly]="editOption" (keyup)="checkingByDay($event)" (input)="assigningDate($event)"
                        placeholder="Days" [min]="minDay" [max]="maxDay" width="2" size="4" id="forDays"
                        [value]="patientDobDate">
                    </div>
                  </div>
                  <span *ngIf="check.submitted &&dateofbirth.invalid" class="bg-warningtxt">Age is required</span>
                </div>

              </div>
            </div>

            <div class="row mt-0">
              <div class="form-group col-md-5 row">
                <label for="patientmobEmailrff" class="text-dark col-sm-4">Email
                  <!--                <span class="requiredSymbol">*</span>-->
                </label>
                <div class="col-sm-8">
                  <input type="email" 
                         class="form-control-sm form-control border border-primary" 
                         #patemail
                         name="email" 
                         [(ngModel)]="model.emailId" 
                         #email="ngModel"
                         (focusout)="existingPatientsCheck(patemail, 'email', existingPatientsModal)"
                         [ngClass]="{ 'is-invalid-error': check.submitted && email.invalid }" 
                         [readOnly]="editOption"
                         pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" 
                         id="patientmobEmailrff">
                  <!--                <span *ngIf="check.submitted && model.emailId === ''" class="bg-warningtxt">Email is required.</span>-->
                  <span *ngIf="email.errors?.pattern" class="bg-warningtxt">Invalid Email format</span>
                </div>
              </div>
              <div class="col-md-7 form-group row">
                <label for="add" class="col-sm-4">Address
                  <span class="requiredSymbol">*</span></label>
                <div class="col-sm-8">
                  <textarea type="text" class="form-control-sm form-control border border-primary" rows="1"
                    cols="55" name="address" [(ngModel)]="model.address" #address="ngModel" [readOnly]="editOption"
                    [ngClass]="{ 'is-invalid-error': check.submitted && address.invalid }" id="add" required>
                  </textarea>
                  <span *ngIf="check.submitted &&address.invalid" class="bg-warningtxt">Address is required</span>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="form-group col-md-5 row">
                <label for="city1" class="col-sm-4">City
                  <span class="requiredSymbol">*</span></label>
                  <div class="col-sm-8">
                    <div *ngIf="editOption">
                      <ng-autocomplete
                        [data]="citiesArray"
                        [searchKeyword]="citiesKeyword"
                        placeholder="Enter the city's Name"
                        (selected)='selectAutocompleteEvent($event, "cities")'
                        (inputCleared)='onRemoveSelected($event,"cities")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                        [(ngModel)]="model.city" #city="ngModel"
                        name="city"
                    [ngClass]="{ 'is-invalid-error': check.submitted && city.invalid }"
                       >                           
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item> 
                        <a [innerHTML]="item.cityName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  
                    <div *ngIf="!editOption"> 
                      <ng-autocomplete
                        [data]="citiesArray"
                        [searchKeyword]="citiesKeyword"
                        placeholder="Enter the city's Name"
                        (selected)='selectAutocompleteEvent($event, "cities")'
                        (inputCleared)='onRemoveSelected($event,"cities")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                       >                           
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item> 
                        <a [innerHTML]="item.cityName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <span *ngIf="check.submitted && !itemCity" class="bg-warningtxt">City is required</span>
                  </div>
              </div>
              <div class="col-md-7 form-group row">
                <label for="ste" class="col-sm-4">State
                  <span class="requiredSymbol">*</span>
                </label>
                <div class="col-sm-8 ">
                  <input type="text" class="form-control-sm form-control border border-primary" name="state123"
                    [(ngModel)]="model.stateCountry" #state123="ngModel" [readOnly]="editOption"
                    [ngClass]="{ 'is-invalid-error': check.submitted && state123.invalid }" id="ste" required>
                  <span *ngIf="check.submitted &&state123.invalid" class="bg-warningtxt">State is required</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group row col-md-5">
                <label for="cont" class="col-sm-4">Country
                  <span class="requiredSymbol">*</span>
                </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control-sm form-control border border-primary" name="countryyy"
                    [(ngModel)]="model.country" #countryyy="ngModel" [readOnly]="editOption"
                    [ngClass]="{ 'is-invalid-error': check.submitted && countryyy.invalid }" id="cont" required>
                  <span *ngIf="check.submitted &&countryyy.invalid" class="bg-warningtxt">Country is required</span>
                </div>
              </div>
              <div class="col-md-7 form-group row">
                <label for="pin" class="col-sm-4">Pincode
                  <span class="requiredSymbol">*</span>
                </label>
                <div class=" col-sm-8">
                  <input type="text" class="form-control-sm form-control border border-primary" name="pincode"
                    [(ngModel)]="model.pincode" #pincode="ngModel" [readOnly]="editOption"
                    [ngClass]="{ 'is-invalid-error': check.submitted && pincode.invalid }" pattern="^(\d{4}|^\d{6})$"
                    id="pin" required>
                  <span *ngIf="check.submitted && model.pincode === ''" class="bg-warningtxt">Pincode is required</span>
                  <span *ngIf="pincode.errors?.pattern" class="bg-warningtxt">Invalid pin code</span>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-md-5 form-group row">
                <label for="IDTYPE" class="col-sm-4">Identity Type
                  <!--                <span class="requiredSymbol">*</span>-->
                </label>
                <div class="col-sm-8">
                  <select class="form-control-sm form-control border border-primary" name="IDTYPE"
                    [(ngModel)]="model.identityType" #IDTYPE="ngModel" (change)="assigningPatterns($event)"
                    [attr.disabled]="(editOption) ? '' : null"
                    [ngClass]="{ 'is-invalid-error': check.submitted && IDTYPE.invalid }" id="IDTYPE">
                    <option value="">Select Identity Type</option>
                    <option value="Aadhar">Aadhar</option>
                    <option value="Driving license">Driving license</option>
                    <option value="Voter ID">Voter ID</option>
                    <option value="Pan Card">Pan card</option>
                    <option value="Passport">Passport</option>
                    <option value="Employee Id">Employee ID </option>
                  </select>
                  <span *ngIf="check.submitted && IDTYPE.invalid" class="bg-warningtxt">Identity Type is required</span>
                </div>
              </div>
              <div class="col-md-7 form-group row">
                <label class="col-sm-4">Identity No
                  <!--                <span class="requiredSymbol">*</span>-->
                </label>
                <div class="col-sm-8">
                  <input [ngClass]="{ 'is-invalid-error':(check.submitted && idNUmber.invalid) && check.submitted}"
                    type="text" name="idNUmber" class="form-control-sm form-control border border-primary"
                    [pattern]="idPattern" #idNUmber="ngModel" [(ngModel)]="model.identityIdNr" [readOnly]="editOption"
                    [required]="model.identityType !== ''">
                  <span class="bg-warningtxt"
                    *ngIf="model.identityType !== '' && check.submitted && model.identityIdNr === ''">Identity Number is
                    required</span>
                  <span class="bg-warningtxt" *ngIf="model.identityType ==='Aadhar' && idNUmber.errors?.pattern">Invalid
                    Aadhar Number</span>
                  <span class="bg-warningtxt"
                    *ngIf="model.identityType ==='Driving license' && idNUmber.errors?.pattern">Invalid Driving
                    license</span>
                  <span class="bg-warningtxt" *ngIf="model.identityType ==='Voter ID' && idNUmber.errors?.pattern">Invalid
                    Voter ID</span>
                  <span class="bg-warningtxt" *ngIf="model.identityType ==='Pan Card' && idNUmber.errors?.pattern">Invalid
                    Pan Card</span>
                  <span class="bg-warningtxt" *ngIf="model.identityType ==='Passport' && idNUmber.errors?.pattern">Invalid
                    Passport</span>
                  <!--                                    <span *ngIf="check.submitted && model.identityIdNr === ''" class="bg-warningtxt">Identity Number is required.</span>-->
                  <!--                    <span *ngIf="mobile.errors?.pattern" class="bg-warningtxt">Identity Number number</span>-->
                </div>
              </div>
            </div>
            <hr class="d-none d-md-block">

            <div class="d-sm-flex px-2 justify-content-between">
              <div class="mx-2 form-group d-sm-flex">
                <label for="kin1" class="mr-sm-2">Next of Kin1 / Parent / Guardian</label>
                <div>
                  <div class="ml-sm-2">
                    <input type="text" class="form-control-sm form-control border border-primary"
                      name="Nextofkin1" [(ngModel)]="model.nextOfKinName" #Nextofkin1="ngModel" [readOnly]="editOption"
                      [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1.invalid }"
                      pattern="^[a-zA-Z][a-zA-Z\. ]*$" id="kin1" [required]="model.nextOfKinRelation !== ''">
                    <span *ngIf="check.submitted && model.nextOfKinName === '' && model.nextOfKinRelation !== ''"
                      class="bg-warningtxt">Next of Kin1 / Parent / Guardian 1 is required</span>
                    <span *ngIf="Nextofkin1.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                  </div>
                </div>
              </div>
              <div class=" mx-2 form-group d-sm-flex">
                <label for="Rkin1" class="mr-sm-2">Relation</label>
                <div class="ml-sm-2">
                  <select class="form-control-sm form-control  border border-primary " name="Nextofkin1relation"
                    [(ngModel)]="model.nextOfKinRelation" #Nextofkin1relation="ngModel"
                    [attr.disabled]="(editOption) ? '' : null"
                    [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin1relation.invalid }" id="Rkin1"
                    [required]="model.nextOfKinName !== ''">
                    <option [ngValue]="''">Select</option>
                    <option>Father</option>
                    <option>Mother</option>
                    <option>Brother</option>
                    <option>Sister</option>
                    <option>Wife</option>
                    <option>Husband</option>
                    <option>Son</option>
                    <option>Daughter </option>
                    <option>Son-in-law</option>
                    <option>Daughter-in-law</option>
                    <option>In-laws</option>
                    <option>Guardian </option>
                    <option>Friends </option>
                  </select>
                  <span *ngIf="check.submitted && Nextofkin1relation.invalid" class="bg-warningtxt">Relation is
                    required</span>
                </div>
              </div>
              <div class="mx-2 form-group d-sm-flex">
                <label for="kin1mobNumber" class="mr-sm-2">Mobile No</label>
                <div class="ml-sm-2">
                  <input type="number" class="form-control-sm form-control border border-primary"
                    name="kinmobNumber122" [(ngModel)]="model.nextOfKinMobilenr" #kinmobNumber122="ngModel"
                    [readOnly]="editOption" [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumber122.invalid }"
                    pattern="^((\\+91-?)|0)?[0-9]{10}$" id="kin1mobNumber" (keydown)="checkingMobileNumber($event)"
                    [required]="model.nextOfKinName !== '' || model.nextOfKinRelation !== ''">
                  <span *ngIf="check.submitted && kinmobNumber122.errors?.required" class="bg-warningtxt">Mobile Number is
                    required.</span>
                  <span *ngIf="kinmobNumber122.errors?.pattern" class="bg-warningtxt">Invalid Mobile Number</span>
                </div>
              </div>
            </div>


            <div class="d-sm-flex px-2 justify-content-between">
              <div class="mx-2 form-group d-sm-flex">
                <label for="kin2" class="mr-sm-2">Next of Kin2 / Parent / Guardian</label>
                <div class="ml-sm-2">
                  <input type="text" class="form-control-sm form-control border border-primary"
                    name="Nextofkin111" [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin111.invalid }"
                    pattern="^[a-zA-Z][a-zA-Z\. ]*$" id="kin2" [(ngModel)]="model.nextOfKin1Name" [readOnly]="editOption"
                    #Nextofkin111="ngModel"
                    [required]="model.nextOfKin1Mobilenr !== null || model.nextOfKin1Relation !== ''">
                  <span
                    *ngIf="check.submitted && (model.nextOfKin1Mobilenr !== null || model.nextOfKin1Relation !== '') && model.nextOfKin1Name === ''"
                    class="bg-warningtxt">Next of Kin2 / Parent / Guardian is required</span>
                  <span *ngIf="Nextofkin111.errors?.pattern" class="bg-warningtxt">Invalid format</span>
                </div>
              </div>
              <div class="mx-2 form-group d-sm-flex">
                <label for="Rkin2" class="mr-sm-2">Relation</label>
                <div class="ml-sm-2">
                  <select class="form-control-sm form-control border border-primary"
                    name="Nextofkin2relation1123" [attr.disabled]="(editOption) ? '' : null"
                    [(ngModel)]="model.nextOfKin1Relation" #Nextofkin2relation1123="ngModel"
                    [attr.disabled]="(editOption) ? '' : null" id="Rkin2"
                    [ngClass]="{ 'is-invalid-error': check.submitted && Nextofkin2relation1123.invalid }"
                    [required]=" model.nextOfKin1Mobilenr !== null || model.nextOfKin1Name !== ''">
                    <option value="">Select</option>
                    <option>Father</option>
                    <option>Mother</option>
                    <option>Brother</option>
                    <option>Sister</option>
                    <option>Wife</option>
                    <option>Husband</option>
                    <option>Son</option>
                    <option>Daughter </option>
                    <option>Son-in-law</option>
                    <option>Daughter-in-law</option>
                    <option>In-laws</option>
                    <option>Guardian </option>
                    <option>Friends </option>
                  </select>
                  <span *ngIf="check.submitted && Nextofkin2relation1123.invalid" class="bg-warningtxt">Relation is
                    required</span>
                </div>
              </div>
              <div class="mx-2 form-group d-sm-flex">
                <label for="kin2mobNumber" class="mr-sm-2">Mobile No</label>
                <div class="ml-sm-2">
                  <input type="number" class="form-control-sm form-control  border border-primary"
                    [(ngModel)]="model.nextOfKin1Mobilenr" name="kinmobNumberr" id="kin2mobNumber"
                    (keydown)="checkingMobileNumber($event)" [readOnly]="editOption" #kinmobNumberr="ngModel"
                    pattern="^((\\+91-?)|0)?[0-9]{10}$"
                    [ngClass]="{ 'is-invalid-error': check.submitted && kinmobNumberr.invalid }"
                    (keyup)="keyUpEnterFunction($event, 1)">
                    <!-- [required]="model.nextOfKin1Name !== '' || model.nextOfKin1Relation !== ''" -->
                  <!-- <span
                    *ngIf="(model.nextOfKin1Name !== '' || model.nextOfKin1Relation !== '')"
                    class="bg-warningtxt">Mobile Number is required.</span> -->
                  <span *ngIf="kinmobNumberr.errors?.pattern" class="bg-warningtxt">Invalid mobile number</span>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-5 form-group row">
                <label for="existing" class="col-sm-4">Existing UMR No
                </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control-sm form-control border border-primary"
                    name="existingnumber" [(ngModel)]="model.patientID" #existingnumber="ngModel" #umrCheck id="existing"
                    [readOnly]="editOption" (keyup)="checkingExistingUMR(umrCheck)">
                  <span *ngIf="existingUmr" class="bg-warningtxt">UMR Number already exists </span>
                </div>
              </div>
              <div class="col-md-7 mt-md-2">
                <div class="form-group twoInputssameLine">
                  <div class="float-md-right text-center">
                    <button *ngIf="!editButton" (click)="setValuestoEmpty()" type="button"
                      class="btn mx-3 resetSub mb-2 text-white"
                      style="background-color: #173267; width: 120px">Reset</button>
                    <button type="submit" class="btn mx-md-3 resetSub mb-2  text-white"
                      style="background-color: #173267; width: 120px">Submit</button>
                  </div>
                </div>
              </div>
            </div> -->
          
          <div>
              <p></p>
            </div>
            <div class="d-sm-flex px-2 justify-content-between">
              <div class="mx-2 form-group d-sm-flex">
                <label for="existing" class="mr-sm-2">Existing UMR No</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control-sm form-control border border-primary"
                    name="existingnumber" [(ngModel)]="model.patientID" #existingnumber="ngModel" #umrCheck id="existing"
                    [readOnly]="editOption" (keyup)="checkingExistingUMR(umrCheck)">
                  <span *ngIf="existingUmr" class="bg-warningtxt">UMR Number already exists </span>            
                </div>
              </div>
              
              <div class="mx-2 form-group d-sm-flex">
                <div class="ml-sm-2">
                  <div class="float-md-right text-center">
                    <button *ngIf="!editButton" (click)="setValuestoEmpty()" type="button"
                      class="btn mx-md-2 resetSub mb-2 text-white"
                      style="background-color: #173267; width: 120px">Reset</button>
                    <button type="submit" class="btn mx-md-2 resetSub mb-2  text-white"
                      style="background-color: #173267; width: 120px">Submit</button>
                  </div> 
                </div>
              </div>
            </div>
          </form>
          <br><br>
        </fieldset>
      </div>
    </ng-container>
    <ng-container *ngIf="toggleOnOff === 'visitForm'">
      <div class="col-md-7 my-custom-scrollbar scroll-1" *ngIf="!visitIpExists">
        <fieldset class="legend-block mt-3" >
          <legend class="legend-title mb-0" *ngIf="!isView">Add Visit</legend>
          <legend class="legend-title mb-0" *ngIf="isView">View Visit</legend>
          <form class="form-group"*ngIf="!isView" (ngSubmit)="addvisit.form.valid && itemDoctor && itemExref && submitVisitFormDetails()" #addvisit="ngForm" autocomplete="off">
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group row mt-0">
                  <label class="col-5 font-weight-bold">Patient Name :</label>
                  <span class="col-7 text-capitalize">{{saveVisitFormData.title}} {{saveVisitFormData.patientName}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row mt-0">
                  <label class="col-5 font-weight-bold">UMR No :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.patientID}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">Gender / Age :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.gender}} / {{saveVisitFormData.patientAge}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">DOB :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.dateOfBirth.split('-').reverse().join('-')}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class=" form-group row">
                  <label class="col-5 font-weight-bold">Mobile No :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.mobileNumber}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row" *ngIf="isView">
                  <label class="col-5 font-weight-bold">Visit ID :</label>
                  <span class="bbb ml-3">{{doctorsList[0].patientVisitID}}</span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="isView">
              <div class="col-md-6">
                <div class=" form-group row">
                  <label class="col-5 font-weight-bold">Visit Date :</label>
                  <span class="bbb ml-3">{{doctorsList[0].visitDate}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <!-- <label class="col-5 font-weight-bold">Visit Time Info :</label>
                  <span class="bbb ml-3"></span> -->
                </div>
              </div>
            </div>
            <hr class="mt-0 mb-0">
            <div>            
              <div class="row" *ngIf="saveVisitFormData.patientTypeId != 2">
                <div class="col-md-6" >
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5">
                      <label for="docname" class="font-weight-bold">Doctor Name: <span
                          class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <!-- <select class="form-control ropdoun bb" name="doctorname" [(ngModel)]="saveVisitFormData.doctorId"
                        #doctorname="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && doctorname.invalid }"
                        (change)="doctorChange(saveVisitFormData.doctorId)" id="docname"
                        [attr.disabled]=" doctorNdepAvailable ? '' : null " required>
                        <option value=null>Select Doctor</option>
                        <option *ngFor="let doctor of filterDoctorList; let i = index" [ngValue]="doctor.doctorId">
                          {{doctor.doctorTitle+ ' ' +doctor.doctorName}}
                        </option>
                      </select> -->
                      <div>
                        <ng-autocomplete
                          [data]="filterDoctorList"
                          [searchKeyword]="doctorNameFilterKeyword"
                          placeholder="Enter the Doctor's Name"
                          (selected)='selectAutocompleteEvent($event, "doctorname")'
                          (inputChanged)='onChangeSearch($event)'
                          (inputCleared)='onRemoveSelected($event,"doctorname")'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplateOrg"
                          [notFoundTemplate]="notFoundTemplateOrg"
                          class="autocomplete-dropdown sm"
                        >
                        </ng-autocomplete>
                        <ng-template #itemTemplateOrg let-item>
                          <a [innerHTML]="item.doctorName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplateOrg let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                      <span *ngIf="addvisit.submitted && !itemDoctor && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Doctor Name is
                        required</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="form-group row">
                    <div class="col-12 col-sm-5">
                      <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <select class="form-control  ropdoun bb" name="dptName" [(ngModel)]="saveVisitFormData.departmentId"
                        #dpartName="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && dpartName.invalid }"
                        (change)="departmentChange(saveVisitFormData.departmentId)" id="dptName"
                        [attr.disabled]="saveVisitFormData.doctorId ? '' : null " required>
                        <option value="">Select Department</option>
                        <option *ngFor="let department of departmentList; let i = index" [ngValue]="department.deptId">
                          {{department.deptName}}
                        </option>
                      </select>
                      <span *ngIf="addvisit.submitted && dpartName.invalid && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Department is
                        required</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group row">
                        <div class="col-12 col-sm-5">
                          <label class="font-weight-bold">Patient Type: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-12 col-sm-7">
                          <div class="ml-3 pl-1">
                            <label class="radio-inline d-block" for="radio12">
                              <input type="radio" class="form-check-input" id="radio12" name="patientType"
                              [(ngModel)]="saveVisitFormData.patientTypeId" #patientType = "ngModel" (click)="setPatientType(1)" [value]="1">Out patient
                            </label>
                            <label class="radio-inline d-block" for="radio123">
                              <input type="radio" class="form-check-input" Id="radio123" name="patientType"
                              [(ngModel)]="saveVisitFormData.patientTypeId" #patientType = "ngModel" (click)="setPatientType(2)" [disabled]="(patientTypeInfo) ? null : '' " [value]="2">Diagnostics
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group row">
                        <div class="col-5">
                          <div class="form-group">
                            <label class="font-weight-bold">Payee Type: <span class="requiredSymbol">*</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="ml-3 pl-1">
                            
                            <label class="radio-inline d-block" for="radio12">
                              <input type="radio" 
                                     class="form-check-input" 
                                     id="radio16" 
                                     name="payeeType"
                                     (change)="payeeTypeChange(1)"
                                     [(ngModel)]="saveVisitFormData.payeeTypeId" 
                                     [value]="1">Self Pay
                            </label>
                            <label class="radio-inline d-block" for="radio13">
                              <input type="radio" 
                                     class="form-check-input" 
                                     id="radio17" 
                                     name="payeeType"
                                     (change)="payeeTypeChange(2)"
                                     [(ngModel)]="saveVisitFormData.payeeTypeId" 
                                     [value]="2">TPA/Insurance/Corporate/Govt Scheme
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
               
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-12 col-sm-5">
                      <label class="font-weight-bold">Payee Name: <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                     
                     <div> 
                      <ng-autocomplete #payeename
                      [data]="payeeNames"
                      [searchKeyword]="nameKeyword"
                      placeholder="Enter the Payee's Name"
                      (selected)='selectAutocompleteEvent($event, "payeeName")'
                      (inputChanged)='onChangeSearch($event)'
                      (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate1"
                      [notFoundTemplate]="notFoundTemplate"
                      class="autocomplete-dropdown sm"
                    >
                    </ng-autocomplete> 
                    <ng-template #itemTemplate1 let-val>
                      <a [innerHTML]="val.PIM_NAME"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                     </div> 
                   
                   <span *ngIf="addvisit.submitted && saveVisitFormData.payeeNameId === ''" 
                   class="bg-warningtxt">Payee Name is
                     required</span>
                  </div>
                </div>
  
               
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group row">
                  <div class="col-12 col-sm-5">
                    <label class="font-weight-bold">Payee Info: <span class="requiredSymbol">*</span></label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <input type="text" 
                           class="form-control ropdoun bb m-0"
                           name="payeeInfo"
                           [(ngModel)]="saveVisitFormData.payeeInfo" 
                           #payeeInfo="ngModel"
                           id="payeeInfo" required />
                           <span *ngIf="addvisit.submitted && saveVisitFormData.payeeInfo === ''" 
                           class="bg-warningtxt">Payee Info is required.</span>
                  </div>
                </div>
              </div> -->
    </div>
              <!-- <div class="row mt-1" *ngIf="saveVisitFormData.payeeTypeId !== 1">
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <label for="External">Id info : </label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Id nr: </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group twoInputssameLine mx-2 mt15">
                    <label for="External">Name on id : </label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Valid till : </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Amount Approved: </label>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-6 ">
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5 pr-0">
                      <label for="External" class="font-weight-bold" name="external">External Referral: <span
                          class="requiredSymbol">*</span> </label>
                    </div>
                    <div class="col-12 col-sm-7">
                       <div>
                        <ng-autocomplete
                        [data]="extRefList"
                        [searchKeyword]="externalReferenceKeyword"
                        placeholder="Enter the Doctor's Name"
                        (selected)='selectAutocompleteEvent($event, "externalReferral")'
                        (inputCleared)='onRemoveSelected($event,"externalReferral")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                        name = "external"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.doctorName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <span *ngIf="addvisit.submitted && !itemExref" class="bg-warningtxt">External Referral is
                    required</span>
                      <!-- <select class="form-control ropdoun bb" name="External" [(ngModel)]="saveVisitFormData.extReferralId"
                        #extRef="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && extRef.invalid }"
                        id="External" required>
                        <option value=null>Select External Referral</option>
                        <option *ngFor="let refObj of extRefList; let i = index" [ngValue]="refObj.referralId">
                          {{refObj.doctorName}}
                        </option>
                      </select> -->
                    </div>
                  </div>
                </div>
    
                <div class="col-md-6 " *ngIf="saveVisitFormData.patientTypeId != 2">
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5" >
                      <label for="visttype" class="font-weight-bold">Visit type :  <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <select class="form-control ropdoun bb"
                              name="visitType" [(ngModel)]="saveVisitFormData.visitTypeId" #visitType="ngModel"
                              [ngClass]="{ 'is-invalid-error': addvisit.submitted && visitType.invalid }"
                              id="visttype" required>
                        <option value=null>Select Visit type</option>            
                        <option *ngFor="let visitObj of visitTypeList; let i = index" [ngValue]="visitObj.vcmId">
                          {{visitObj.visitName}}
                        </option>
                      </select>
                      <span *ngIf="addvisit.submitted && visitType.invalid && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Visit Type is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 ">
                <div class="form-group float-right mb-0 mt-3">                  
                  <button *ngIf="!isView" class="btn btn-md cont" [disabled]="disableSave"
                    (click)="addvisit.resetForm();disableSave=false">Reset</button>
                  <button *ngIf="!isView" class="btn btn-md cont mr-2" [disabled]="!addvisit.form.valid">Submit</button>
                </div>
              </div>              
            </div>
          </form>             
          <form class="form-group" *ngIf="isView" (ngSubmit)="addvisit.form.valid && itemDoctor && itemExref && submitVisitFormDetails()" #addvisit="ngForm" autocomplete="off">
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group row mt-0">
                  <label class="col-5 font-weight-bold">Patient Name :</label>
                  <span class="col-7 text-capitalize">{{saveVisitFormData.title}} {{saveVisitFormData.patientName}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row mt-0">
                  <label class="col-5 font-weight-bold">UMR No :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.patientID}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">Gender / Age :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.gender}} / {{saveVisitFormData.patientAge}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">DOB :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.dateOfBirth.split('-').reverse().join('-')}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class=" form-group row">
                  <label class="col-5 font-weight-bold">Mobile No :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.mobileNumber}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">Visit ID :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.patientVisitId}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class=" form-group row">
                  <label class="col-5 font-weight-bold">Visit Date :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.patientVisitDate}}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-5 font-weight-bold">Visit Time Info :</label>
                  <span class="bbb ml-3">{{saveVisitFormData.patientVisitTime}}</span>
                </div>
              </div>
            </div>
            
            <table class="table docTable table-body-scroll">
              <thead>
                <tr>
                  <th class="col-2" scope="col fweight600">Doctor Id</th>
                  <th class="col-3" scope="col fweight600">Doctor Name</th>
                  <th class="col-3 pl-4" scope="col fweight600">External Referral</th>
                  <th class="col-3 text-center pr-4" scope="col fweight600">Visit Type</th>
                  <th class="col-1" class="historyIcon"><i class="fa fa-history" aria-hidden="true" (click)="getPatientPreviousVisits(saveVisitFormData.patientID,'existingPatientVisitsModel')"></i></th>
                </tr>
              </thead>
              <tbody class="mt-1">
                <tr *ngFor="let doctor of doctorsList">
                  <td class="col-2">{{doctor.doctorID}}</td>
                  <td class="col-3"><span *ngIf="doctor.doctorID > 8">{{doctor.title?doctor.title:'Dr.'}}</span> {{doctor.dmName}}</td>
                  <td class="col-3 pl-4">{{doctor.referalDoctorName}}</td>
                  <td class="col-3 text-center pr-2">{{doctor.visitType}}</td>
                  <td class="col-1"></td>
                </tr>
              </tbody>
            </table>

            <hr class="mt-0 mb-0">

            <div *ngIf="isView && enableCrossConsultation" [hidden]="doctorBtn == true" class="float-left mb-0 mt-3">
              <button (click)="toggleShow()" class="btn btn-md cont m-2" name="doctorBtn">Add Cross Consultation/ Add Doctor</button>
            </div>
            
            <div *ngIf="isShown">
              <div class="row" *ngIf="saveVisitFormData.patientTypeId != 2">
                <div class="col-md-6" >
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5">
                      <label for="docname" class="font-weight-bold">Doctor Name: <span
                          class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <!-- <select class="form-control ropdoun bb" name="doctorname" [(ngModel)]="saveVisitFormData.doctorId"
                        #doctorname="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && doctorname.invalid }"
                        (change)="doctorChange(saveVisitFormData.doctorId)" id="docname"
                        [attr.disabled]=" doctorNdepAvailable ? '' : null " required>
                        <option value=null>Select Doctor</option>
                        <option *ngFor="let doctor of filterDoctorList; let i = index" [ngValue]="doctor.doctorId">
                          {{doctor.doctorTitle+ ' ' +doctor.doctorName}}
                        </option>
                      </select> -->
                      <div>
                        <ng-autocomplete
                          [data]="filterDoctorListForVisit"
                          [searchKeyword]="doctorNameFilterKeyword"
                          placeholder="Enter the Doctor's Name"
                          (selected)='selectAutocompleteEvent($event, "doctorname")'
                          (inputChanged)='onChangeSearch($event)'
                          (inputCleared)='onRemoveSelected($event,"doctorname")'
                          (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                          class="autocomplete-dropdown sm"
                        >
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.doctorName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                      <span *ngIf="addvisit.submitted && !itemDoctor && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Doctor Name is
                        required</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="form-group row">
                    <div class="col-12 col-sm-5">
                      <label class="font-weight-bold">Department: <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <select class="form-control  ropdoun bb" name="dptName" [(ngModel)]="saveVisitFormData.departmentId"
                        #dpartName="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && dpartName.invalid }"
                        (change)="departmentChange(saveVisitFormData.departmentId)" id="dptName"
                        [attr.disabled]="saveVisitFormData.doctorId ? '' : null " required>
                        <option value="">Select Department</option>
                        <option *ngFor="let department of departmentList; let i = index" [ngValue]="department.deptId">
                          {{department.deptName}}
                        </option>
                      </select>
                      <span *ngIf="addvisit.submitted && dpartName.invalid && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Department is
                        required</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row" [hidden]="isView">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group row">
                        <div class="col-12 col-sm-5">
                          <label class="font-weight-bold">Patient Type: <span class="requiredSymbol">*</span></label>
                        </div>
                        <div class="col-12 col-sm-7">
                          <div class="ml-3 pl-1">
                            <label class="radio-inline d-block" for="radio12">
                              <input type="radio" class="form-check-input" id="radio12" name="patientType"
                              [(ngModel)]="saveVisitFormData.patientTypeId" #patientType = "ngModel" (click)="setPatientType(1)" [value]="1">Out patient
                            </label>
                            <label class="radio-inline d-block" for="radio123">
                              <input type="radio" class="form-check-input" Id="radio123" name="patientType"
                              [(ngModel)]="saveVisitFormData.patientTypeId" #patientType = "ngModel" (click)="setPatientType(2)" [disabled]="(patientTypeInfo) ? null : '' " [value]="2">Diagnostics
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                     
                      <div class="form-group row">
                        <div class="col-5">
                          <div class="form-group">
                            <label class="font-weight-bold">Payee Type: <span class="requiredSymbol">*</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="ml-3 pl-1">
                            
                            <label class="radio-inline d-block" for="radio12">
                              <input type="radio" 
                                     class="form-check-input" 
                                     id="radio16" 
                                     name="payeeType"
                                     (change)="payeeTypeChange(1)"
                                     [(ngModel)]="saveVisitFormData.payeeTypeId" 
                                     [value]="1">Self Pay
                            </label>
                            <label class="radio-inline d-block" for="radio13">
                              <input type="radio" 
                                     class="form-check-input" 
                                     id="radio17" 
                                     name="payeeType"
                                     (change)="payeeTypeChange(2)"
                                     [(ngModel)]="saveVisitFormData.payeeTypeId" 
                                     [value]="2">TPA/Insurance/Corporate/Govt Scheme
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" [hidden]="isView">
                <div class="row">
               
                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="col-12 col-sm-5">
                        <label class="font-weight-bold">Payee Name: <span class="requiredSymbol">*</span></label>
                      </div>
                      <div class="col-12 col-sm-7">
                       
                       <div> 
                        <ng-autocomplete #payeename
                        [data]="payeeNames"
                        [searchKeyword]="nameKeyword"
                        placeholder="Enter the Payee's Name"
                        (selected)='selectAutocompleteEvent($event, "payeeName")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate1"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                      >
                      </ng-autocomplete> 
                      <ng-template #itemTemplate1 let-val>
                        <a [innerHTML]="val.PIM_NAME"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                       </div> 
                     
                     <span *ngIf="addvisit.submitted && saveVisitFormData.payeeNameId === ''" 
                     class="bg-warningtxt">Payee Name is
                       required</span>
                    </div>
                  </div>
    
                 
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-12 col-sm-5">
                      <label class="font-weight-bold">Payee Info: <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <input type="text" 
                             class="form-control ropdoun bb m-0"
                             name="payeeInfo"
                             [(ngModel)]="saveVisitFormData.payeeInfo" 
                             #payeeInfo="ngModel"
                             id="payeeInfo" required />
                             <span *ngIf="addvisit.submitted && saveVisitFormData.payeeInfo === ''" 
                             class="bg-warningtxt">Payee Info is required.</span>
                    </div>
                  </div>
                </div> -->
      </div>
              </div>
              <!-- <div class="row mt-1" *ngIf="saveVisitFormData.payeeTypeId !== 1">
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <label for="External">Id info : </label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Id nr: </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group twoInputssameLine mx-2 mt15">
                    <label for="External">Name on id : </label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Valid till : </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group twoInputssameLine mx-2 ">
                    <div class="form-group twoInputssameLine mx-2 mt15">
                      <label for="External">Amount Approved: </label>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row" *ngIf="isShown">
                <div class="col-md-6 ">
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5 pr-0">
                      <label for="External" class="font-weight-bold" name="external">External Referral: <span
                          class="requiredSymbol">*</span> </label>
                    </div>
                    <div class="col-12 col-sm-7">
                       <div>
                        <ng-autocomplete
                        [data]="extRefList"
                        [searchKeyword]="externalReferenceKeyword"
                        placeholder="Enter the Doctor's Name"
                        (selected)='selectAutocompleteEvent($event, "externalReferral")'
                        (inputCleared)='onRemoveSelected($event,"externalReferral")'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        class="autocomplete-dropdown sm"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.doctorName"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <span *ngIf="addvisit.submitted && !itemExref && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">External Referral is
                    required</span>
                      <!-- <select class="form-control ropdoun bb" name="External" [(ngModel)]="saveVisitFormData.extReferralId"
                        #extRef="ngModel" [ngClass]="{ 'is-invalid-error': addvisit.submitted && extRef.invalid }"
                        id="External" required>
                        <option value=null>Select External Referral</option>
                        <option *ngFor="let refObj of extRefList; let i = index" [ngValue]="refObj.referralId">
                          {{refObj.doctorName}}
                        </option>
                      </select> -->
                    </div>
                  </div>
                </div>
    
                <div class="col-md-6 " *ngIf="saveVisitFormData.patientTypeId != 2">
                  <div class=" form-group row">
                    <div class="col-12 col-sm-5" >
                      <label for="visttype" class="font-weight-bold">Visit type :  <span class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-12 col-sm-7">
                      <select class="form-control ropdoun bb"
                              name="visitType" [(ngModel)]="saveVisitFormData.visitTypeId" #visitType="ngModel"
                              [ngClass]="{ 'is-invalid-error': addvisit.submitted && visitType.invalid }"
                              id="visttype" required>
                        <option value=null>Select Visit type</option>            
                        <option *ngFor="let visitObj of visitTypeList; let i = index" [ngValue]="visitObj.vcmId">
                          {{visitObj.visitName}}
                        </option>
                      </select>
                      <span *ngIf="addvisit.submitted && visitType.invalid && saveVisitFormData.patientTypeId == 1" class="bg-warningtxt">Visit Type is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 " *ngIf="isShown">
                <div class="form-group float-right mb-0 mt-3" >
                  <!-- <button *ngIf="!isView" class="btn btn-md cont" [disabled]="disableSave"
                    (click)="addvisit.resetForm();disableSave=false">Reset</button>
                  <button *ngIf="!isView" class="btn btn-md cont mr-2" [disabled]="!addvisit.form.valid">Submit</button> -->
                  <button *ngIf="isView" class="btn btn-md cont m-2" [disabled]="!addvisit.form.valid">Submit</button>
                </div>
              </div>              
          </form>
        </fieldset>
      </div>
      <div *ngIf="visitIpExists" class="col-6 p-5 text-info text-center font-weight-bold">{{ipExistText}}</div>
      <br><br>    
    </ng-container>

    <div class="modal" [class]="dataLoaded ? 'forModalDisplay' : 'd-none'" id="dataNotFound">
      <div class="modal-dialog modal-sm modal-dialog-centered">

        <div class="modal-body px-0 font-weight-bold text-center">

          <div align="center" class="fond">
            <div class="contener_general">
              <div class="contener_mixte">
                <div class="ballcolor ball_1">&nbsp;</div>
              </div>
              <div class="contener_mixte">
                <div class="ballcolor ball_2">&nbsp;</div>
              </div>
              <div class="contener_mixte">
                <div class="ballcolor ball_3">&nbsp;</div>
              </div>
              <div class="contener_mixte">
                <div class="ballcolor ball_4">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <ng-template #existingPatientsModal let-modal>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content p-0">
          <div class="modal-header d-flex justify-content-center m-0">
            <h4 class="modal-title font-weight-bold text-info">Existing Patient Records by Mail/ Mobile Number</h4>
          </div>
          <div class="modal-body py-0 px-1 text-center">
            <table class="table my-0 table-hover table-striped">
              <thead>
                <tr class="text-center">
                  <th>Name</th>
                  <th>Gender</th>
                  <th>D.O.B</th>
                  <th>UMR No</th>
                  <th>Mobile No</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr class="handCursor text-center" *ngFor="let patient of existingPatients, let  i = index"
                  (click)="formFillPatientFun(dataFillModal, patient, i)">
                  <td>{{patient.title}} {{patient.patientName}}</td>
                  <td>{{patient.gender}}</td>
                  <td>{{patient.dateOfBirth.split('-').reverse().join('-')}}</td>
                  <td>{{patient.patientID}}</td>
                  <td>{{patient.mobileNumber}}</td>
                  <td>{{patient.emailId}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-danger btn-sm" (click)="modal.dismiss('click')">Close</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #dataFillModal let-modal>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="modal-title text-info">
              Confirmation
            </h3>
          </div>
          <div class="modal-body text-dark">
            Do you want to fill the form with Selected Patient Data?
          </div>
          <div class="modal-footer d-flex justify-content-around">
            <button class="btn btn-outline-success" (click)="newPatientWithExisitng()">Yes, Fill it</button>
            <button class="btn btn-outline-danger" (click)="modal.dismiss()">No</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #duplicateRecordsMod let-modal>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content p-0 w-100">
          <div class="modal-header d-flex justify-content-center">
            <h4 class="modal-title text-info">Duplicate Records Found</h4>
          </div>
          <div class="modal-body p-0">
            <table class="table my-0 table-hover table-striped">
              <thead>
                <tr class="text-center">
                  <th>Name</th>
                  <th>Gender</th>
                  <th>D.O.B</th>
                  <th>UMR No</th>
                  <th>Mobile No</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr class="handCursor text-center" *ngFor="let patient of duplicateRecords, let  i = index"
                  (click)="formFillPatientFun(dataFillModal, patient, i)">
                  <td>{{patient.title}} {{patient.patientName}}</td>
                  <td>{{patient.gender}}</td>
                  <td>{{patient.dateOfBirth.split('-').reverse().join('-')}}</td>
                  <td>{{patient.patientID}}</td>
                  <td>{{patient.mobileNumber}}</td>
                  <td>{{patient.emailId}}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-danger mt-3"><strong>Note:</strong> Please select existing record or register with new mobile
              number</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-danger btn-sm" (click)="modal.dismiss('click')">Close</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #StatusInfoModal let-modal>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content p-0 w-100">
          <div class="modal-body p-0">
            <p class="text-info font-weight-bold mt-3">Please fill required fields to proceed with Add Visit</p>
          </div>
          <div class="modal-footer d-flex justify-content-center p-2">
            <button class="btn btn-success btn-sm w-25" (click)="modal.dismiss('click')">OK</button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #existingPatientVisitsModel let-modal>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content p-0">
          <div class="modal-header d-flex justify-content-center m-0">
            <h4 class="modal-title font-weight-bold text-info">Existing Patient Visits</h4>
          </div>
          <div class="modal-body py-0 px-1 text-center">
            <table class="table my-0 table-hover table-striped">
              <thead>
                <tr class="text-center">
                  <th>Visit ID</th>
                  <th>Visit Date</th>
                  <th>Doctor Name</th>
                  <th>Visit Type</th>
                </tr>
              </thead>
              <tbody>
               <tr *ngFor="let visitsInfo of patientVisits, let  i = index">
                  <td>{{visitsInfo.VISIT_ID}}</td>
                  <td>{{visitsInfo.VISIT_DATE}}</td>
                  <td><span *ngIf="visitsInfo.DM_ID >9">Dr.</span>{{visitsInfo.DM_NAME}}</td>
                  <td>{{visitsInfo.VISIT_NAME}}</td>
               </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-danger btn-sm" (click)="modal.dismiss('click')">Close</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="bodyDisplay === 'viewoutPatient'" class="container-fluid viewOutpatient p-0"
  style="height:calc(100vh - 139px);">
  <app-viewoutpatient [registerVisitId]="visitID" [registerDocId]="saveVisitFormData.doctorId" ></app-viewoutpatient>
</div>

<div *ngIf="bodyDisplay === 'viewoutPatientBill'" class="container-fluid viewoutPatientBill p-0"
    style="height:calc(100vh - 139px);">
  <app-viewoutpatientbill [registerVisitId]="visitID"></app-viewoutpatientbill>
</div>

<div *ngIf="bodyDisplay === 'reports'" class="container-fluid reports p-0"
  style="height:calc(100vh - 139px);">
  <app-reports></app-reports>
</div>

