import { Injectable } from '@angular/core';
import { UrlsettingService } from './urlsetting.service';
import {bootstrapError, serverUrl, value, privileges, partialUrl} from '../constants/commonConstants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {variable} from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  // tslint:disable:max-line-length
   headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  constructor(private http: HttpClient, private urlsettings: UrlsettingService) {
  }

  public getEncodedURL(url, partialFlag?: boolean): string {
    if (typeof url === 'string' && url.indexOf(this.urlsettings.getURLConstants().get(partialUrl)) !== -1) {
      let restURL = url.substring(url.indexOf(this.urlsettings.getURLConstants().get(partialUrl)));
      restURL = encodeURIComponent(restURL);
      if (partialFlag) {
        url = '/' + restURL;
      } else {
        url = this.urlsettings.getURLConstants().get(serverUrl) + '/' + restURL;
      }
    }
    return url;
  }

  public encodeItem(item: string): string {
    const encodedItem = this.getEncodedURL(item, true);
    return encodedItem;
  }

  public encodeItemList(inputList: string[]): string[] {
    let encodedList = inputList;
    if (inputList && Array.isArray(inputList)) {
      encodedList = [];
      inputList.forEach((val, i) => {
        encodedList.push(this.getEncodedURL(inputList[i], true));
      });
    }
    return encodedList;
  }

  public encodeObject(item: any): any {
    if (typeof item === 'string') {
      item = this.encodeItem(item);
    } else if (Array.isArray(item)) {
      if (typeof item[0] === 'string') {
        item = this.encodeItemList(item);
      } else {
        // tslint:disable-next-line:forin
        if (item) {
          item.forEach((val) => {
            for (const key in val) {
              if (typeof val[key] === 'string') {
                val[key] = this.encodeItem(val[key]);
              } else {
                val[key] = this.encodeItemList(val[key]);
              }
            }
          });
        }
      }
    } else {
      for (const key in item) {
        if (typeof item[key] === 'string') {
          item[key] = this.encodeItem(item[key]);
        } else {
          item[key] = this.encodeItemList(item[key]);
        }
      }
    }
    return item;
  }

  public getEncodedPatchData(data: any): any {
    if (data) {
      data.forEach((val, index) => {
        data[index][value] = this.encodeObject(val.value);
      });
    }
    return data;
  }

  public getEncodedPostData(data: any): any {
    // tslint:disable-next-line:forin
    for (const key in data) {
      data[key] = this.encodeObject(data[key]);
    }
    return data;
  }

  // tslint:disable-next-line:typedef
  public getserviceText(url: string, ...params: string[]) {
    let i: number;
    for (i = 0; i < params.length; i++) {
      if (params[i][0] !== '/') {
        url = url + '/';
      }
      url = url + params[i];
    }  
     return this.http.get(url, {responseType: 'text'})
  }

  // tslint:disable-next-line:typedef
  public getservice(url: string, ...params: string[]) {
    let i: number;
    for (i = 0; i < params.length; i++) {
      if (params[i][0] !== '/') {
        url = url + '/';
      }
      url = url + params[i];
    }
    // url = this.getEncodedURL(url, false);
    // return this.http.get(url).pipe(map(this.extractData));
    return this.http.get(url);

    // this.http.get(url).subscribe((response) => {
    //   getData = JSON.parse(JSON.stringify(response));
    //   console.log(response);
    // });
    // return getData;
  }

  // tslint:disable-next-line:typedef
  public deleteservice(url: string, ...params: string[]) {
    let i: number;
    for (i = 0; i < params.length; i++) {
      if (params[i][0] !== '/') {
        url = url + '/';
      }
      url = url + params[i];
    }
    url = this.getEncodedURL(url);
    return this.http.delete(url);
  }

  // tslint:disable-next-line:typedef
  public postservice(url: string, data: any, params?: string[]) {
    let i: number;
    if (params) {
      for (i = 0; i < params.length; i++) {
        if (params[i]) {
          if (params[i][0] !== '/') {
            url = url + '/';
          }
          url = url + params[i];
        }
      }
    }
    // Condition to handle proper POST urls during test automation
    // if (url.indexOf(bootstrapError) !== -1) {
    //   url = url.substring(0, url.indexOf(bootstrapError));
    // }
    // if (Array.isArray(data)) {
    //   if (data) {
    //     // tslint:disable-next-line:no-shadowed-variable
    //     data.forEach((value, index ) => {
    //       data[index] = this.getEncodedPostData(value);
    //     });
    //   }
    // } else {
    //   data = this.getEncodedPostData(data);
    // }
    // url = this.getEncodedURL(url);
    return this.http.post(url, JSON.stringify(data), {headers: this.headers});
  }

  // tslint:disable-next-line:typedef
  public patchservice(url: string, data: any, params?: string[]) {
    let i: number;
    for (i = 0; i < params.length; i++) {
      if (params[i][0] !== '/') {
        url = url + '/';
      }
      url = url + params[i];
    }
    url = this.getEncodedURL(url);
    data = this.getEncodedPatchData(data);
    return this.http.patch(url, JSON.stringify(data));
  }

  // tslint:disable-next-line:typedef
  public putservice(url: string, data: any) {
    return this.http.put(url, data);
  }

  // tslint:disable-next-line:typedef
  public saveQuery(data) {
    return this.postservice('', data);
  }

  // tslint:disable-next-line:typedef
  public loadQuery() {
    return this.getservice('');
  }

  // tslint:disable-next-line:typedef
  public deleteQuery(name) {
    return this.deleteservice('', name);
  }

  // public searchQuery(data) {
  //   return this.postservice(searchQueryUrl, data).map(this.extractData);
  // }

  // public logInfo(data) {
  //   return this.postservice(loggingUrl, data);
  // }

  // Actual REST functions
  // Function to fetch list of all object classes with definitions
  // tslint:disable-next-line:typedef
  public getObjectClassDefList() {
    return this.getservice(this.urlsettings.getURLConstants().get('objectClassDefUrl'));
  }

  // Function to fetch definition of specified class name
  // tslint:disable-next-line:typedef
  public getObjectClassDef(type) {
    return this.getservice(this.urlsettings.getURLConstants().get('objectClassDefUrl'), type);
  }

  // Function to create a new object class
  // tslint:disable-next-line:typedef
  public postObjectClassDef(data) {
    return this.postservice(this.urlsettings.getURLConstants().get('objectClassDefUrl'), data);
  }

  // Function to modify object class
  // tslint:disable-next-line:typedef
  public putObjectClassDef(data) {
    return this.putservice(this.urlsettings.getURLConstants().get('objectClassDefUrl'), data);
  }

  // Function to delete an objectclass
  // tslint:disable-next-line:typedef
  public deleteObjectClassDef(name) {
    return this.deleteservice(this.urlsettings.getURLConstants().get('objectClassDefUrl'), name);
  }

  // Function to create entry
  // tslint:disable-next-line:typedef
  public postEntry(data, dn) {
    // dn = encodeURIComponent(dn);
    return this.postservice(this.urlsettings.getURLConstants().get('restServerUrlWOTree'), data, [dn]);
  }

  // Function to create entry
  // tslint:disable-next-line:typedef
  public postSession(data, url) {
    return this.postservice(this.urlsettings.getURLConstants().get('restServerUrl'), data, [url]);
  }

  // tslint:disable-next-line:typedef
  public patchEntry(data, dn) {
    // dn = encodeURIComponent(dn);
    return this.patchservice(this.urlsettings.getURLConstants().get('entryUrl'), data, [dn]);
  }

  // Function to fetch entry with the given rdn1 and rdn2
  // tslint:disable-next-line:typedef
  public getEntry(dn) {
    return this.getservice(this.urlsettings.getURLConstants().get('entryUrl'), dn);
  }

  // tslint:disable-next-line:typedef
  public getEntry1(dn) {
    return this.getservice(this.urlsettings.getURLConstants().get('searchUrl'), dn);
  }

  // Function to fetch the syntax with the specified id
  // tslint:disable-next-line:typedef
  public getSyntaxDef(data) {
    return this.getservice(this.urlsettings.getURLConstants().get('syntaxDefUrl'), data);
  }

  // Function to fetch the definition of the specified attribute
  // tslint:disable-next-line:typedef
  public getAttributeDef(data) {
    return this.getservice(this.urlsettings.getURLConstants().get('AttributeDefUrl'), data);
  }

  // Function to fetch the definition of all attributes
  // tslint:disable-next-line:typedef
  public getAttributeDefList() {
    return this.getservice(this.urlsettings.getURLConstants().get('AttributeDefUrl'));
  }

  // Function to create a new attribute
  // tslint:disable-next-line:typedef
  public postAttributeDef(data) {
    return this.postservice(this.urlsettings.getURLConstants().get('AttributeDefUrl'), data, [name]);
  }

  // Function to delete an attribute
  // tslint:disable-next-line:typedef
  public deleteAttributeDef(name) {
    return this.deleteservice(this.urlsettings.getURLConstants().get('AttributeDefUrl'), name);
  }

  // Function to fetch the definition of all attributes
  // tslint:disable-next-line:typedef
  public getSyntaxDefList() {
    return this.getservice(this.urlsettings.getURLConstants().get('syntaxDefUrl'));
  }

  // tslint:disable-next-line:typedef
  public searchEntries(data, params: string[]) {
    params.push('search');
    return this.postservice(this.urlsettings.getURLConstants().get('searchUrl'), data, params);
  }

  // tslint:disable-next-line:typedef
  public deleteEntry(dn) {
    // dn = encodeURIComponent(dn);
    return this.deleteservice(this.urlsettings.getURLConstants().get('entryUrl'), dn);
  }

  // tslint:disable-next-line:typedef
  public deleteSession(session) {
    return this.deleteservice(this.urlsettings.getURLConstants().get('restServerUrl'), session);
  }

  // tslint:disable-next-line:typedef
  public searchPages(url) {
    return this.getservice(url);
  }

  // tslint:disable-next-line:typedef
  public getEndPoints(url) {
    return this.getservice(url);
  }

  // tslint:disable-next-line:typedef
  public getAuthURL(url) {
    return this.getservice(url);
  }

  // tslint:disable-next-line:typedef
  public searchContexts(data, params: string[]) {
    params.push('search');
    return this.postservice(this.urlsettings.getURLConstants().get('restServerUrlWOTree') + '', data, params);
  }

  // tslint:disable-next-line:typedef
  public getEffectivePrivileges(data, params: string[]) {
    return this.postservice(this.urlsettings.getURLConstants().get('restServerUrl') + '', data, params);
  }

  // tslint:disable-next-line:typedef
  public calculateRights(trusteeName: string, objectName: string, properties: string[]) {
    const data = this.getRightsBody(trusteeName, properties);
    return this.getEffectivePrivileges(data, [objectName, privileges]);
  }

  // tslint:disable-next-line:typedef
  public getRightsBody(trusteeName, properties) {
    const data = {
      trusteeDN: trusteeName,
      attributes: properties
    };
    return data;
  }

  // tslint:disable-next-line:typedef
  public getObject(object) {
    return this.getservice(this.urlsettings.getURLConstants().get('restServerUrlWOTree') + '', object);
  }

  // tslint:disable-next-line:typedef
  public genericSearch(data, params: string[]) {
    params.push('objectClassesSearch');
    return this.postservice(this.urlsettings.getURLConstants().get('searchUrl'), JSON.stringify(data), params);
  }

  // tslint:disable-next-line:typedef
  private extractData(res: any) {
    // let body: any[] = [];
    const body = res.json();
    return body || {};
  }

}
