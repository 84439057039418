import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';
import { NgxPrinterService } from 'ngx-printer';
@Component({
  selector: 'app-billing-insurance',
  templateUrl: './billing-insurance.component.html',
  styleUrls: ['./billing-insurance.component.css']
})

export class BillingInsuranceComponent implements OnInit {
  @ViewChild('coverLetterPopup') coverLetterPopup;
  // insuranceForArray = ['Self', 'Father', 'Mother', 'Spouse', 'Son', 'Daughter', 'Father - in - Law', 'Mother - In - Law']
  billingInsuranceForm;
  submitted = false;
  existingRecord = false;
  coverLetterInfo: any;
  public userInfo: any = {};
  loading = true;
  selectedIPPatientNO;
  billingInsuranceData;
  printPrescription;
  editButton = false;
  editOption;
  insuranceList: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private printerService: NgxPrinterService,
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
    private inpatientService: InpatientService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private modalService: NgbModal,
  ) {
    this.userInfo = this.tokenService.getUser();
    const id = this.route.snapshot.paramMap.get('id');
    this.selectedIPPatientNO = parseInt(id);
  }
  ngOnInit(): void {
    this.billingInsuranceForm = this.formBuilder.group({
      insuranceID: ['', [Validators.required, Validators.maxLength(25)]],
      cinNO: ['', [Validators.required, Validators.maxLength(25)]],
      availableSum: ['', [Validators.required, Validators.maxLength(10)]],
      primaryInsurance: ['', [Validators.required, Validators.maxLength(25)]],
    });
    this.getCoverLetterData();
    this.getInsuranceList()
  }
  get f() { return this.billingInsuranceForm.controls; }

  getInsuranceList() {
  this.inpatientService.getPrimaryInsuranceList().subscribe(
    (list)=>{
      console.log(list);
      this.insuranceList = list;
    });
  }
  getCoverLetterData() {
    const inputReq = {
      ipNr: this.selectedIPPatientNO
    }
    this.inpatientService.getBillingInsuranceInfo(inputReq).subscribe(
      (response) => {
        if (response) {
          this.existingRecord = true;
          this.editButton = true;
          this.billingInsuranceData = response;
          this.billingInsuranceForm.patchValue({
            insuranceID: this.billingInsuranceData.REF_NR,
            cinNO: this.billingInsuranceData.CLAIM_NR,
            availableSum: this.billingInsuranceData.INSURED_SUM,
            primaryInsurance: this.billingInsuranceData.PRIMARY_INSURANCE
          });
          this.billingInsuranceForm.disable();
        }
      },
      (err) => {
        this.existingRecord = false;
        console.log('HTTP Error', err.error);
        this.toastrService.error(err.error.message);
      }
    );
  }
  enableEditOption() {
    this.editOption = false;
    this.billingInsuranceForm.enable();
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.billingInsuranceForm.invalid) {
      return;
    } else {
      if (this.existingRecord) {
        this.updateInsuranceDetails();
      } else {
        const inputRequest = {
          insuranceId: this.billingInsuranceForm.value.insuranceID,
          cinNo: this.billingInsuranceForm.value.cinNO,
          ipNr: this.selectedIPPatientNO,
          insuranceSum: this.billingInsuranceForm.value.availableSum,
          insuranceFor: this.billingInsuranceForm.value.primaryInsurance
        }
        this.inpatientService.patientBillingInsuranceInfo(inputRequest).subscribe(
          (response) => {
            this.toastrService.success(response.message);
            this.existingRecord = true;
            this.editButton = true;
            this.getCoverLetterData();
          },
          (err) => {
            this.existingRecord = true;
            console.log('HTTP Error', err.error);
            this.toastrService.error(err.error.message);
          }
        );
      }
    }
  }
  updateInsuranceDetails() {
    this.submitted = true;
    if (this.billingInsuranceForm.invalid) {
      return;
    } else {
      const inputRequest = {
        insuranceId: this.billingInsuranceForm.value.insuranceID,
        cinNo: this.billingInsuranceForm.value.cinNO,
        ipNr: this.selectedIPPatientNO,
        insuranceSum: this.billingInsuranceForm.value.availableSum,
        insuranceFor: this.billingInsuranceForm.value.primaryInsurance,
        rowId: this.billingInsuranceData.I_ID
      }
      this.inpatientService.updateBillingInsuranceInfo(inputRequest).subscribe(
        (response) => {
          this.toastrService.success(response.message);
          this.existingRecord = true;
          this.getCoverLetterData();
        },
        (err) => {
          this.existingRecord = true;
          console.log('HTTP Error', err.error);
          this.toastrService.error(err.error.message);
        }
      );

    }
  }
  printBill() {
    const shortName = this.userInfo.hospitalShortName;
    if (shortName === 'DPMTA' || shortName === 'KPMTA' || shortName === 'CPMTA' || shortName === 'JPMTA') {
      const sheet = document.createElement('style');
      sheet.innerHTML = '@page { margin-top: 2in; } @page:first { margin-top: 0.2in; }';
      document.getElementById('print-discharge-summary-section').appendChild(sheet);
    }

    this.printerService.printDiv('print-discharge-summary-section');

  }
  openCoverLetter() {
    this.openCoverLetterPopup(this.coverLetterPopup);
  }

  openCoverLetterPopup(coverLetterPopup): void {
    const inputReq = {
      ipNr: this.selectedIPPatientNO,
      hospitalId: this.userInfo.hospitalID
    }

    this.route.queryParams.subscribe((params) => {
      if (params.mode === 'dischargelist') {
        inputReq['moduleType'] = 'dischargeList'
      }
    });
    this.inpatientService.getcoverLetterData(inputReq).subscribe((response) => {
      this.loading = false;
      this.coverLetterInfo = response;
      console.log(this.coverLetterInfo);
    });
    this.modalService.open(coverLetterPopup, { size: 'xl' })
  }
  backNav(): void {
    this.router.navigate(['/Inpatient']);
  }


}