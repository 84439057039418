<!-- 
<div class="land" *ngIf="registerVisitId === ''">
  <div class="container-fluid ">
    <div class="row btn_align">
      <div class="col-sm-2 m-2">
        <button type="button" (click)="bodyDisplay = 'viewoutPatient'" class="btn btn-sm btnn btn-light"
        [class.active]="bodyDisplay === 'viewoutPatient'">View Outpatients1</button>
      </div>
      <div class="col-sm-2 m-2">
        <button type="button" (click)="bodyDisplay = 'viewoutPatientBill'" class="btn btn-sm btnn btn-light"
        [class.active]="bodyDisplay === 'viewoutPatientBill'">Search/View OP Bills</button>
      </div>
      <div class="col-sm-2 m-2">
        <button type="button" class="btn btn-sm btnn btn-light" >Reports</button>
      </div>

    </div>
  </div>
</div> -->
<div class="container-fluid">
  <div class="row" *ngIf="bodyDisplay === 'viewoutPatientBill'" >
    <div class="col-md-5 bgbox pb-5 p-0">
      <div class="row mt-md-3 p-1">
        <div class="col-md-6">
          <div class="form-group ">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input  placeholder="Search by  Visit Id" type="number" name="visitnumSearch"
                class="form-control" [(ngModel)]="searchVisitId" (keydown)="enterKeytriggerFunction($event, searchVisitId, 'visitId')" />

              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchOutpatients(searchVisitId,'visitId')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>

            </div>

            <label class="searchLabel my-1">Search with full Visit Id</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group ">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input placeholder="Search by  UMR Number" type="number" name="visitnumSearch"
                class="form-control" [(ngModel)]="searchUMR" (keydown)="enterKeytriggerFunction($event, searchUMR, 'patientId')"/>

              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchOutpatients(searchUMR,'patientId')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1">Search with full UMR Number</label>
          </div>
        </div>
      </div>

      <div class="row p-1">
        <div class="col-md-6">
          <div class="form-group ">
            <div class="input-group input-group-sm mb-0 inputRadius">
              <input  placeholder="Search by Patient Name" type="text" name="patNameSearch" #patientname
                class="form-control" [(ngModel)]="searchPatientName" (keydown)="enterKeytriggerFunction($event, searchPatientName, 'patientName')"
                [class.is-invalid-error]="patientname.value.length < 3 && patientname.value.length > 0"/>

              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchOutpatients(searchPatientName,'patientName')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>

            </div>

            <label class="searchLabel my-1">Search with minimum 3 characters</label><br>
            <span *ngIf="patientname.value.length < 3 && patientname.value.length > 0" class="bg-warningtxt">
              Please search with minimum 3 characters
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group ">
            <div class="input-group input-group-sm mb-0 inputRadius">
                <input type="date" class="form-control-sm form-control rounded-0 field-border" placeholder="dd/mm/yyyy"
                (click)="enterKeytriggerFunction($event, searchDate, 'visitDate')"
                (keyup)="enterKeytriggerFunction($event, searchDate, 'visitDate')"
                name="visitDateSearch" #dob #dateofbirth="ngModel"
                [(ngModel)]="searchDate" id="visitDateSearch">
              <div class="input-group-append">
                <span class="handCursor input-group-text" (click)="searchOutpatients(searchDate,'visitDate')"><i
                    class="fas fa-search text-danger"></i></span>
              </div>
            </div>
            <label class="searchLabel my-1">Search with proper date</label>
          </div>
        </div>
      </div>

      <div class="VisitResponsive mt-md-1">
        <table class="table table-striped table-hover visitTable">
          <thead>
            <tr>
              <th class="wid160 fweight600 thwidth">Patient Name</th>
              <th class="wid105 fweight600">UMR No</th>
              <th class="wid100 fweight600">Visit Id</th>
              <th class="wid100 fweight600">Visit Date</th>
              <th class="wid135 fweight600">Options</th>

            </tr>
          </thead>
          <tbody  class="handCursor">
            <tr *ngFor="let patient of outPatientList">
              <td class="text-capitalize">{{patient?.title}} {{patient.patientName}}</td>
              <td>{{patient.patientId}}</td>
              <td>{{patient.visitId}}</td>
              <td>{{patient?.visitDate | date: "dd-MMM, yy"}}</td>
              <td><button class="con2 btn btn-sm btn-primary" type="button" (click)="getPatientInfo(patient)">{{buttonName}}</button>
                <!-- <button class="con2 btn btn-sm btn-primary mt-1" type="button" (click)="checkOutVisitStatus(patient.visitId)">{{buttoncheck}}</button> -->
              </td>

            </tr>
          </tbody>
        </table>
        <div *ngIf="outPatientList.length === 0"
              class="text-info text-center font-weight-bold">Please search using UMR Number or Visit ID or Patient Name or Visit Date and select the respective Patient to generate a bill or view the bills.</div>
      </div>

    </div>

    <ng-container *ngIf="show">

      <div class="col-md-7 datPage align">

        <div class="opbr">
          <form autocomplete="off">
            <div class="row">

              <div class="col-md-6 row">
                <div class="col-sm-5">
                <label class="font-weight-bold"> Patient Name</label>

                </div>
                <div class="col-sm-7">
                <span class="span_ipad fontChanged text-capitalize">: {{patientInfo?.title}} {{patientInfo.patientName}}</span>
                </div>
              </div>
              <div class="col-md-6 row">
                <div class="col-sm-5">
                <label class="font-weight-bold">UMR No </label>
                </div>
                <div class="col-sm-7">
                <span class="span_ipad fontChanged">: {{patientInfo.patientId}}</span>
                </div>

              </div>
            </div>
            <div class="row my-md-3">
              <div class="col-md-6 row">
                <div class="col-sm-5">
                <label class="font-weight-bold">Visit Id </label>

                </div>
                <div class="col-sm-7">
                <span class="span_ipad fontChanged">: {{patientInfo.visitId}}</span>
              </div>
              </div>
              <div class="col-md-6 row">
                <div class="col-sm-5">
                <label class="font-weight-bold">Payee Name </label>
                </div>
                <div class="col-sm-7">
                <span class="span_ipad fontChanged">: {{payeeName}}</span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 row">
                <div class="col-sm-5">
                <label class="font-weight-bold"> Gender / Age </label>
                </div>
                <div class="col-sm-7">
                <span class="span_ipad fontChanged">: {{patientInfo.gender}} / {{patientInfo.patientAge}}</span>
                </div>

              </div>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col fweight600">Doctor Name</th>
                  <th scope="col fweight600">External Referral</th>
                  <th scope="col fweight600">Visit type</th>
                  <th scope="col fweight600">Action</th>
                </tr>
              </thead>
              <tbody class="mt-2">
                <tr *ngFor="let doctor of doctorsList">
                  <td>Dr.{{doctor.dmName}}</td>
                  <td>{{doctor.referalDoctorName}}</td>
                  <td>{{doctor.visitType}}</td>
                  <td>
                      <button class="btn brand-btn mr-2 btn-sm"
                              type="button"
                              (click)="paymentIndex = 0;printBillDetails(doctor)"
                              id="proceed">View bill</button>
                              <!-- myModal11 -->
                      <button type="button"
                              class="btn brand-btn btn-sm"
                              (click)="prescriptionPrint(patientInfo.patientId, patientInfo.visitId, doctor.doctorID)">Prescription</button>
                    </td>

                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="patientInfo.length === 0 && !show">
      <div class="col-md-6 text-info text-center font-weight-bold mt-5">{{visitExpireMessage}}</div>
    </ng-container> -->


    <!-- Prescription modal popup -->
    <div id="print-prescription-section" class="print-form mt-5" *ngIf="printPrescription && prescriptionData !== undefined && prescriptionData.patientModel !== undefined">
      
      <div *ngIf="userInfo.hospitalShortName != 'DPMTA' && userInfo.hospitalShortName != 'KPMTA'
       && userInfo.hospitalShortName != 'CPMTA' && userInfo.hospitalShortName !='CBPMTA' && userInfo.hospitalShortName != 'JPMTA'">
        <div class="row no-gutters heading-block" >
          <div class="col-md-2 logo">
            <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
          </div>
          <div class="col-md-9">
            <div class="text-center">
              <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
              <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
              <p class="mb-0">{{userInfo.hospitalAdress}}</p>
              <!-- <p class="mb-0">Hyderabad - 500 082, Ph: 040-6666 2345,6699 2345.</p> -->
              <p class="mb-0">Email: health@lsch.in, www.lsch.in</p>
            </div>
          </div>
        </div>
  
        <div class="doctor-name mt-1">
          <h4>Dr. {{prescriptionData.billingDoctorInformation.doctorName}}</h4>
          <p class="mb-1">&nbsp; &nbsp; {{prescriptionData.billingDoctorInformation.doctorQualification}}</p>
        </div>
  
        <hr class="m-0 hr" />
        <!-- Patient information -->
        <div class="row mt-2 patient-info">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <b>Patient Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Guardian Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Ref. Doctor </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Date of Birth </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
              </div>
            </div>            
          </div>
          <div class="col-md-3">        
            <div class="row">
              <div class="col-md-5">
                <b>Age </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.age}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Gender </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.gender}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>UMR No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.patientID}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>OP.No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{patientInfo.visitId}}
              </div>
            </div>          
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5">
                <b>Visit Type </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
              </div>
            </div>            
            <div class="row">
              <div class="col-md-5">
                <b>Date/Time </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Phone No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <b class="mr-5">Address</b>
              <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
            </div>
          </div>
        </div>
        <!-- ./END Patient information -->
  
        <!-- Prescription info -->
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <td><b>Vital signs</b> <br> </td>
              <td>
                <span class="font-14"><b>Pulse:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Temp:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>B.P:</b>    </span> <pre class="border-0">      </pre> </td>
              <td>
                <span class="font-14"><b>RR:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>SPO2:</b> </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Nursing Initial Assessment:</b></span>
              </td>
            </tr>
            <tr>
              <td><span class="font-14"><b>Anthroprometry</b></span></td>
              <td>
                <span class="font-14"><b>Weight:</b>  </span> <pre class="border-0">     </pre> </td>
              <td>
                <span class="font-14"><b>Height: </b>   </span> <pre class="border-0">     </pre>
              </td>
              <td>
                <span class="font-14"><b>HC:</b>  </span> <pre class="border-0">      </pre></td>
              <td>
                <span class="font-14"><b>Pain score:</b> </span> </td>
              <td></td>
              <td>
                <span class="font-14"><b>Name:</b> </span> <br/>
                <span class="font-14"><b>Date /Time:</b></span><br/>
                <span class="font-14"><b>Sign:</b></span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Chief complaints and Past history:</b> </span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>O/E:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Provisional Diagnosis:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Investigations Advised:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="4"><span class="font-14"><b>Treatment Advised</b></span>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Dose:</b>
                </span>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Frequency:</b>
                </span>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Duration:</b>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Patient Education and Nutritional Advise:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <span class="font-14"><b>Next follow-up Date:</b></span>
                <span class="font-12"></span>
              </td>
              <td colspan="4">
                <span class="font-14"><b>Signature of the Consultant:</b></span><br />
                <div class="pt-2">
                  <span class="font-14 pr-5"><b>Date:</b></span>
                  <span class="font-14 pl-5"><b>Time:</b></span>
                </div>
                
              </td>
            </tr>
          </table>
          <p class="text-center mt-4 mb-0" style="font-size: 20px;"
          *ngIf="billingDoctorInformation !== null 
          && billingDoctorInformation.freeFollowUpDays !== null 
          && billingDoctorInformation.freeFollowUpDays !== 0"><b>{{billingDoctorInformation.footerText}} : {{getFollowupDate() | date:"dd-MM-yyyy"}}</b></p>
        </div>
        <!-- ./END Prescription info -->
      </div>
      <div *ngIf="userInfo.hospitalShortName == 'DPMTA'">
        <div *ngIf="printedDoctor == true"><br><br><br><br></div>
        <div class="row no-gutters" *ngIf="printedDoctor == false">
          <div class="col-12 text-right mt-0 mb-0">
            <h4><b>Dr. {{prescriptionData.billingDoctorInformation.doctorName}}</b></h4>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorQualification}}</b></p>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorDesignation}}</b></p>
          </div>
        </div>     
        <!-- Patient information -->          
        <div class="row">
          <div class="col-12">
            <div class="text-center"><span  style="font-size: 19px;"><b>Out Patient Consultation</b></span></div>
              <fieldset class="legend-block">
                  <legend class="legend-title mb-0 font-19">Patient Details</legend>
                  <div class="row mt-2 prescription-info">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-6">
                          <b>P. Name </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>Guardian Name </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>Ref. Doctor </b>
                        </div>
                        <div class="col-md-6">
                          <div *ngIf="walkin == true">
                            <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
                          </div>    
                          <div *ngIf="walkin == false">                          
                            <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
                            ({{prescriptionData.billingDoctorInformation.externalReferalCity}})
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>Date of Birth </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
                        </div>
                      </div>                        
                    </div>
                    <div class="col-md-4">        
                      <div class="row">
                        <div class="col-md-6">
                          <b>Age </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.age}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>Gender </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.gender}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>UMR No </b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> {{prescriptionData.patientModel.patientID}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <b>Consult Doctor</b>
                        </div>
                        <div class="col-md-6">
                          <b>:</b> Dr. {{prescriptionData.billingDoctorInformation.doctorName}}
                        </div>
                      </div>          
                    </div>
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-5">
                          <b>Visit Type </b>
                        </div>
                        <div class="col-md-7">
                          <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
                        </div>
                      </div>                        
                      <div class="row">
                        <div class="col-md-5">
                          <b>Date/Time </b>
                        </div>
                        <div class="col-md-7">
                          <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <b>Phone No </b>
                        </div>
                        <div class="col-md-7">
                          <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <b class="mr-5">Address</b>
                        <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
                      </div>
                    </div>
                  </div>
                  <p class="text-right mt-4 mb-0 font-weight-bold" 
                    *ngIf="billingDoctorInformation !== null 
                    && billingDoctorInformation.freeFollowUpDays !== null 
                    && billingDoctorInformation.freeFollowUpDays !== 0">
                    THIS CARD IS VALID UPTO "{{getFollowupDate()}}" (OR) 1 VISIT WHICH EVER IS EARLIER</p>
              </fieldset>
          </div>
        </div>
      </div>
      <div *ngIf="userInfo.hospitalShortName == 'KPMTA'">
        <div class="doctor-name mt-1 font-weight-bold">
          <h3><b>Dr. {{prescriptionData.billingDoctorInformation.doctorName}}</b></h3>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorQualification}}</b></p>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorDesignation}}</b></p>
        </div>
        <hr class="m-0 hr" />
        <!-- Patient information -->
        <div class="row mt-2 patient-info" style="font-weight: bolder;">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <b>P. Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Guardian Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Ref. Doctor </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Date of Birth </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
              </div>
            </div>            
          </div>
          <div class="col-md-3">        
            <div class="row">
              <div class="col-md-5">
                <b>Age </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.age}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Gender </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.gender}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>UMR No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.patientID}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>OP.No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{patientInfo.visitId}}
              </div>
            </div>          
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5">
                <b>Visit Type </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-5">
                <b>Date/Time </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Phone No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Validity </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{getFollowupDate() | date:"dd-MM-yyyy"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <b class="mr-5">Address</b>
              <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
            </div>
          </div>
        </div>
        <!-- ./END Patient information -->
  
        <!-- Prescription info -->
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <td><b>Vital signs</b> <br> </td>
              <td>
                <span class="font-14"><b>Pulse:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Temp:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>B.P:</b>    </span> <pre class="border-0">      </pre> </td>
              <td>
                <span class="font-14"><b>RR:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>SPO2:</b> </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Nursing Initial Assessment:</b></span>
              </td>
            </tr>
            <tr>
              <td><span class="font-14"><b>Anthroprometry</b></span></td>
              <td>
                <span class="font-14"><b>Weight:</b>  </span> <pre class="border-0">     </pre> </td>
              <td>
                <span class="font-14"><b>Height: </b>   </span> <pre class="border-0">     </pre>
              </td>
              <td>
                <span class="font-14"><b>HC:</b>  </span> <pre class="border-0">      </pre></td>
              <td>
                <span class="font-14"><b>Pain score:</b> </span> </td>
              <td></td>
              <td>
                <span class="font-14"><b>Name:</b> </span> <br/>
                <span class="font-14"><b>Date /Time:</b></span><br/>
                <span class="font-14"><b>Sign:</b></span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Chief complaints and Past history:</b> </span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>O/E:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Provisional Diagnosis:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Investigations Advised:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="4"><span class="font-14"><b>Treatment Advised</b></span>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Dose:</b>
                </span>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Frequency:</b>
                </span>
              </td>
              <td colspan="1">
                <span class="font-14">
                  <b>Duration:</b>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Patient Education and Nutritional Advise:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
          </table>
          <div class="row mt-5 text-left">            
            <div class="offset-6 col-6">
              <span class="font-16"><b>Signature of the Consultant:</b></span><br/>
                <div class="pt-2">
                  <span class="font-16 pr-5"><b>Date:</b></span>
                  <span class="font-16 pl-5"><b>Time:</b></span>
                </div>
            </div>
          </div>         
        </div>
        <!-- ./END Prescription info -->
      </div>
      <div class="margin-top" *ngIf="userInfo.hospitalShortName == 'CPMTA'">
        <hr class="m-0 hr" />
        <!-- Patient information -->
        <div class="row mt-2 patient-info">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <b>P. Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Guardian Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Ref. Doctor </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Date of Birth </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
              </div>
            </div>
            
          </div>
          <div class="col-md-3">        
            <div class="row">
              <div class="col-md-6">
                <b>Age </b>
              </div>
              <div class="col-md-6">
                <b>:</b> {{prescriptionData.patientModel.age}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b>Gender </b>
              </div>
              <div class="col-md-6">
                <b>:</b> {{prescriptionData.patientModel.gender}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b>UMR No </b>
              </div>
              <div class="col-md-6">
                <b>:</b> {{prescriptionData.patientModel.patientID}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b>OP.No </b>
              </div>
              <div class="col-md-6">
                <b>:</b> {{patientInfo.visitId}}
              </div>
            </div>          
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5">
                <b>Visit Type </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-5">
                <b>Date/Time </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Phone No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Validity </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{getFollowupDate() | date:"dd-MM-yyyy"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <b class="mr-5">Address</b>
              <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
            </div>
          </div>
        </div>
        <!-- ./END Patient information -->
  
        <!-- Prescription info -->
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <td><b>Vital signs</b> <br> </td>
              <td>
                <span class="font-14"><b>Pulse:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Temp:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>B.P:</b>    </span> <pre class="border-0">      </pre> </td>
              <td>
                <span class="font-14"><b>RR:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>SPO2:</b> </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Nursing Initial Assessment:</b></span>
              </td>
            </tr>
            <tr>
              <td><span class="font-14"><b>Anthroprometry</b></span></td>
              <td>
                <span class="font-14"><b>Weight:</b>  </span> <pre class="border-0">     </pre> </td>
              <td>
                <span class="font-14"><b>Height: </b>   </span> <pre class="border-0">     </pre>
              </td>
              <td>
                <span class="font-14"><b>HC:</b>  </span> <pre class="border-0">      </pre></td>
              <td>
                <span class="font-14"><b>Pain score:</b> </span> </td>
              <td></td>
              <td>
                <span class="font-14"><b>Name:</b> </span> <br/>
                <span class="font-14"><b>Date /Time:</b></span><br/>
                <span class="font-14"><b>Sign:</b></span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Chief complaints and Past history:</b> </span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>O/E:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
          </table>
        </div>
        <!-- ./END Prescription info -->
      </div>
      <div *ngIf="userInfo.hospitalShortName == 'CBPMTA'">
        <div class="row no-gutters heading-block">
          <!-- <div class="col-md-2 logo">
            <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
          </div>
          <div class="col-md-9">
            <br><br>
            <div class="text-center">
              <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
              <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
              <p class="mb-0">{{userInfo.hospitalAdress}}</p>              
            </div>
          </div> -->
          <div class="doctor-name mt-0">
            <h3><b>Dr. {{prescriptionData.billingDoctorInformation.doctorName}}</b></h3>
            <p class="mb-0" style="font-size: 20px;"><b>{{prescriptionData.billingDoctorInformation.doctorQualification}}</b></p>
            <p class="mb-3" style="font-size: 20px;"><b>{{prescriptionData.billingDoctorInformation.doctorDesignation}}</b></p>
          </div>
        </div>        
  
        <hr class="m-0 hr" />
        <!-- Patient information -->
        <div class="row mt-2 patient-info">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <b>P. Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Guardian Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Ref. Doctor </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Date of Birth </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
              </div>
            </div>            
          </div>
          <div class="col-md-3">        
            <div class="row">
              <div class="col-md-5">
                <b>Age </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.age}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Gender </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.gender}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>UMR No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.patientID}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>OP.No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{patientInfo.visitId}}
              </div>
            </div>          
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5">
                <b>Visit Type </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
              </div>
            </div>            
            <div class="row">
              <div class="col-md-5">
                <b>Date/Time </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Phone No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Validity </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{getFollowupDate() | date:"dd-MM-yyyy"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <b class="mr-5">Address</b>
              <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
            </div>
          </div>
        </div>
        <!-- ./END Patient information -->
  
        <!-- Prescription info -->
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <td><b>Vital signs</b> <br> </td>
              <td>
                <span class="font-14"><b>Pulse:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Temp:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>B.P:</b>    </span> <pre class="border-0">      </pre> </td>
              <td>
                <span class="font-14"><b>RR:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>SPO2:</b> </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Nursing Initial Assessment:</b></span>
              </td>
            </tr>
            <tr>
              <td><span class="font-14"><b>Anthroprometry</b></span></td>
              <td>
                <span class="font-14"><b>Weight:</b>  </span> <pre class="border-0">     </pre> </td>
              <td>
                <span class="font-14"><b>Height: </b>   </span> <pre class="border-0">     </pre>
              </td>
              <td>
                <span class="font-14"><b>HC:</b>  </span> <pre class="border-0">      </pre></td>
              <td>
                <span class="font-14"><b>Pain score:</b> </span> </td>
              <td></td>
              <td>
                <span class="font-14"><b>Name:</b> </span> <br/>
                <span class="font-14"><b>Date /Time:</b></span><br/>
                <span class="font-14"><b>Sign:</b></span>
              </td>
            </tr>    
          </table>
        </div>
        <!-- ./END Prescription info -->
      </div>
      <div *ngIf="userInfo.hospitalShortName == 'JPMTA'">
        <br><br>
        <div class="doctor-name mt-1 font-weight-bold">
          <h3><b>Dr. {{prescriptionData.billingDoctorInformation.doctorName}}</b></h3>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorQualification}}</b></p>
            <p class="mb-0" style="font-size: 18px;"><b>{{prescriptionData.billingDoctorInformation.doctorDesignation}}</b></p>
        </div>
        <hr class="m-0 hr" />
        <!-- Patient information -->
        <div class="row mt-2 patient-info" style="font-weight: bolder;">
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <b>P. Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.title}} {{prescriptionData.patientModel.patientName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Guardian Name </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.nextOfKinName}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Ref. Doctor </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.billingDoctorInformation.externalReferal}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b>Date of Birth </b>
              </div>
              <div class="col-md-8">
                <b>:</b> {{prescriptionData.patientModel.dateOfBirth  | date: "dd-MMM, yyyy"}}
              </div>
            </div>            
          </div>
          <div class="col-md-3">        
            <div class="row">
              <div class="col-md-5">
                <b>Age </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.age}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Gender </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.gender}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>UMR No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.patientID}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>OP.No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{patientInfo.visitId}}
              </div>
            </div>          
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-5">
                <b>Visit Type </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.billingDoctorInformation.visitCategory}}
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-5">
                <b>Date/Time </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.visitDate | date: "dd-MMM, yyyy"}} {{prescriptionData.visitTime}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Phone No </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{prescriptionData.patientModel.mobileNumber}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b>Validity </b>
              </div>
              <div class="col-md-7">
                <b>:</b> {{getFollowupDate() | date:"dd-MM-yyyy"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <b class="mr-5">Address</b>
              <span class="ml-5">: {{prescriptionData.patientModel.address}}</span>
            </div>
          </div>
        </div>
        <!-- ./END Patient information -->
  
        <!-- Prescription info -->
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <td><b>Vital signs</b> <br> </td>
              <td>
                <span class="font-14"><b>Pulse:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Temp:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>B.P:</b>    </span> <pre class="border-0">      </pre> </td>
              <td>
                <span class="font-14"><b>RR:</b>   </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>SPO2:</b> </span> <pre class="border-0">   </pre>
              </td>
              <td>
                <span class="font-14"><b>Nursing Initial Assessment:</b></span>
              </td>
            </tr>
            <tr>
              <td><span class="font-14"><b>Anthroprometry</b></span></td>
              <td>
                <span class="font-14"><b>Weight:</b>  </span> <pre class="border-0">     </pre> </td>
              <td>
                <span class="font-14"><b>Height: </b>   </span> <pre class="border-0">     </pre>
              </td>
              <td>
                <span class="font-14"><b>HC:</b>  </span> <pre class="border-0">      </pre></td>
              <td>
                <span class="font-14"><b>Pain score:</b> </span> </td>
              <td></td>
              <td>
                <span class="font-14"><b>Name:</b> </span> <br/>
                <span class="font-14"><b>Date /Time:</b></span><br/>
                <span class="font-14"><b>Sign:</b></span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>Chief complaints and Past history:</b> </span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <span class="font-14"><b>O/E:</b></span>
                <span class="font-12">
                  <br>
                  <br>
                  <br>
                  <br>
                </span>
              </td>
            </tr>
          </table>         
        </div>
        <!-- ./END Prescription info -->
      </div>
    </div>

    <ng-template #myModal11 let-modal>
   
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="text-center">Outpatient Billing</h4><button type="button" class="close" (click)="onClosePopup()"  aria-label="Close"><span aria-hidden="true">×</span></button>
          </div>

          <div class="row">
            <div class="col" style="border-right-color: rgb(13,14,16);" *ngIf="printDetailsData.hospitalModel !== undefined && false">
              <div [hidden]="true">
                <div class="table-responsive table-borderless" style="border-bottom-style: none;">
                  <table class="table table-bordered">
                    <tbody style="border-bottom-style: none;">
                    <tr>
                      <td class="text-center"><strong>{{userInfo.hospitalName}}
                       </strong><br></td>
                    </tr>
                    <tr>
                      <td class="text-center flex-grow-1">{{userInfo.hospitalTagLine}}<br></td>
                    </tr>
                    <tr>
                      <td class="text-center"> {{userInfo.hospitalAdress}}</td>
                    </tr>
                    <tr class="text-center">
                      <td><strong>OP Bill-Cum-Receipt</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col" *ngIf="printDetailsData.patientModel !== undefined && printDetailsData.patientModel !== null">
              <div class="table-responsive table-borderless col-md-12 pb-3">
                <div class="row">
                  <div class="col-md-12 d-flex p-2">
                    <div class="col-md-4 d-flex">
                      <div class="col-md-5 font-weight-bold">Patient Name</div>
                      <div class="col-md-7"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold">UMR No</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.patientID}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold">Visit ID</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.visitID}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex p-2">
                    <div class="col-md-4 d-flex">
                      <div class="col-md-5 font-weight-bold">Gender / Age</div>
                      <div class="col-md-7"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.gender}} / {{printDetailsData.patientModel.age}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold">Mobile No</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span> {{printDetailsData.patientModel.mobileNumber}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold">Visit Date</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.visitDate}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex p-2">
                    <div class="col-md-4 d-flex">
                      <div class="col-md-5 p-0 pl-3 font-weight-bold">Parent / Guardian</div>
                      <div class="col-md-7"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.nextOfKinName}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold"> Mobile No </div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.nextOfKinMobilenr}}</div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <div class="col-md-4 font-weight-bold">Visit Time</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.visitTime}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex p-2">
                    <div class="col-md-4 d-flex">
                      <div class="col-md-5 font-weight-bold">DOB</div>
                      <div class="col-md-7"><span class="font-weight-bold">: </span>{{printDetailsData.patientModel.dateOfBirth}}</div>
                    </div>
                    <div class="col-md-4 d-flex" *ngIf="billingFinalBillDetailModel[0] !== undefined">
                      <div class="col-md-4 font-weight-bold"> Bill No </div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{billingFinalBillDetailModel[0].finalID}}</div>
                    </div>
                    <div class="col-md-4 d-flex" *ngIf="billingFinalBillDetailModel[0] !== undefined">
                      <div class="col-md-4 font-weight-bold">Bill Date</div>
                      <div class="col-md-8"><span class="font-weight-bold">: </span>{{getFinalBillDate()}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex p-2">
                    <div class="col-md-4 d-flex">
                      <div class="col-md-5 font-weight-bold"> Payee Name </div>
                      <div class="col-md-7"><span class="font-weight-bold">: </span>{{payeeName}}</div>
                    </div>
                    <div class="col-md-8 d-flex" *ngIf="billingDoctorInformation !== null">
                      <div class="col-md-2 font-weight-bold">Consultant</div>
                      <div class="col-md-10 p-0 pl-2 ml-1"><span class="font-weight-bold">: </span><span *ngIf="billingDoctorInformation.doctorId != userInfo.hospitalID">Dr. </span>{{billingDoctorInformation.doctorName}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 tab d-flex p-0" *ngIf="printDetailsData !== undefined && printDetailsData.billingPaymentSubmitModel !== undefined">
            <div class="col-md-2 p-0 m-0" *ngFor="let obj of printDetailsData.billingPaymentSubmitModel;let i=index">
              <button class="tablinks" [ngClass]="{'selService':paymentIndex == i}" (click)="setServicesIndex(i)" > <span *ngIf="obj.billingFinalBillDetailModel[0] !== undefined">Bill No:{{obj.billingFinalBillDetailModel[0].finalID}}</span></button>
            </div>
          </div>

          <!-- <div class="tab" *ngIf="printDetailsData !== undefined && printDetailsData.billingPaymentSubmitModel !== undefined">
            <button  *ngFor="let obj of printDetailsData.billingPaymentSubmitModel;let i=index" class="tablinks" [ngClass]="{'selService':paymentIndex == i}" (click)="setServicesIndex(i)" > <span *ngIf="obj.billingFinalBillDetailModel[0] !== undefined">Bill No:{{obj.billingFinalBillDetailModel[0].finalID}}</span></button>
          </div> -->

          <div class="modal-body align-self-center">

            <!-- <div class="row" style="display: none;" id="only-print">
              <div class="col" style="border-right-color: rgb(13,14,16);" *ngIf="printDetailsData.hospitalModel !== undefined">
                <div>
                  <div class="table-responsive table-borderless" style="border-bottom-style: none;">
                    <table class="table table-bordered">
                      <tbody style="border-bottom-style: none;">
                      <tr>
                        <td class="text-center"><strong>{{printDetailsData.hospitalModel.hospitalName}}</strong><br></td>
                      </tr>
                      <tr>
                        <td class="text-center flex-grow-1">(A UNIT OF THIRUMALA CHILDHEALTH SERVICES PVT.LTD)<br></td>
                      </tr>
                      <tr>
                        <td class="text-center">{{printDetailsData.hospitalModel.hospitalAddress}}</td>
                      </tr>
                      <tr class="text-center">
                        <td><strong>OP Billing Details</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="printDetailsData.patientModel !== undefined && printDetailsData.patientModel !== null">
                <div class="table-responsive table-borderless">
                  <table class="table table-bordered">
                    <tbody>
                    <tr>
                      <td>P Name: {{printDetailsData.patientModel.patientName}}</td>
                      <td *ngIf="billingFinalBillDetailModel[0] !== undefined">Bill No: {{billingFinalBillDetailModel[0].finalID}}</td>
                    </tr>
                    <tr>
                      <td>UMR NO: {{printDetailsData.patientModel.patientID}}</td>
                      <td *ngIf="billingFinalBillDetailModel[0] !== undefined">Visit Id: {{billingFinalBillDetailModel[0].visitID}}</td>
                    </tr>
                    <tr>
                      <td>Age/Sex :{{printDetailsData.patientModel.age}}/{{printDetailsData.patientModel.gender}}</td>
                      <td>Bill Date: {{printDetailsData.patientModel.regDate}}</td>
                    </tr>
                    <tr>
                    </tr>
                    <tr>
                      <td>Phone: {{printDetailsData.patientModel.mobileNumber}}</td>
                      <td *ngIf="billingDoctorInformation !== null">Consultant: DR.{{billingDoctorInformation.doctorName}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}"><label class="col-form-label">Ref By: {{billingDoctorInformation.externalReferal}}</label></div>
            </div>
            <div class="row">
              <div class="col m-auto">
                <div class="table-responsive table-bordered border rounded-0">
                  <table class="table table-bordered">
                    <thead>
                    <tr class='alignCenter'>
                      <th scope="col fweight600">SI No</th>
                      <th scope="col fweight600">Service Name</th>
                      <th scope="col fweight600">Service Id</th>
                      <th scope="col fweight600">Qty</th>
                      <th scope="col fweight600">Rate</th>
                      <th scope="col fweight600">Amount (Rs)</th>
                      <!-- <th id="cancelHeader" scope="col fweight600">
                        <button type="button" [disabled]="isDisabled() || disableCancel" class="btn btn-primary cancelBtn" (click)="cancelPaymentService()">
                        Cancel
                      </button></th> -->
                      <th id="remarksHeader" scope="col fweight600">Remarks</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="billingPaymentSubmitDetails !== undefined && billingPaymentSubmitDetails.length != 0">
                    <tr class='alignCenter' *ngFor="let service of billingPaymentSubmitDetails; let i= index">
                      <td>{{i+1}}</td>
                      <td>{{service.serviceName}}</td>
                      <td>{{service.serviceMasterId}}</td>
                      <td>{{service.serviceQuantity}}</td>
                      <td>{{service.serviceAmount}}</td>
                      <td>{{service.serviceGrossAmt}}</td>
                      <!-- <td id="cancelRow"><input *ngIf="service.sbiStatus!=='cancelled'" type="checkbox" name="selectserv" [attr.disabled]="(service.requestAccept)?'':null" [(ngModel)]="service.isSelect" /><span *ngIf="service.sbiStatus=='cancelled'">Cancelled</span></td> -->
                      <td id="remarksRow"><input *ngIf="service.isSelect && service.sbiStatus!=='cancelled'" type="text" name="remarks" placeholder="Remarks" [(ngModel)]="service.remarks" (ngModelChange)="disableCancel = false"/>
                      <span *ngIf="service.sbiStatus=='cancelled'">{{service.sbiRemarks}}</span></td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="3">Gross amount</td>
                      <td colspan="2">:</td>
                      <td>{{getGrossAmount()}}</td>
                    </tr>
                    <tr *ngIf="getDiscountAmount() > 0">
                      <td class="text-right" colspan="3">Discount amount</td>
                      <td colspan="2">:</td>
                      <td>{{getDiscountAmount()}}</td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="3">Net amount</td>
                      <td colspan="2">:</td>
                      <td>{{getNetAmount()}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
<!--             <div class="row" *ngIf="billingCancelDetails !== undefined && billingCancelDetails.length != 0"> -->
            <div class="row" *ngIf="billingCancelList !== undefined && billingCancelList.length != 0">

              <div class="col m-auto">
                <div class="pt-3 pb-2">
                  <h5><b>Cancellation Transaction Details:</b></h5><br>
                </div>
                <div class="table-responsive table-bordered border rounded-0">
                  <table class="table table-bordered">
                    <thead>
                    <tr class='alignCenter'>
                      <th scope="col fweight600">SI No</th>
                      <th scope="col fweight600">Receipt Nr</th>
                      <th scope="col fweight600">Receipt Date</th>
                      <th scope="col fweight600">Receipt Time</th>
                      <th scope="col fweight600">Amount Refunded</th>
                      <th scope="col fweight600">Mode of Payment</th>
                      <th scope="col fweight600">User Name</th>
                    </tr>
                    </thead>
                    <tbody>

<!--                       <tr class='alignCenter' *ngFor="let calcelService of billingCancelDetails; let i= index"> -->

                      <tr class='alignCenter' *ngFor="let calcelService of billingCancelList; let i= index">

                        <td>{{i+1}}</td>
                        <td>{{calcelService.receiptNo}}</td>
                        <td>{{calcelService.receiptDate}}</td>
                        <td>{{calcelService.receiptTime}}</td>
                        <td>{{calcelService.amountRefunded}}</td>
                        <td>{{calcelService.cancelledPaymentType}}</td>
                        <td>{{calcelService.createdId}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="table-responsive table-borderless serviceWidth">
                  <table class="table table-bordered">
                    <tbody>
                      <tr *ngIf="getCashAmount() !== 0">
                        <td>Cash Amount </td>
                        <td>: {{getCashAmount()}}</td>
                      </tr>
                      <tr *ngIf="getCardAmount() !== 0">
                        <td>Card Amount </td>
                        <td>: {{getCardAmount()}}</td>
                        <td>Transaction Ref. No : </td>
                        <td>{{getTransactionNo()}}</td>
                      </tr>
                      <tr *ngIf="getCardAmount() !== 0">
                        <td>Card Bank </td>
                        <td>: {{getBankName()}}</td>
                        <td>Card Nr </td>
                        <td>: {{getCardNumber()}}</td>
                      </tr>
                      <tr *ngIf="getWalletAmount() !== 0">
                        <td>Wallet Amount </td>
                        <td>: {{getWalletAmount()}}</td>
                        <td *ngIf="getWalletAmount() !== 0 && getCardAmount() == 0">Transaction Ref. No  </td>
                        <td *ngIf="getWalletAmount() !== 0 && getCardAmount() == 0">: {{getTransactionReferenceNo()}}</td>
                      </tr>
                      <tr *ngIf="getWalletAmount() !== 0 && getCardAmount() == 0">
                        <td>Mobile Nr </td>
                        <td>: {{getMobileNo()}}</td>
                      </tr>
                    <tr>
                      <td>Created By</td>
                      <td>: {{getCreatedBy()}}</td>
                      <td>Created Date</td>
                      <td>: {{getCreatedDate()}}</td>
                    </tr>
                    <!-- <tr>
                      <td>Printed By</td>
                      <td>: {{userInfo.username}}</td>
                      <td>Printed Date</td>
                      <td>: {{getDate()}}</td>
                    </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col"><label class="col-form-label">*** Cheques are subject to realisation</label></div>
              <div class="col"><label class="col-form-label">Email: health@lsch.in, www.lsch.in</label></div>
              <div class="col"><label class="col-form-label">(Authorised Signatory)</label></div>
            </div> -->
          </div>
          <div class="modal-footer"><button type="button" class="btn btn-primary" (click)="printBillToPrinter()" *ngIf="billingPaymentSubmitDetails.length != 0"><i class="fa fa-print fa-lg" aria-hidden="true"></i> Print Bill</button></div>
        </div>
    </ng-template>
  </div>  
</div>

<div id="print-section"
  *ngIf="printDetailsData !== undefined && printDetailsData.hospitalModel !== undefined && billingFinalBillDetailModel[0] != undefined"
  style="display:none" class="printt-form mt-5">
  <div *ngIf="userInfo.hospitalShortName != 'DPMTA' && userInfo.hospitalShortName != 'KPMTA' 
  && userInfo.hospitalShortName != 'CPMTA' && userInfo.hospitalShortName !='CBPMTA' && userInfo.hospitalShortName != 'JPMTA'">
    <div class="row no-gutters heading-blockk" >
      <div class="col-md-3 logo">
        <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
      </div>
      <div class="col-md-7">
        <div class="text-center">
          <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
          <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
          <p class="mb-0">{{userInfo.hospitalAdress}}</p>
          <!-- <p class="mb-0">Hyderabad - 500 082, Ph: 040-6666 2345,6699 2345.</p> -->
          <p class="mb-0">Email: health@lsch.in, www.lsch.in</p>
        </div>
      </div>
    </div>
    
    <hr class="m-0 hr" />
    <!-- Patient information -->
    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Patient Name </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill No </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{billingFinalBillDetailModel[0].finalID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>UMR NO </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.patientID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Age /Sex </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.age}} /{{printDetailsData.patientModel.gender}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Consultant </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> <span *ngIf="billingDoctorInformation.doctorId != userInfo.hospitalID">Dr. </span>{{ billingDoctorInformation.doctorName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getFinalBillDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Phone </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.mobileNumber}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Refer By </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> {{billingDoctorInformation.externalReferal}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="table table-bordered mb-2 mt-4">
        <tr>
          <th>
            <span class="text-size">S.No</span>
          </th>
          <th>
            <span class="text-size">Service Name</span>
          </th>
          <th>
            <span class="text-size">Service Cd</span>
          </th>
          <th>
            <span class="text-size">Qty</span>
          </th>
          <th>
            <span class="text-size">Rate</span>
          </th>
          <th>
            <span class="text-size">Amount (Rs)</span>
          </th>
        </tr>
        <tr *ngFor="let service of billingPaymentSubmitDetails; let i= index" >
            <td>
              <span class="text-size">{{i+1}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceName}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceMasterId}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceQuantity}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceAmount}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceGrossAmt}}</span>
            </td>
        </tr>
      </table>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6" style="margin-left: 71%;">
        <div class="row">
          <div class="col-md-3">
            <b>Gross amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getGrossAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Discount amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDiscountAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Net amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getNetAmount()}}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row" *ngIf="getCashAmount() !== 0">
          <div class="col-md-3">
            <b>Cash Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCashAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Wallet Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getWalletAmount()}}
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
        <div class="row"  *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Bank </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getBankName()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Transaction Ref. No </b>
          </div>
          <div class="col-md-9"  *ngIf="getCardAmount() > 0">
            <b>:</b> {{getTransactionNo()}}
          </div>
          <div class="col-md-9"  *ngIf="getWalletAmount() > 0 && getCardAmount() ==0">
            <b>:</b> {{getTransactionReferenceNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Mobile Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getMobileNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Card Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardNumber()}}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedBy()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{userInfo.username}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDate()}}
          </div>
        </div>
      </div>
    </div>

    <p class="mt-4">*** Cheques are subject to realisation</p>
    <p>Email: health@lsch.in</p>
    <div style="margin-left: 80%;">
      <p>(Authorised Signatory)</p>
    </div>
    <div *ngIf="cancelBillPrint.length !== 0">
      <h3>Cancellation Details :</h3>
      <div>
        <table class="table table-bordered mb-2 mt-4">
          <tr>
            <th>
              <span class="text-size">S.No</span>
            </th>
            <th>
              <span class="text-size">Service Name</span>
            </th>
            <th>
              <span class="text-size">Service Cd</span>
            </th>
            <th>
              <span class="text-size">Qty</span>
            </th>
            <th>
              <span class="text-size">Rate</span>
            </th>
            <th>
              <span class="text-size">Amount (Rs)</span>
            </th>
            <th>
              <span class="text-size">Remarks (Rs)</span>
            </th>
          </tr>
          <tr *ngFor="let service of cancelBillPrint; let i= index" >
              <td>
                <span class="text-size">{{i+1}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceName}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceMasterId}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceQuantity}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceAmount}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceNetAmt}}</span>
              </td>
              <td>
                <span class="text-size">{{service.sbiRemarks}}</span>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="userInfo.hospitalShortName == 'DPMTA'">
    <div class="row no-gutters heading-block">
	  	<fieldset class="col-md-12">
		    <div class="col-md-12 d-flex">
		      <div class="col-md-12 text-center" style="padding-right: 100px;">
            <!-- <h2 class="main-title hname mb-0 text-uppercase">{{userInfo.hospitalName}}</h2> -->
		        <!-- <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3> -->
		        <!-- <p class="mb-0">{{userInfo.hospitalAdress}}</p> -->
		        <h2 class="main-title hname mb-0">PARAMITHA CHILDREN'S HOSPITAL</h2>
		        <p class="mb-0">Beside Karachi Bakery, Green Hills Colony</p>
		        <p class="mb-0">Kothapet Main Road, Hyderabad - 35</p>
		        <p class="mb-0 opconsult"><b>OP Consultation Receipt</b></p>
		      </div>          
		    </div>
	  	</fieldset>

	  	<fieldset class="col-md-12 printbill-border">
		    <legend class="printbill-border" style="font-size: 18px;">Patient Details</legend>
		    <div class="row col-md-12 pt-table dpmta-info">
          <div class="col-md-12 d-flex p-0">
            <div class="col-md-6 d-flex p-0">
              <div class="col-4 p-0"><b>MRNo</b></div>
              <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.patientID}}</div>
            </div>
            <div class="col-md-6 d-flex p-0">
              <div class="col-5 p-0"><b>Bill No</b></div>
              <div class="col-7 p-0 pl-2"><b>:</b> {{billingFinalBillDetailModel[0].finalID}}</div>
            </div>
          </div>

          <div class="col-md-12 d-flex p-0">
            <div class="col-md-6 d-flex p-0">
              <div class="col-4 p-0"><b>Name</b></div>
              <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}</div>
            </div>
            <div class="col-md-6 d-flex p-0">
              <div class="col-5 p-0"><b>Bill Date</b></div>
              <div class="col-7 p-0 pl-2"><b>:</b> {{getFinalBillDate() | date: "dd-MM-yyyy"}}</div>
            </div>
          </div>

          <div class="col-md-12 d-flex p-0">
            <div class="col-md-6 d-flex p-0">
              <div class="col-4 p-0"><b>Age/Gender</b></div>
              <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.age}}/{{printDetailsData.patientModel.gender}}</div>
            </div>
            <div class="col-md-6 d-flex p-0">
              <div class="col-5 p-0"><b>OP ID</b></div>
              <div class="col-7 p-0 pl-2"><b>:</b> {{billingFinalBillDetailModel[0].visitID}}</div>
            </div>
          </div>

          <div class="col-md-12 d-flex p-0">
            <div class="col-md-6 d-flex p-0">
              <div class="col-4 p-0"><b>Doctor</b></div>
              <div class="col-6 p-0"><b>:</b>  <span *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {} && billingDoctorInformation.doctorId != userInfo.hospitalID"> Dr.</span>{{billingDoctorInformation.doctorName}}</div>
            </div>
            <div class="col-md-6 d-flex p-0">
              <div class="col-5 p-0"><b>Department</b></div>
              <div class="col-7 p-0 pl-2"><b>:</b> {{billingPaymentSubmitDetails[0].departmentName}}</div>
            </div>
          </div>

          <div class="col-md-12 d-flex p-0">
            <div class="col-md-6 d-flex p-0">
              <div class="col-4 p-0"><b>Pay Type</b></div>
              <div class="col-6 p-0"><b>:</b> SELF PAY</div>
              <!-- <div class="col-6 p-0" *ngIf="billingFinalBillDetailModel[paymentIndex].paymentModeType > 1"><b>:</b> PAYING</div> -->
            </div>
            <div class="col-md-6 d-flex p-0">
              <div class="col-5 p-0"><b></b></div>
              <div class="col-7 p-0 pl-2"></div>
            </div>
          </div>
        </div>
		  </fieldset>

      <fieldset class="col-md-12 mt-2 printbill-border">
        <legend class="printbill-border" style="font-size: 18px;">Payment Details</legend>
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="col-md-11 text-right" *ngFor="let service of billingPaymentSubmitDetails; let i= index"><b>{{service.serviceName}}:</b> <span> {{service.serviceAmount}}</span></div> -->
            <div>
              <table class="table table-bordered mb-1 mt-1 payment-info">
                <tr>
                  <th>
                    <span>S.No</span>
                  </th>
                  <th>
                    <span>Service Name</span>
                  </th>
                  <th>
                    <span>Service Cd</span>
                  </th>
                  <th>
                    <span>Qty</span>
                  </th>
                  <th>
                    <span>Rate</span>
                  </th>
                  <th>
                    <span>Amount (Rs)</span>
                  </th>
                </tr>
                <tr *ngFor="let service of billingPaymentSubmitDetails; let i= index" >
                    <td>
                      <span>{{i+1}}</span>
                    </td>
                    <td>
                      <span>{{service.serviceName}}</span>
                    </td>
                    <td>
                      <span>{{service.serviceMasterId}}</span>
                    </td>
                    <td>
                      <span>{{service.serviceQuantity}}</span>
                    </td>
                    <td>
                      <span>{{service.serviceAmount}}</span>
                    </td>
                    <td>
                      <span>{{service.serviceGrossAmt}}</span>
                    </td>
                </tr>
              </table>
            </div>
            <!-- <div class="col-md-11 text-right amount-info"  style="padding-right: 40px;"><b>Gross Amount:</b> <span> {{getGrossAmount()}}</span> </div>
            <div class="col-md-11 text-right amount-info"  style="padding-right: 40px;"><b>Discount:</b> <span> {{getDiscountAmount()}}</span> </div>
            <div class="col-md-11 text-right amount-info"  style="padding-right: 40px;"><b>Amount Paid:</b> <span> {{totalBillAmount}}</span> </div> -->
            <!-- <div class="col-md-11 text-right amount-info"  style="padding-right: 40px;"><b>Net Amount:</b> <span> {{getNetAmount()}}</span> </div> -->
          </div>
          <div class="col-md-12 mt-2 amount-info">
            <div class="col-md-6" style="margin-left: 70%;">
              
              <div class="row">
                <div class="col-md-4">
                  <b>Gross Amount </b>
                </div>
                <div class="col-md-8">
                  <b>:</b> {{getGrossAmount()}}
                </div>
              </div>
              <div class="row" *ngIf=" getDiscountAmount() > 0 ">
                <div class="col-md-4">
                  <b>Discount </b>
                </div>
                <div class="col-md-8">
                  <b>:</b> {{getDiscountAmount()}}
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-3">
                  <b>Net Amount </b>
                </div>
                <div class="col-md-9">
                  <b>:</b> {{getNetAmount()}}
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-4">
                  <b>Amount Paid </b>
                </div>
                <div class="col-md-8">
                  <b>:</b> {{totalBillAmount}}
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-12 payment-info amount-info">
            <div class="col-md-12 text-left"><b>Mode of payments:</b> <span> By {{BillPayType}} </span></div>
            <div class="col-md-12 text-left"><b>In Words:</b> <span> {{getAmountInWords()}}</span></div>
            <div class="col-md-12 d-flex">
              <div class="col-md-6 text-left p-0"><b>Print User:</b> <span> {{userInfo.username}}</span></div>
              <div class="col-md-5 text-right pr-4"><b>Billed User:</b> <span> {{getCreatedBy()}}</span></div>
            </div>
            <div class="col-md-12 text-left"><b>Print Date:</b> <span> {{getDateToPrint()}}</span></div>
          </div>
          <div class="col-md-12 text-right pt-4"><b> Authorized Signature</b></div>
        </div>
      </fieldset>
	  </div>
  </div>
  <div *ngIf="userInfo.hospitalShortName == 'KPMTA'">
    <br><br><br><br><br>
    <!-- <div class="row no-gutters heading-blockk">
      <div class="col-md-7">
        <div class="text-left">
          <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
          <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
          <p class="mb-0">{{userInfo.hospitalAdress}}</p>      
        </div>
      </div>
    </div> -->
    <h3 class="text-center font-weight-bold">Out-Patient Receipt</h3>
    <hr class="m-0 hr" />
    <!-- Patient information -->
    <div class="row mt-2 patientt-info" style="font-weight: bolder;">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>P. Name </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill No </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{billingFinalBillDetailModel[0].finalID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>UMR NO </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.patientID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Age /Sex </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.age}} /{{printDetailsData.patientModel.gender}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Consultant </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> <span *ngIf="billingDoctorInformation.doctorId != userInfo.hospitalID">Dr. </span>{{ billingDoctorInformation.doctorName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getFinalBillDate() | date:"dd-MM-yyyy"}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Phone </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.mobileNumber}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Refer By </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> {{billingDoctorInformation.externalReferal}}
          </div>
        </div>
      </div>
    </div>

    <div>
      <table class="table table-bordered mb-2 mt-4">
        <tr>
          <th>
            <span class="text-size">S.No</span>
          </th>
          <th>
            <span class="text-size">Service Name</span>
          </th>
          <th>
            <span class="text-size">Service Cd</span>
          </th>
          <th>
            <span class="text-size">Qty</span>
          </th>
          <th>
            <span class="text-size">Rate</span>
          </th>
          <th>
            <span class="text-size">Amount (Rs)</span>
          </th>
        </tr>
        <tr *ngFor="let service of billingPaymentSubmitDetails; let i= index" >
            <td>
              <span class="text-size">{{i+1}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceName}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceMasterId}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceQuantity}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceAmount}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceGrossAmt}}</span>
            </td>
        </tr>
      </table>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6" style="margin-left: 71%;">
        <div class="row">
          <div class="col-md-3">
            <b>Gross amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getGrossAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Discount amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDiscountAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Net amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getNetAmount()}}
          </div>
        </div>
      </div>
    </div>  

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row" *ngIf="getCashAmount() !== 0">
          <div class="col-md-3">
            <b>Cash Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCashAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Wallet Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getWalletAmount()}}
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
        <div class="row"  *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Bank </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getBankName()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Transaction Ref. No </b>
          </div>
          <div class="col-md-9"  *ngIf="getCardAmount() > 0">
            <b>:</b> {{getTransactionNo()}}
          </div>
          <div class="col-md-9"  *ngIf="getWalletAmount() > 0 && getCardAmount() ==0">
            <b>:</b> {{getTransactionReferenceNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Mobile Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getMobileNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 ">
          <div class="col-md-3">
            <b>Card Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardNumber()}}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedBy()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{userInfo.username}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDate()}}
          </div>
        </div>
      </div>
    </div>

    <!-- <p class="mt-4">*** Cheques are subject to realisation</p> -->
    <p class="mt-4 font-weight-bold" >Email: info@paramithahospitalskompally.com</p>
    <div style="margin-left: 80%;">
      <p class="font-weight-bold">(Authorised Signatory)</p>
    </div>
    <div *ngIf="cancelBillPrint.length !== 0">
      <h3>Cancellation Details :</h3>
      <div>
        <table class="table table-bordered mb-2 mt-4">
          <tr>
            <th>
              <span class="text-size">S.No</span>
            </th>
            <th>
              <span class="text-size">Service Name</span>
            </th>
            <th>
              <span class="text-size">Service Cd</span>
            </th>
            <th>
              <span class="text-size">Qty</span>
            </th>
            <th>
              <span class="text-size">Rate</span>
            </th>
            <th>
              <span class="text-size">Amount (Rs)</span>
            </th>
            <th>
              <span class="text-size">Remarks (Rs)</span>
            </th>
          </tr>
          <tr *ngFor="let service of cancelBillPrint; let i= index" >
              <td>
                <span class="text-size">{{i+1}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceName}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceMasterId}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceQuantity}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceAmount}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceNetAmt}}</span>
              </td>
              <td>
                <span class="text-size">{{service.sbiRemarks}}</span>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>  
  <div *ngIf="userInfo.hospitalShortName == 'CPMTA'">
    <fieldset class="col-md-12 printbill-border mb-1">
      <div class="col-md-12 text-center d-flex p-0 m-0">
        <div class="col-md-3 logo text-left p-0 m-0">
          <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
        </div>
        <!-- <h2 class="main-title hname mb-0 text-uppercase">{{userInfo.hospitalName}}</h2> -->
        <!-- <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3> -->
        <!-- <p class="mb-0">{{userInfo.hospitalAdress}}</p> -->
        <div class="col-md-8">
          <h2 class="main-title hname mb-0">PARAMITHA WOMEN & CHILDREN'S HOSPITAL</h2>
          <p class="mb-0">NH - 9,Opp. Bharat Petrol Pump</p>
          <p class="mb-0">Chandanagar, Hyderabad - 500050</p>
          <p class="mb-0 opconsult">OP Consultation Receipt</p>
        </div>
      </div>
    </fieldset>
    
    <fieldset class="col-md-12 printbill-border mb-1">
      <legend class="printbill-border legend-font">Patient Details</legend>
      <div class="row col-md-12 pt-table patientt-info">
        <div class="col-md-12 d-flex p-0">
          <div class="col-md-6 d-flex p-0">
            <div class="col-4 p-0"><b>MRNo</b></div>
            <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.patientID}}</div>
          </div>
          <div class="col-md-6 d-flex p-0">
            <div class="col-5 p-0"><b>Bill No</b></div>
            <div class="col-7 p-0 pl-2"><b>:</b> {{billingFinalBillDetailModel[0].finalID}}</div>
          </div>
        </div>

        <div class="col-md-12 d-flex p-0">
          <div class="col-md-6 d-flex p-0">
            <div class="col-4 p-0"><b>Name</b></div>
            <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}</div>
          </div>
          <div class="col-md-6 d-flex p-0">
            <div class="col-5 p-0"><b>Bill Date</b></div>
            <div class="col-7 p-0 pl-2"><b>:</b> {{getFinalBillDate()}}</div>
          </div>
        </div>

        <div class="col-md-12 d-flex p-0">
          <div class="col-md-6 d-flex p-0">
            <div class="col-4 p-0"><b>Age/Gender</b></div>
            <div class="col-6 p-0"><b>:</b> {{printDetailsData.patientModel.age}}/{{printDetailsData.patientModel.gender}}</div>
          </div>
          <div class="col-md-6 d-flex p-0">
            <div class="col-5 p-0"><b>OP ID</b></div>
            <div class="col-7 p-0 pl-2"><b>:</b> {{billingFinalBillDetailModel[0].visitID}}</div>
          </div>
        </div>

        <div class="col-md-12 d-flex p-0">
          <div class="col-md-6 d-flex p-0">
            <div class="col-4 p-0"><b>Doctor</b></div>
            <div class="col-6 p-0"><b>:</b>  <span *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {} && billingDoctorInformation.doctorId != userInfo.hospitalID"> Dr.</span>{{billingDoctorInformation.doctorName}}</div>
          </div>
          <div class="col-md-6 d-flex p-0">
            <div class="col-5 p-0"><b>Department</b></div>
            <div class="col-7 p-0 pl-2"><b>:</b> {{billingPaymentSubmitDetails[0].departmentName}}</div>
          </div>
        </div>

        <div class="col-md-12 d-flex p-0">
          <div class="col-md-6 d-flex p-0">
            <div class="col-4 p-0"><b>Pay Type</b></div>
            <div class="col-6 p-0"><b>:</b> SELF PAY</div>
            <!-- <div class="col-6 p-0" *ngIf="billingFinalBillDetailModel[paymentIndex].paymentModeType > 1"><b>:</b> PAYING</div> -->
          </div>
          <div class="col-md-6 d-flex p-0">
            <div class="col-5 p-0"><b></b></div>
            <div class="col-7 p-0 pl-2"></div>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="col-md-12 printbill-border">
      <legend class="printbill-border legend-font">Payment Details</legend>
      <div class="row patientt-info">
        <div class="col-md-12">
          <div class="col-md-11 text-right" *ngFor="let service of billingPaymentSubmitDetails; let i= index"><b>{{service.serviceName}}:</b> <span> {{service.serviceAmount}}</span></div>
          <div class="col-md-11 text-right"><b>Amount Paid:</b> <span> {{totalBillAmount}}</span> </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 text-left"><b>Mode of payments:</b> <span> By {{BillPayType}} </span></div>
          <div class="col-md-12 text-left"><b>In Words:</b> <span> {{getAmountInWords()}}</span></div>
          <div class="col-md-12 d-flex">
            <div class="col-md-6 text-left p-0"><b>Print User:</b> <span> {{userInfo.username}}</span></div>
            <div class="col-md-5 text-right pr-4"><b>Billed User:</b> <span> {{getCreatedBy()}}</span></div>
          </div>
          <div class="col-md-12 text-left"><b>Print Date:</b> <span> {{getDate()}}</span></div>
        </div>
        <div class="col-md-12 text-right pt-4"><b> Authorized Signature</b></div>
      </div>
      <div *ngIf="cancelBillPrint.length !== 0">
        <h5><b>Cancellation Details :</b></h5>
        <div>
          <table class="table table-bordered mb-2 mt-4">
            <tr>
              <th>
                <span class="cancel-info">S.No</span>
              </th>
              <th>
                <span class="cancel-info">Service Name</span>
              </th>
              <th>
                <span class="cancel-info">Service Cd</span>
              </th>
              <th>
                <span class="text-size">Qty</span>
              </th>
              <th>
                <span class="cancel-info">Rate</span>
              </th>
              <th>
                <span class="cancel-info">Amount (Rs)</span>
              </th>
              <th>
                <span class="cancel-info">Remarks (Rs)</span>
              </th>
            </tr>
            <tr *ngFor="let ccService of cancelBillPrint; let i= index" >
                <td>
                  <span class="cancel-info">{{i+1}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.serviceName}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.serviceMasterId}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.serviceQuantity}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.serviceAmount}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.serviceNetAmt}}</span>
                </td>
                <td>
                  <span class="cancel-info">{{ccService.sbiRemarks}}</span>
                </td>
            </tr>
          </table>
        </div>
      </div>
    </fieldset>
  </div>
  <div *ngIf="userInfo.hospitalShortName == 'CBPMTA'">
    <div class="row no-gutters heading-blockk" >
      <div class="col-md-3 logo">
        <!-- <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" /> -->
      </div>
      <div class="col-md-7 mb-1">
        <br><br>
        <div class="text-center">
          <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
          <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
          <p class="mb-0">{{userInfo.hospitalAdress}}</p>
        </div>
      </div>
    </div>
    
    <hr class="m-0 hr" />
    <!-- Patient information -->
    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>P. Name </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill No </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{billingFinalBillDetailModel[0].finalID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>UMR NO </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.patientID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Age /Sex </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.age}} /{{printDetailsData.patientModel.gender}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Consultant </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> <span *ngIf="billingDoctorInformation.doctorId != userInfo.hospitalID">Dr. </span>{{ billingDoctorInformation.doctorName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getFinalBillDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Phone </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.mobileNumber}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Refer By </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> {{billingDoctorInformation.externalReferal}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="table table-bordered mb-2 mt-4">
        <tr>
          <th>
            <span class="text-size">S.No</span>
          </th>
          <th>
            <span class="text-size">Service Name</span>
          </th>
          <th>
            <span class="text-size">Service Cd</span>
          </th>
          <th>
            <span class="text-size">Qty</span>
          </th>
          <th>
            <span class="text-size">Rate</span>
          </th>
          <th>
            <span class="text-size">Amount (Rs)</span>
          </th>
        </tr>
        <tr *ngFor="let service of billingPaymentSubmitDetails; let i= index" >
            <td>
              <span class="text-size">{{i+1}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceName}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceMasterId}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceQuantity}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceAmount}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceGrossAmt}}</span>
            </td>
        </tr>
      </table>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6" style="margin-left: 71%;">
        <div class="row">
          <div class="col-md-3">
            <b>Gross amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getGrossAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Discount amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDiscountAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Net amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getNetAmount()}}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row" *ngIf="getCashAmount() !== 0">
          <div class="col-md-3">
            <b>Cash Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCashAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Wallet Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getWalletAmount()}}
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
        <div class="row"  *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Bank </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getBankName()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Transaction Ref. No </b>
          </div>
          <div class="col-md-9"  *ngIf="getCardAmount() > 0">
            <b>:</b> {{getTransactionNo()}}
          </div>
          <div class="col-md-9"  *ngIf="getWalletAmount() > 0 && getCardAmount() ==0">
            <b>:</b> {{getTransactionReferenceNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Mobile Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getMobileNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Card Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardNumber()}}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedBy()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{userInfo.username}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDate()}}
          </div>
        </div>
      </div>
    </div>

    <p class="mt-4">*** Cheques are subject to realisation</p>
    <!-- <p>Email: health@lsch.in</p> -->
    <div style="margin-left: 80%;">
      <p>(Authorised Signatory)</p>
    </div>
    <div *ngIf="cancelBillPrint.length !== 0">
      <h3>Cancellation Details :</h3>
      <div>
        <table class="table table-bordered mb-2 mt-4">
          <tr>
            <th>
              <span class="text-size">S.No</span>
            </th>
            <th>
              <span class="text-size">Service Name</span>
            </th>
            <th>
              <span class="text-size">Service Cd</span>
            </th>
            <th>
              <span class="text-size">Qty</span>
            </th>
            <th>
              <span class="text-size">Rate</span>
            </th>
            <th>
              <span class="text-size">Amount (Rs)</span>
            </th>
            <th>
              <span class="text-size">Remarks (Rs)</span>
            </th>
          </tr>
          <tr *ngFor="let service of cancelBillPrint; let i= index" >
              <td>
                <span class="text-size">{{i+1}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceName}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceMasterId}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceQuantity}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceAmount}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceNetAmt}}</span>
              </td>
              <td>
                <span class="text-size">{{service.sbiRemarks}}</span>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="userInfo.hospitalShortName == 'JPMTA'">
    <br><br><br><br><br>
    <!-- <div class="row no-gutters heading-blockk">
      <div class="col-md-7">
        <div class="text-left">
          <h2 class="main-title mb-0">{{userInfo.hospitalName}}</h2>
          <h3 class="sub-title mb-1">{{userInfo.hospitalTagLine}}</h3>
          <p class="mb-0">{{userInfo.hospitalAdress}}</p>      
        </div>
      </div>
    </div> -->
    <h3 class="text-center font-weight-bold">Out-Patient Receipt</h3>
    <hr class="m-0 hr" />
    <!-- Patient information -->
    <div class="row mt-2 patientt-info" style="font-weight: bolder;">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>P. Name </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.title}} {{printDetailsData.patientModel.patientName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill No </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{billingFinalBillDetailModel[0].finalID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>UMR NO </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.patientID}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Age /Sex </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.age}} /{{printDetailsData.patientModel.gender}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Consultant </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> <span *ngIf="billingDoctorInformation.doctorId != userInfo.hospitalID">Dr. </span>{{ billingDoctorInformation.doctorName}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Bill Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getFinalBillDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Phone </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{printDetailsData.patientModel.mobileNumber}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Refer By </b>
          </div>
          <div class="col-md-9" *ngIf="billingDoctorInformation !== null && billingDoctorInformation !== {}">
            <b>:</b> {{billingDoctorInformation.externalReferal}}
          </div>
        </div>
      </div>
    </div>

    <div>
      <table class="table table-bordered mb-2 mt-4">
        <tr>
          <th>
            <span class="text-size">S.No</span>
          </th>
          <th>
            <span class="text-size">Service Name</span>
          </th>
          <th>
            <span class="text-size">Service Cd</span>
          </th>
          <th>
            <span class="text-size">Qty</span>
          </th>
          <th>
            <span class="text-size">Rate</span>
          </th>
          <th>
            <span class="text-size">Amount (Rs)</span>
          </th>
        </tr>
        <tr *ngFor="let service of billingPaymentSubmitDetails; let i= index" >
            <td>
              <span class="text-size">{{i+1}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceName}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceMasterId}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceQuantity}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceAmount}}</span>
            </td>
            <td>
              <span class="text-size">{{service.serviceGrossAmt}}</span>
            </td>
        </tr>
      </table>
    </div>
    <div class="row mt-2 patientt-info">
      <div class="col-md-6" style="margin-left: 71%;">
        <div class="row">
          <div class="col-md-3">
            <b>Gross amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getGrossAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Discount amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDiscountAmount()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Net amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getNetAmount()}}
          </div>
        </div>
      </div>
    </div>  

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row" *ngIf="getCashAmount() !== 0">
          <div class="col-md-3">
            <b>Cash Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCashAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardAmount()}}
          </div>
        </div>
        <div class="row" *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Wallet Amount </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getWalletAmount()}}
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="getCardAmount() > 0 || getWalletAmount() > 0">
        <div class="row"  *ngIf="getCardAmount() > 0">
          <div class="col-md-3">
            <b>Card Bank </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getBankName()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Transaction Ref. No </b>
          </div>
          <div class="col-md-9"  *ngIf="getCardAmount() > 0">
            <b>:</b> {{getTransactionNo()}}
          </div>
          <div class="col-md-9"  *ngIf="getWalletAmount() > 0 && getCardAmount() ==0">
            <b>:</b> {{getTransactionReferenceNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getWalletAmount() > 0">
          <div class="col-md-3">
            <b>Mobile Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getMobileNo()}}
          </div>
        </div>
        <div class="row"  *ngIf="getCardAmount() > 0 ">
          <div class="col-md-3">
            <b>Card Nr </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCardNumber()}}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 patientt-info">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedBy()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed By </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{userInfo.username}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <b>Created Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getCreatedDate()}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b>Printed Dt </b>
          </div>
          <div class="col-md-9">
            <b>:</b> {{getDate()}}
          </div>
        </div>
      </div>
    </div>

    <p class="mt-4">*** Cheques are subject to realisation</p>
    <!-- <p>Email: info@paramithahospitalskompally.com</p> -->
    <div style="margin-left: 80%;">
      <p>(Authorised Signatory)</p>
    </div>
    <div *ngIf="cancelBillPrint.length !== 0">
      <h3>Cancellation Details :</h3>
      <div>
        <table class="table table-bordered mb-2 mt-4">
          <tr>
            <th>
              <span class="text-size">S.No</span>
            </th>
            <th>
              <span class="text-size">Service Name</span>
            </th>
            <th>
              <span class="text-size">Service Cd</span>
            </th>
            <th>
              <span class="text-size">Qty</span>
            </th>
            <th>
              <span class="text-size">Rate</span>
            </th>
            <th>
              <span class="text-size">Amount (Rs)</span>
            </th>
            <th>
              <span class="text-size">Remarks (Rs)</span>
            </th>
          </tr>
          <tr *ngFor="let service of cancelBillPrint; let i= index" >
              <td>
                <span class="text-size">{{i+1}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceName}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceMasterId}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceQuantity}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceAmount}}</span>
              </td>
              <td>
                <span class="text-size">{{service.serviceNetAmt}}</span>
              </td>
              <td>
                <span class="text-size">{{service.sbiRemarks}}</span>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>
