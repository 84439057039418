import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberdecimal]'
})

// Class to validate both Number and Decimal //
export class OnlyNumberdecimalDirective {

  // numericType can be number | decimal
  // tslint:disable-next-line:no-input-rename
  @Input('numericType') numericType: string;

  private regex = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]+(\.[0-9]{0,4})?$/g),
    decimal6digits: new RegExp(/^[0-9]+(\.[0-9]{0,6})?$/g),
    decimal2digits: new RegExp(/^[0-9]+(\.[0-9]{0,2})?$/g)
  };
  private specialKeys = {
    number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal6digits: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    decimal2digits: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
  };
  constructor(private el: ElementRef) { }

  // tslint:disable-next-line:typedef
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  // tslint:disable-next-line:typedef
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  // tslint:disable-next-line:typedef
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
  // tslint:disable-next-line:typedef
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex[this.numericType])) {
      event.preventDefault();
    }
  }

  // tslint:disable-next-line:typedef
  @HostListener('input', ['$event']) onInputChange(event) {
    // tslint:disable-next-line:triple-equals
    if (this.numericType == 'number') {
      const initalValue = this.el.nativeElement.value;
      this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
      if (initalValue !== this.el.nativeElement.value) {
        event.stopPropagation();
      }
    }

  }
}
