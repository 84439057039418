import { Component, Input, OnInit } from '@angular/core';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { TokenStorageService } from '../loginservices/token-storage.service';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css']
})
export class PatientInfoComponent implements OnInit {

  userInfo: any;
  patientInfo: any = [];

  @Input() ipPatientNO: number;
  @Input() patientURMNo: number;
  walkin: boolean = false;
  constructor(
    private inpatientService: InpatientService,
    private tokenService: TokenStorageService
  ) { 
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    //
  }

  ngOnChanges():void {
    this.getPatientInfo();
  }
  
getPatientInfo(): void {

    const inputRequest = {

      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID
    };
    this.inpatientService.ipPatientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        if(response.REFERRED_BY == "Walk-In")
        {
          this.walkin = true;
        }
        console.log("patient info", response );
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

}
