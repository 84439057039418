<div class=" p-3 mx-3">
  <form [formGroup]="form" (ngSubmit)="submitData(form)">
    <div class="row">
      <div class="col-12">
        <fieldset class="legend-block mt-3">
            <legend class="legend-title blue-title mb-0">Patient Info</legend>
            <div class="col-12" *ngIf="patientType == 'IP'">
                <div class="form-inline" autocomplete="off">
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Name :</label>
                            <div class="col-sm-7">
                                <p class="modname2">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">IP Number :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo?.IP_NR}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Ward(Bed No) :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo?.WM_WARD_NAME}}
                                    <span>({{patientInfo?.WM_BED_NO}})</span></p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Gender/Age :</label>
                            <div class="col-sm-7">
                                <p class="modname2">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Admitting Doctor :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo?.DM_NAME}}
                                    <!-- <small>02-13456</small> -->
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname"> Payee Name :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo?.PIM_NAME}}</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname"> Referred By :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo?.REFERRED_BY}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Request Time :</label>
                            <div class="col-sm-7">
                                <p class="modname2">{{patientInfo.requestTime}}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-12" *ngIf="patientType == 'OP'">

                <div class="form-inline" autocomplete="off">
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Name :</label>
                            <div class="col-sm-7">
                                <p class="modname2">{{patientInfo.title}} {{patientInfo.patientName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">UMR Number :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.umrNo}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Referred by :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.referredBy}}
                                    <!-- <small>02-13456</small> -->
                                </p>

                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Gender/Age :</label>
                            <div class="col-sm-7">
                                <p class="modname2">
                                    {{patientInfo.gender}} / {{patientInfo.dob}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Visit ID :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.visitId}}</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Department :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.serviceTypeName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-5 modname">Request Date :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.serviceRequestDate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="form-group">
                            <label class="col-sm-6 modname">Request Time :</label>
                            <div class="col-sm-6">
                                <p class="modname2">{{patientInfo.serviceRequestTime}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
      </div>
      <div class="col-12 mt-3">
        <div class="row">
            <div class="col-6">
                <label class="font-weight-bold">Select Doctor Name<span
                        class="color pl-2 text-danger">*</span></label>
                <select class="form-control rounded-0 bb" name="doctorName" id="doctorName"
                    formControlName="doctorName" required
                    [ngClass]="{ 'is-invalid': submitted && f.doctorName.errors }">
                    <option value="">-- Select Doctor Name --</option>
                    <option [ngValue]="doctor.reportingDoctorName" *ngFor="let doctor of reportingDoctorsList">
                        {{doctor.reportingDoctorName}}</option>
                </select>

                <div *ngIf="submitted && f.doctorName.errors" class="invalid-feedback">
                    <div *ngIf="f.doctorName.errors.required">Doctor Name is required</div>
                </div>
            </div>
            <div class="col-6">
                <label class="font-weight-bold">Select Doctor Designation<span
                        class="color pl-2 text-danger">*</span></label>
                <select class="form-control rounded-0 bb" name="doctorDesignation" id="doctorDesignation"
                    formControlName="doctorDesignation" required
                    [ngClass]="{ 'is-invalid': submitted && f.doctorDesignation.errors }">
                    <option value="">-- Select Doctor Designation --</option>
                    <option [ngValue]="doctor.designation" *ngFor="let doctor of reportingDoctorsList">
                        {{doctor.designation}}</option>
                </select>
                <div *ngIf="submitted && f.doctorDesignation.errors" class="invalid-feedback">
                    <div *ngIf="f.doctorDesignation.errors.required">Doctor Designation is required</div>
                </div>
            </div>

            <div class="col-6 mt-3">
                <label class="font-weight-bold">Select Template</label>
                <select class="form-control rounded-0 bb" 
                        name="templateName" 
                        id="templateName"
                        (change)="getSummary($event)"
                        formControlName="templateName">
                    <option value="">-- Select Template --</option>
                    <option *ngFor="let template of templatesList">{{template.TEMPLATE_NAME}}</option>
                </select>
            </div>

        </div>
      </div>

      <div class="mt-4 mb-1 pl-3 font-weight-bold">Result Summary<span class="color pl-2 text-danger">*</span></div>
      <div class="NgxEditor__Wrapper mx-3">
        <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
        <ngx-editor [style]="{'height':'320px'}" [editor]="editor" [placeholder]="'Type here...'"
            [ngModel]="html" formControlName="editorContent"></ngx-editor>

      </div>
        <div *ngIf="(form.controls.editorContent.touched && form.controls.editorContent.invalid) || (showErrorMessage && form.controls.editorContent.invalid)"
            class="bg-warningtxt ml-3 mt-1">Result Summary is required</div>

        <div class="col-12 mt-3">
            <div class="text-right">
                <button type="button" 
                        (click)="resultEntryPrint()" 
                        class="btn btn-primary float-right mr-2 mb-2"
                        *ngIf="existingRecord">
                    Print
                </button>
                <button type="button" (click)="submitData(form)" class="btn btn-primary float-right mr-2 mb-2">
                    {{existingRecord ? "Save": "Submit"}}
                </button>
            </div>
        </div>
    </div>
  </form>
</div>


<div id="print-result-entry-section" style="display: none;"> 
    <table style="border: 0px!important;" width="100%">  
        <thead>  
         <tr>  
          <th style="width:100%">
         
        
        </th>  
        </tr>  
        <tr>  
         <th><hr style="color:#000080"/></th>  
        </tr>  
       </thead>  
       <tfoot> 
        <tr>  
         <td width="100%">  
          <table width="100%" style="border: 0px!important;">  
            <tr>  
              <td colspan="4"><br>&nbsp;</td>  
           </tr>  
         </table>  
       </tfoot> 
       <tbody>  
         <tr>  
           <td width="100%">  
            <div style="height: 150px;"></div><br />
            <fieldset class="legend-block mb-3 mt-5 py-2">
              <legend class="legend-title blue-title mb-0">Patient Information</legend>
              <!-- Patient Info for IP -->
              <div class="row patient-info print-information" 
                   style="font-weight: bolder;"
                   *ngIf="patientType == 'IP'">
                   <div class="form-inline" autocomplete="off">
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-5">Name :</label>
                            <div class="col-sm-7">
                                <p class="">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">IP Number :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo?.IP_NR}}</p>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Ward(Bed No) :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo?.WM_WARD_NAME}}
                                    <span>({{patientInfo?.WM_BED_NO}})</span></p>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-5">Gender/Age :</label>
                            <div class="col-sm-7">
                                <p class="">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                            </div>
                        </div>
                    </div>
        
                    <br>
        
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Admitting Doctor :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo?.DM_NAME}}
                                    <!-- <small>02-13456</small> -->
                                </p>
        
                            </div>
                        </div>
                    </div>
        
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6"> Payee Name :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo?.PIM_NAME}}</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6"> Referred By :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo?.REFERRED_BY}}</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        
              <div class="row patient-info print-information" 
                   style="font-weight: bolder;"
                   *ngIf="patientType == 'OP'">
                   <div class="form-inline" autocomplete="off">
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-5">Name :</label>
                            <div class="col-sm-7">
                                <p class="">{{patientInfo.title}} {{patientInfo.patientName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">UMR Number :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.umrNo}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Referred by :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.referredBy}}
                                    <!-- <small>02-13456</small> -->
                                </p>
        
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-5">Gender/Age :</label>
                            <div class="col-sm-7">
                                <p class="">
                                    {{patientInfo.gender}} / {{patientInfo.dob}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Visit ID :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.visitId}}</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Department :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.serviceTypeName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-5 pr-0">Request Date:</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.serviceRequestDate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 pb-3">
                        <div class="row">
                            <label class="col-sm-6">Request Time :</label>
                            <div class="col-sm-6">
                                <p class="">{{patientInfo.serviceRequestTime}}</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <!-- Patient Info -->
            </fieldset>
        
            <fieldset class="legend-block mb-3 py-2">
                <div class="mt-4 print-editor-context">
                    <div [innerHTML]="existingData.resultData | safeHtml"></div>
                   
                </div>
            </fieldset>
           </td>  
        </tr> 
      </tbody>  
     </table>
     <table id="footer" width="100%">  
        <tr>  
        <td width="100%">  
            <div class="text-right">
                <div *ngIf="existingData.signaturePath">
                    <img class="w-25" 
                         src="assets/digitalSignatures/reportingDoctorNames/{{existingData.signaturePath}}" />
                </div>
                {{existingData.reportingDoctorName}}<br />
                {{existingData.reportingDoctorDesignation}}
            </div>
       <div class="p-13"></div>
        </td> 
        </tr> 
      </table> 
  
    </div>
