<!--<div class="  p-3" >
<div class="container bg-white border-secondary p-2">

  <div class="row mt-5">
    <div class="col-md-5  text-center p-0 ">
      <div class="card border border-secondary" >
        <img class="card-img-top mbl_img md-4" src="assets/Dr.%20Mehul%20A%20Shah.jpg" alt="Dr. Mehul shah">
        <div class="card-body border-0 text-center">
          <h4 class="card-title font-weight-bold">Dr. Mehul A Shah (M.D Pediatrics)</h4>
          <p class="card-text text-secondary h5">Pediatric Nephrology</p>

<div class=" d-flex justify-content-around mx-5 my-3">
  <a href="#" class="btn btn-outline-secondary rounded-circle"><i class="fab fa-facebook-f"></i></a>
  <a href="#" class="btn btn-outline-secondary rounded-circle"><i class="fab fa-whatsapp"></i></a>
  <a href="#" class="btn btn-outline-secondary rounded-circle"><i class="fab fa-twitter"></i></a>
  <a href="#" class="btn btn-outline-secondary rounded-circle"><i class="fab fa-telegram-plane"></i></a>
</div>

          <div class="border border-primary row my-1  p-0"  style="height: 78px">
            <p class="text-info col-3"><i class="far fa-calendar-alt" style="font-size: 55px; line-height: 73px"></i></p>
            <div class="col-9">
              <p class="h5 font-weight-bold"><small>Appointment Date</small></p>
              <p class="h3 m-0 time_date_bookapp">July 27, 2020</p>
            </div>
          </div>

          <div class="border border-primary row my-2 " style="height: 78px" >

            <p class="text-info col-3"><i class="far fa-clock" style="font-size: 55px; line-height: 73px"></i></p>
            <div class="col-9">
              <p class="h5 font-weight-bold"><small>Appointment Time</small></p>
              <p class="h3 m-0 time_date_bookapp">10:30 AM</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-7 mt-4 mt-md-0 ">
<div class="d-md-flex justify-content-between">
      <div class="form-group twoInputssameLine mx-2 ">
        <label for="patientfirstName" class="text-dark">First Name :</label>
        <input type="text" class="form-control rounded-0 border border-primary" id="patientfirstName"  value="Ravi Kiran Chowdary">
      </div>
      <div class="form-group twoInputssameLine mx-2 ">
        <label for="patientLastName" class="text-dark">Last Name :</label>
        <input type="text" class="form-control rounded-0 border border-primary" id="patientLastName"  value="K V">
      </div>
</div>


      <div class="form-group mx-2 w-100 ">
        <label for="patientmobNumber" class="text-dark">Mobile Number: </label>
        <input type="text" class="form-control rounded-0 border border-primary" id="patientmobNumber"  value="9999999999">
      </div>

      <div class="d-md-flex justify-content-between">
        <div class="form-group twoInputssameLine mx-2 ">
          <label for="patientEmail" class="text-dark">Email: :</label>
          <input type="email" class="form-control rounded-0 border border-primary" id="patientEmail"  value="kvravi@gmail.com">
        </div>
        <div class="form-group twoInputssameLine mx-2">
          <label for="patientDob">DOB:</label>
          <input type="text" class="form-control border  border-primary rounded-0" id="patientDob" value="25-07-2020" >
        </div>
      </div>

      <div class="my-3">

      <div class="form-check-inline">
        <label class="form-check-label mr-1">Are you an Indian national ? : </label>
        <span class="form-check-label" for="nationalYes">
          <input type="radio" class="form-check-input" id="nationalYes" name="nationality" value="Yes" checked>Yes
        </span>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="nationalNo">
          <input type="radio" class="form-check-input" id="nationalNo" name="nationality" value="No">No
        </label>
      </div>

      </div>

      <div class="my-3">

      <div class="form-check-inline">
        <label class="form-check-label mr-5">Gender: </label>
        <label class="form-check-label" for="radio1">
          <input type="radio" class="form-check-input" id="radio1" name="gender" value="Male" checked>Male
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="radio2">
          <input type="radio" class="form-check-input" id="radio2" name="gender" value="Female">Female
        </label>
      </div>

      </div>

      <div class="my-3">
      <div class="form-check-inline">
        <label class="form-check-label mr-5">Consultation type : </label>
        <label class="form-check-label" for="consultationpre">
          <input type="radio" class="form-check-input" id="consultationpre" name="consultationType" value="first" checked> First time
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="consultationfollowup">
          <input type="radio" class="form-check-input" id="consultationfollowup" name="consultationType" value="followUp">Follow Up
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="consultationtherapy">
          <input type="radio" class="form-check-input" id="consultationtherapy" name="consultationType" value="therapy">Therapy
        </label>
      </div>

      </div>

      <div class="form-group mx-2">
        <label for="remarks">Remarks/ Reasons if any:</label>
        <textarea class="form-control w-100 border border-primary" rows="3"   id="remarks" style="resize: none"></textarea>
      </div>
<form>
      <div class="custom-control custom-radio">
        <input type="radio" class="custom-control-input" id="customRadio" name="termsnConditions" value="termsAndCond">
        <label class="custom-control-label" for="customRadio">I Agree to terms and Conditions</label>
      </div>
</form>
      <div class="mt-4 text-center"><a (click)="bookingAppointmentEmitter()" class="btn btn-lg btn-primary " style="border-radius: 24px">Book Appointment</a></div>

    </div>

  </div>
</div>
</div>-->
<div class="container-fluid" >
  <div class="row p-2 mt-1">
    <div class="col-sm-4 col-md-2">
      <img class="drprofile both" src="assets/{{receivedDoctorProfile.doctorName}}.png" alt="DocProfilePic"></div>
    <div class="col-sm-6 col-md-6 ml-2">
      <p class="card-title">{{receivedDoctorProfile.doctorTitle}}{{receivedDoctorProfile.doctorName}}</p>
      <p class="doc_info_spl1" style="font-size: 15px;"> {{receivedDoctorProfile.doctorSpecilization}}
      </p>
      <hr>
      <p  class="doc_info_spl2 my-2"><i class="fas fa-stethoscope text-dark font-weight-bold mr-2"></i>
        {{receivedDoctorProfile.doctorExperience}} Years Experience</p>
      <!--              <hr>-->
      <!--              <div class="h6 my-2 d-flex align-items-center">-->

      <!--                <span  class="font-weight-bold mr-2">-->
      <!--                  <i  class="far fa-clock"></i></span>-->
      <!--                <div class="text-dark h6 mx-1">-->
      <!--                <p  class="my-1 f1">Mon to Fri 8:00 AM to 6:00 PM</p>-->
      <!--                <p  class="my-1 f1">Sat &amp; Sun 9:00 Am to 1:00 PM</p></div></div>-->

      <hr>
    </div>
    <div class="col-sm-4 col-md-3">
      <div class="btnalign">
        <div class="border row my-1  p-0 show-date-time"  >
          <p class=" col-3"><i class="far fa-clock fa-ic"></i></p>
          <div class="col-9">
            <p class="h5 font-weight-bold"><small>Appointment Time</small></p>
            <p class="m-0 time_date_bookapp doc_info_spl2">{{slotTimereceived}}</p>
          </div>
        </div>

        <div class="border  row my-1  p-0 show-date-time"  >
          <p class=" col-3"><i class="far fa-calendar-alt fa-ic" ></i></p>
          <div class="col-9">
            <p class="h5 font-weight-bold"><small>Appointment Date</small></p>
            <p class="m-0 time_date_bookapp doc_info_spl2">{{slotDatereceived.toUpperCase()}}</p>
          </div>
        </div>


      </div>
    </div>
  </div>

  <!--<div class="row mt-5">
    <div class="col-md-12">
      <div class="both">
          <ul>
            <li><img class="drprofile both" src="assets/{{receivedDoctorProfile.doctorName}}.png" alt="DocProfilePic"><br>

            </li>
            <li> <h4>{{receivedDoctorProfile.doctorTitle}}{{receivedDoctorProfile.doctorName}}</h4>
              <p> {{receivedDoctorProfile.doctorSpecilization}}
               </p>
              <hr>
              <p  class="text-primary h6 my-2"><i class="fas fa-stethoscope text-dark font-weight-bold mr-2"></i>
                {{receivedDoctorProfile.doctorExperience}} Years Experience</p>
<hr>
            </li>
            <li>
              <div class="btnalign">
              <div class="border border-primary row my-1  p-0"  style="height: 78px">
                <p class="text-info col-3"><i class="far fa-clock" style="font-size: 36px;
    line-height: 58px;"></i></p>
                <div class="col-9">
                  <p class="h5 font-weight-bold"><small>Appointment Time</small></p>
                  <p class="m-0 time_date_bookapp" style="   font-weight: 700; font-size: 21px;">{{slotTimereceived}}</p>
                </div>
              </div>

              <div class="border border-primary row my-1  p-0"  style="height: 78px">
                <p class="text-info col-3"><i class="far fa-calendar-alt" style="font-size: 36px;
    line-height: 58px;"></i></p>
                <div class="col-9">
                  <p class="h5 font-weight-bold"><small>Appointment Date</small></p>
                  <p class="m-0 time_date_bookapp" style=" font-weight: 700;   font-size: 21px;">{{slotDatereceived.toUpperCase()}}</p>
                </div>
              </div>


            </div>
            </li>

          </ul>


      </div>
    </div>
  </div>-->
<form name="form" class="p-2" autocomplete="off" (ngSubmit)="f.form.valid && bookingAppointmentEmitter(patientRemarks)" #f="ngForm" novalidate>
  <div class="row">
    <div  class="col-md-6">
    <div class="form-group row">
      <div class="col-4">
        <label for="patientfirstName" class="text-dark">Patient Name :
          <span class="requiredSymbol">*</span>
        </label>
      </div>
      <div class="col-8">
        <input type="text" class="form-control bb"
               name="firstName" [(ngModel)]="receivedpatientProfile.patientName" #patientName="ngModel"
               [ngClass]="{ 'is-invalid-error': f.submitted && patientName.invalid }"
               id="patientfirstName" required="required" readonly>
        <span *ngIf="f.submitted && patientName.invalid" class="bg-warningtxt">Name is required</span>
      </div>
    </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
      <div class="col-4">
        <label for="patientmobNumber" class="">Mobile No :
          <span class="requiredSymbol">*</span>
        </label>
      </div>
      <div class="col-8">
        <input type="text" class="form-control bb"
               name="mobile" [(ngModel)]="receivedpatientProfile.mobileNumber" #mobile="ngModel"
               [ngClass]="{ 'is-invalid-error': f.submitted && mobile.invalid }"
               id="patientmobNumber" required="required" readonly>
        <span *ngIf="f.submitted && mobile.invalid" class="bg-warningtxt">Mobile is required</span>
      </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-4">
          <label for="patientEmail" class="text-dark">Email :
          </label>
        </div>
        <div class="col-8">

          <input type="email" class="form-control  bb"
                 name="email" [(ngModel)]="receivedpatientProfile.emailId" #email="ngModel"
                 [ngClass]="{ 'is-invalid-error': f.submitted && email.invalid }"
                 id="patientEmail" readonly>
          <span *ngIf="f.submitted && email.invalid" class="bg-warningtxt">Email is required</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-4">
          <label for="patientDob">DOB :
            <span class="requiredSymbol">*</span>
          </label>
        </div>
        <div class="col-8">
          <input type="text" class="form-control   bb"
                 name="dateOfBirth" [(ngModel)]="receivedpatientProfile.dateOfBirth" #dateOfBirth="ngModel"
                 [ngClass]="{ 'is-invalid-error': f.submitted && dateOfBirth.invalid }"
                 id="patientDob" required="required" readonly>
          <span *ngIf="f.submitted && dateOfBirth.invalid" class="bg-warningtxt">DOB is required</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-check-inline">
        <label class="form-check-label ">Are you an Indian national  ? : &nbsp;&nbsp;</label>
        <span class="form-check-label" >
          <input type="radio" class="form-check-input" id="nationalYes" name="nationality" value="Yes" checked>Yes
        </span>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="nationalNo">
          <input type="radio" class="form-check-input" id="nationalNo" name="nationality" value="No">No
        </label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-check-inline">
        <label class="form-check-label ">Gender : &nbsp;&nbsp;</label>
        <label class="form-check-label" for="radio1">
          <input type="radio" class="form-check-input" id="radio1" name="gender" value="Male" [disabled]="dateOfBirth" [checked]="receivedpatientProfile.gender === 'MALE' ">Male
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label" for="radio2">
          <input type="radio" class="form-check-input" id="radio2" name="gender" value="Female" [disabled]="dateOfBirth" [checked]="receivedpatientProfile.gender === 'FEMALE' ">Female
        </label>
      </div>
    </div>
  </div>
  <div class="row m-2">
    <div class="form-check-inline" style="    margin-left: -9px;">
      <label class="form-check-label mr-2">Consultation type : &nbsp;&nbsp;</label>
      <label class="form-check-label" for="consultationpre">
        <input type="radio" class="form-check-input" id="consultationpre" name="consultationType" value="Consultation" [(ngModel)]="appointmentType"  checked> Consultation
      </label>
    </div>
    <div class="form-check-inline">
      <label class="form-check-label" for="consultationfollowup">
        <input type="radio" class="form-check-input" id="consultationfollowup" name="consultationType" value="Followup" [(ngModel)]="appointmentType" >Follow Up
      </label>
    </div>
    <div class="form-check-inline">
      <label class="form-check-label" for="consultationtherapy">
        <input type="radio" class="form-check-input" id="consultationtherapy" name="consultationType" value="Therapy" [(ngModel)]="appointmentType" >Therapy
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-3">
          <label for="remarks">Remarks/ Reasons if any :</label>
        </div>
        <div class="col-9">
          <textarea class="form-control w-100  bb" rows="2"   id="remarks" style="resize: none" #patientRemarks></textarea>
        </div>
      </div>
    </div>
  </div>
  <form>
    <div class="custom-control custom-radio">
      <input type="radio" class="custom-control-input" id="customRadio" name="termsnConditions" value="termsAndCond" #termsNConditions checked>
      <label class="custom-control-label" for="customRadio">I Agree to terms and Conditions</label>
    </div>
  </form>
  <div class="text-center float-right">
    <button class="btn btn-md cont" (click)="backToDetails()">Go Back</button>
    &nbsp; &nbsp;<button type="submit" class="btn btn-md cont">Book Appointment</button>
  </div>



</form>
  <div class="modal" [class]="bookAppointmentLoaded ? 'forModalDisplay' : 'd-none'" >
    <div class="modal-dialog modal-sm modal-dialog-centered" >
      <div class="modal-content" style="background-color: #cbd8db">
        <div class="modal-body px-0 font-weight-bold text-center">
          <div class="font-weight-bold text-dark">Booking Appointment</div>
          <div class="spinner-border text-primary"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" [class]="timeSlotExpired ? 'forModalDisplay' : 'd-none'" >
    <div class="modal-dialog modal-sm modal-dialog-centered" >
      <div class="modal-content" style="background-color: #cbd8db">
        <div class="modal-body px-0 font-weight-bold text-center">
          <div class="font-weight-bolder text-info">Chosen Time Slot Expired.</div>
          <div class="font-weight-bolder text-info">Please Select other Slot.</div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
        <div class="btn btn-warning btn-sm" (click)="redirectionForExpiredSlot()">Close</div>
        </div>
      </div>
    </div>
  </div>
  </div>


