import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppointmentComponent } from './appointment/appointment.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpecialisationComponent } from './specialisation/specialisation.component';
import { DoctorinfoComponent } from './doctorinfo/doctorinfo.component';
import { SlotbookingComponent } from './slotbooking/slotbooking.component';
import { FormfillingComponent } from './formfilling/formfilling.component';
import { CancelmessageComponent } from './cancelmessage/cancelmessage.component';
import { ConfirmationmessageComponent } from './confirmationmessage/confirmationmessage.component';
import { DecideappointmentComponent } from './decideappointment/decideappointment.component';
import {RouterModule, Routes} from '@angular/router';
import { BookingappointmentComponent } from './bookingappointment/bookingappointment.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ViewappointmentsComponent } from './viewappointments/viewappointments.component';
import { PatientregistrationComponent } from './patientregistration/patientregistration.component';
import { DoctorprofilesComponent } from './doctorprofiles/doctorprofiles.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import {SharedModule} from '../shared/shared.module';
import { PrescriptionComponent } from './prescription/prescription.component';
import {LandingpageModule} from '../landingpage/landingpage.module';
import {AuthGuard} from '../shared/loginservices/auth.guard';

const  Appointmentroutes: Routes = [
    {path: 'appointment', component: AppointmentComponent, canActivate: [AuthGuard]},
    {path: 'doctorinfo', component: DoctorinfoComponent, canActivate: [AuthGuard]},
    {path: 'booking', component: SlotbookingComponent, canActivate: [AuthGuard]},
    // {path: 'patient_profile', component: FormfillingComponent},
    // {path: 'confirmed', component: ConfirmationmessageComponent},
    // {path: 'canceled', component: CancelmessageComponent},
    // {path: 'reschedule', component: DecideappointmentComponent},
    {path: 'details_check', component: BookingappointmentComponent, canActivate: [AuthGuard]},
    {path: 'specialization', component: DoctorprofilesComponent, canActivate: [AuthGuard] },
    {path: 'viewappointments', component: ViewappointmentsComponent, canActivate: [AuthGuard]}
];

@NgModule({
  declarations: [AppointmentComponent,
    SpecialisationComponent,
    DoctorinfoComponent,
    SlotbookingComponent,
    FormfillingComponent,
    CancelmessageComponent,
    ConfirmationmessageComponent,
    DecideappointmentComponent,
    BookingappointmentComponent,
    HomepageComponent,
    ViewappointmentsComponent,
    PatientregistrationComponent,
    DoctorprofilesComponent,
    SearchbarComponent,
    PrescriptionComponent,
  ],
    exports: [
      RouterModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        SharedModule,
        RouterModule.forChild(Appointmentroutes)
    ]
})
export class AppointmentsModule { }
