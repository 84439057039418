<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">  
      <div class="row page-header">
          <div class="col-md-11 text-center p-0">
           <div>
            <h4 class="text-uppercase bold font-weight-bold orange-color">
           Master Management Landing 
            </h4>
           </div>
          </div> 
      </div>
      <div class="row col-md-12 justify-content-center">
        <div class="col-md-3"></div>
        <div class="col-md-3" routerLink="/reportTemplatelist" style="cursor: pointer;">
          <h3 class="col mt-4 text-center">
            <i class="fa fa-file" aria-hidden="true"></i>
          </h3>
          <div class="text-center">Report Template</div>
        </div>
        <div class="col-md-3" (click)="referralDoctorPopup(addReferralDoctor)">
          <h3 class="col mt-4 text-center">
            <i class="fas fa-user-md" aria-hidden="true"></i>
          </h3>
          <div class="text-center" >Add Referral Doctor</div>
        </div>
        <div class="col-md-3"></div>          
      </div>
    </div>
</div>
<ng-template #addReferralDoctor let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Referral Doctor</h4>
    <button class="btn text-right" type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
  </div>
  <div class="modal-body">
    <app-add-referral></app-add-referral>
  </div>
</ng-template>