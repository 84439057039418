<form #admissionInfoForm="ngForm" (ngSubmit)="admissionInfoForm.form.valid && submitAdmissionFormDetails()"
  autocomplete="off">
  <div class="text-center mt-1 mb-2 clearfix" *ngIf="isFormReadonlyMode"> 
    <i class="fas fa-edit close handCursor" 
      (click)="isFormReadonlyMode = false" 
      style="color: #173267"
      title="click here to edit form"></i>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-12 col-sm-5">
          <label class="font-weight-bold">MLC: <span class="requiredSymbol">*</span></label>
        </div>
        <div class="col-12 col-sm-7">
          <div class="form-check-inline">
            <input type="radio" 
                   class="form-check-input" 
                   name="mlc" 
                   [checked]="admissionFormValues.mlc === 'Y'"
                   value="Yes"
                   [readOnly]="isFormReadonlyMode" />Yes
          </div>
          <div class="form-check-inline">
            <input type="radio" 
                   class="form-check-input" 
                   name="mlc" 
                   [checked]="admissionFormValues.mlc === 'N'"
                   value="No"
                   [readOnly]="isFormReadonlyMode" />No
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-12 col-sm-5">
          <label class="font-weight-bold">MLC Number: <span class="requiredSymbol">*</span></label>
        </div>
        <div class="col-12 col-sm-7">
          <input type="text" 
                 class="form-control bb" 
                 name="mlcNumber" 
                 [(ngModel)]="admissionFormValues.mlcNumber"
                 #mlcNumber="ngModel" 
                 value="admissionFormValues.mlcNumber" 
                 required
                 [readOnly]="isFormReadonlyMode" />
          <span *ngIf="admissionInfoForm.submitted && mlcNumber.invalid" class="bg-warningtxt">
            MLC Number is required
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-12 col-sm-5">
          <label class="font-weight-bold">Initial Assessment: <span class="requiredSymbol">*</span></label>
        </div>
        <div class="col-12 col-sm-7">
          <textarea type="text" 
                    class="form-control bb" 
                    name="initialAssessment" 
                    rows="5"
                    [(ngModel)]="admissionFormValues.initialAssessment" 
                    #initialAssessment="ngModel"
                    value="admissionFormValues.initialAssessment" 
                    required
                    [readOnly]="isFormReadonlyMode"></textarea>
          <span *ngIf="admissionInfoForm.submitted && initialAssessment.invalid" class="bg-warningtxt">
            Initial Assessment is required
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-12 col-sm-5">
          <label class="font-weight-bold">Provision Diagnosis: <span class="requiredSymbol">*</span></label>
        </div>
        <div class="col-12 col-sm-7">
          <textarea type="text" 
                    class="form-control bb" 
                    name="provisionDiagnosis" 
                    rows="5"
                    [(ngModel)]="admissionFormValues.provisionDiagnosis" 
                    #provisionDiagnosis="ngModel"
                    value="admissionFormValues.provisionDiagnosis" 
                    required
                    [readOnly]="isFormReadonlyMode"></textarea>
          <span *ngIf="admissionInfoForm.submitted && provisionDiagnosis.invalid" class="bg-warningtxt">
            Provision Diagnosis is required
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group row">
        <div class="col-12 col-sm-5">
          <label class="font-weight-bold">Surgical or Medical: <span class="requiredSymbol">*</span></label>
        </div>
        <div class="col-12 col-sm-7">
          <div class="form-check-inline">
            <input type="radio" 
                   class="form-check-input" 
                   name="surgicalMedical" 
                   value="surgical"
                   [(ngModel)]="admissionFormValues.surgicalMedical" 
                   #surgicalMedical="ngModel"
                   [checked]="admissionFormValues.surgicalMedical === 'surgical'"
                   [readOnly]="isFormReadonlyMode" />Surgical
          </div>
          <div class="form-check-inline">
            <input type="radio" 
                   class="form-check-input" 
                   name="surgicalMedical" 
                   value="medical"
                   [(ngModel)]="admissionFormValues.surgicalMedical" 
                   #surgicalMedical="ngModel"
                   [checked]="admissionFormValues.surgicalMedical === 'medical'"
                   [readOnly]="isFormReadonlyMode" />Medical
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12" *ngIf="admissionFormValues.surgicalMedical === 'surgical'">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Surgical Surgery Posted on:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="datetime-local" 
                     class="form-control bb" 
                     name="surgeryPostedOn"
                     [(ngModel)]="admissionFormValues.surgeryPostedOn" 
                     #surgeryPostedOn="ngModel"
                     [value]="admissionFormValues.surgeryPostedOn"
                     [min]="admissionFormValues.admissionDate"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Surgeon:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="text" 
                     class="form-control bb" 
                     name="surgeon"
                     [(ngModel)]="admissionFormValues.surgeon" 
                     #surgeon="ngModel"
                     value="admissionFormValues.surgeon"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Asst. Surgeon:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="text" 
                     class="form-control bb" 
                     name="surgeonAsst"
                     [(ngModel)]="admissionFormValues.surgeonAsst" 
                     #surgeonAsst="ngModel"
                     value="admissionFormValues.surgeonAsst"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Anesthetist:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="text" 
                     class="form-control bb" 
                     name="anesthetist"
                     [(ngModel)]="admissionFormValues.anesthetist" 
                     #anesthetist="ngModel"
                     value="admissionFormValues.anesthetist"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Technician:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="text" 
                     class="form-control bb" 
                     name="technician"
                     [(ngModel)]="admissionFormValues.technician" 
                     #technician="ngModel"
                     value="admissionFormValues.technician"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-12 col-sm-5">
              <label class="font-weight-bold">Planned Discharge Date:</label>
            </div>
            <div class="col-12 col-sm-7">
              <input type="datetime-local" 
                     class="form-control bb" 
                     name="dischargeDate"
                     [(ngModel)]="admissionFormValues.plannedDischargeDate" 
                     #plannedDischargeDate="ngModel"
                     value="admissionFormValues.plannedDischargeDate"
                     [min]="admissionFormValues.admissionDate"
                     [readOnly]="isFormReadonlyMode" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="float-right mb-5">
        <button class="btn btn-outline-info mr-2" 
                type="button" 
                (click)="closePopup()">Cancel</button>
        <button class="btn btn-primary" [disabled]="isFormReadonlyMode">Submit</button>
      </div>
    </div>
  </div>
</form>