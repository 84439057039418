import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

const myAccountRouting: Routes = [
  {
    path: 'changepassword',
    component: ChangePasswordComponent
  }
];

@NgModule({
  declarations: [ChangePasswordComponent],
  exports: [
    RouterModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(myAccountRouting)
  ]
})
export class MyAccountModule { }
