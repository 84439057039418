import { Component, OnInit, Input } from '@angular/core';
import {createElementCssSelector} from '@angular/compiler';

interface IPatientPrescription {
  pulse: string;
  temp: string;
  bp: string;
  rr: string;
  spo2: string;
  hc: string;
  weight: string;
  height: string;
  painScore: string;
  oe: string;
  provisionalDiagnosis: string;
  investigationsAdvised: string;
  dose: string;
  frequency: string;
  duration: string;
  nutritionalAdvise: string;
  nextFollowUpDate: string;
}

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})

export class PrescriptionComponent implements OnInit {
  @Input() patientInfo;
  patientPrescriptionInfo: IPatientPrescription = {
    pulse: '',
    temp: '',
    bp: '',
    rr: '',
    spo2: '',
    hc: '',
    weight: '',
    height: '',
    painScore: '',
    oe: '',
    provisionalDiagnosis: '',
    investigationsAdvised: '',
    dose: '',
    frequency: '',
    duration: '',
    nutritionalAdvise: '',
    nextFollowUpDate: ''
  };
  public update = false;
  formActiveTab = "vitalSigns";
  widzetTabIndex = 0;
  isFormBlockActive = true;
  addresses: {
      Medichine: string, 
      Dosage: string, 
      Freq: string, 
      Duration: string, 
      Instruction: string
    }[] = [];
  editRow = -1;
  updateAddress(i: number, update: boolean): any{
    this.update = update;
    if (update){
      this.editRow = i;
    }
    else{
      this.editRow = -1;
    }
  }
  // public show: {Address: string, Street: string, City: string, Country: string}[];
  ngOnInit(): void {
     // window.open('https://www.w3schools.com/js/js_variables.asp','_blank','status=0,scrollbars=1,resizable=1,location=1');
  }
  /** for changing the form tab */
  tabAction(e, index) {
    this.widzetTabIndex = index;
  }
  /** next method for changing the wizard */
  wizardNext(e, wizardIndex) {
    this.widzetTabIndex = this.widzetTabIndex + 1;
  }
  /**  */
  wizardPrev(e, wizardIndex) {
    this.widzetTabIndex = this.widzetTabIndex - 1;
  }

  formEdit() {
    this.widzetTabIndex = 0;
    this.isFormBlockActive = true;
  }

  addAddresses(medicine, dosage, freq, duration, instruction): any {
    if (medicine.value) {
      this.addresses.push({
        Medichine: medicine.value,
        Dosage: dosage.value,
        Freq: freq.value,
        Duration: duration.value,
        Instruction: instruction.value
      });
    }
  }

  removeAddress(i: number): any {
    this.addresses.splice(i, 1);
  }

  logValue(): any {
    console.log(this.addresses);
  }

  prescriptionFornSubmit() {
    this.isFormBlockActive = false;
    console.log("form submit", this.patientPrescriptionInfo);
    console.log("patient info", this.patientInfo)
  }




}
