<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">

        <div class="row page-header">
            <!-- <div class="col-1">
                <div class="info-count-box">
                    <div class="text-center info-box-number info-box-primary-text mb-0">
                        <i class="fa fa-calendar-check"></i>
                    </div>
                    <div class="text-center info-box-text">Calendar</div>
                </div>
            </div> -->

            <div class="col-md-10 text-center">
                <h4 class="text-uppercase bold font-weight-bold orange-color">{{listTitle}}</h4>
            </div>
            <div class="col-md-2 text-center" style="line-height: 80px;">
                <button type="button" (click)="returnToMainPage()" class="btn border-btn mr-3 px-4">Back</button>
            </div>

            <!-- <div class="col-1">
                <div class="info-count-box">
                  <div class="text-center info-box-number info-box-primary-text mb-0">
                    <span class="fa-stack fa-stack-adjust" data-notification-count="9">
                      <i class="fa fa-bell"></i>
                    </span>
                  </div>
                  <div class="text-center info-box-text">Todo</div>
                </div>
            </div> -->

        </div>

        <!-- Patient info -->
        <div class="row justify-content-center mb-3 summary-info">
            <div class="col-10">
                <app-patient-info [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo">                    
                </app-patient-info>
            </div>
        </div>
        <!-- Patient info -->

        <div class="row justify-content-center mb-2" style="margin-top: 12px;">

            <div class="col-md-12">

                <div class="matcontent">
                    <mat-table #table [dataSource]="receiptListData" matSort>

                        <ng-container matColumnDef="RECEIPT_NUMBER">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Receipt Number</div>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <div>{{ element.RECEIPT_NO }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="AMOUNT">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Amount</div>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <div>{{ element.AMOUNT }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="CREATEDBY">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Created By</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>{{ element.CREATED_BY }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="CREATEDDATE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Created Date</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>{{ element.CREATE_DATE }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PAYMENTTYPE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Receipt Type</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>{{element?.PAY_TYPE}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PAYMENTMODE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Payment Mode</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>{{element?.PAYMENT_MODE}}</div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="PAYMENTSTATUS">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                <div>Payment Status</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>{{element?.PAYMENT_STATUS}}</div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ACTION">
                            <mat-header-cell *matHeaderCellDef>
                                <div>Action</div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <div>

                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                                        <button *ngIf="element?.REFUND_BUTTON_STATUS == 'Y'" (click)="refundPopup(refund, element)"
                                            mat-menu-item><span>{{element?.REFUND_BUTTON_LABEL}}</span></button>
                                        <button *ngIf="element?.DUE_PENDING_BUTTON_STATUS == 'Y'" (click)="pendingDuePopup(pendingDue, element)"
                                            mat-menu-item><span>{{element?.DUE_PENDING_BUTTON_LABEL}}</span></button>
                                        <button *ngIf="element?.REFUND_LATER_BUTTON_STATUS == 'Y'" (click)="pendingRefundPopup(pendingRefund, element)"
                                            mat-menu-item><span>{{element?.REFUND_LATER_BUTTON_LABEL}}</span></button>

                                        <button mat-menu-item *ngIf="element.PaymentDone == 'Y'"
                                            (click)="paymentDone()"><span>Payment Done</span></button>
                                        <button mat-menu-item
                                            (click)="printReceipt(element)"><span>Print</span></button>
                                    </mat-menu>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
                    </mat-table>

                    <div class="mat-norecords" *ngIf="receiptListData.data.length === 0">No records found</div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                        style="border-bottom-left-radius:6px; border-bottom-right-radius:6px"
                        class="mat-paginator-sticky">
                    </mat-paginator>
                </div>

            </div>

        </div>
    </div>    
</div>

<ng-template #refund let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Refund</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">X</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <fieldset class="legend-block">
                    <legend class="legend-title font-16">Patient Info</legend>
                    <div class="row">
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Name </div>
                          <div class="col"> {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> IP Number </div>
                          <div class="col"> {{patientInfo?.IP_NR}} </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
                          <div class="col"> {{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></div>
                        </div>
                      </div>
              
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Gender/Age </div>
                          <div class="col"> {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Admitting Doctor </div>
                          <div class="col"> {{patientInfo?.DM_NAME}} </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Payee Name </div>
                          <div class="col"> {{patientInfo?.PIM_NAME}} </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 pt-2">
                        <div class="row">
                          <div class="col-5 font-weight-bold"> Referred By </div>
                          <div class="col">{{patientInfo?.REFERRED_BY}} </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-12 col-sm-12 margin45">
                <p>{{receiptInfo?.AmountLabel}}: {{ receiptInfo?.Amount}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7">
                <fieldset class="legend-block">
                    <legend class="legend-title mb-0 font-16">Payment Selection</legend>

                    <p>Please select the mode of payment to collect Advance/Deposit for the patient:</p>

                    <ng-container *ngFor="let paymentMode of paymentModeList">

                        <div class="mt-2" *ngIf="paymentMode.paymentType == 1">

                            <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                (ngModelChange)="clearAmount(paymentMode)"
                                (change)="changePaymentMode($event, paymentMode)">
                            <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>

                            <input *ngIf="paymentMode.selectedPaymentType" [(ngModel)]="paymentMode.collectedAmount"
                                type="text" 
                                class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="number" />

                        </div>

                        <div class="mt-2" *ngIf="paymentMode.paymentType == 3">

                            <span
                                *ngIf="receiptInfo?.Amount===0 || receiptInfo?.Amount > 0">
                                <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                    (ngModelChange)="clearAmount(paymentMode)"
                                    (change)="changePaymentMode($event, paymentMode)">
                                <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>
                            </span>

                        </div>

                        <div class="mt-2" *ngIf="paymentMode.paymentType == 2">

                            <span *ngIf="receiptInfo?.Amount < 0">
                                <input type="radio" name="payment" [checked]="paymentMode.selectedPaymentType"
                                    (ngModelChange)="clearAmount(paymentMode)"
                                    (change)="changePaymentMode($event, paymentMode)">
                                <label class="paymentType pl-2">{{paymentMode.paymentModeName}}</label>
                            </span>

                        </div>

                    </ng-container>

                    <!-- <div class="mt-2"
                        *ngIf="billingSummaryDetails?.Balance_Amount===0 || billingSummaryDetails?.Balance_Amount > 0">

                        <input type="checkbox" [disabled]="modeCreditVoucher.isDisable"
                            [(ngModel)]="modeCreditVoucher.selectedPaymentType" name="payment"
                            (ngModelChange)="clearAmount(modeCreditVoucher)" (change)="changeCreditVoucher($event)">
                        <label class="paymentType pl-2">{{modeCreditVoucher.paymentModeName}}</label>

                    </div>

                    <div class="mt-2" *ngIf="billingSummaryDetails?.Balance_Amount < 0">

                        <input type="checkbox" [disabled]="modeRefundLater.isDisable"
                            [(ngModel)]="modeRefundLater.selectedPaymentType" name="payment"
                            (ngModelChange)="clearAmount(modeRefundLater)" (change)="changeRefundLater($event)">
                        <label class="paymentType pl-2">{{modeRefundLater.paymentModeName}}</label>

                    </div> -->

                </fieldset>
            </div>

            <div class="col-12 col-lg-5">
                <fieldset class="legend-block" *ngIf="isPaymentSelected()">
                    <legend class="legend-title mb-0 font-16">Transaction details</legend>
                    <div class="form-group twoInputssameLine mx-2 mb-2">
                        <label for="transactionId" class="text-dark mb-1">
                            Transaction ID
                            <span class="requiredSymbol">*</span>
                        </label>
                        <input type="text" class="form-control form-control-sm" id="transactionId"
                            [(ngModel)]="transactionId" />
                    </div>
                    <div class="form-group twoInputssameLine mx-2 mb-2">
                        <label for="bankname" class="text-dark mb-1">
                            Bank Name
                            <span class="requiredSymbol">*</span>
                        </label>
                        <input type="text" class="form-control form-control-sm" id="bankname"
                            [(ngModel)]="bankName" />
                    </div>
                    <div class="form-group twoInputssameLine mx-2 mb-2">
                        <label for="mobilee" class="text-dark mb-1">
                            Mobile
                            <span class="requiredSymbol">*</span>
                        </label>
                        <input type="text" class="form-control form-control-sm" id="mobilee"
                            [(ngModel)]="mobileNumber" maxlength="10" minlength="10" appOnlyNumberdecimal numericType="number" />
                    </div>
                    <div *ngIf="isPaymentChequeSelected()" class="form-group twoInputssameLine mx-2 mb-2">
                        <label for="issuedate" class="text-dark mb-1">
                            Issue Date
                            <span class="requiredSymbol">*</span>
                        </label>
                        <input type="date" class="form-control form-control-sm" id="issuedate"
                            [(ngModel)]="issueDate" (keypress)="numberOnlyVal($event, 'data')" />
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-12">&nbsp;</div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="button" [disabled]="checkCollectAmount()" class="btn btn-primary"
                    (click)="submitRefundForm()">
                    Submit
                </button>
                &nbsp; &nbsp;
                <button type="button" class="btn btn-primary cancelBtn" (click)="refundPopupClose()">
                    Cancel
                </button>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #pendingRefund let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Process Pending Refund</h4>
        <button type="button" class="close pt-3 pl-4" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">X</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
              <fieldset class="legend-block">
                <legend class="legend-title mb-0 font-16">Patient Info</legend>
                <div class="row">
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Name </div>
                      <div class="col"> {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> IP Number </div>
                      <div class="col"> {{patientInfo?.IP_NR}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
                      <div class="col"> {{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></div>
                    </div>
                  </div>
          
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Gender/Age </div>
                      <div class="col"> {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Admitting Doctor </div>
                      <div class="col"> {{patientInfo?.DM_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Payee Name </div>
                      <div class="col"> {{patientInfo?.PIM_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Referred By </div>
                      <div class="col">{{patientInfo?.REFERRED_BY}} </div>
                    </div>
                  </div>
                </div>
              </fieldset>          
            </div>
            
            <div class="col-12 col-sm-12 margin45 pt-3">
                <p>{{receiptInfo?.AmountLabel}}: {{ receiptInfo?.Amount}}</p>
            </div>
        
            <div class="col-12 col-lg-7">
              <fieldset class="legend-block">
                <legend class="legend-title mb-0 font-16">Payment Selection</legend>
                <p>Please select the mode of payment to collect advance/Deposit for the patient:</p>
                <div class="mt-2" *ngFor="let paymode of paymentModesList">
                  <input (change)="selectedradioButton(paymode, $event)" type="radio" [checked]="paymode.selectedPaymentType" name="payment" />
                  <label class="paymentType pl-2" >{{paymode.paymentModeName}}</label>
                  <input type="text" #searchInput [disabled]="!paymode.isSelected" [(ngModel)]="paymode.collectedAmount" 
                    class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="decimal2digits" />
                </div>
              </fieldset>
            </div>
          
            <div class="col-12 col-lg-5">
            <fieldset class="legend-block" *ngIf="isCashSelected()">
                <legend class="legend-title mb-0 font-16">Transaction details</legend>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="transactionId" class="text-dark mb-1">
                    Transaction ID
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #transactionID name="transactionId" [(ngModel)]="transactionId"
                    class="form-control form-control-sm" id="transactionId" />
                </div>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="bankName" class="text-dark mb-1">
                    Bank Name
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #bank_Name name="bankName" [(ngModel)]="bankName" class="form-control form-control-sm"
                    id="bankName" />
                </div>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="mobileNumber" class="text-dark mb-1">
                    Mobile
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #mobile_Numbr name="mobileNumber" [(ngModel)]="mobileNumber"
                    class="form-control form-control-sm" maxlength="10" minlength="10" id="mobileNumber" appOnlyNumberdecimal numericType="number" />
                </div>
            </fieldset>
            </div>

            <div class="col-12 mt-2 text-right">
            <button type="button" class="btn btn-primary mr-2" [disabled]="collectedAmountCheck()" (click)="submitPendingRefundForm()">
                Submit
            </button>
            <button type="button" class="btn btn-primary cancelBtn" (click)="pendingRefundPopupClose()">
                Cancel
            </button>
            <div *ngIf="showErrorMessage">
                <p style="color: red;text-align:center">Please fill required fields(Transaction Id, Bank Name and Mobile Number)
                </p>
            </div>
            </div>          
        </div>
    </div>
</ng-template>

<ng-template #pendingDue let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Collection Dues</h4>
        <button type="button" class="close pt-3 pl-4" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">X</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
              <fieldset class="legend-block">
                <legend class="legend-title mb-0 font-16">Patient Info</legend>
                <div class="row">
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Name </div>
                      <div class="col"> {{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> IP Number </div>
                      <div class="col"> {{patientInfo?.IP_NR}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Ward(Bed No) </div>
                      <div class="col"> {{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></div>
                    </div>
                  </div>
          
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Gender/Age </div>
                      <div class="col"> {{patientInfo?.GENDER}}/{{patientInfo?.AGE}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Admitting Doctor </div>
                      <div class="col"> {{patientInfo?.DM_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Payee Name </div>
                      <div class="col"> {{patientInfo?.PIM_NAME}} </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 pt-2">
                    <div class="row">
                      <div class="col-5 font-weight-bold"> Referred By </div>
                      <div class="col">{{patientInfo?.REFERRED_BY}} </div>
                    </div>
                  </div>
                </div>
              </fieldset>          
            </div>
            
            <div class="col-12 col-sm-12 margin45 pt-3">
                <p>{{receiptInfo?.AmountLabel}}: {{ receiptInfo?.Amount}}</p>
            </div>
        
            <div class="col-12 col-lg-7">
              <fieldset class="legend-block">
                <legend class="legend-title mb-0 font-16">Payment Selection</legend>
                <p>Please select the mode of payment to collect advance/Deposit for the patient:</p>
                <div class="mt-2" *ngFor="let paymode of paymentOptionsList">
                  <input (change)="selectedradioOption(paymode, $event)" type="radio" [checked]="paymode.selectedPaymentType" name="payment" />
                  <label class="paymentType pl-2" >{{paymode.paymentModeName}}</label>
                  <input type="text" #searchInput [disabled]="!paymode.isSelected" [(ngModel)]="paymode.collectedAmount" 
                    class="ml-2 form-control d-inline-block w-25 form-control-sm" appOnlyNumberdecimal numericType="number" />
                </div>
              </fieldset>
            </div>
          
            <div class="col-12 col-lg-5">
            <fieldset class="legend-block" *ngIf="isCashOptionSelected()">
                <legend class="legend-title mb-0 font-16">Transaction details</legend>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="transactionId" class="text-dark mb-1">
                    Transaction ID
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #transactionID name="transactionId" [(ngModel)]="transactionId"
                    class="form-control form-control-sm" id="transactionId" />
                </div>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="bankName" class="text-dark mb-1">
                    Bank Name
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #bank_Name name="bankName" [(ngModel)]="bankName" class="form-control form-control-sm"
                    id="bankName" />
                </div>
                <div class="form-group twoInputssameLine mx-2 mb-2">
                <label for="mobileNumber" class="text-dark mb-1">
                    Mobile
                    <span *ngIf="showErrorMessage" class="requiredSymbol">*</span>
                </label>
                <input type="text" #mobile_Numbr name="mobileNumber" [(ngModel)]="mobileNumber" 
                    class="form-control form-control-sm" maxlength="10" minlength="10" id="mobileNumber" appOnlyNumberdecimal numericType="number" />
                </div>
            </fieldset>
            </div>

            <div class="col-12 mt-2 text-right">
            <button type="button" class="btn btn-primary mr-2" [disabled]="collectedDueAmountCheck()" (click)="submitPendingDueForm()">
                Submit
            </button>
            <button type="button" class="btn btn-primary cancelBtn" (click)="pendingDuePopupClose()">
                Cancel
            </button>
            <div *ngIf="showErrorMessage">
                <p style="color: red;text-align:center">Please fill required fields(Transaction Id, Bank Name and Mobile Number)
                </p>
            </div>
            </div>          
        </div>
    </div>
</ng-template>

<div id="print-receipt-section" class="print-form mt-5 border border-dark top-margin"
        *ngIf="printReceiptDiv && ipReceiptDetailsList !== undefined">
    <div class="row no-gutters heading-block border-bottom border-dark">
        <div class="col-md-6 border-right border-dark">
            <div class="row">
                <div class="col-md-2 logo">
                    <img src="./assets/hospital-logo.jpg" class="img-thumbnail" />
                </div>
                <div class="col-md-10">
                    <h2 class="main-title mb-0 font-weight-bold" [innerHtml]="ipReceiptDetailsList?.HospitalName">
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="text-align: center;">
                    <p>{{ipReceiptDetailsList?.HospitalAddress}}</p>
                    <h3 class="sub-title1 mb-1 font-weight-bold">
                        <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                    </h3>
                </div>
            </div>
        </div>
        <div class="col-md-6 p-3">
            <div class="row">
                <div class="col-md-3">
                    P. Name
                </div>
                <div class="col-md-9">
                    <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    UMR No
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                </div>
                <div class="col-md-3">
                    Admn No
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Recpt No
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                </div>
                <div class="col-md-3">
                    Recpt Dt
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    Age/Sex
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                </div>
                <div class="col-md-3">
                    Phone
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.Phone}}
                </div>
            </div>
        </div>
    </div>
    <div class="row-md-8 p-3">
        <div class="row">
            <div class="col-md-2">
                <span> S/O.D/O.W/O</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
            </div>
            <div class="col-md-2">
                <span> Ward/Bed</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.WardBed}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <span> Relation</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.relation}}
            </div>
            <div class="col-md-2">
                <span>Advance NO</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <span> Consultant</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.Consultant}}
            </div>
            <div class="col-md-2">
                <span> Dept Name</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.DeptName}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <span>Receipt Amt</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
            </div>
            <div class="col-md-2">
                <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
            </div>
            <div class="col-md-4">
                <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                    <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                </span>
                <span *ngIf="ipReceiptDetailsList?.WalletMode">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </span>
            </div>
        </div>
        
        <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
            <div class="col-md-2">
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
            </div>
            <div class="col-md-4">
                <b>:</b>
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
            </div>
            <div class="col-md-2">
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
            </div>
            <div class="col-md-4">
                <b>:</b>
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
            </div>
        </div>
        <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
            <div class="col-md-2">
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.BankName}}
            </div>
            <div class="col-md-2">
                <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
            </div>
        </div>
        <div>
            <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
            <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
        </div>
    </div>
    <footer class="p-3">
        <div class="row">
            <div class="col-md-2">
                Created By
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
            </div>
            <div class="col-md-2">
                Create Dt
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                Print By
            </div>
            <div class="col-md-4">
                <b>:</b> {{userInfo.username}}
            </div>
            <div class="col-md-2">
                Print Dt
            </div>
            <div class="col-md-4">
                <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
            <div class="col-md-4 text-center">Email: health@lsch.in, www.lsch.in</div>
            <div class="col-md-4 text-right">(Authorised Signatory)
            </div>
        </div>
    </footer>

</div>