import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ViewAppointmentsModel} from '../../shared/appointmentsModels/viewAppointmentsModel';
import {AppointmentService} from '../appointment.service';
import {
  AppointmentAppointmentsByDate,
  AppointmentAppointmentsByNameNumberUmr,
  AppointmentCancellingAppointment,
  AppointmentDataBYDoctor,
  AppointmentDepartmentsList,
  AppointmentPatientAllCities,
  AppointmentPatientRegistration,
  AppointmentPatientSearch,
  AppointmentSearchDoctor, AppointmentSearchHospitals, AppointmentSingleDoctorInfo,
  AppointmentViewAppointments, GettingCityInfoById
} from '../appointmentconstants';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {merge, Observable, Subject} from 'rxjs';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {SearchDepartments} from '../../shared/appointmentsModels/searchDepartments';
import {ServerReply} from '../../shared/appointmentsModels/serverReply';
import {FormControl, Validators} from '@angular/forms';
import {DoctorInfoModel} from '../../shared/appointmentsModels/doctorInfoModel';
import {SearchHospitals} from '../../shared/appointmentsModels/searchHospitals';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TokenStorageService} from '../../shared/loginservices/token-storage.service';

@Component({
  selector: 'app-viewappointments',
  templateUrl: './viewappointments.component.html',
  styleUrls: ['./viewappointments.component.css']
})
export class ViewappointmentsComponent implements OnInit {
  chooseDay: string;
  @ViewChild('modalForRegistration') private modalReg: ElementRef;
  @ViewChild('modalSubmittingForm') private modalSubmitForm: ElementRef;
  @ViewChild('modalSubmitClose') private modalSubmitClose: ElementRef;
  patternvalidation = '^\\d{4}\\s\\d{4}\\s\\d{4}$';
  patterndriving = '^(?<intro>[A-Z]{2})(?<numeric>\\d{2})(?<year>\\d{4})(?<tail>\\d{8})$';
  allDoctorsData: { doctorName: string, doctorId: number, deptId: number }[] = [];
  arrOfAllSpecialities: string[] = [];
  arrOfAllPatientsnNUmbers: string[] = [];
  arrOfDepartmentId: number[] = [];
  arrOfAllDoctors: string[] = [];
  viewAppLoaded = false;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  formDoctor: string;
  patientAge: string;
  page = 1;
  pageSize = 7;
  formSpeciality: string;
  cancellationMessage = 'Loading...' ;
  listOfAllDoctors: SearchDoctors[];
  doctorInfoById: DoctorInfoModel;
  patientCityModel: {cityName: string, cityId: number}[] = [];
  formPatientNamenNUmber: string;
  formDate = new Date();
  modalInfo = 'Loading Appointments';
  appointmentDate: string;
  hospitalIdbyTokenService: number;
  selectedAppointmentForChange: ViewAppointmentsModel;
  days = ['Yesterday', 'Today', 'Tomorrow', 'Calendar'];
  searchByDoctor$ = new Subject<string>();
  searchByDoctorClick$ = new Subject<string>();
  searchBySpeciality$ = new Subject<string>();
  searchBySpecialityClick$ = new Subject<string>();
  searchByPatientNamenNumber$ = new Subject<string>();
  searchByPatientNamenNumberClick$ = new Subject<string>();
  tabname = 'Today';
  date = new Date().getTime();
  selectedId: any = 'Aadhar';
  receivedData = true;
  todayDate: string;
  yesterdayDate: string;
  tomorrowDate: string;
  calendardate: string;
  appointmentsData: ViewAppointmentsModel[];
  appoitntmentsByDay: ViewAppointmentsModel[];
  patientInfo:any;
  public filter = false;
  public show = false;
  public buttonName: any = 'Show';
  model: PatientProfile = { address: '',
    city: '',
    country: '',
    createDateTime: '',
    createId: '',
    dateOfBirth: '',
    emailId: '',
    familyGroupId: '',
    familyGroupRegistration: '',
    gender: '',
    identityIdNr: '',
    identityType: '',
    mobileNumber: '',
    nextOfKin1Mobilenr: '',
    nextOfKin1Name: '',
    nextOfKin1Relation: '',
    nextOfKinMobilenr: '',
    nextOfKinName: '',
    nextOfKinRelation: '',
    patientID: null,
    patientName: '',
    firstName: '',
    lastName: '',
    permanentHomeAddress: '',
    pincode: '',
    regDate: '',
    stateCountry: '',
    status: 1,
    title: '',
  };
  identityNumber: FormControl = new FormControl('', [Validators.required, Validators.pattern('^\\d{4}\\s\\d{4}\\s\\d{4}$')]);
  constructor(private appointmentService: AppointmentService,
              private router: Router,

              private modalService: NgbModal,
              private tokenService: TokenStorageService) { }

  // tslint:disable-next-line:typedef
  selectChangeHandler(event: any): any {
    this.identityNumber = new FormControl('');
    this.selectedId = event.target.value;
    if (event.target.value === 'Aadhar'){
      this.identityNumber.setValidators([Validators.pattern('^\\d{4}\\d{4}\\d{4}$'), Validators.required]);
    }
    else if (event.target.value === 'Driving license'){this.identityNumber.setValidators([Validators.pattern('^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$'), Validators.required]);
    }
    else if (event.target.value === 'Voter ID'){
      this.identityNumber.setValidators([Validators.pattern('^([a-zA-Z]){3}([0-9]){7}?$'), Validators.required]);
    }
    else if (event.target.value === 'Pan Card'){
      this.identityNumber.setValidators([Validators.pattern('^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$'), Validators.required]);

    }
    else if (event.target.value === 'Passport'){
      this.identityNumber.setValidators([Validators.pattern('^(?!^0+$)[a-zA-Z0-9]{6,9}$'), Validators.required]);
    }
  }
  // tslint:disable-next-line:typedef
  onKeyUp(): any{
    this.identityNumber.markAsTouched();
  }
  ngOnInit(): void {
    this.hospitalIdbyTokenService = this.tokenService.getUser().hospitalID;
    this.viewAppLoaded = true;
    this.assigningEachDay();
    this.chooseDay = 'Today';
    this.appointmentService.getAppointmentViewAppointment(AppointmentViewAppointments)
      .subscribe( (allAppointments: ViewAppointmentsModel[]) => {
        this.appoitntmentsByDay = allAppointments;
        this.activeDay('Today', this.todayDate);
        this.gettingDoctornamesByHospitalId();
      });
    this.appointmentService.getAppointmentDepartmentList(AppointmentDepartmentsList +
      this.hospitalIdbyTokenService)
      .subscribe( alldepartmnets => {
        alldepartmnets.forEach( (department: SearchDepartments) => {
          if (this.arrOfAllSpecialities.indexOf(department.deptName)) {
            this.arrOfAllSpecialities.push(department.deptName);
            this.arrOfDepartmentId.push(department.deptId);
          }
        });
      });
  }
  checkinTimeSlot(slottoCheck): boolean {
    const dateCheck = this.appointmentDate === this.appointmentService.requiredDateFormat(this.formDate);
    const totMin = (this.formDate.getHours() * 60) + this.formDate.getMinutes() - 30;
    const hours = (Math.floor(totMin / 60)).toString().length < 2 ? '0' + (Math.floor(totMin / 60)) : (Math.floor(totMin / 60));
    const min = (totMin % 60).toString().length < 2 ? '0' + (totMin % 60) : (totMin % 60);
    const timesSlot =  hours + ':' + min + ':00';
    //   if (this.appointmentDate < this.formDate.toJSON().substr(0, 10)) {
    //     return true;
    //   }
    //   if ((slottoCheck <= timesSlot) && dateCheck) {
    // return true;
    //   }
    return (this.appointmentDate < this.appointmentService.requiredDateFormat(this.formDate)) || ((slottoCheck <= timesSlot) && dateCheck);
    // return false;
  }

  searchByEnterKey(event): any {
    if (event.keyCode === 13){
      this.appointmentsByPateientNameOrNumber(event.target);
    }
  }
  onSubmit(maleData, femaleData): any {
    this.identityNumber.markAsTouched();
    if (!this.model.gender) {
      this.model.gender = (maleData.checked) ? 'MALE' : (femaleData.checked) ? 'FEMALE' : '';
    }
    this.appointmentService.registeringPatientIfUnavailable(AppointmentPatientRegistration, this.model)
      .subscribe(reply => {
        if (reply.errorMessage === null) {
          this.modalService.dismissAll();
          // this.appointmentService.appAddVisitPatient = this.model;
          // this.modalReg.nativeElement.click();
          this.router.navigate(['/registration']);
        }
        else {
          alert('Please Check all the fields');
        }
      });
  }

  changingGenderFields(): any {
    if (this.model.title === 'Master.' || this.model.title === 'Mr.') {
      this.model.gender = 'MALE';
    }
    else if (this.model.title === 'Miss.' || this.model.title === 'Ms.' || this.model.gender === 'Mrs.'){
      this.model.gender = 'FEMALE';
    }
  }
  gettingAge(dobValue): any {
    const fullDate = new Date(dobValue);
    var todayDate=new Date();
    let patientYears = todayDate.getFullYear() - fullDate.getFullYear();
    let patientMonths = todayDate.getMonth() - fullDate.getMonth();
    let patientDays = todayDate.getDate() - fullDate.getDate();

    if (patientMonths <= 0) {
      patientYears--;
      patientMonths = (12 + patientMonths);
    }
    if (patientDays < 0) {
      patientMonths--;
      patientDays = 31 + patientDays;
      if(patientDays == 31) {
        patientMonths++;
        patientDays = 0;
      }
    }
    if (patientMonths == 12) {
      patientYears = patientYears + 1;
      patientMonths = 0;
    }
    this.patientAge = patientYears + 'Y ' + patientMonths + 'M ' + patientDays + 'D';
  }

  searchByDoctorName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.searchByDoctorClick$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.searchByDoctor$;
    if (this.arrOfAllDoctors) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.arrOfAllDoctors
          : this.arrOfAllDoctors.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }
  SearchBySpeciality = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.searchBySpecialityClick$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.searchBySpeciality$;
    if (this.arrOfAllSpecialities) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.arrOfAllSpecialities
          : this.arrOfAllSpecialities.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }

  // tslint:disable-next-line:typedef
  filterhideandshow() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Hide';
    }
    else {
      this.buttonName = 'Show';
    }
  }
  // tslint:disable-next-line:typedef
  showingCancelMessage(){
    if (this.selectedAppointmentForChange){
      const dateChange = this.selectedAppointmentForChange.patientAppointmentDate.split('-').reverse().join('-');
      return'Your Appointment on' + dateChange + 'at' + this.intoTweleve(this.selectedAppointmentForChange.patientAppointmentTime) + 'is cancel';
    }
  }
  intoTweleve(recTime): any {
    let arr = recTime.split(':');
    if (Number(arr[0]) >= 12) {
      arr[2] = 'PM';
      arr[0] = Number(arr[0]) > 12 ? Number(arr[0]) - 12 : arr[0];
    }
    else {
      arr[2] = 'AM';
    }
    return arr[0] + ':' + arr[1] + ' ' + arr[2];
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  SearchByPatientNameAndNumber = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.searchByPatientNamenNumberClick$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.searchByPatientNamenNumber$;
    if (this.arrOfAllPatientsnNUmbers) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.arrOfAllPatientsnNUmbers
          : this.arrOfAllPatientsnNUmbers.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }
  gettingDoctornamesByHospitalId(): any {
    this.appointmentService.getAppointmentSearchDoctors(AppointmentSearchDoctor +
      this.hospitalIdbyTokenService || this.appointmentService.appSelectedHospitalId.toString() )
      .subscribe( doctorData => {
        this.listOfAllDoctors = doctorData;
        this.appointmentService.appallDoctorsList = doctorData;
        doctorData.forEach( (eachDoctorData: SearchDoctors) => {
          this.allDoctorsData.push({doctorName: eachDoctorData.doctorTitle + ' ' +
              eachDoctorData.doctorName, doctorId: eachDoctorData.doctorId, deptId: eachDoctorData.departmentId });
          this.arrOfAllDoctors.push(eachDoctorData.doctorTitle + ' ' + eachDoctorData.doctorName);
        });
      });
  }
  gettingAllPatientData(content, receivedID, patientInfo): any {
    console.log(patientInfo);
    const umrId = {
      patientID: patientInfo.patientID
    };
    this.appointmentService.appAddVisitPatient = patientInfo;
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, umrId)
      .subscribe((patientData: any) => {
        console.log(patientData[0]);
        if (patientData[0]?.status === 1) {
          // this.appointmentService.appAddVisitPatient = patientInfo;
          this.router.navigate(['/registration']);
        }
        else if (patientData[0]?.status === 0) {
          // this.router.navigate(['/registration']);
          this.modalService.open(content, { size: 'xl' });
          this.model = patientData[0];
          this.gettingAge(this.model.dateOfBirth);
          this.patientCityModel = [];
          this.appointmentService.gettingAllCitiesForPatient(AppointmentPatientAllCities)
            .subscribe(citiesData => {
              if (citiesData.length > 0) {
                citiesData.forEach(cityItem => {
                  this.patientCityModel.push({ cityName: cityItem.cscmCityAreaName, cityId: cityItem.cityID });
                });
              }
            });
        }
      });
  }
  gettingCityInfo(): any {
    const cityobj = {
      cityID: this.patientCityModel.find( getId => {
        return getId.cityName === this.model.city;
      }).cityId
    };
    this.appointmentService.patientCityInfoById(GettingCityInfoById, cityobj)
      .subscribe( cityInfo => {
        this.model.country = cityInfo[0].cityCountryName;
        this.model.stateCountry = cityInfo[0].cityState;
        this.model.pincode = cityInfo[0].cityPinZip;
      });
  }
  activeDay(day, date): any {
    this.tabname = day;
    this.appointmentDate = date;
    this.chooseDay = day;
    if (this.appoitntmentsByDay) {
      this.viewAppLoaded = false;
      this.appointmentsData = this.appoitntmentsByDay.filter((tablerows) => {
        return tablerows.patientAppointmentDate === date;
      });
    }
  }
  reverseDate(): any {
    if (this.appointmentDate) {
     return this.appointmentDate.split('-').reverse().join('-');
    }
    return ;
  }
  appointmentsByPateientNameOrNumber(nameOrNumber): any {
    this.modalInfo = 'Loading Data...';
    this.viewAppLoaded = true;
    let patientData: {};
    if (isNaN(nameOrNumber.value) === true) {
      patientData = {patientName: nameOrNumber.value};
    }
    else {
      if (nameOrNumber.value.length === 10) {
        patientData = {patientMobile: nameOrNumber.value};
      }
      else {
        patientData = {umrNumber: nameOrNumber.value};
      }
    }
    this.formDoctor = '';
    this.formSpeciality = '';
    this.appointmentService.patientAppointmentsByNameOrUmrOrNumber(AppointmentAppointmentsByNameNumberUmr, patientData)
      .subscribe( (allAppointments: ViewAppointmentsModel[]) => {
        this.viewAppLoaded = false;
        this.appoitntmentsByDay = allAppointments;
        this.activeDay('Today', this.todayDate);
      });
  }
  gettingDataByDoctor(doctorNameToSearch): any {
    this.formPatientNamenNUmber = '';
    this.formSpeciality = '';
    this.modalInfo = 'Loading Data';
    this.viewAppLoaded = true;
    const doctorId = this.allDoctorsData.find( (item ) => {
      return item.doctorName === doctorNameToSearch.item;
    }).doctorId;
    this.appointmentService.gettingTheDataByDoctor(AppointmentDataBYDoctor + doctorId).subscribe( (dataReceived) => {
      this.viewAppLoaded = false;
      this.appoitntmentsByDay = dataReceived;
      this.activeDay('Today', this.todayDate);
    });
  }
  assigningEachDay(): any{
    this.todayDate = this.appointmentService.requiredDateFormat(new Date(this.date));
    this.yesterdayDate = this.appointmentService.requiredDateFormat(new Date(this.date - 86400000));
    this.tomorrowDate = this.appointmentService.requiredDateFormat(new Date(this.date + 86400000));
  }
  gettingAppointmentsByDate(ngbDate): any {
    this.modalInfo = 'Loading Data';
    this.viewAppLoaded = true;
    const ngmonth = (ngbDate.month.toString().length < 2) ? '0' + ngbDate.month : ngbDate.month;
    const ngDate = (ngbDate.day.toString().length < 2) ? '0' + ngbDate.day : ngbDate.day;
    const recDate = ngbDate.year + '-' + ngmonth + '-' + ngDate;
    this.appointmentDate = recDate;
    this.date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime();
    if (Number(ngbDate.year) >= 2020) {
      this.appointmentService.gettingPatientAppointmentsByDate(AppointmentAppointmentsByDate + recDate)
        .subscribe( appointments => {
          this.viewAppLoaded = false;
          this.appoitntmentsByDay = appointments;
          this.assigningEachDay();
          // this.days = [];
          this.activeDay('Today', this.todayDate);
        });
    }
    // this.chooseDay = 'Today';
  }
  gettingAppointmentsBySpeciality(speciality): any {
    this.formDoctor = '';
    this.formPatientNamenNUmber = '';
    this.modalInfo = 'Loading Data';
    this.viewAppLoaded = true;
    const depId = this.arrOfDepartmentId[this.arrOfAllSpecialities.indexOf(speciality.item)];
    this.appoitntmentsByDay = [];
    this.allDoctorsData.forEach( docProfile => {
      if (docProfile.deptId === depId) {
        this.appointmentService.gettingTheDataByDoctor(AppointmentDataBYDoctor + docProfile.doctorId)
          .subscribe( (dataReceived) => {
            this.viewAppLoaded = false;
            this.appoitntmentsByDay = [...this.appoitntmentsByDay , ...dataReceived];
            this.activeDay('Today', this.todayDate);
          });
      }
    });
  }

  cancellingAppointment(): any{
    this.selectedAppointmentForChange.patientAppointmentStatus = 'Cancelled';
    this.appointmentService.cancellingAppointment(AppointmentCancellingAppointment, this.selectedAppointmentForChange)
      .subscribe( (reply: ServerReply) => {
        if (reply.errorMessage === null) {
          this.cancellationMessage = this.showingCancelMessage();
        }
        else {
          this.cancellationMessage = reply.errorMessage;
        }
      });
  }
  getDoctorTitle(docname): string {
    if (this.listOfAllDoctors) {
      const drTitle =  this.listOfAllDoctors.find(doctor => {
        return doctor.doctorName === docname;
      });
      if (drTitle) {
        return drTitle.doctorTitle;
      }
      else {
        return 'Dr.';
      }
    }
  }
  checkingEmptyFields(): any {
    if (!this.formDoctor && !this.formPatientNamenNUmber && !this.formSpeciality) {
      this.appointmentService.getAppointmentViewAppointment(AppointmentViewAppointments)
        .subscribe( (allAppointments: ViewAppointmentsModel[]) => {
          this.appoitntmentsByDay = allAppointments;
        });
      this.activeDay('Today', this.todayDate);
    }
  }
  navigationForReschedule(): any{
    this.modalInfo = 'Rescheduling Appointment';
    this.viewAppLoaded = true;
    this.appointmentService.appPatientForReschedule = this.selectedAppointmentForChange;
    this.appointmentService.appSelectedHospital = this.selectedAppointmentForChange.hospitalName;
    this.appointmentService.appSelectedHospitalId = this.selectedAppointmentForChange.hospitalID;
    this.appointmentService.gettingSingleDoctorInfoById(AppointmentSingleDoctorInfo + this.selectedAppointmentForChange.doctorID)
      .subscribe( (doctorInfo: DoctorInfoModel) => {
        this.doctorInfoById = doctorInfo;
        this.appointmentService.getAppointmentSearchHospitals(AppointmentSearchHospitals + this.doctorInfoById.cityId)
          .subscribe((data: SearchHospitals[]) => {
            this.appointmentService.appSelectedHospitaldata = data.find(requiredHospital => {
              return this.selectedAppointmentForChange.hospitalName === requiredHospital.hospitalName;
            });
          });
        this.appointmentService.appSearchFields = {locationName: doctorInfo.cityName,
          hospitalName: doctorInfo.hospitalName, doctorName: doctorInfo.doctorName};
        this.appointmentService.searchResults = {
          locationId: doctorInfo.cityId,
          hospitalId: doctorInfo.hospitalId,
          doctorOrSpecialisationId: null,
          doctorNameRSpecialisation: doctorInfo.doctorName,
          bySpecialisation: false,
          departmentId: doctorInfo.deptId
        };
        this.appointmentService.appSelectedDoctor = this.listOfAllDoctors.find( (eachDoc: SearchDoctors) => {
          return eachDoc.doctorName === doctorInfo.doctorName;
        });
        this.appointmentService.searchResults.doctorOrSpecialisationId = this.appointmentService.appSelectedDoctor.hospitaldocdepId;
        this.viewAppLoaded = false;
        this.router.navigate(['/specialization']);
      });
  }

  openPresectiptionPopup(presectiptionPopup, patientInfo) {
    this.patientInfo = patientInfo;
    this.modalService.open(presectiptionPopup, { size: 'xl' });
  }
}

