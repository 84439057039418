import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HimscontentComponent } from './himscontent/himscontent.component';
import {AppointmentsModule} from '../appointments/appointments.module';
import {RouterModule} from '@angular/router';



@NgModule({
    declarations: [FooterComponent, HimscontentComponent],
    exports: [
        HimscontentComponent,
      FooterComponent
    ],
    imports: [
        CommonModule,
        AppointmentsModule,
        RouterModule
    ]
})
export class LandingpageModule { }
