
import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/billing-module/billing.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { TokenStorageService } from '../loginservices/token-storage.service';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { payeeNamesUrl} from '../../billing-module/billingconstants'

import * as moment from 'moment';
@Component({
  selector: 'app-pre-auth',
  templateUrl: './pre-auth.component.html',
  styleUrls: ['./pre-auth.component.css']
})
export class PreAuthComponent implements OnInit {

  @Output() closePopup = new EventEmitter();

  @Input() ipPatientNO: number;
  @Input() patientURMNo: number;

  public userInfo: any = {};
  // patientURMNo: number;
  // ipPatientNO: number;
  public patientInfo: any;
  date: any;
  showErrorMessage = false;
  preAuthForm;
  preAuthList;
  invalidRange = false;
  selectedValue: number;
  insurancePayeeNames: any = []
  sentONMin = '';
  sentONMax = '';

  receivedONMin = '';
  receivedONMax = '';

  constructor(private billingService: BillingService,
              private formBuilder: FormBuilder,
              private tokenService: TokenStorageService,
              private inpatientService: InpatientService,
              private route: ActivatedRoute,
              private toastrService: ToastrService
  ) {
    this.userInfo = this.tokenService.getUser();

    const today = new Date();
    this.sentONMin = moment(today).format('YYYY-MM-DDTHH:MM');
    this.sentONMax = moment(today).format('YYYY-MM-DDTHH:MM');

    this.receivedONMin = moment(today).format('YYYY-MM-DDTHH:MM');
    this.receivedONMax = moment(today).format('YYYY-MM-DDTHH:MM');

  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const preauthSentOn = this.preAuthForm && this.preAuthForm.get('preauthSentOn').value;
    const to = new Date();
    const fromDate = this.patientInfo && this.patientInfo.IP_ADMISSION_DATE;
    if (preauthSentOn && to) {
      console.log(new Date(preauthSentOn).valueOf());
      console.log(new Date(fromDate).valueOf());
      console.log(new Date(to).valueOf());
      invalid = new Date(preauthSentOn).valueOf() > new Date(fromDate).valueOf() &&
       (new Date(preauthSentOn).valueOf() < new Date(to).valueOf());
      console.log(invalid);
    }

    return invalid ? { invalidRange: { preauthSentOn } } : null;
  }
  private dateRangeValidator1: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const informationReceivedOn = this.preAuthForm && this.preAuthForm.get('informationReceivedOn').value;
    const to = new Date();
    const fromDate = this.patientInfo && this.patientInfo.IP_ADMISSION_DATE + ' ' + this.patientInfo.IP_ADMISSION_TIME;
    if (informationReceivedOn && to) {
      invalid = (new Date(informationReceivedOn).valueOf() < new Date(to).valueOf()) &&
      new Date(informationReceivedOn).valueOf() > new Date(fromDate).valueOf();
    }
    console.log(invalid);
    return invalid ? { invalidRange: { informationReceivedOn} } : null;
  }

  ngOnInit(): void {

    // preauthSentOn: ['', [Validators.required, this.dateRangeValidator]],
    // informationReceivedOn: ['', [Validators.required, this.dateRangeValidator1]],

    this.preAuthForm = this.formBuilder.group({
      preauthSentOn: ['', [Validators.required]],
      preauthSentTo: ['', Validators.required],
      preauthEstimationSubmittedFor: ['', Validators.required],
      amountApproved: ['', Validators.required],
      approvedStatus: ['', Validators.required],
      informationReceivedOn: ['', [Validators.required]],
      payeeName: ['', [Validators.required]],
      dateofdischarge: ['', [Validators.required]],
      approvalType: ['', [Validators.required]],
      claimNumber: ['', [Validators.required]]
    });

    this.getPatientInfo();
    this.getAuthList();
    this.getInsurancePayeeNames();
  }

  getInsurancePayeeNames()
  {
    this.billingService.getPayeeNames(payeeNamesUrl)
      .subscribe(
        (response) =>
        {
          this.insurancePayeeNames = response
        },
        (err) =>
        {
          console.log("Error: ", err)
        }
      )
  }


  onChangeSentON(event): void {
    console.log('setme', event.target.value);
    this.receivedONMin = event.target.value;
  }

  closeModalPopup(): void {
    this.closePopup.emit(true);
  }

  getPatientInfo(): void {

    const inputRequest = {
      sear_umr_no: this.patientURMNo,
      sear_ip_no: this.ipPatientNO,
      sear_hospitalid: this.userInfo.hospitalID,
    };

    this.inpatientService.patientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        // console.log("patient info", response);

        const adm = this.patientInfo.IP_ADMISSION_DATE + ' ' + this.patientInfo.IP_ADMISSION_TIME;
        this.sentONMin = moment(adm).format('YYYY-MM-DDTHH:MM');

      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  onSubmit(): void {
    
    const payeeData = this.insurancePayeeNames.filter((data) => data.mpm_id === this.preAuthForm.value.payeeName);
    const inputRequest = {
      IpNo: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID,
      SentOn: this.preAuthForm.value.preauthSentOn,
      SentTo: this.preAuthForm.value.preauthSentTo,
      EstimationSubmitPreauth: this.preAuthForm.value.preauthEstimationSubmittedFor,
      AmountApprove: this.preAuthForm.value.amountApproved,
      ApprovalStatus: this.preAuthForm.value.approvedStatus,
      InformationReceive: this.preAuthForm.value.informationReceivedOn ,
      CreatedName: this.userInfo.username,
      mpm_id: this.preAuthForm.value.payeeName,
      insurancePayeeName: payeeData[0].main_payee_name,
      claimNumber: this.preAuthForm.value.claimNumber,
      approvalType: this.preAuthForm.value.approvalType,
      dischargeDate: this.preAuthForm.value.dateofdischarge
    };

    this.inpatientService.patientPreAuthFormInfo(inputRequest).subscribe(
      (response) => {
        this.toastrService.success(response.message);
        this.closeModalPopup();
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  getAuthList(): void {

    const inputRequest = {
      IpNo: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID,
    };
    this.inpatientService.getPreAuthList(inputRequest).subscribe(
      (response) => {
        this.preAuthList = response;
        console.log(this.preAuthList);
         },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

}
