import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

interface IuserInfo{
  userName: string,
  hospitalName: string
}

const userInfo = {
  userName: '',
  hospitalName: ''
}

@Injectable({
  providedIn: 'root'
})


export class TokenStorageService {

  private currentUserHospitalNameStore = new BehaviorSubject<IuserInfo>(userInfo);
  public currentUserHospitalName$ = this.currentUserHospitalNameStore.asObservable();

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  // Setter to update UserName
  setCurrentUserHospitalName(userInfo: IuserInfo) {
    this.currentUserHospitalNameStore.next(userInfo);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
