import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ICD_URL } from './constants'

@Injectable({
  providedIn: 'root'
})
export class Icd10Service {

  constructor(private http: HttpClient) { }

  /**
   * get the ICD list 
   * @param data 
   * @returns 
   */
  public getICDList(data)
  {
    return this.http.post(ICD_URL.GET_ICD_LIST, data);
  }

  /**
   * submit the user ICD selected info
   * @param data 
   * @returns 
   */
  public submitICDList(data)
  {
    return this.http.post(ICD_URL.SUBMIT_ICD_LIST, data)
  }

  /**
   * get the user ICD list
   * @param inputRequest 
   * @returns 
   */
  public getuserICDList(inputRequest)
  {
    return this.http.post(ICD_URL.GET_USER_ICD_LIST, inputRequest)
  }

  public updateUserICD(inputRequest)
  {
    return this.http.post(ICD_URL.UPDATE_USER_ICD, inputRequest)
  }

}
