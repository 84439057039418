<div class="row ">
    <div class="col-md-12 text-right">
        <button (click)="printBill()" type="button" class="btn btn-primary mr-3">Print</button><!-- *ngIf="existingRecord" -->
    </div>
    <br><br><br><br><br><br><br><br>
    <h3 class="col-md-12 text-center font-weight-bold"><u>EMERGENCY ADMISSION CERTIFICATE</u></h3><br><br><br><br>
    <div class="row col-md-12 ml-2">
        <p style="font-size: 18px;" class="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that <b>{{patientInfo.Pname}}</b>, S/O.D/O <b>{{patientInfo.SoDoRelation}}</b>,
            <b>IPNO: {{patientInfo.IPNO}}</b> aged <b>{{patientInfo.Age}}</b>, admitted at Paramitha Childrens Hospital Pvt Ltd. H.No. 11-13-728/5, Green Hills Colony, 
            Kothapet main road, L.B.Nagar Hyderabad-500074 under the care of <b>{{patientInfo.TreatingDoctor}}, {{patientInfo.DoctorQualification}}</b>, on 
            <b>{{patientInfo.Doa | date: "dd-MM-yyyy"}}</b> at <b>{{patientInfo.DoaTime}}</b>.
        </p>
        <br><br><br><br>
    </div>
    <div class="col-md-12"> 
        <p style="font-size: 18px; padding-left: 75px;">The provisional Diagnosis is : <b>{{patientInfo.Diagnosis}}</b></p>
    </div><br><br><br><br>
    <div class="col-md-12 ml-2" style="font-size: 18px;">
       <p>You are requested to issue and an admission memo.</p>
        <br><br><br>
        <p>Thanking you,</p>        
    </div><br><br><br><br><br>
    <div class="col-md-12 text-right" style="font-size: 18px; padding-right: 20px;">
        for PARAMITHA CHILDRENS HOSPITAL PVT LTD
    </div>
   
  </div>

  <!-- Essentiality form print -->
  <div id="essentiality-form-print" style="display: none;" class="print-form"><!-- style="display: none;" -->
    <div class="row">
      <div class="col-md-12">
          <div class="col-md-12" style="margin-top: 2.5in;">  
            <!-- <br><br><br><br><br><br><br><br> -->
            <h3 class="text-center font-weight-bold"><u>EMERGENCY ADMISSION CERTIFICATE</u></h3>
            <div><br><br><br><br><br>
                <p style="font-size: 22px;" class="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is to certify that <b>{{patientInfo.Pname}}</b>, S/O.D/O 
                    <b>{{patientInfo.SoDoRelation}}</b>, <b>IPNO: {{patientInfo.IPNO}}</b> aged <b>{{patientInfo.Age}}</b>, admitted at Paramitha Childrens Hospital Pvt 
                    Ltd. H.No. 11-13-728/5, Green Hills Colony, Kothapet main road, L.B.Nagar Hyderabad-500074 under the care of 
                    <b>{{patientInfo.TreatingDoctor}}, {{patientInfo.DoctorQualification}}</b>, on <b>{{patientInfo.Doa | date: "dd-MM-yyyy"}}</b> 
                    at <b>{{patientInfo.DoaTime}}</b>.
                </p>
            </div><br><br><br><br><br>
            <div>
                <p style="font-size: 22px; padding-left: 75px;">The provisional Diagnosis is : <b>{{patientInfo.Diagnosis}}</b></p>
            </div><br><br><br><br><br>

            <div style="font-size: 22px;">
                You are requested to issue and an admission memo.
                <br><br><br>
                Thanking you,                
            </div><br><br><br><br>

            <div class="text-right" style="font-size: 22px; padding-right: 20px;">
                for PARAMITHA CHILDRENS HOSPITAL PVT LTD.
            </div>              
          </div>
      </div>
    </div>
  </div>
