<form (ngSubmit)="updatepayee.form.valid && pName && submitUpdateFormInfo()"                    
        #updatepayee="ngForm"
        autocomplete="off">
            <div class="form-group">
                <div class="row mt-3 mb-3">
                    <div class="col-md-3">
                        <label class="font-weight-bold">Payee Type: </label>
                    </div>
                    <div class="col-md-3">
                        <label class="radio-inline d-block" for="radio16">
                        <input type="radio"
                                class="form-check-input" 
                                id="radio16" 
                                name="payeeType"
                                (change)="updatePayeeTypeChange(updateFormData.payeId)"
                                [checked]="updateFormData.payeType == 1"
                                [(ngModel)]="updateFormData.payeType" 
                                #payeeType = "ngModel"
                                value="1">Self Pay
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label class="radio-inline d-block" for="radio17">
                        <input type="radio" 
                                class="form-check-input" 
                                id="radio17" 
                                name="payeeType"
                                (change)="updatePayeeTypeChange(updateFormData.payeId)"
                                [checked]="updateFormData.payeType == 2"
                                [(ngModel)]="updateFormData.payeType" 
                                #payeeType = "ngModel"
                                value="2">TPA/INSURANCE/CORPORATE/GOVT.SCHEME/NGOs
                        </label>
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class="col-md-3">
                        <label class="font-weight-bold">Payee Name: <span
                            class="requiredSymbol">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <ng-autocomplete 
                            [data]="payeeArray"
                            [searchKeyword]="searchNameKeyword"
                            placeholder="Enter the Payee's Name"
                            (selected)='autocompleteEvent($event, "payeeName")'
                            (inputChanged)='onUpdateChangeSearch($event)'
                            (inputCleared)='onRemoveSelected($event, "payeeName")'
                            (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate1"
                            [notFoundTemplate]="notFoundTemplate"
                            class="autocomplete-dropdown sm"
                            name= "payeename"
                            [(ngModel)]="updateFormData.payeName" 
                            #payeename = "ngModel"
                            >
                        </ng-autocomplete> 
                        <ng-template #itemTemplate1 let-item>
                        <a [innerHTML]="item.payeeName"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>                    
                    <span *ngIf="updatepayee.submitted && !pName && updateFormData.payeName == ''"
                    class="bg-warningtxt">Payee Name is required.</span>
                </div>
            </div>
            <div class="row mt-4 mb-3">
                <div class="col-12 text-center">
                  <button class="btn ward-btn border-btn mr-3 px-4" type="submit">Update</button>
                </div>
            </div>
</form>
   
