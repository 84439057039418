<div class="preview-container">
    <div class="row">
        <div class="col-md-12">
    
            <div id="print-section">
    
                <ng-container *ngIf="mode == 'receipt'">
                    <app-receipt-print [ipno]="ipno" [receiptno]="receiptno" ></app-receipt-print>
                </ng-container>
    
                <ng-container *ngIf="mode == 'summarybill'">
                    <app-summary-bill-print [ipno]="ipno" [umrno]="umrno" [typeofbill]="typeofbill"></app-summary-bill-print>
                </ng-container>

                <ng-container *ngIf="mode == 'admissionform'">
                    <app-admissionform-print [ipno]="ipno" [umrno]="umrno"></app-admissionform-print>
                </ng-container>

                <ng-container *ngIf="mode == 'dischargeform'">
                    <app-dischargeform-print [ipno]="ipno" [umrno]="umrno"></app-dischargeform-print>
                </ng-container>                
                
            </div>
    
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="text-align: center;">
            <button type="button" class="btn btn-primary advanced-search-btn" (click)="printMe()" >Print</button>
            <button type="button" class="btn btn-primary advanced-search-btn" (click)="closeMe()" >Close</button>
        </div>
    </div>
</div>