import { Injectable } from '@angular/core';
import {FacadeService} from '../shared/facade.service';
import {SearchBarResults} from '../shared/appointmentsModels/searchBarResults';
import {PatientProfile} from '../shared/appointmentsModels/patientProfile';
import {DoctorProfile} from '../shared/appointmentsModels/doctorProfile';
import {SearchDoctors} from '../shared/appointmentsModels/searchDoctors';
import {PatientAppointmentModel} from '../shared/appointmentsModels/patientAppointmentModel';
import {SearchHospitals} from '../shared/appointmentsModels/searchHospitals';
import {ViewAppointmentsModel} from '../shared/appointmentsModels/viewAppointmentsModel';



@Injectable({
  providedIn: 'root'
})

export class AppointmentService {
 searchResults: SearchBarResults = {
   locationId: null,
   hospitalId: null,
   doctorOrSpecialisationId: null,
   doctorNameRSpecialisation: null,
   bySpecialisation: null,
   departmentId: null
 };
 appReceivedPatient: PatientProfile;
 appSelectedDoctor: SearchDoctors;
 appallDoctorsList: SearchDoctors[];
 appSelectedSlotDate: string;
 appHospitalsDataArray: SearchHospitals[];
 appSelectedHospitaldata: SearchHospitals;
 appOnlineAppointmentType: number;
 appMaximumLimitConsultation: number = null;
 appPatientAppointmentId: number;
 appQueueType: number;
 dataLoaded: boolean;
 appLocationsArray: string[];
 appDoctorListArray: string[];
 appHospitalsArray: string[];
 appDoctorIdArray: number[];
 appHospitalIdArray: number[];
 appLocationsIdArray: string[];
 appDepartmentIdArray: number[];
 appDepartmentsArray: string[];
 appPatientForCancellation: PatientAppointmentModel;
 appPatientForReschedule: PatientAppointmentModel;
 appSelectedHospital: string;
 appSelectedHospitalId: number;
 apppatientAge: string;
 appSelectedSlot: string;
 appAddVisitPatient: PatientProfile;
 appSelectedTimeForServer: string;
 appSelectedSlotToDisplay: string;
 appSearchFields: {locationName: string, hospitalName: string, doctorName: string};
  constructor(private facadeService: FacadeService) { }
 public getAppointmentSearchLocations(paramaters: string): any {
  return   this.facadeService.getService(paramaters);
  }
  public getAppointmentSearchHospitals(paramaters: string): any{
    return this.facadeService.getService(paramaters);
  }
  public getAppointmentSearchDoctors(paramaters: string): any{
    return this.facadeService.getService(paramaters);
  }
  public fullDoctorScheduleDetails(parameters: string, data: any): any{
    return this.facadeService.postService(data, parameters);
  }
  public fullDoctorScheduleOnline(parameters: string, data: any): any{
    return this.facadeService.postService(data, parameters);
  }
  public getAppointmentViewAppointment(parameters): any{
    return this.facadeService.getService(parameters);
  }
  public getAppointmentDepartmentList(parameters): any{
    return this.facadeService.getService(parameters);
  }
  public getExternalReferralList(parameters): any{
    return this.facadeService.getService(parameters);
  }
  public getVisitTypeList(parameters): any{
    return this.facadeService.getService(parameters);
  }
  public searchPatientRegistration(parameters: string, data: any): any{
    return this.facadeService.postService(data, parameters);
  }
  public bookingTheAppointment(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getPayeeTypeDetails(parameters: string): any {
    return this.facadeService.getService(parameters);
  }

  public savePatientVisitDetails(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public addDoctorToVisit(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public gettingTheDataByDoctor(parameters): any {
    return this.facadeService.getService(parameters);
  }
  public registeringPatientIfUnavailable(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }
  public cancellingAppointment(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }
  public  gettingPatientAppointmentsByDate(parameters: string): any {
    return this.facadeService.getService(parameters);
  }
  public patientAppointmentsByNameOrUmrOrNumber(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }
  public gettingAllCitiesForPatient(parameters: string): any {
    return this.facadeService.getService(parameters);
  }
  public gettingAllCitiesForPatientWithInput(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }
  public patientCityInfoById(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }
  public gettingSingleDoctorInfoById(parameters: string): any {
    return this.facadeService.getService(parameters);
  }
  public requiredDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return funYear + '-' + this.checkingTwoDigits(funMonth) + '-' + this.checkingTwoDigits(funDay);
  }
  public checkingTwoDigits(info): string {
    const twoDig = info.toString().length < 2 ? '0' + info : '' + info;
    return twoDig;
  }

  public updateOPVisitStatus(paramaters: string): any{
    return this.facadeService.getService(paramaters);
  }

  public getPatientPreviousVisits(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

}
