<div class="land">
  <div class="container-fluid text-center">
    <div class="row justify-content-center pt-1">
        <div class="col-sm-2 my-sm-2 my-1 mx-md-2">
          <button type="button" 
                  routerLink="/Inpatient" 
                  class="btn btn-sm btnn btn-light text-dark mr-4"
                  *ngIf="rolesInfo?.subFunctionalityLevel?.ward_bed_management_in_patient_list"
          >In Patient List</button>
        </div>
        <div class="col-sm-2 my-sm-2 my-1 mx-md-2">
          <button type="button" 
            routerLink="/ipAdmission" 
            class="btn btn-sm btnn btn-light text-dark mr-4" 
            [class.active]="bodyDisplay === 'ipAdmission'"
            *ngIf="rolesInfo?.subFunctionalityLevel?.ward_bed_management_ip_admissions"
          >IP Admissions</button>
        </div>
        <div class="col-sm-2 my-sm-2 my-1 mx-md-2">
          <button type="button" 
                  (click)="dischargeList()"
                  class="btn btn-sm btnn btn-light text-dark mr-4"
                  *ngIf="rolesInfo?.subFunctionalityLevel?.ward_bed_management_ip_discharge_list"
          >IP Discharge List</button>
        </div>
        <!-- <div class="col-sm-2 my-sm-2 my-1 mx-md-2">
          <button type="button" 
                  routerLink="/nursing-staff-mangement" 
                  class="btn btn-sm btnn btn-light text-dark mr-4"
                  >Nursing Staff Management</button>
        </div> -->
        <div class="col-sm-2 my-sm-2 my-1 mx-md-2">
          <button type="button" 
            routerLink="/nursestation" 
            class="btn btn-sm btnn btn-light text-dark mr-4"
            [class.active]="bodyDisplay === 'nursestation'"
            *ngIf="rolesInfo?.subFunctionalityLevel?.ward_bed_management_nurse_stations"
            >Nurse  Stations <br></button><!-- <small>(IP Wards)</small> -->
        </div>
    </div>
  </div>
</div>