<!--<nav class="sidebar-navigation ps-container ps-theme-default ps-active-y">
<div class="land">
<div class="menu">
  <ul>
    <li class="menu-item active">
      <a class="menu-link" >
        <i class="fa fa-home" aria-hidden="true"></i>
        <span class="arrow"></span>
      </a>
    </li>
  </ul>
</div>
</div>
</nav>-->

    <nav class="navbar navbar-vertical-left "id="style-1">
      <ul class="nav navbar-nav">

        <li>
          <div class="ic-hover">
           <img class="first-ic " src="assets/labimages/Vector1.1.svg" >
          </div>
        </li>
        <li>
          <a href="#" class="two">
            <img class="first-icc ic-hover" src="assets/labimages/two.svg" >
          </a>
        </li>
        <li>
          <a (click)="navigateTo('/ipAdmission')" class="one">
            <img class="first-icc ic-hover"  src="assets/labimages/ward.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="one">
            <img class="first-icc ic-hover"  src="assets/labimages/doctorworkstation.svg" >
          </a>
        </li>
        <li>
            <div class="tooltip">
              <img  class="ic-hover first-icc " src="assets/labimages/lab.svg" >

              <span class="tooltiptext">
                <span class="modname"><a routerLink="/labmodule">Lab/Radiology Management</a></span>
                <ul class="float-left ml-3">
                  <li><i class="fa fa-caret-right ic-color" aria-hidden="true"></i>&nbsp;&nbsp;<span class="modname"><a (click)="navigateTo('/sampleCollection')" class="one lablist">Sample Data</a></span></li>
                  <!-- <li><i class="fa fa-caret-right ic-color" aria-hidden="true"></i>&nbsp;&nbsp;<span class="modname"><a routerLink="/sample2">Sample Authorization</a></span></li> -->
                  <!-- <li><i class="fa fa-caret-right ic-color" aria-hidden="true"></i>&nbsp;&nbsp;<span class="modname"><a routerLink="/result2">Result Entry/Authorization</a></span></li> -->
                </ul>
              </span>

            </div>
        </li>
        <li>
          <a href="#" class="one">
            <img class="first-icc ic-hover"  src="assets/labimages/eqmanage.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="one">
            <img class="first-icc ic-hover"  src="assets/labimages/inventory.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="two">
            <img class="first-icc ic-hover" src="assets/labimages/regop.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="for">
            <img  class="first-icc ic-hover" src="assets/labimages/operation.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="five">
            <img  class="first-icc ic-hover" src="assets/labimages/billing.svg" >

          </a>
        </li>
        <li>
          <a href="#" class="two">
            <img class="first-icc ic-hover" src="assets/labimages/catering.svg" >
          </a>
        </li>
        <li>
          <a href="#" class="two">
            <img class="first-icc ic-hover" src="assets/labimages/otherss.svg" >
          </a>
        </li>
      </ul>
    </nav>


