<app-ward-header></app-ward-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-5 bgbox pb-5 p-0">
      <div class="row mt-4 p-2">
        <div class="col-sm-6 col-md-6">
          <div class="form-group">
            <div class="input-group mb-3 inputRadius">
              <input style="font-size: 13px;"
                     placeholder="Search by Name"
                     type="text"
                     name="umrSearch"
                     class="form-control form-control-lg"/>
              <div class="input-group-append">
                <span class="input-group-text" ><i class="fas fa-search text-danger"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="form-group">
            <div class="input-group mb-3 inputRadius">
              <input style="font-size: 13px;"
                placeholder="Search by IP Nr"
                type="text"
                name="umrSearch"
                class="form-control form-control-lg"/>
              <div class="input-group-append">
                <span class="input-group-text" ><i class="fas fa-search text-danger"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row  p-2">
        <div class="col-sm-6 col-md-6">
          <div class="form-group">
            <div class="input-group mb-3 inputRadius">
              <input style="font-size: 13px;"
                placeholder="Search by UMR Number"
                type="text"
                name="umrSearch"
                class="form-control form-control-lg"/>
              <div class="input-group-append">
                <span class="input-group-text" ><i class="fas fa-search text-danger"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="modname">Name/Gender</th>
            <th class="modname">Ip Nr</th>
            <th class="modname">UMR Number</th>
            <th class="modname">Ward</th>
            <th class="modname">Bed Number</th>
            <th class="modname">Consultant Name</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="modname2">ABC/Male</td>
            <td (click)="toggle()" class="modname2"><u>1234</u></td>
            <td class="modname2">5435</td>
            <td class="modname2">General Ward</td>
            <td class="modname2">01</td>
            <td class="modname2">Dr.XYZ</td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
      <ng-container *ngIf="show">

        <div class="col-md-7 datPage align mt-4">

            <form autocomplete="off">
              <div class="row mt-2">
                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >Patient Name:</label>
                    <div class="col-sm-6">
                      <p class="modname2">Mr.RAGHAV Challa</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >Ip No:</label>
                    <div class="col-sm-6">
                      <p class="modname2">455</p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >UMR No:</label>
                    <div class="col-sm-6">
                      <p class="modname2">5433</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >Ward:</label>
                    <div class="col-sm-6">
                      <p class="modname2">General Ward</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >Bed Number:</label>
                    <div class="col-sm-6">
                      <p class="modname2">01</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group d-flex">
                    <label class="col-sm-6 modname" >Consultant Name:</label>
                    <div class="col-sm-6">
                      <p class="modname2">Dr.SATISH</p>
                    </div>
                  </div>
                </div>
              </div>
<div class="row ">
  <div class="col-sm-4">
    <div class="form-group d-flex">
      <label class="col-sm-6 modname" >Gender /Age:</label>
      <div class="col-sm-6">
        <p class="modname2">MALE/ 1 Year 2 Months 10 Days</p>
      </div>
    </div>
  </div>

</div>
            </form>
          <hr>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle float-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Options
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="/ipAdmission">Admission Form</a>
              <a class="dropdown-item" href="#">Consent Form</a>
              <a class="dropdown-item" href="#">Patient Labels</a>
              <a class="dropdown-item" href="#">MLC Form</a>
              <a class="dropdown-item" href="#">Admission Slip</a>
              <a class="dropdown-item" href="#">Transfer In /Transfer Out</a>
            </div>
          </div>
        </div>

      </ng-container>

  </div>
</div>
