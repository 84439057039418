import { Input, OnInit, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { WardService } from "src/app/ward-management/ward.service";
import { TokenStorageService } from "../loginservices/token-storage.service";


@Component({
  selector: 'app-update-payee',
  templateUrl: './update-payee.component.html',
  styleUrls: ['./update-payee.component.css']
})
export class UpdatePayeeComponent implements OnInit {

  @Input() ipPatientNO: number;
  @ViewChild('payeename') payeename;

  userInfo: any = {};
  searchNameKeyword = "payeeName";
  existPayeeInfo: any = [];
  payeeNamesList: any = [];
  updateFormData: any = {
    payeId: '',
    payeName: '',
    payeType: ''
  }
  payeeNames: any = [];
  updateSubmitFormData: any = [];
  payeeArray = [];
  payeeListArray = [];
  pName: boolean = false;
  errorMessage: boolean = false;
  constructor(          
    private tokenService: TokenStorageService,
    private wardService: WardService,
    private toastrService: ToastrService,
    private modalService: NgbModal
  )
  {
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    this.getExistingPayeeInfo();
  }

  ngOnChanges(): void {    
    
    }

  getExistingPayeeInfo(){
    const request={
      "hospitalId":this.userInfo.hospitalID,
      "IpNr":this.ipPatientNO
    } 
    this.wardService.existingPayeeInfo(request).subscribe((response) =>{
      this.existPayeeInfo = response;
      this.updateFormData.payeId = this.existPayeeInfo.payeeId;
      this.updateFormData.payeType = this.existPayeeInfo.payeeType;
      this.updateFormData.payeName = this.existPayeeInfo.payeeName;
      });    
  }

 
  updatePayeeTypeChange(type): void {
    
     this.payeeArray = [];
     this.payeeListArray = [];
     this.updateFormData.payeName = "";
     const input = {
      "hospitalId": this.userInfo.hospitalID,
      "payeeId": this.updateFormData.payeId,
      "payeeType": this.updateFormData.payeType
    }
    this.wardService.existingPayeeNamesList(input).subscribe((obj) =>{
      this.payeeNames = obj;
      if(this.payeeNames.length != 0)
      {
        this.payeeNames.forEach( payeeList => {
          this.payeeListArray.push({payeeId: payeeList.payeeId, payeeName: payeeList.payeeName});
        });
        this.payeeArray = this.payeeListArray;        
      }
      
     if (type === 1) {
       this.updateFormData.payeName = this.payeeArray[0].payeeName;
     }
    });
  }

  autocompleteEvent(item: any, field: string): void {
    this.updateFormData.payeId ='';
    if (field === 'payeeName') {
      this.updateFormData.payeId = item.payeeId;
      this.pName = true;
      this.updatePayeeTypeChange(item.payeeId);
    }
    else{
      this.pName = false;
    }
  }

  onRemoveSelected(item: any,field: string): void{
    if(field === 'payeeName'){
        this.updateFormData.payeName = '';
        this.pName = false;
    }
  }

  onUpdateChangeSearch(val: string): void {
     return this.payeeNames.filter(option => option.payeeName.toLowerCase().includes(val));
  }

  submitUpdateFormInfo(){  
    const data = {
      "hospitalId": this.userInfo.hospitalID,
      "IpNr": this.ipPatientNO,
      "payeeType": this.updateFormData.payeType,
      "payeeId": this.updateFormData.payeId,
      "payeeName": this.updateFormData.payeName.payeeName,
      "createdBy": this.userInfo.username
    }
      this.wardService.updatePayeeInfo(data).subscribe(
        (response) => {
        this.toastrService.success('Payee Updated Successfully');
        this.closePopup();
        },
        (err) => {
          this.toastrService.error('Something went wrong..!');
      });  
  }

  onFocused(e): void {
    // do something when input is focused
  }
  
  closePopup(){
    this.modalService.dismissAll();
  }

}

