import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {AppointmentService} from '../appointment.service';
import {AppointmentPatientRegistration, AppointmentPatientSearch} from '../appointmentconstants';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {PatientSearch} from '../../shared/appointmentsModels/patientSearch';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-formfilling',
  templateUrl: './formfilling.component.html',
  styleUrls: ['./formfilling.component.css']
})
export class FormfillingComponent implements OnInit{
  date = new Date();
  formDataLoaded = false;
  patientYears = 0;
  patientMonths = 0;
  patientDays = 0;
  yearError = false;
  monthError = false;
  dateError = false;
  maxDate: string;
  minDate: string;
  minYear: number;
  duplicateRecords: PatientProfile[];
  minMonth = 0;
  minDay = 0;
  maxYear: number;
  maxMonth = 11;
  maxDay = 30;
  multiPatient: PatientProfile;
  multipleRecords: PatientProfile[];
  patientAvailable = false;
  dateFormat = this.appointmentService.requiredDateFormat(this.date);
  @ViewChild('multipleRecordsModal') private multipleModal: ElementRef;
  @ViewChild('f', { static: true }) myForm;
  @ViewChild('datanotFound') private modalDataNotFound: ElementRef;
  @ViewChild('duplicateRecordsMod') private duplicate: ElementRef;
  selectedPatient: PatientProfile = { patientID: null,
    address: '',
    city: '',
    country: '',
    createDateTime: this.dateFormat + ' ' + this.date.toTimeString().substr(0, 8),
    createId: '',
    dateOfBirth: '',
    emailId: '',
    familyGroupId: '',
    familyGroupRegistration: '',
    gender: 'MALE',
    identityIdNr: '',
    identityType: '',
    mobileNumber: '',
    nextOfKinMobilenr: '',
    nextOfKinName: '',
    nextOfKinRelation: '',
    nextOfKin1Mobilenr: '',
    nextOfKin1Name: '',
    nextOfKin1Relation: '',
    patientName: '',
    firstName: '',
    lastName: '',
    permanentHomeAddress: '',
    pincode: '',
    regDate: this.dateFormat,
    stateCountry: '',
    status: 0,
    title: null
  };

  @Output() formEmitter = new EventEmitter<null>();

  public isemptySearch = false;
  public searchForm = {
    UHIDNo: '',
    mobileNumber: '',
    email: ''
  };


  constructor(private appointmentService: AppointmentService, private modalService: NgbModal) {
  }
  ngOnInit(): any {
    // this.appointmentService.getAppointmentPatientProfile(AppointmentPatientSearch + '.json')
    //   .subscribe( (patientsData: PatientProfile[]) => {
    //    this.allPatients = patientsData;
    //    console.log(this.allPatients);
    //   });
    if (this.appointmentService.appReceivedPatient !== undefined) {
      this.selectedPatient = this.appointmentService.appReceivedPatient;
      this.gettingAge(this.selectedPatient.dateOfBirth);
     }
    this.appointmentService.appReceivedPatient = undefined;
  }
  enterKeyTriggerFunction(event, umr, email, mobile): any {
    this.isemptySearch = false;
    if (umr) {
      this.searchForm.mobileNumber = '';
      this.searchForm.email = '';
    }
    else if (email) {
      this.searchForm.UHIDNo = '';
      this.searchForm.mobileNumber = '';
    }
    else if (mobile) {
      this.searchForm.UHIDNo = '';
      this.searchForm.email = '';
    }
    if (event.keyCode === 13) {
      this.assigningPatientProfile(umr, email, mobile);
    }
  }
  assigningPatientProfile(umr, email, mobile): any {
    if (this.searchForm.email === '' && this.searchForm.UHIDNo === '' && this.searchForm.mobileNumber === '') {
      this.isemptySearch = true;
      return;
    }
    let patientSearchObj: {};
    if (umr.value && !mobile.value && !email.value) {
      patientSearchObj = {
        patientID: umr.value
      };
    } else if (mobile.value && !umr.value && !email.value) {
      patientSearchObj = {
        patientMobile: mobile.value
      };
    } else if (!umr.value && !mobile.value && email.value) {
      patientSearchObj = {
        patientEmail: email.value
      };
    }
    this.loadingPatiendRecords(patientSearchObj);
  }
    loadingPatiendRecords(patientSearchObj): any {
    this.formDataLoaded = true;
    this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, patientSearchObj)
      .subscribe((data: PatientProfile[]) => {
        this.formDataLoaded = false;
        if (!data.length) {
          this.modalDataNotFound.nativeElement.click();
        }
        else {
          if (data.length === 1) {
            this.selectedPatient = data[0];
            this.gettingAge(this.selectedPatient.dateOfBirth);
            this.patientAvailable = true;
          }
          else {
            this.multipleRecords = data;
            this.modalService.open(this.multipleModal, {size: 'xl'});
            this.patientAvailable = true;
          }
        }
    });
  }
  searchingMultiplePatientRecords(data, type): any {
    let searchObj = {};
    if (type === 'num' && data.value.toString().length === 10) {
      searchObj = {
        patientMobile: data.value
      };
      this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, searchObj)
        .subscribe((patientData: PatientProfile[]) => {
          this.formDataLoaded = false;
          if (patientData.length >= 1) {
              this.multipleRecords = patientData;
              this.modalService.open(this.multipleModal, {size: 'xl'});
              this.patientAvailable = true;
            }
        });
    }
    else if (type === 'email' && data.value !== '') {
      searchObj = {
        patientEmail: data.value
      };
      this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, searchObj)
        .subscribe((patientData: PatientProfile[]) => {
          this.formDataLoaded = false;
          if (patientData.length >= 1) {
            this.multipleRecords = patientData;
            this.modalService.open(this.multipleModal, {size: 'xl'});
            this.patientAvailable = true;
          }
        });
    }
  }
  gettingAge(dobValue): any {
    const fullDate = new Date(dobValue);
    var todayDate=new Date();
    this.patientYears = todayDate.getFullYear() - fullDate.getFullYear();
    this.patientMonths = todayDate.getMonth() - fullDate.getMonth();
    this.patientDays = todayDate.getDate() - fullDate.getDate();

    if (this.patientMonths <= 0) {
      this.patientYears--;
      this.patientMonths = (12 + this.patientMonths);
    }
    if (this.patientDays < 0) {
      this.patientMonths--;
      this.patientDays = 31 + this.patientDays;
      if(this.patientDays == 31) {
        this.patientMonths++;
        this.patientDays = 0;
      }
    }
    if (this.patientMonths == 12) {
      this.patientYears = this.patientYears + 1;
      this.patientMonths = 0;
    }
    this.appointmentService.apppatientAge = this.patientYears + 'Y' + this.patientMonths + 'M' + this.patientDays + 'D';
  }
  assigningYears(data): any {
 this.patientYears = Number(data.target.value);
 if (this.patientYears === this.maxYear) {
      this.maxMonth = 0;
      this.maxDay = 0;
      this.minMonth = 0;
      this.minDay = 0;
    }
    else {
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
    }
 this.gettingDOBFromAge();
  }
  assigningMonths(data): any {
    this.patientMonths = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  assigningDate(data): any {
    this.patientDays = Number(data.target.value);
    this.gettingDOBFromAge();
  }
  gettingDOBFromAge(): any {
    const ageToSeconds = ((this.patientYears * 365.25) + (this.patientMonths * 30.41) + this.patientDays) * 86400000;
    const secondsDiff = new Date().getTime() - ageToSeconds;
    this.selectedPatient.dateOfBirth = this.appointmentService.requiredDateFormat(new Date(secondsDiff));
    this.appointmentService.apppatientAge = this.patientYears + 'Y' + this.patientMonths + 'M' + this.patientDays + 'D';
  }
  // functionality for Selecting a record from multiple records
  selectPatientFromMultiple(patientRec: PatientProfile, content): any {
    this.multiPatient = patientRec;
    this.modalService.open(content);
  }
  assignSelectedPatient(): any{
    this.selectedPatient = this.multiPatient;
    this.gettingAge(this.selectedPatient.dateOfBirth);
    this.modalService.dismissAll();
  }
formFillEmitter(): any{
    if (!this.selectedPatient.patientID) {
      const patMobileSearch = {
        patientMobile: this.selectedPatient.mobileNumber
      };
      this.selectedPatient.permanentHomeAddress = '',
      this.selectedPatient.pincode = '',
      this.selectedPatient.patientID = 0,
      this.selectedPatient.regDate = this.appointmentService.requiredDateFormat(this.date),
        this.appointmentService.searchPatientRegistration(AppointmentPatientSearch, patMobileSearch)
          .subscribe((patients: PatientProfile[]) => {
            this.duplicateRecords = patients.filter( eachPatient => {
              return (Number(eachPatient.mobileNumber) === Number(this.selectedPatient.mobileNumber)) &&
                (eachPatient.dateOfBirth === this.selectedPatient.dateOfBirth)
                && eachPatient.gender === this.selectedPatient.gender;
            });
            if (this.duplicateRecords.length > 0 && this.selectedPatient.patientID === 0) {
              this.modalService.open(this.duplicate, {size: 'xl'});
            } else {
              this.appointmentService.registeringPatientIfUnavailable(AppointmentPatientRegistration, this.selectedPatient)
                .subscribe( reply => {
                  this.selectedPatient.patientID = reply.recordID;
                });
              this.appointmentService.appReceivedPatient = this.selectedPatient;
              this.formEmitter.emit();
            }
          });
    }
    else {
      this.appointmentService.appReceivedPatient = this.selectedPatient;
      this.formEmitter.emit();
    }
  }

  changeRadioGender(event): any {
    if (this.selectedPatient.title === 'B/O.') {
      this.selectedPatient.gender = event.target.value;
    }
  }
  numberOnly(event: any, data: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    const value = data + event.key;
    if (value.length > 10) {
      return false;
    }
    return true;
  }
  changingGenderFields(): any {
    this.yearError = false;
    this.monthError = false;
    this.dateError = false;
    if (this.selectedPatient.title === 'Master.' || this.selectedPatient.title === 'Mr.') {
      this.selectedPatient.gender = 'MALE';
    }
    else if (this.selectedPatient.title === 'B/O.') {
      this.selectedPatient.gender = '';
    }
    else{
      this.selectedPatient.gender = 'FEMALE';
    }
    this.selectedPatient.dateOfBirth = '';
    this.patientYears = 0;
    this.patientMonths = 0;
    this.patientDays = 0;
    this.settingMinnMaxDates();
  }
  settingMinnMaxDates(): any {
    const presentYear = this.date.getFullYear();
    const presentMOnth = ((this.date.getMonth() + 1).toString().length < 2) ?
      '0' + (this.date.getMonth() + 1) : (this.date.getMonth() + 1);
    const presentDate = (this.date.getDate().toString().length < 2) ?
      '0' + this.date.getDate() : this.date.getDate();
    if (this.selectedPatient.title === 'B/O.') {
      this.minDate = presentYear - 1 + '-' + presentMOnth + '-' + presentDate;
      this.maxDate = this.appointmentService.requiredDateFormat(this.date);
      this.maxYear = 1;
      this.minYear = 0;
    }
    else if (this.selectedPatient.title === 'Master.' || this.selectedPatient.title === 'Miss.') {
      this.maxDate = this.minDate = presentYear - 2 + '-' + presentMOnth + '-' + presentDate;
      this.minDate = presentYear - 12 + '-' + presentMOnth + '-' + presentDate;
      this.maxYear = 12;
      this.minYear = 2;
    }
    else {
      this.maxDate = presentYear - 13 + '-' + presentMOnth + '-' + presentDate;
      this.minDate = null;
      this.maxYear = 150;
      this.minYear = 13;
    }
  }
  checkInputDate(event): boolean {
    this.settingMinnMaxDates();
    const recValue = event.target.value;
    const recyear = parseInt(recValue, 10).toString();
    if ((recValue.length >= 10) && (recValue > this.maxDate) && (recyear.length >= 4) ){
      this.selectedPatient.dateOfBirth = this.maxDate;
      this.gettingAge(this.maxDate);
      this.yearError = true;
      return false;
    }
    if ((recValue.length >= 10) && (recValue < this.minDate)  && (recyear.length >= 4)){
      this.selectedPatient.dateOfBirth = this.minDate;
      this.gettingAge(this.minDate);
      this.yearError = true;
      return false;
    }
    this.yearError = false;
    return true;
  }
  // tslint:disable-next-line:typedef
  resetData(): any{
    this.searchForm.UHIDNo = '';
    this.searchForm.email = '';
    this.searchForm.mobileNumber = '';
  }
  checkingByYear(event): boolean {
    if (Number(event.target.value) >= this.maxYear) {
      this.yearError = (Number(event.target.value) > this.maxYear);
      this.patientYears = this.maxYear;
      this.maxMonth = 0;
      this.maxDay = 0;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();
      return false;
    }
    else {
      this.maxMonth = 11;
      this.maxDay = 30;
      this.minDay = 0;
      this.minMonth = 0;
      this.gettingDOBFromAge();

    }
    if (Number(event.target.value) <= this.minYear) {
      // this.patientYears = this.minYear;
      this.yearError = (Number(event.target.value) < this.minYear);
      this.minMonth = 0;
      this.minDay = 0;
      this.maxMonth = 11;
      this.maxDay = 30;
      this.gettingDOBFromAge();
      return false;
    }
    this.yearError = false;
    return true;
  }

  checkingByMonth(event): boolean {
    if (Number(event.target.value) >= this.maxMonth) {
      this.monthError = (Number(event.target.value) > this.maxMonth);
      this.patientMonths = this.maxMonth;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minMonth) {
      this.monthError = (Number(event.target.value) < this.minMonth);
      this.patientMonths = this.minMonth;
      this.gettingDOBFromAge();
      return false;
    }
    this.monthError = false;
    return true;
  }

  checkingByDay(event): boolean {
    if (Number(event.target.value) >= this.maxDay) {
      this.dateError = (Number(event.target.value) > this.maxDay);
      this.patientDays = this.maxDay;
      this.gettingDOBFromAge();
      return false;
    }
    if (Number(event.target.value) <= this.minDay) {
      this.dateError = (Number(event.target.value) < this.minDay);
      this.patientDays = this.minDay;
      this.gettingDOBFromAge();
      return false;
    }
    this.dateError = false;
    return true;
  }

}
