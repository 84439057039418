import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slotbooking',
  templateUrl: './slotbooking.component.html',
  styleUrls: ['./slotbooking.component.css']
})
export class SlotbookingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
