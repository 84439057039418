import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {TokenStorageService} from './token-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router, private tokenStorageService: TokenStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this.tokenStorageService.getToken()) {
        // this.isLoggedIn = true;
        // this.roles = this.tokenStorageService.getUser().roles;
        return true;
      }else {
        this.router.navigate(['/']);
        return false;
      }
    }
}
