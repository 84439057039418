import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageCalculation'
})
export class AgeCalculationPipe implements PipeTransform {
  patientDobYear:number = 0;
  patientDobMonth:number = 0;
  patientDobDate:number = 0;
  patientAge: string;

  transform(value: string, ...args: unknown[]): unknown {
    
    const fullDate = new Date(value);
    var todayDate=new Date();
    this.patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
    this.patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
    this.patientDobDate = todayDate.getDate() - fullDate.getDate();

    if (this.patientDobMonth <= 0) {
      this.patientDobYear--;
      this.patientDobMonth = (12 + this.patientDobMonth);
    }
    if (this.patientDobDate < 0) {
      this.patientDobMonth--;
      this.patientDobDate = 31 + this.patientDobDate;
      if(this.patientDobDate == 31) {
        this.patientDobMonth++;
        this.patientDobDate = 0;
      }
    }  
    if (this.patientDobMonth == 12) {
      this.patientDobYear = this.patientDobYear + 1;
      this.patientDobMonth = 0;
    }
    this.patientAge = this.patientDobYear + 'Y ' + this.patientDobMonth + 'M ' + this.patientDobDate + 'D';
    return this.patientAge;
  }

}
