import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { BillingService } from '../billing.service';
import { LoadServices,SingleService} from '../billingconstants';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  public show = false;
  public rolesInfo: any = [];
  public doctorServicesList: any = [];
  public selectedServices: any = [];
  public userInfo: any = {};
  public searchServiceName: any = '';
  public allServices: any = [];
  public selectedAllServices: any = [];
  public searchServiceNameVal = '';
  public searchServiceType = '';

  constructor(private billingService: BillingService, private tokenService: TokenStorageService) {

    this.userInfo = this.tokenService.getUser();
    this.rolesInfo = this.tokenService.getUser().rolesInfo;
  }

  ngOnInit(): void {
    this.searchServiceData();
  }
  /*gotoBilling(){
    this.router.navigate(['/billing']);
  }*/

  public onSelectService(serviceObj: any): any {
    if (serviceObj.isSelected) {
      if (serviceObj.serviceUnitQty === 0) {
        serviceObj.serviceUnitQty = 1;
      }
      serviceObj.totalPrice = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      serviceObj.serviceNetAmt = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      this.selectedServices.push(serviceObj);
    } else {
      const hasObj = this.selectedServices.filter(obj => obj.serviceName === serviceObj.serviceName);
      if (hasObj.length !== 0) {
        const index = this.selectedServices.indexOf(hasObj[0]);
        this.selectedServices.splice(index, 1);
      }
      if (serviceObj.ismain) {
          const index = this.doctorServicesList.indexOf(serviceObj);
          this.doctorServicesList.splice(index, 1);
      }
    }
  }



  public searchServiceData(): any {
        const searchJson: any = {
            gender: 'BOTH',
            hospitalId: this.userInfo.hospitalID,
            serviceName: ''
        };
        this.allServices = [];
        this.selectedAllServices = [];
        this.billingService.getAllServices(LoadServices, searchJson)
        .subscribe(response => {
          if (response !== null) {
            this.allServices = response;
            this.selectedAllServices = response;
          }
        });
  }

  public servicenameSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    }else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceName.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceName.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }
  }

  public serviceTypeSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    } else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    } else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    }
  }

  public selectService(): any {
    const serviceObj = this.allServices.filter(obj => obj.serviceName === this.searchServiceName);
    this.selectedAllServices.push(serviceObj[0]);
    this.searchServiceName = '';
    this.allServices = [];
  }

  public addService(service: any): any {
    const serviceJson = {
      hospitalId: this.userInfo.hospitalID,
      serviceMasterId : service.smId,
      visitId: 0
    };
    this.show = true;
    const hasService = this.doctorServicesList.filter(obj => obj.serviceMasterID === service.smId);
    if (hasService.length === 0) {
      this.billingService.getServiceDetail(SingleService, serviceJson)
        .subscribe(response => {
          console.log(response);
          if (response !== null) {
            const obj = response[0];
            this.doctorServicesList = [];
            if (obj !== undefined) {
              obj.ismain = true;
              obj.isSelected = true;
              response[0].serviceTypeId = service.serviceTypeId;
              this.doctorServicesList.push(response[0]);
              this.onSelectService(response[0]);
            }
          }
      });
    }
  }
}
