import {Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {AppointmentService} from '../appointment.service';
import {DoctorProfile} from '../../shared/appointmentsModels/doctorProfile';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {AppointmentBookingAppointment, AppointmentCancellingAppointment} from '../appointmentconstants';
import {PatientAppointmentModel} from '../../shared/appointmentsModels/patientAppointmentModel';
import {ServerReply} from '../../shared/appointmentsModels/serverReply';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bookingappointment',
  templateUrl: './bookingappointment.component.html',
  styleUrls: ['./bookingappointment.component.css']
})
export class BookingappointmentComponent implements OnInit {
@ViewChild('f', { static: true }) myForm;
@Output() bookingEmit = new EventEmitter(null);
@Output() detailsEmitter = new EventEmitter();
receivedpatientProfile: PatientProfile;
receivedDoctorProfile: SearchDoctors;
patientAppointmentType: string;
slotTimereceived: string;
bookAppointmentLoaded = false;
slotDatereceived: string;
todayDate = new Date();
timeSlotExpired = false;
appointmentType = 'Consultation';
  constructor(private appointmentService: AppointmentService, private router: Router,
    private toastr: ToastrService) {
  }

  ngOnInit(): any {
    this.receivedpatientProfile = this.appointmentService.appReceivedPatient;
    this.receivedDoctorProfile = this.appointmentService.appSelectedDoctor;
    this.slotTimereceived = this.appointmentService.appSelectedSlot;
    this.slotDatereceived = this.appointmentService.appSelectedSlotToDisplay;
  }
  bookingAppointmentEmitter(remarks): any {
      const patientBookingDetails: PatientAppointmentModel = {
      doctorID: this.receivedDoctorProfile.doctorId,
      doctorName: this.receivedDoctorProfile.doctorName,
      doctorMaxLimitConsultation: this.appointmentService.appMaximumLimitConsultation,
      doctorQueuetype: this.appointmentService.appQueueType,
      hospitalID: this.appointmentService.appSelectedHospitalId || this.appointmentService.searchResults.hospitalId,
      hospitalName: this.appointmentService.appSelectedHospital,
      patientAge:  this.appointmentService.apppatientAge,
      onlineAppointmentType: this.appointmentService.appOnlineAppointmentType,
      patientAppointmentCreatedDateTime: this.gettingDateTimeFormat(this.todayDate) + ' ' + this.gettingTimeFormat(this.todayDate),
      patientAppointmentDate: this.appointmentService.appSelectedSlotDate,
      patientAppointmentID: null,
      patientAppointmentStatus: 'Pending',
      patientAppointmentTime: this.appointmentService.appSelectedTimeForServer,
      patientAppointmentType: this.appointmentType,
      patientCreatedID: '',
      patientDateOfBirth: this.receivedpatientProfile.dateOfBirth,
      patientEmail: this.receivedpatientProfile.emailId,
      patientGender: this.receivedpatientProfile.gender,
      patientID: this.receivedpatientProfile.patientID,
      patientName: this.receivedpatientProfile.patientName,
      patientPhone: this.receivedpatientProfile.mobileNumber,
      patientRemarks: remarks.value};
      if ((this.todayDate.toTimeString().substr(0, 8) > this.appointmentService.appSelectedTimeForServer) &&
        this.appointmentService.requiredDateFormat(this.todayDate) === this.appointmentService.appSelectedSlotDate) {
        this.timeSlotExpired =  true;
      }
      else {
        if (this.appointmentService.appPatientForReschedule) {
          this.appointmentService.appPatientForReschedule.patientAppointmentStatus = 'Cancelled';
          this.appointmentService.cancellingAppointment(AppointmentCancellingAppointment, this.appointmentService.appPatientForReschedule)
            .subscribe((reply: ServerReply) => {
              if (reply.errorMessage === null) {
                this.bookingAppointment(patientBookingDetails);
                this.appointmentService.appPatientForReschedule = undefined;
              } else {
                alert('Unable to Reschedule Appointment');
              }
            });
        } else {
          this.bookingAppointment(patientBookingDetails);
        }
      }
  }
  bookingAppointment(patientBookingDetails): any {
    this.bookAppointmentLoaded = true;
    this.appointmentService.bookingTheAppointment(AppointmentBookingAppointment, patientBookingDetails)
      .subscribe( reply => {
        if (reply.errorMessage === null) {
          this.bookAppointmentLoaded = false;
          patientBookingDetails.patientAppointmentID = reply.recordID ;
          this.appointmentService.appPatientAppointmentId = reply.recordID;
          this.appointmentService.appPatientForCancellation = patientBookingDetails;
          this.bookingEmit.emit();
          this.toastr.success('Appointment booked successfully!');
        }
        else {
          this.bookAppointmentLoaded = false;
          this.toastr.error(reply.errorMessage);
        }
      });
  }
  redirectionForExpiredSlot(): any{
    this.timeSlotExpired = false;
    this.router.navigate(['/specialisation']);
  }
  gettingDateTimeFormat(date): string {
   let month = '' + (date.getMonth() + 1);
   let  day = '' + date.getDate();
   const year = date.getFullYear();

   if (month.length < 2) {
      month = '0' + month;
    }
   if (day.length < 2) {
     day = '0' + day;
   }
   return [year, month, day].join('-');
  }
gettingTimeFormat(timeData): string {
    const timeArr = timeData.toLocaleTimeString().split(' ');
    if (timeArr[1] === 'AM') {
      return timeData.toLocaleTimeString().split(' ')[0];
    }
    else {
     const eveTimesARR = timeArr[0].split(':');
     eveTimesARR[0] = Number(eveTimesARR[0]) + 12;
     return eveTimesARR.join(':');
    }

}

backToDetails(): any {
  this.detailsEmitter.emit();
}

}
