<app-ward-header></app-ward-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 col-sm-4 col-lg-4 p-0">
      <div class="sidebox">
        
        
      </div>
    </div>
    <ng-container>
      <div class="col-md-8 col-lg-8 col-sm-8 mt-4">
        Hi
      </div>
    </ng-container>
  </div>
</div>

