
<div class="bgImage">
  <app-searchbar></app-searchbar>
<div class="container-md mt-3">

  <div class="row ">
    <div class="col-md-6 col-lg-6 text-center">

      <div  class="font-weight-bolder bestDoctors mb-0 text-white h2 text-center mt-3">
       <p class="text-md-left land_page-font mb-0">Best Doctors</p>
        <p class="text-md-left land_page-font mb-0">At the best Hospitals</p>
        <p class="text-md-left land_page-font mb-0">Search and Book now</p>
        <button routerLink="/viewappointments" class="btn btn-md mt-3" style="background-color:#173267;float: left;"><span class="h6" style="color: #e6e6e6">View Appointments</span></button>
      </div>
    </div>
    <!--<div class="offset-lg-2 col-md-6  col-lg-4">
      <div id="upcomingAppointment" class="border text-left border-secondary p-2 rounded-lg bg-light text-dark mt-md-5 text-dark">
        <p class="text-center h5"> Upcoming Appointments</p>
        <p>Doctor name: </p>
        <p>Patient name: </p>
        <p>Appointment Date: </p>
      </div>
    </div>-->
  </div>
  </div>


</div>

