import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToWords } from 'to-words';

import * as SecureLS from 'secure-ls';

import * as moment from 'moment';
import { FacadeService } from '../facade.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  patientDobYear = 0;
  patientDobMonth = 0;
  patientDobDate = 0;
  patientAge: string;

  private ls = new SecureLS({ encodingType: 'aes' });

  constructor(private facadeService: FacadeService) { }

  private patientDetails = new Subject<any>();

  displayPatientDetails(event): void {
    this.patientDetails.next(event);
  }

  get events$(): any {
    return this.patientDetails.asObservable();
  }

  gettingAge(dobValue): any {
    const fullDate = new Date(dobValue);
    const todayDate = new Date();
    this.patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
    this.patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
    this.patientDobDate = todayDate.getDate() - fullDate.getDate();

    if (this.patientDobMonth <= 0) {
      this.patientDobYear--;
      this.patientDobMonth = (12 + this.patientDobMonth);
    }
    if (this.patientDobDate < 0) {
      this.patientDobMonth--;
      this.patientDobDate = 31 + this.patientDobDate;
      if (this.patientDobDate === 31) {
        this.patientDobMonth++;
        this.patientDobDate = 0;
      }
    }
    if (this.patientDobMonth === 12) {
      this.patientDobYear = this.patientDobYear + 1;
      this.patientDobMonth = 0;
    }
    this.patientAge = this.patientDobYear + 'Y ' + this.patientDobMonth + 'M ' + this.patientDobDate + 'D';
    return this.patientAge;
  }

  formatDate(date, split?): any {
    let d;
    if (date === '') {
      d = new Date();
    } else {
      d = new Date(date);
    }

    let month = '' + (d.getMonth() + 1);
    let  day = '' + d.getDate();
    const  year = d.getFullYear() !== null ? d.getFullYear() : 0;

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if(split)
      return [year, month, day].join(split);
    else 
      return [year, month, day].join('-');
  }

  dateFormatForDatepicker(date)
  {
    let d;
    if (date === '') {
      d = new Date();
    } else {
      d = new Date(date);
    }

    let month = '' + (d.getMonth() + 1);
    let  day = '' + d.getDate();
    const  year = d.getFullYear() !== null ? d.getFullYear() : 0;

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day

  }

  /** convert number to word */
  backupToWords(s): any {
    const th = ['', 'thousand', 'million', 'billion', 'trillion'];
    const dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s !== parseFloat(s)) { return 'not a number'; }
    let x = s.indexOf('.');
    if (x === -1) { x = s.length; }
    if (x > 15) { return 'too big'; }
    const n = s.split('');
    let str = '';
    let sk = 0;
    for (let i = 0; i < x; i++)
    {
        if ((x - i) % 3 === 2)
        {
            if (n[i] === '1')
            {
                str += tn[Number(n[i + 1])] + ' ';
                i++;
                sk = 1;
            }
            else if (n[i] !== 0)
            {
                str += tw[n[i] - 2] + ' ';
                sk = 1;
            }
        }
        else if (n[i] !== 0)
        {
            str += dg[n[i]] + ' ';
            if ((x - i) % 3 === 0) { str += 'hundred '; }
            sk = 1;
        }

        if ((x - i) % 3 === 1)
        {
            if (sk) { str += th[(x - i - 1) / 3] + ' '; }
            sk = 0;
        }
    }
    if (x !== s.length)
    {
        const y = s.length;
        str += 'point ';
        for (let i: number = x + 1; i < y; i++) { str += dg[n[i]] + ' '; }
    }
    return str.replace(/\s+/g, ' ');


  }

  toWords(amount): any {
    if (amount > 1) {
      const toWord = new ToWords();
      const words = toWord.convert(amount, { currency: true });
      return words;
    }
    else {
      return '';
    }

  }

  openWindow(url): any {

    // const newwindow = window.open(url, '_blank', 'location=yes,height=570,width=1100,scrollbars=yes,status=yes');
    const newwindow = window.open(url, '_blank');
    if (window.focus) { newwindow.focus(); }
    return false;

  }

  removePrintData(): void {

    this.ls.removeAll();

  }

  public get getPrintData(): any {
    return this.ls.get('printdata');
  }

  public setPrintData(data: any): any {

    this.ls.remove('printdata');

    return this.ls.set('printdata', data);

  }

  getCurrentDate(): any {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    return year + '-' + month + '-' + day;
  }

  // Convert DateTime format //
  formatDateTime(date): any {

    const options = {timeZone: 'Asia/Kolkata' };
    const today = date.toLocaleString('en-IN', options);
    console.log(today);
    return moment(today).format('MM-DD-YYYY HH:mm:ss');

  }

  // Convert Date format //
  formatDateOnly(date): any {

    const options = {timeZone: 'Asia/Kolkata' };
    const today = date.toLocaleString('en-IN', options);

    return moment(today).format('MM-DD-YYYY');

  }

  dateFormate(date, formate)
  {
    const options = {timeZone: 'Asia/Kolkata' };
    const today = date.toLocaleString('en-IN', options);
    let finalDate = moment(today).format(formate);
    return finalDate;
  }

  // Convert Date format //
  formatTimeOnly(date): any {

    const options = {timeZone: 'Asia/Kolkata' };
    const today = date.toLocaleString('en-IN', options);

    return moment(today).format('HH:mm:ss');

  }

  public patentDemographicDetails(parameters: string, inputRequest: any) {
    return this.facadeService.postService(inputRequest, parameters);
  }

}
