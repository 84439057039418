import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../services/Loader/loader.service';
import { LoaderState } from './loader.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  show = false;

  isFullPage = false;

  private subscription: Subscription;
  mylocation: any;

  constructor(private loaderService: LoaderService, private location: Location) {

    this.mylocation = this.location;

  }

  ngOnInit(): void {

    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show;

      const currentURL = this.mylocation.path();
      const currentURLArr = currentURL.split('/');
      const currentPage = '/' + currentURLArr[2];

      this.isFullPage = false;

    });

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {

    this.subscription.unsubscribe();
    this.isFullPage = false;

  }

}
