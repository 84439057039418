import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/loginservices/auth.service';
import {TokenStorageService} from '../../shared/loginservices/token-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-himscontent',
  templateUrl: './himscontent.component.html',
  styleUrls: ['./himscontent.component.css']
})
export class HimscontentComponent implements OnInit {
  private roles: string[];
  public rolesInfo: any = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  showUserBoard = false;
  showDoctorBoard = false;
  username: string;
  constructor(private authService: AuthService, private tokenStorageService: TokenStorageService) {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorageService.getUser().roles;
      this.rolesInfo = this.tokenStorageService.getUser().rolesInfo;
      // console.log("roles list", this.rolesInfo);

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_FRONTEND');
      this.showUserBoard = this.roles.includes('ROLE_USER');
      this.showDoctorBoard = this.roles.includes('ROLE_DOCTOR');
      this.username = this.tokenStorageService.getUser().username;
    }
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_FRONTEND');
      this.showUserBoard = this.roles.includes('ROLE_USER');
      this.showDoctorBoard = this.roles.includes('ROLE_DOCTOR');
      this.username = user.username;
    }
  }

  hasDoctorRole(): boolean {
    return this.showDoctorBoard;
  }
  hasUserRole(): boolean {
    return this.showUserBoard;
  }
  hasAdminRole(): boolean {
    return this.showAdminBoard;
  }
  hasModeratorRole(): boolean {
    return this.showModeratorBoard;
  }

}
