<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">
        <div class="row page-header">

            <div class="col-md-11 text-center">
                <h4 class="text-uppercase bold font-weight-bold orange-color">
                   ESSENTIALITY CERTIFICATE INFORMATION
                </h4>
            </div>
            <div class="col-md-1 text-center" style="line-height: 80px;">
                <button type="button" (click)="backNav()" class="btn border-btn mr-3 px-4">Back</button>
            </div>

        </div>
       
        <div class="row justify-content-center mb-2">
            <div class="col-md-6">
                <div class="text-right mt-2">
                    <i class="fas fa-edit close handCursor" (click)="enableEditOption()" *ngIf="editButton" style="color: #173267"
                    title="click here to edit form"></i>
                </div>
                <form class="row" (ngSubmit)="check.form.valid && onSubmit()" #check="ngForm">

                    <div class="col-12 pt-3">
                         <div class="col">
                            <label class="font-weight-bold"> Hospital Final Bill </label>
                            <input type="text" id="finalbill" class="form-control form-control-sm"
                            name="finalbill"  [readOnly]="!editOption"
                            [(ngModel)]="EssentialityForm.finalBill" #finalBill="ngModel"
                            (blur)="getTotal()" appOnlyNumberdecimal numericType="decimal2digits"
                            [ngClass]="{ 'is-invalid': check.submitted && finalBill.invalid }"                           
                            required/>
                            
                            <div *ngIf="check.submitted && finalBill.invalid" class="invalid-feedback">                                
                                Final Bill is required
                            </div>
                        </div>
                    </div>
                    <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold"> Investigation Charges </label>
                            <input type="text" id="investcharges" class="form-control form-control-sm" 
                            name="investcharges" [(ngModel)]="EssentialityForm.investCharges" #investCharges="ngModel"
                            (blur)="getTotal()" [readOnly]="!editOption" 
                            appOnlyNumberdecimal numericType="decimal2digits" 
                            [ngClass]="{ 'is-invalid': check.submitted && investCharges.invalid }"
                             required/>
                            
                            <div *ngIf="check.submitted && investCharges.invalid" class="invalid-feedback">                                
                                Inverstigation charges are required
                            </div>
                        </div>
                    </div>                    
                    <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold">Drugs & Disposable Charges</label>
                            <input type="text" id="drugscharges" class="form-control form-control-sm"
                            name="drugscharges" [(ngModel)]="EssentialityForm.drugsCharges" #drugsCharges="ngModel"
                            (blur)="getTotal()" [readOnly]="!editOption" 
                            appOnlyNumberdecimal numericType="decimal2digits"
                            [ngClass]="{ 'is-invalid': check.submitted && drugsCharges.invalid }"
                            required/>

                            <div *ngIf="check.submitted && drugsCharges.invalid" class="invalid-feedback">
                                Drugs & Disposuble charges are required
                            </div>
                        </div>
                    </div>
                    <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold">Other Charges</label>                
                            <input type="text" id="othercharges" class="form-control form-control-sm"
                            name="othercharges" [(ngModel)]="EssentialityForm.otherCharges" #otherCharges="ngModel"
                            (blur)="getTotal()" [readOnly]="!editOption" 
                            appOnlyNumberdecimal numericType="decimal2digits"
                            [ngClass]="{ 'is-invalid': check.submitted && otherCharges.invalid }"
                            required/>

                            <div *ngIf="check.submitted && otherCharges.invalid" class="invalid-feedback">                                
                                Other charges are required
                            </div>
                        </div>
                    </div>
                    <div class="col-12  pt-3">
                        <div class="col">
                            <label class="font-weight-bold">Grand Total</label>                
                            <input type="text" id="grandtotal" class="form-control form-control-sm"
                            name="grandtotal" [(ngModel)]="EssentialityForm.grandTotal" 
                            #grandTotal="ngModel" [readOnly]="!editOption" appOnlyNumberdecimal numericType="decimal2digits"
                            [ngClass]="{ 'is-invalid': check.submitted && grandTotal.invalid}"
                             required/>

                            <div *ngIf="check.submitted && grandTotal.invalid" class="invalid-feedback">                                
                                Grand total is required
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3 text-right">
                      <button type="submit" [hidden]="existingRecord" class="btn btn-primary mr-3">Submit</button>
                      <button type="button" *ngIf="existingRecord" (click)="updatingForm()"class="btn btn-primary mr-3">Save</button>
                      <button *ngIf="existingRecord" (click)="openEssentialityPopup(essentialityPopup)" 
                      type="button" class="btn btn-primary mr-3">View & Print</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Essentiality certificate popup start -->
<ng-template #essentialityPopup let-modal>
    <div class="modal-header">
      <!-- <h4 class="modal-title">ESSENTIALITY CERTIFICATE</h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-right">
        <button (click)="printBill();popupClose()" type="button" class="btn btn-primary mr-3">Print</button><!-- *ngIf="existingRecord" -->
      </div>
        <div>
            <h3 class="text-center font-weight-bold"><u>ESSENTIALITY CERTIFICATE</u></h3><br><br><br><br>
            <p style="font-size: 18px;" class="text-justify">&nbsp;&nbsp;&nbsp;This is to certify that <b>{{patientInfo.Pname}}</b>, aged <b>{{patientInfo.Age}}</b>
                .S/O.D/O <b>{{patientInfo.SoDoRelation}}</b>, <b>IPNO: {{patientInfo.IPNO}}</b> has been under my treatment for <b>{{patientInfo.Diagnosis}}</b>. 
                Disease from <b>{{patientInfo.Doa | date: "dd-MM-yyyy"}}</b> to <b>{{patientInfo.Dod | date: "dd-MM-yyyy"}}</b>. In the Hospital/Consulting
                Room and that the under medicines prescribed by me in this connection were essential for recovery / prevention of serious denotation in the 
                condition of the patient. The medicines are not stocked in this hospital for supply to the patient and do not include proprietary preparation 
                for which cheaper substance of equal therauptic value are available not preparations which are primarily foods, toilets or disinfection.
            </p><br><br><br><br><br>

            <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="col-2">S.NO</th>
                    <th class="col-7">PARTICULARS</th>
                    <th class="col-3">AMOUNT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="col-2">01</td>
                    <td class="col-7">HOSPITAL FINAL BILL</td>
                    <td class="col-3">{{patientInfo.HospitalFinalBill}}</td>
                </tr>
                <tr>
                    <td class="col-2">02</td>
                    <td class="col-7">INVESTIGATION CHARGES</td>
                    <td class="col-3">{{patientInfo.InvestigationCharges}}</td>
                </tr>
                <tr>
                    <td class="col-2">03</td>
                    <td class="col-7">DRUGS & DISPOSABLE CHARGES</td>
                    <td class="col-3">{{patientInfo.DrugsDisposableCharges}}</td>
                </tr>
                <tr>
                    <td class="col-2">04</td>
                    <td class="col-7">OTHER CHARGES</td>
                    <td class="col-3">{{patientInfo.OtherCharges}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td class="pr-3 text-right font-weight-bold">GRAND TOTAL</td>
                    <td class="font-weight-bold">{{patientInfo.GrandTotal}}</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
</ng-template>
<!-- End -->

<!-- Essentiality form print -->
<div id="essentiality-form-print" style="display: none;" class="print-form"><!-- style="display: none;" -->
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-12" style="margin-top: 2.5in;">
                <!-- <br><br><br><br><br><br> -->
                <h3 class="text-center font-weight-bold"><u>ESSENTIALITY CERTIFICATE</u></h3><br><br><br><br>
                <p style="font-size: 22px;" class="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This is to certify that <b>{{patientInfo.Pname}}</b>, aged 
                <b>{{patientInfo.Age}}</b> .S/O.D/O <b>{{patientInfo.SoDoRelation}}</b>, <b>IPNO: {{patientInfo.IPNO}}</b> 
                has been under my treatment for <b>{{patientInfo.Diagnosis}}</b>. Disease from <b>{{patientInfo.Doa | date: "dd-MM-yyyy"}}</b>
                to <b>{{patientInfo.Dod | date: "dd-MM-yyyy"}}</b>. In the Hospital / Consulting Room and that the under 
                medicines prescribed by me in this connection were essential for recovery / prevention of serious denotation 
                in the condition of the patient. The medicines are not stocked in this hospital for supply to the patient and 
                do not include proprietary preparation for which cheaper substance of equal therauptic value are available not
                preparations which are primarily foods, toilets or disinfection.
                </p><br><br><br><br><br>          
                
                <div class="col-md-12">
                    <div class="row head-info">
                        <div class="col-md-1" style="border-right: 1px solid black;">S.NO</div>
                        <div class="col-md-8" style="border-right: 1px solid black;">PARTICULARS</div>
                        <div class="col-md-3">AMOUNT</div>
                    </div>
                    <div class="row body-info">
                        <div class="col-md-1" style="border-right: 1px solid black;">01</div>
                        <div class="col-md-8" style="border-right: 1px solid black;">HOSPITAL FINAL BILL</div>
                        <div class="col-md-3">{{patientInfo.HospitalFinalBill}}</div>
                    </div>
                    <div class="row body-info">
                        <div class="col-md-1" style="border-right: 1px solid black;">02</div>
                        <div class="col-md-8" style="border-right: 1px solid black;">INVESTIGATION CHARGES</div>
                        <div class="col-md-3">{{patientInfo.InvestigationCharges}}</div>
                    </div>
                    <div class="row body-info">
                        <div class="col-md-1" style="border-right: 1px solid black;">03</div>
                        <div class="col-md-8" style="border-right: 1px solid black;">DRUGS & DISPOSABLE CHARGES</div>
                        <div class="col-md-3">{{patientInfo.DrugsDisposableCharges}}</div>
                    </div>
                    <div class="row body-info">
                        <div class="col-md-1" style="border-right: 1px solid black;">04</div>
                        <div class="col-md-8" style="border-right: 1px solid black;">OTHER CHARGES</div>
                        <div class="col-md-3" >{{patientInfo.OtherCharges}}</div>
                    </div>
                    <div class="row" style="border: 1px solid black; padding: 10px;">
                        <div class="col-md-1" style="border-right: 1px solid black;"></div>
                        <div class="col-md-8 text-right" style="border-right: 1px solid black; font-size: 22px; font-weight: bolder;"><b>GRAND TOTAL</b></div>
                        <div class="col-md-3" style="font-size: 22px; font-weight: bolder;"><b>{{patientInfo.GrandTotal}}</b></div>
                    </div>
                </div>
                <div class="col-md-12 text-right font-weight-bold" style="font-size: 20px; padding-right: 20px; margin-top: 2in;">
                    for PARAMITHA CHILDRENS HOSPITAL PVT LTD.
                </div>
            
            </div>
        </div>
    </div>
</div>
