<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">
  
      <div class="row page-header">      
        <!-- <div class="col-1">
          <div class="info-count-box">
            <div class="text-center info-box-number info-box-primary-text mb-0">
              <i class="fa fa-calendar-check"></i>
            </div>
            <div class="text-center info-box-text">Calendar</div>
          </div>
        </div> -->
        
        <div class="col-md-9 text-center">
          <h4 class="text-uppercase bold font-weight-bold orange-color">List of Date Wise Services Charged</h4>
        </div>
        <div class="col-md-3 text-center" style="line-height: 80px;">
          <button type="button" (click)="gotoNav()" class="btn border-btn mr-3 px-4">Bill Summary</button>
          <button type="button" (click)="navigate()" class="btn border-btn mr-3 px-4">Back to list</button>
        </div>
  
        <!-- <div class="col-1">
          <div class="info-count-box">
            <div class="text-center info-box-number info-box-primary-text mb-0">
              <span class="fa-stack fa-stack-adjust" data-notification-count="9">
                <i class="fa fa-bell"></i>
              </span>
            </div>
            <div class="text-center info-box-text">Todo</div>
          </div>
        </div> -->
  
      </div>
      <div class="row mt-4" *ngIf="finalBillStatus == 'Y'">
        <div class="col-12 m-auto">
          <span class="holdservicealert">Final bill closed for this Patient</span>
        </div>
      </div>
      <!-- Patient Info -->
      <div class="row mt-1">
        <div class="col-10 m-auto">
          <app-patient-info [ipPatientNO]="ipPatientNO" [patientURMNo]="patientURMNo">                    
          </app-patient-info>
        </div>
      </div>
      <!-- ./END Patient Info -->
  
      <div class="row justify-content-center mb-2">
        <div class="col-12">
        <div *ngIf="patientServiceList.length === 0">
          <div class="text-center font-weight-bold border-top border-dark"><h4 class="mt-3">No records found</h4></div>
        </div>
          <div class="matcontent" *ngFor="let value of dataArray;index as i">
                  <div class="p-4"><b class="mr-4">Service Charged Date</b>: {{patientServiceList[i].ServiceDate}}</div>
            <mat-table #table [dataSource]="value" matSort class="example-table mat-elevation-z8 inner-scroll">
      
              <ng-container matColumnDef="SNO">
                <mat-header-cell class="centre" *matHeaderCellDef mat-sort-header >
                  <div>SNO</div>
                </mat-header-cell>
                <mat-cell class="centre" mat-cell *matCellDef="let element; let i = index;" >
                  <div>{{ i+1 }}</div>
                </mat-cell>
              </ng-container>
  
           
              <ng-container matColumnDef="CREATED_BY">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                  <div>Created By</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.CREATED_BY }} ({{element?.CREATE_DATETIME | date: 'dd/MM/yy HH:mm' }})</div>
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="SERVICE_NAME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                  <div>Service Name</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.SERVICE_NAME }}</div>
                </mat-cell>
              </ng-container>
  
              <ng-container matColumnDef="UNIT">
                <mat-header-cell *matHeaderCellDef class="centre" mat-sort-header>
                  <span>Unit</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="centre">
                  <span>{{element?.SERVICE_UNIT_QTY}}</span>
                </mat-cell>
              </ng-container>
  
              <ng-container matColumnDef="SERVICE_TYPE_NAME">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>
                  <div>Service Type</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.SERVICE_TYPE_NAME }}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="SM_ID">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                  <div>SM_ID</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.SM_ID }}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="SERVICE_PRESCRIBED_BY_DOCTOR">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                  <div>Service Prescribed by Doctor</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.PRESCRIBING_DOCTOR }}</div>
                </mat-cell>
              </ng-container>
              <!-- <ng-container matColumnDef="REMARKS">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                  <div>Remarks</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.PRESCRIBING_DOCTOR }}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="PRIORITY">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                  <div>Priority</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div>{{ element?.PRESCRIBING_DOCTOR }}</div>
                </mat-cell>
              </ng-container> -->
              <ng-container matColumnDef="BILL_PROCESSED">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                  <div>Bill Processed</div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div *ngIf="element?.BILL_PROCESSED === 'Y'">Yes</div>
                  <div *ngIf="element?.BILL_PROCESSED === 'N'">No</div>
                </mat-cell>
              </ng-container>
  
              <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let element; columns: columnsToDisplay"></mat-row>
            </mat-table>
  
            <mat-paginator [length]="value.length"
            [pageSize]="value.length"
            [pageSizeOptions]="[10, 20, 30]">
            </mat-paginator>
          </div>
  
        </div>
      </div>
    </div>  
  </div>