/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BillingService } from 'src/app/billing-module/billing.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxPrinterService } from 'ngx-printer';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { AppointmentService } from 'src/app/appointments/appointment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { formatCurrency } from '@angular/common';
@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  styleUrls: ['./vaccination.component.css']
})
export class VaccinationComponent implements OnInit {
 
  vaccineForm: FormGroup;
  rangeFormGroup: FormGroup;
  submitted = false;
  maxDate: string;
  date = new Date().getTime();
  toDayDate = new Date();
  public userInfo: any = [];
  public vaccinesList: any = [];
  public selectedVaccineInfo: any = [];
  public printDetails: any = [];
  public existingPatientInfo: any = {};
  patientInfo: any = [];
  existingPatientData: any = [];
  patient_pvid: any = '0';
  patient_aadhaarNo: any = '0';
  patient_printedBy: any = '';
  existingPatients: any = [];
  existingPatientsAvailability = false;
  formFillPatient: any =[];
  patientData: any = []; 
   model = {
    vaccineTypeName: '',
    vaccineTypeId: '',
    doseType: '',
    ptname: '',
    gender: '',
    aadhaarNo: '',
    dob: '',
    mobileNo: '',
    cowinNo: '',
    discount: '',
    paymentMode: '',
    transactionId: ''
  };  
  editOption = false;
  editButton = false;
  dataLoaded = false;
  patientsData: any = [];
  toggleOnOff = 'nothing';
  mobileValue: string = null;
  nameValue: string = null;
  vaccineAmount: any = '';
  hospitalAmount: any = '';
  indexSelected: number;
  selectedVisitIdArray = {};
  vaccineSelected = false;
  totalAmount = 0;
  discountAmount = 0;
  total:any = 0.00;
  damnt = false;
  startDate: string;
  endDate: string;
  sdate: any;
  edate: any;
  discount: any;
  patientsList: any = [];

  @ViewChild('patientsListPopup') patientsListInfo;
  amountInfo: any = {};
  collection: any;
  patientList: any = [];
  p_pvid: any;
  

  constructor(
    private appointmentService: AppointmentService,
    private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private billingService: BillingService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.userInfo = this.tokenService.getUser();
    // console.log(this.userInfo);
  }

 

  ngOnInit(): void {

    this.vaccineForm = new FormGroup({
      vaccineType: new FormControl('', [Validators.required]),
      doseType: new FormControl('', [Validators.required]),
      ptname: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      aadhaarNo: new FormControl('', [Validators.required, Validators.maxLength(12)]),
      mobileNo: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      cowinNo: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      discount: new FormControl('', [Validators.required]),
      paymentMode: new FormControl('', [Validators.required]),
      transactionId: new FormControl('', [Validators.required])
    });

    this.rangeFormGroup = new FormGroup({  
      start: new FormControl('', [Validators.required]),  
      end: new FormControl('', [Validators.required])  
    });
    this.getMaxDate();
    //this.getAllVaccinesList();
    this.getSelectedVaccineData();
    // this.getPatientsList();
  }
  getMaxDate(): void {
    const presentYear = this.toDayDate.getFullYear();
    const myDate = new Date();
    this.maxDate = this.appointmentService.requiredDateFormat(new Date(myDate.setFullYear(presentYear)));
  }

  /* To get all vaccinType Names */
  getAllVaccinesList(): void {
    const input = {
      vaccineId: ''
    };
    this.billingService.getVaccinesList(input).subscribe((list) => {
      //console.log(list);
      this.vaccinesList = list;
    });
  }

  get f(): any { return this.vaccineForm.controls; }
  get d(): any { return this.rangeFormGroup.controls; }

  /* to get selected VaccineType Name */
  getSelectedVaccineData(): void {
    const input = {
      vaccineId: '1'
    };
    this.billingService.getSelectedVaccineInfo(input).subscribe((selectedVaccine) => {
      //console.log(selectedVaccine);
      this.selectedVaccineInfo = selectedVaccine;
      this.vaccineSelected = true;
      this.vaccineAmount = this.selectedVaccineInfo[0].vaccinePrice;
      this.hospitalAmount = this.selectedVaccineInfo[0].hospitalPrice;
    });
  }

  /* submitting dates to get date waise patients list */
  getDateWiseList(form): void {
    const input = {
      start: '',
      limit: '',
      mobileNo: '',
      aadhaarNo: '',
      fromDate: form.value.start, 
      toDate: form.value.end 
    };
    this.billingService.getVaccinisedPatientsList(input).subscribe((response) => {
      this.amountInfo = response;
      this.patientsList = this.amountInfo.data;
      this.collection = Number((response.cashTotal + response.cardTotal + response.walletTotal)-response.discountTotal);
      this.patientsListPopup();
    });
  }
  
  /* Patients List Popup code */
  patientsListPopup(){
    this.modalService.open(this.patientsListInfo,{size:'xl'});
  }
  patientListPopupClose(){
    this.modalService.dismissAll();
  }

  /* Vaccination form submitting code */
  submitData(form): void {
    this.submitted = true;
    this.editButton = false;
    this.discountAmount= form.value.discount;
    const input = {
      vaccineTypeId: this.selectedVaccineInfo[0].vaccineTypeId /* '1' */,
      vaccineTypeName: this.selectedVaccineInfo[0].vaccineTypeName /* 'COVISHIELD' */,
      doseType: form.value.doseType,
      patientName: form.value.ptname,
      aadhaarNo: form.value.aadhaarNo,
      mobileNo: form.value.mobileNo,
      cowinNo: form.value.cowinNo,
      gender: form.value.gender,
      DOB: form.value.dob,
      discountAmount: form.value.discount,
      paymentMode: form.value.paymentMode,
      transcationId: form.value.transactionId,
      createUser: this.userInfo.username
    };
    this.billingService.submitFormData(input).subscribe((response) => {

      if(response.recordstatus == 1){
        this.toastr.success(response.message);
        this.existingPatientInfo = response;
        this.patient_pvid = this.existingPatientInfo.pvid;
        this.patient_aadhaarNo = this.existingPatientInfo.aadhaarNo;
        this.patient_printedBy = this.existingPatientInfo.printedBy;
        this.getFormPrint();
        this.submitted = false;
      }
      else{
        this.toastr.error(response.message);
      }
      
    });

  }
  
  /* get Print after submit the Form */
  getFormPrint(): void {
    const patient_pvid = this.patient_pvid;
    const patient_aadhaarNo = this.patient_aadhaarNo;
    const patient_printedBy = this.patient_printedBy;

    const request = {
      pvid: patient_pvid,
      aadhaarNo: patient_aadhaarNo,
      printedBy: patient_printedBy
    };
    this.billingService.vaccinationDataPrint(request).subscribe((printdata) => {
      console.log(printdata);
      this.printDetails = printdata;
      this.totalAmount = this.printDetails.ttlAmount;
      const discount = this.printDetails.discountAmount;
      if(discount != 0 && discount <= this.totalAmount){
        this.damnt = true;
        
        this.total = (this.totalAmount - discount).toFixed(2);
        console.log(this.total);
      }
      else{
        this.total= this.printDetails.ttlAmount;
      }      

      setTimeout(() => {
        this.printBill();
      }, 2000);

      this.setValuestoEmpty();
    });

  }  

  /* setValuestoEmpty(): void {
    this.vaccineForm.reset();
    this.editButton = false;
    this.editOption = false;
  } */

  checkingName(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      /* this.textCount = ''; */
      return true;
    }
    if (event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }

  checkingMobileNumber(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      /* this.textCount = ''; */
      return true;
    }
    if (event.target.value.toString().length >= 10 || event.keyCode === 38 || event.keyCode === 40) {
      return false;
    }
    return true;
  }

  keyUpEnterFunction(event, idRec): any {
    if (idRec === 'name') {
      this.mobileValue = '';
    }
    else if (idRec === 'mobile') {
      this.nameValue = '';      
    }
    if (event.keyCode === 13) {
      this.searchByNumber(event.target, idRec);
    }
  }

  searchByNumber(recNumber, type): any {
    this.toggleOnOff = 'nothing';
    let patientObj = {};
    
    if (type === 'name' && recNumber.value.length > 2) {
      this.dataLoaded = true;
      patientObj = {
        start: '',
        limit: '',
        mobileNo: '',
        fromDate: '',
        toDate: '',
        patientName: recNumber.value,
      };
      this.gettingSinglepatient(patientObj);      
    }
    else if (type === 'mobile') {
      this.dataLoaded = true;
      patientObj = {
        start: '',
        limit: '',
        aadhaarNo: '',
        fromDate: '',
        toDate: '',
        mobileNo: recNumber.value
      };
      this.gettingSinglepatient(patientObj);
    }
  }

  gettingSinglepatient(receivedParam): any {

    this.patientsData = [];
    
    this.billingService.getVaccinisedPatientsList(receivedParam)  
      .subscribe( patients => {
        console.log(patients);        
        if(patients.data != "" ){
          this.patientList = patients.data;
          this.patientList.forEach( eachPatient => {
          this.patientsData.push(eachPatient);
          console.log(this.patientsData);          
        });
        
      }else{
        this.toastr.error('No Records Found');
      }
    });
  }

  /* PatientInfoModal Popup code */
  getPatientInfoModal(patientInfoModal, element){    
    this.modalService.open(patientInfoModal,{ size: 'lg', backdrop: 'static' });
    this.selectedPatientInfo(element);
  }

  selectedPatientInfo(element){    
    this.p_pvid=element.pvid;

    const request = {
      pvid: element.pvid,
      aadhaarNo: element.adharNo,
      printedBy: element.createdBy
    };
    this.billingService.vaccinationDataPrint(request).subscribe((printdata) => {
      console.log(printdata);
      this.patientInfo = printdata;
    });
  }

  /* to get print for a Vaccinised patient of pantientInfoModal */
  getPrint(pInfo){
     const patient_pvid = this.patientInfo.pvid;

    const request = {
      pvid: this.p_pvid,
      aadhaarNo: pInfo.adharNo,
      printedBy: pInfo.createdBy
    };
    this.billingService.vaccinationDataPrint(request).subscribe((printdata) => {
      console.log(printdata);
      this.printDetails = printdata;
      const discount = this.printDetails.discountAmount;
      if(discount != 0){
        this.damnt = true;        
        this.total = (this.printDetails.ttlAmount - discount).toFixed(2);
        console.log(this.total);
      }
      else{
        this.total= this.printDetails.ttlAmount;
      }

      setTimeout(() => {
        this.printBill();
      }, 2000);
    });
  }
 
  public printBill(): any {

    const htmlele = document.getElementById('print-section');
    htmlele.style.display = 'block';
    this.printerService.printDiv('print-section');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);

  }

  public printPatientsList(): any {

    const htmlele = document.getElementById('patients-list');
    htmlele.style.display = 'block';
    this.printerService.printDiv('patients-list');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000);

  }

  /* getting patient data to edit the form */
  gettingPatientData(patientid, recIndex): any{
    // this.show = true;
    // this.existingPatientsAvailability = false;
    this.editButton = true;
    this.editOption = true;

    Object.keys(this.model).forEach( keys => {
      this.model[keys] = '';
      /* if (keys === 'status') {
        this.model[keys] = 1;
      } */
    });
    /* if (this.indexSelected === recIndex || this.indexSelected === undefined || (this.indexSelected !== recIndex && this.toggleOnOff === 'nothing')) {
      //this.toggleOnOff = 'registration'; // (this.toggleOnOff === 'registration') ? 'nothing' : 'registration';
      this.indexSelected = null;
    } */
    this.indexSelected = recIndex;
    const requestData = {
        start: '',
        limit: '',
        mobileNo: '',
        fromDate: '',
        toDate: '',
        aadhaarNo: patientid
    };
    this.billingService.getVaccinisedPatientsList(requestData)
      .subscribe( patients => {
        console.log(patients);
        if (patients !== null && patients.length > 0) {
          this.patientData = patients[0];   
          this.vaccineForm.patchValue({
            vaccineType: this.patientData.vaccineType,
      doseType: this.patientData.doseType,
      ptname: this.patientData.patientName,
      gender: this.patientData.gender,
      dob: this.formatDate(new Date(this.patientData.dob)) ,
      aadhaarNo:this.patientData.adharNo,
      mobileNo: this.patientData.mobileNo,
      cowinNo: this.patientData.cowinNo,
      discount: this.patientData.discountAmount,
      paymentMode: this.patientData.paymentMode,
      transactionId: this.patientData.transactionId
          })       
        }
      });
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  setValuestoEmpty(){
    this.nameValue = '';
    this.mobileValue = '';
    this.patientsData = [];
    this.vaccineForm.reset();
    this.rangeFormGroup.reset();   
  }  
}
