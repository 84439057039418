import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {DoctorProfile} from '../../shared/appointmentsModels/doctorProfile';
import {AppointmentService} from '../appointment.service';
import {PatientProfile} from '../../shared/appointmentsModels/patientProfile';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {AppointmentCancellingAppointment} from '../appointmentconstants';
import {ServerReply} from '../../shared/appointmentsModels/serverReply';
import {SearchHospitals} from '../../shared/appointmentsModels/searchHospitals';

@Component({
  selector: 'app-confirmationmessage',
  templateUrl: './confirmationmessage.component.html',
  styleUrls: ['./confirmationmessage.component.css']
})
export class ConfirmationmessageComponent implements OnInit {
  conSelectedDoctor: SearchDoctors;
  conSelectedpatient: PatientProfile;
  selectedSlot: string;
  selectedDate: string;
  modalString: string;
  receivedHospital: SearchHospitals;
  receivedAppointmentId: number;
  constructor(private appointmentService: AppointmentService) { }
@Output() confirmationEmitter = new EventEmitter(null);
  ngOnInit(): any {
    this.conSelectedDoctor = this.appointmentService.appSelectedDoctor;
    this.conSelectedpatient = this.appointmentService.appReceivedPatient;
    this.selectedSlot = this.appointmentService.appSelectedSlot;
    this.selectedDate = this.appointmentService.appSelectedSlotToDisplay;
    this.receivedAppointmentId = this.appointmentService.appPatientAppointmentId;
    this.receivedHospital = this.appointmentService.appSelectedHospitaldata;
    this.appointmentService.appReceivedPatient = undefined;
  }
  cancellingAppointment(): any{
  this.modalString = 'something';
  this.appointmentService.appPatientForCancellation.patientAppointmentStatus = 'Cancelled';
  this.appointmentService.cancellingAppointment(AppointmentCancellingAppointment, this.appointmentService.appPatientForCancellation)
    .subscribe( (reply: ServerReply) => {
      if (reply.errorMessage === null) {
       this.modalString = 'cancellingAppointment';
       console.log(reply);
      }
      else {
        alert('Please Check and try again');
      }
    });
  }
  confirmationMessageEmitter(): any {
    this.appointmentService.appPatientForReschedule = this.appointmentService.appPatientForCancellation;
    this.appointmentService.appPatientForCancellation = undefined;
    this.confirmationEmitter.emit();
  }
}
