<button type="button" id="videoPopup"  #btnpopup class="btn btn-info" data-toggle="modal" data-target="#videoCallPopup"
 [style.display]="'none'" data-backdrop="static">Open Popup</button>


<div class="modal fade" id="videoCallPopup">
    <div class="modal-dialog modal modal-dialog-centered videoModel">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title text-primary" style="margin:0 auto;">Video Call</h6>
                <a class="btn btn-sm btn-danger" data-dismiss="modal" (click)="stopVideo()"
                    style="position: absolute;top:0;right: 0;width: 25px;height: 25px;padding: 2px">
                    <span class="p-0" style="height: 20px">&times;</span>
                </a>
            </div>
            <div class="modal-body w-75 d-flex justify-content-around mx-auto my-md-5">
                <div [id]="localCallId" class="local">

                </div>
                <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote"></div>
            </div>
        </div>
    </div>
</div>