<div class="row">
    <div class="col-6 mx-auto mt-5">
        <input type="file" id="file" multiple 
        (change)="getFileDetails($event)">

        <button (click)="uploadFiles()">Upload</button>

        <div *ngFor="let file of myFiles">
            <div>*  {{file.name}} - {{file.type}} - {{file.size}}</div> 
          </div>
    </div>
</div>
