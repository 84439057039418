import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LabService } from 'src/app/lab-module/lab.service';
import { SampleIdResultService } from 'src/app/lab-module/labconstants';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { WardService } from 'src/app/ward-management/ward.service';
import { textChangeRangeIsUnchanged } from 'typescript';
import { TokenStorageService } from '../loginservices/token-storage.service';

@Component({
  selector: 'app-view-lab-reports',
  templateUrl: './view-lab-reports.component.html',
  styleUrls: ['./view-lab-reports.component.css']
})
export class ViewLabReportsComponent implements OnInit {

  @Input() patientIPNo: number;
  @Input() patientUMRNo: number;

  public userInfo: any = {};
  public patientInfo: any = {};
  doctorsList: any[];
  initialValue: any;
  walkin: boolean;
  selectedAllServices: any = [];
  labServiceResult: any = [];
  sampleIdsList: any = {};
  resultIndex: number = 0;
  selectedTestName: any = [];
  units: any;
  normal_ranges: any;
  remarks: any;
  methodology: any;
  labSampleTextEditor: boolean;
  labSampleResults: any = [];
  labResultStausSequenceNo: any = [];
  labResultServices: any = [];
  subTestHeading: any = [];
  subTestServices: any = [];
  subTestInnerServices: any = [];
  html: string;
  selectedDoctor: string;
  selectedreportingDoctorID: number;
  form: any;
  labResBtn: any;
  labAllServiceResult: any;
  public labServiceType:any = "";
  public selServiceName:any = "";
  public labSequenceObj = {
    "patientType": "view_IP",
    "visitID" : 0,
    "patientID" : 0, 
    "ipNr" : 0,
    "sampleId" : 0,
    "serviceName" :"" 
  }

  constructor(
    private inpatientService: InpatientService,
    private tokenService: TokenStorageService,
    private toastrService: ToastrService,
    private wardService: WardService,
    private labService: LabService,
    private modalService: NgbModal,
    ) {
      this.userInfo = this.tokenService.getUser();
     }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void{
    this.getPatientInfo();
    //this.getLabServicesList();
  }

  public getPatientInfo(): any {

    const inputRequest = {
      sear_umr_no: this.patientUMRNo,
      sear_ip_no: this.patientIPNo,
      sear_hospitalid: this.userInfo.hospitalID
    };

    this.inpatientService.ipPatientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        this.getLabServicesList();
      });
  }

  getLabServicesList(){
    
    const postObj = {
      ipNr : this.patientIPNo
      //ipNr : 201319
    }
    this.labSequenceObj.ipNr = this.patientIPNo;
    this.wardService.getIpLabServiceList(postObj).subscribe(
      (response)=>{
        this.selectedAllServices = response;        
    });
  }

  getSampleIds(element: any){
    
    //console.log(element);
    this.selServiceName = element.serviceName;
    const postObj = {
      ipNr : this.patientIPNo,
      serviceName : element.serviceName //"CBP (COMPLETE BLOOD PICTURE)"//
    }
    this.wardService.getSmapleIdsByIpNr(postObj).subscribe(
      (data)=>{
        this.sampleIdsList = data;
        this.resultIndex = 0;
        console.log(this.sampleIdsList);
      });
  }

  /* getSampleIdResults(element){
    //console.log(element);
    const postObj = {
      sampleId: element.sampleId //322002353  
    }
    this.wardService.getSubtestsByService(postObj).subscribe(
      data => {
        this.labServiceResult = data
        console.log(this.labServiceResult);
      });     
  } */

  public getSampleIdResults(sampleIdsPopup, element){
   
    this.labSequenceObj.sampleId = element.sampleId;
    this.labSequenceObj.patientID = this.patientUMRNo;
    this.labSequenceObj.serviceName = this.selServiceName;

    this.labSampleTextEditor = false;
    this.labSampleResults = [];
    this.labResultStausSequenceNo = [];
    this.labResultServices = [];
    this.subTestHeading = [];
    this.subTestServices = [];
    this.subTestInnerServices = [];
    this.html = "";
    this.selectedDoctor = "";
    this.selectedreportingDoctorID = 0;
    /* this.form = new FormGroup({
      editorContent: new FormControl("", Validators.required())
    }); */
    // console.log(this.labSequenceObj);
    // return;
    this.labService.getLabServiceResultEntry(SampleIdResultService, this.labSequenceObj)
    .subscribe(response => {
      // this.labServiceResult = response.labResult;
      this.labSampleTextEditor = response.labTextEditorStatus;
      if(this.labSampleTextEditor) {
        this.labServiceType  = "textEditor";
        // this.html = response.labResult.RESULT_TEXT_VALUE;
        // this.form.value.editorContent = response.labResult.RESULT_TEXT_VALUE;
      } else {
        this.labServiceType  = response.serviceType;
        this.labServiceResult = response.labResult;
      } 
      this.modalService.open(sampleIdsPopup, { size: 'lg', backdrop: 'static' });
      //console.log(this.labServiceResult);
    });
  }

}
