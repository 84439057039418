export const radiologyoutPatientList = '/Radiology/outPatientsList';
export const radiologyipPatientList = '/Radiology/ipPatientList';
export const updateIpResultEntry = '/Radiology/updateIpResultEntry';
export const updateOpResultEntry = '/Radiology/updateOpResultEntry';
export const insertOpResultEntry = '/Radiology/insertOpResultEntry';
export const insertIpResultEntry = '/Radiology/insertIpResultEntry';
export const GET_RADIOLOGY_TEMPLATE = '/ReportTemplates/getTemplateInfo'
export const GET_TEMPLATES_LIST = '/ReportTemplates/getAllReportTemplates'
export const GetResultEntry = '/Radiology/getResultEntry';
export const getReportedDoctorsList = '/CommonController/getReportingDoctorsList'
