import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TokenStorageService} from '../../shared/loginservices/token-storage.service';

@Component({
  selector: 'app-ward-header',
  templateUrl: './ward-header.component.html',
  styleUrls: ['./ward-header.component.css']
})
export class WardHeaderComponent implements OnInit {
  bodyDisplay = 'ipAdmission';

  public rolesInfo: any = [];

  constructor(  private router: Router, private tokenStorageService: TokenStorageService) {
    if (this.tokenStorageService.getToken()) {
      this.rolesInfo = this.tokenStorageService.getUser().rolesInfo;
    }
   }

  ngOnInit(): void {
  }

  dischargeList(): void {
    const queryParam = { mode: 'dischargelist' };
    this.router.navigate(['/Inpatient'], {  queryParams: queryParam })
  }
}
