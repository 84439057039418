// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

function getEnv() {
  const hostName: string = window.location.hostname
  const returnResult = {
    apiUrl: '',
    hostUrl: ''
  }
  console.log("host name", hostName)
  switch(hostName)
  {
    case 'devhost.igzolt.in':
      returnResult.apiUrl = 'https://devapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://devapi.igzolt.in/'
      return returnResult

    case 'chintalparamitha.igzolt.in':
      returnResult.apiUrl = 'https://chintalparamithaapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://chintalparamithaapi.igzolt.in/'
      return returnResult

    case 'lsch.igzolt.in':
      returnResult.apiUrl = 'https://lschapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://lschapi.igzolt.in/'
      return returnResult

    case 'dlnparamitha.igzolt.in':
      returnResult.apiUrl = 'https://dlnparamithaapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://dlnparamithaapi.igzolt.in/'
      return returnResult

    case 'chnparamitha.igzolt.in':
      returnResult.apiUrl = 'https://chnparamithaapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://chnparamithaapi.igzolt.in/'
      return returnResult

    case 'kparamitha.igzolt.in':
      returnResult.apiUrl = 'https://kparamithaapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://kparamithaapi.igzolt.in/'
      return returnResult

    case 'paramithaclinics.igzolt.in':
      returnResult.apiUrl = 'https://paramithaclinicsapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://paramithaclinicsapi.igzolt.in/'
      return returnResult

    default:
      returnResult.apiUrl = 'https://devapi.igzolt.in/index.php/'
      returnResult.hostUrl = 'https://devapi.igzolt.in/'
    return returnResult
  }
}

export const environment = {
  production: false,
  agora: {
    appId: '4cb8692aba194d97b0ae2841a75dd856',
  },
  apiUrl: getEnv().apiUrl,
  hostUrl: getEnv().hostUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
