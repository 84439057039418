import { environment } from 'src/environments/environment';

export const serverUrl = environment.apiUrl;
export const GET_DOCTOR_LIST = 'Doctor/getAllDoctors';
export const GET_TEMPLATES_LIST = 'v1/ReportTemplates/getAllReportTemplates';
export const GET_SERVICE_TYPE_LIST = 'v1/ReportTemplates/serviceTypesList';
export const GET_SERVICE_NAME_LIST = 'v1/ReportTemplates/serviceNamesList';
export const POST_TEMPLATES_DATA = 'v1/ReportTemplates/insertTemplate';
export const UPDATE_TEMPLATES_DATA = 'v1/ReportTemplates/updateTemplate';
export const GET_TEMPLATE_BY_ID = 'v1/ReportTemplates/getTemplateInfo'
export const ADD_REFERRAL_DOCTOR = 'Api/addExternalReferral';
export const MasterMng_URL = {
    GET_DOCTOR_LIST: serverUrl+ GET_DOCTOR_LIST,
    GET_TEMPLATES_LIST : serverUrl + GET_TEMPLATES_LIST,
   GET_SERVICE_TYPE_LIST: serverUrl + GET_SERVICE_TYPE_LIST,
    GET_SERVICE_NAME_LIST: serverUrl + GET_SERVICE_NAME_LIST,
    POST_TEMPLATES_DATA: serverUrl + POST_TEMPLATES_DATA,
    UPDATE_TEMPLATES_DATA: serverUrl + UPDATE_TEMPLATES_DATA,
    GET_TEMPLATE_BY_ID: serverUrl + GET_TEMPLATE_BY_ID,
    ADD_REFERRAL_DOCTOR: serverUrl + ADD_REFERRAL_DOCTOR
    
};

