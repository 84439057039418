import {RestService} from './rest.service';
import {UrlsettingService} from './urlsetting.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  constructor(private restservice: RestService, private urlsettingservice: UrlsettingService) {
  }

  public getService(...params: string[] ): any{
    const myData = this.restservice.getservice(this.urlsettingservice.getServerURL(), ...params);
    return this.restservice.getservice(this.urlsettingservice.getServerURL(), ...params);
  }

  public getServiceText(...params: string[] ): any{
    return this.restservice.getserviceText(this.urlsettingservice.getServerURL(), ...params);
  }

  public postService(data: any, ...params: string[] ): any{
    return this.restservice.postservice(this.urlsettingservice.getServerURL(), data, params);
  }
  // REST function from here
  // tslint:disable-next-line:typedef
  public deleteEntry(dn) {
    return this.restservice.deleteEntry(dn);
  }

  // tslint:disable-next-line:typedef
  public saveQuery(data) {
    return this.restservice.saveQuery(data);
  }

  // tslint:disable-next-line:typedef
  public loadQuery() {
    return this.restservice.loadQuery();
  }

  // tslint:disable-next-line:typedef
  public deleteQuery(name) {
    return this.restservice.deleteQuery(name);
  }

  // tslint:disable-next-line:typedef
  public calculateRights(trusteeName: string, objectName: string, properties: string[]) {
    return this.restservice.calculateRights(trusteeName, objectName, properties);
  }

  // public searchQuery(data) {
  //   return this.restservice.searchQuery(data);
  // }

  // public logInfo(data) {
  //   return this.restservice.logInfo(data);
  // }


  // tslint:disable-next-line:typedef
  public getObjectClassDefList() {
    return this.restservice.getObjectClassDefList();
  }

  // tslint:disable-next-line:typedef
  public getObjectClassDef(type) {
    return this.restservice.getObjectClassDef(type);
  }

  // tslint:disable-next-line:typedef
  public postObjectClassDef(data) {
    return this.restservice.postObjectClassDef(data);
  }

  // tslint:disable-next-line:typedef
  public putObjectClassDef(data) {
    return this.restservice.putObjectClassDef(data);
  }

  // tslint:disable-next-line:typedef
  public deleteObjectClassDef(name) {
    return this.restservice.deleteObjectClassDef(name);
  }

  // tslint:disable-next-line:typedef
  public postEntry(data, dn) {
    return this.restservice.postEntry(data, dn);
  }

  // tslint:disable-next-line:typedef
  public postSession(data, url) {
    return this.restservice.postSession(data, url);
  }

  // tslint:disable-next-line:typedef
  public getEntry(dn) {
    return this.restservice.getEntry(dn);
  }

  // tslint:disable-next-line:typedef
  public genericSearch(data, params: string[]) {
    return this.restservice.genericSearch(data, params);
  }

  // tslint:disable-next-line:typedef
  public getEntry1(dn) {
    return this.restservice.getEntry1(dn);
  }

  // tslint:disable-next-line:typedef
  public getAttributeDefList() {
    return this.restservice.getAttributeDefList();
  }

  // tslint:disable-next-line:typedef
  public postAttributeDef(data) {
    return this.restservice.postAttributeDef(data);
  }

  // tslint:disable-next-line:typedef
  public deleteAttributeDef(name) {
    return this.restservice.deleteAttributeDef(name);
  }

  // tslint:disable-next-line:typedef
  public getSyntaxDefList() {
    return this.restservice.getSyntaxDefList();
  }

  // tslint:disable-next-line:typedef
  public getSyntaxDef(data) {
    return this.restservice.getSyntaxDef(data);
  }

  // tslint:disable-next-line:typedef
  public getAttributeDef(data) {
    return this.restservice.getAttributeDef(data);
  }

  // tslint:disable-next-line:typedef
  public searchEntries(data, params) {
    return this.restservice.searchEntries(data, params);
  }

  // tslint:disable-next-line:typedef
  public deleteSession(session) {
    return this.restservice.deleteSession(session);
  }

  // tslint:disable-next-line:typedef
  public patchEntry(data, dn) {
    return this.restservice.patchEntry(data, dn);
  }

  // tslint:disable-next-line:typedef
  public searchPages(url) {
    return this.restservice.searchPages(url);
  }

  // tslint:disable-next-line:typedef
  public getEndPoints(url) {
    return this.restservice.getEndPoints(url);
  }

  // tslint:disable-next-line:typedef
  public getAuthURL(url) {
    return this.restservice.getAuthURL(url);
  }

  // tslint:disable-next-line:typedef
  public searchContexts(data, params) {
    return this.restservice.searchContexts(data, params);
  }
}
