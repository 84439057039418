import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { InpatientService } from '../inpatient.service';

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.css']
})
export class EmergencyComponent implements OnInit {

  @Input() ipPatientNO: number;
  
  userInfo: any = {};
  patientInfo: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private toastrService: ToastrService

  ) { 
    this.userInfo = this.tokenService.getUser();
  }

  ngOnInit(): void {
    this.getPatientInfo();
  }

  getPatientInfo() {
    const input = {
      Ipno: this.ipPatientNO,
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName,
      EssentialStatusUpdateStatus: "N"
    }
    this.inpatientService.essentialCertificateInfo(input).subscribe(
      (response)=>{
        this.patientInfo = response;
      });
  }

  printBill(){

    const htmlele = document.getElementById('essentiality-form-print');
    htmlele.style.display = 'block';
    this.printerService.printDiv('essentiality-form-print');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000); 
  }

}
