import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacadeService } from '../shared/facade.service';

@Injectable({
  providedIn: 'root'
})
export class RadiologyService {
  public patientInfo:any;
  public patientType: any;
  public params;
  constructor(private facadeService: FacadeService, private http: HttpClient) { }
 
  
  public getSampleCollection(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getLabPatientServiceByID(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveLabServicesRequest(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getLabServiceResultEntry(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public saveLabResultEntryServices(parameters: string, data: any): any {
    return this.facadeService.postService(data, parameters);
  }

  public getReportingDoctorsList(parameters, data) {
    return this.facadeService.postService(data, parameters);
  }

  public getRadiologyTemplate(url, data)
  {
    return this.facadeService.postService(data, url);
  }

  public getTemplatesList(data, url)
  {
    return this.facadeService.postService(data, url)
  }


}
