import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/billing-module/billing.service';
import { InpatientService } from 'src/app/viewinpatient-module/inpatient.service';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-credit-bill-submission',
  templateUrl: './credit-bill-submission.component.html',
  styleUrls: ['./credit-bill-submission.component.css']
})
export class CreditBillSubmissionComponent implements OnInit {

  @Output() closePopup = new EventEmitter();

  @Input() ipPatientNO: number;
  @Input() patientURMNo: number;

  public userInfo: any = {};
  // patientURMNo: number;
  // ipPatientNO: number;
  public patientInfo: any;
  date: any;
  showErrorMessage = false;
  creditBillSubmissionForm;
  creditBillSubmissionData;
  preAuthList;
  invalidRange = false;
  selectedValue: number;

  sentONMin = '';
  sentONMax = '';
  remarkFormData: any = {
    remark: '',
  };
  remarksList: any = [];
  receivedONMin = '';
  receivedONMax = '';

  constructor(private billingService: BillingService,
              private formBuilder: FormBuilder,
              private tokenService: TokenStorageService,
              private inpatientService: InpatientService,
              private route: ActivatedRoute,
              private toastrService: ToastrService
  ) {
    this.userInfo = this.tokenService.getUser();

    const today = new Date();
    this.sentONMin = moment(today).format('YYYY-MM-DDTHH:MM');
    this.sentONMax = moment(today).format('YYYY-MM-DDTHH:MM');

    this.receivedONMin = moment(today).format('YYYY-MM-DDTHH:MM');
    this.receivedONMax = moment(today).format('YYYY-MM-DDTHH:MM');

  }

  ngOnInit(): void {
    this.creditBillSubmissionForm = this.formBuilder.group({
      billSubmittedOn: ['', [Validators.required]],
      billSubmittedTo: ['', Validators.required],
      billSubmittedBy: ['', Validators.required],
    });

    this.getPatientInfo();
    this.getRemarksList();
    this.onInitialSubmit();

  }

  onChangeSentON(event): void {
    console.log('setme', event.target.value);
    this.receivedONMin = event.target.value;
  }

  closeModalPopup(): void {
    this.closePopup.emit(true);
  }

  getPatientInfo(): void {

    const inputRequest = {
      IpNo: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID,
    };

    this.inpatientService.creditBillpatientInfo(inputRequest).subscribe(
      (response) => {
        this.patientInfo = response;
        console.log('patient info', response);

        const adm = this.patientInfo.BillDate;
        this.sentONMin = moment(adm).format('YYYY-MM-DDTHH:MM');
        console.log(this.sentONMin);

      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }
/** submit the remark form */
submitRemarks(): void {

  if (this.remarkFormData.remark === '') {
    this.toastrService.error('Please enter remarks');
    return;
  } else {
    if (this.remarkFormData.remark.length < 10) {
      this.toastrService.error('Please enter at least 10 characters');
      return;
    }
  }

  const inputRequest = {
    IpNo: this.ipPatientNO,
    CrateName: this.userInfo.username,
    Remarks: this.remarkFormData.remark,
  };

  this.inpatientService.submitCreditBillRemark(inputRequest).subscribe(
    (response) => {
      this.remarksList = response;
      this.getRemarksList();
      this.remarkFormData.remark = '';
      this.toastrService.success('Remarks saved successfully');
    },
    (err) => {
      console.log('HTTP Error', err);
      this.remarkFormData.remark = '';
      this.toastrService.error('Something went wrong..!');
    }
  );

}
  /** get remarks list */
  getRemarksList(): void {
    const inputRequest = {
      IpNo : this.ipPatientNO,
    };
    this.inpatientService.getCreditBillRemarksList(inputRequest).subscribe(
      (response) => {
        this.remarksList = response;
        if (this.remarksList[0].Message){ this.remarksList.length = 0; }
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );
  }

  onInitialSubmit(): void {

    const inputRequest = {
      IpNo: this.ipPatientNO,
      HospitalID: this.userInfo.hospitalID,
      BillSubmittedOn: '',
      BillSubmittedTo: '',
      BillSubmittedBy: '',
      CreatedName: '',
      InitialCheck: 1
    };

    this.inpatientService.creditBillSubmission(inputRequest).subscribe((response) => {
        this.creditBillSubmissionData = response;
        console.log(response);
        if (response && response.BillSubmittedOn !== '') {
          const adm = this.creditBillSubmissionData.BillSubmittedOn;
          this.creditBillSubmissionData.BillSubmittedOn = moment(adm).format('YYYY-MM-DDTHH:MM');
          this.creditBillSubmissionForm.controls.billSubmittedOn.setValue(this.creditBillSubmissionData.BillSubmittedOn);
          this.creditBillSubmissionForm.controls.billSubmittedTo.setValue(this.creditBillSubmissionData.BillSubmittedTo);
          this.creditBillSubmissionForm.controls.billSubmittedBy.setValue(this.creditBillSubmissionData.BillSubmittedBy);
         // console.log(this.creditBillSubmissionForm);
          this.creditBillSubmissionForm.disable();
        }
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

  onSubmit(): void {

    const inputRequest = {
      HospitalID: this.userInfo.hospitalID,
      IpNo: this.ipPatientNO,
      BillSubmittedOn: this.creditBillSubmissionForm.value.billSubmittedOn,
      BillSubmittedTo: this.creditBillSubmissionForm.value.billSubmittedTo,
      BillSubmittedBy: this.creditBillSubmissionForm.value.billSubmittedBy,
      CreatedName: this.userInfo.username,
      InitialCheck: 0
    };

    this.inpatientService.creditBillSubmission(inputRequest).subscribe(
      (response) => {
        this.creditBillSubmissionData = response;
        if (response) {
          this.toastrService.success(response.message);
          this.closeModalPopup();
        }
      },
      (err) => {
        console.log('HTTP Error', err);
      }
    );

  }

}
