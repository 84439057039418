import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { InpatientService } from '../inpatient.service';

@Component({
  selector: 'app-essentiality',
  templateUrl: './essentiality.component.html',
  styleUrls: ['./essentiality.component.css']
})
export class EssentialityComponent implements OnInit {
  
  essentialityForm: FormGroup;
  formBuilder: any;
  editOption: boolean = true;
  existingRecord: boolean = false;
  editButton: boolean = false;
  submitted: boolean = false;
  enableDischargeList: boolean = false;
  returnMode: any;
  userInfo: any;

  public EssentialityForm: any = {
      finalBill: '',
      investCharges: '',
      drugsCharges: '',
      otherCharges: '',
      grandTotal: '' 
  }
  
  id: string;  
  finalAmount: number = 0;
  investAmount: number = 0;
  drugsAmount: number = 0;
  otherAmount: number = 0;
  fAmount: any;
  essentialData: any = {};
  patientInfo: any = {};
  updateStatus: string = "N";
  constructor(
    private service: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService,
    private printerService: NgxPrinterService,
    private modalService: NgbModal,
    private inpatientService: InpatientService,
    private toastrService: ToastrService ) 
    { 
      this.userInfo = this.tokenService.getUser();
       this.id = this.route.snapshot.paramMap.get('id');

      this.route.queryParams.subscribe((params) => {
        this.returnMode = params.mode;
        if (params.mode === 'dischargelist') {
          this.enableDischargeList = true;
        } 
      });      
    }

  ngOnInit(): void {
    /* this.essentialityForm = new FormGroup({
      finalBill: new FormControl('', [Validators.required]),
      investCharges: new FormControl('', [Validators.required]),
      drugsCharges: new FormControl('', [Validators.required]),
      otherCharges: new FormControl('', [Validators.required]),
      grandTotal:  new FormControl('', [Validators.required])
    }); */
    this.getPatientInfo();
  }

  backNav(): void {
    const queryParam = { mode: 'dischargelist'};
      if (queryParam.mode === this.returnMode){
        this.router.navigate(['/Inpatient'], {queryParams: queryParam});
      }
  }

  enableEditOption() {
    this.editOption = true;
  }
  /* get f() { return this.essentialityForm.controls; } */

/*   getTotal(recAmount, type){

    let amount = Number(recAmount);
    //this.EssentialityForm.grandTotal = 0;

    if(type == 'final'){
        if(amount >0){
          this.finalAmount = this.finalAmount + amount;
        }else{
          this.finalAmount = 0;
        }
      
    }else if( type == 'invest'){
      if(amount >0){
        this.investAmount = this.investAmount + amount;
      }else{
        this.investAmount = 0;
      }
      
    }else if(type == 'drugs'){
      if(amount >0){
        this.drugsAmount = this.drugsAmount + amount;
      }else{
        this.drugsAmount = 0;
      }
      
    }else if(type == 'other'){
      if(amount >0){
        this.otherAmount = this.otherAmount + amount;
      }else{
        this.otherAmount = 0;
      }
      
    }
    this.EssentialityForm.grandTotal = this.finalAmount + this.investAmount + this.drugsAmount + this.otherAmount;
    //this.totalAmount = this.EssentialityForm.grandTotal;   
  } */

  getTotal(){
    
    if(this.EssentialityForm.finalBill >= 0 && this.EssentialityForm.investCharges >= 0 && 
      this.EssentialityForm.drugsCharges >= 0 && this.EssentialityForm.otherCharges >= 0)
      {
        this.EssentialityForm.grandTotal = Number(this.EssentialityForm.finalBill) + Number(this.EssentialityForm.investCharges) +
        Number(this.EssentialityForm.drugsCharges) + Number(this.EssentialityForm.otherCharges);
      }else{
      this.EssentialityForm.grandTotal = 0;
      }            
  }

  getPatientInfo() {
    const input = {
      Ipno: this.id,
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName
    }
    this.inpatientService.essentialCertificateInfo(input).subscribe(
      
      (response)=>{
          this.patientInfo = response;
          if(this.patientInfo.HospitalFinalBill != null && this.patientInfo.InvestigationCharges != null &&
            this.patientInfo.DrugsDisposableCharges != null && this.patientInfo.OtherCharges != null &&
            this.patientInfo.GrandTotal != null)
            {
              this.existingRecord = true;
              this.editOption = false;
              this.editButton = true;

              this.EssentialityForm.finalBill = Number(this.patientInfo.HospitalFinalBill),
              this.EssentialityForm.investCharges = Number(this.patientInfo.InvestigationCharges),
              this.EssentialityForm.drugsCharges = Number(this.patientInfo.DrugsDisposableCharges),
              this.EssentialityForm.otherCharges = Number(this.patientInfo.OtherCharges),
              this.EssentialityForm.grandTotal = Number(this.patientInfo.GrandTotal)
            }
    });
  }
  updatingForm() {
    this.updateStatus = 'Y';
    const input = {
      Ipno: this.id,
      HospitalFinalBill: this.EssentialityForm.finalBill,
      InvestigationCharges: this.EssentialityForm.investCharges,
      DrugsDisposableCharges: this.EssentialityForm.drugsCharges,
      OtherCharges: this.EssentialityForm.otherCharges,
      GrandTotal: this.EssentialityForm.grandTotal,
      UpdateStatus: this.updateStatus,
      createdBy: this.userInfo.username,
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName
    }

    this.inpatientService.addEssentialCertificate(input).subscribe(
      (response)=>{
      this.toastrService.success(response.message);
      this.updateStatus ="N";
      this.editButton = true;
      this.editOption = false;
      this.getPatientInfo();
    });
  
  }

  onSubmit(){

    const input = {
      Ipno: this.id,
      HospitalFinalBill: this.EssentialityForm.finalBill,
      InvestigationCharges: this.EssentialityForm.investCharges,
      DrugsDisposableCharges: this.EssentialityForm.drugsCharges,
      OtherCharges: this.EssentialityForm.otherCharges,
      GrandTotal: this.EssentialityForm.grandTotal,
      UpdateStatus: this.updateStatus,
      createdBy: this.userInfo.username,
      hospitalID: this.userInfo.hospitalID,
      hospitalShortName: this.userInfo.hospitalShortName
    }

    this.inpatientService.addEssentialCertificate(input).subscribe(
      (response)=>{
        this.essentialData = response;
        this.toastrService.success(response.message);
        this.existingRecord = true;
        this.editButton = true;
        this.editOption = false;
        this.updateStatus = 'Y';
        this.getPatientInfo();
    });    
  }

  openEssentialityPopup(essentialityPopup) {
    this.modalService.open(essentialityPopup, {size: 'xl', backdrop: 'static'});
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  printBill(){    
    const htmlele = document.getElementById('essentiality-form-print');
    htmlele.style.display = 'block';
    this.printerService.printDiv('essentiality-form-print');

    setTimeout(() => {
      htmlele.style.display = 'none';
    }, 1000); 
  }

}
