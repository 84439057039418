<div class="py-1 px-2 mt-2">
  <div class="container  bg-white">
    <div class="row d-flex">
      <div class="col-lg-8 col-sm-6 mt-2 mb-2 text-sm-left text-center doct_info_media">
        <p class=" card-title dr_name my-2 doc-name">
          {{selectedDoctorProfile.doctorTitle}}{{selectedDoctorProfile.doctorName}}</p>
        <hr class="line w-100 blue-line" />
        <p class="doc_info_spl my-2">- {{selectedDoctorProfile.doctorSpecilization}}</p>
        <hr class="line w-100 blue-line" />

        <div class="row pb-3">
          <div class="col-sm-12 col-md-12">
            <p class=" doc_info_spl2 my-2"><i class="fas fa-graduation-cap text-dark font-weight-bold mr-2"></i>
              Qualification</p>
            <p class="doc_info_spl1 my-2">
              <a 
                href="javascript:void(0)" 
                [attr.title]="selectedDoctorProfile.doctorQulalification"
                class="text-default-color"
              >
                {{selectedDoctorProfile.doctorQulalification}}
              </a>
            </p>
          </div>
          <div class="col-sm-12 col-md-12">
            <p class=" doc_info_spl2 my-2"><i class="fas fa-stethoscope text-dark font-weight-bold mr-2"></i>
              {{selectedDoctorProfile.doctorExperience}} Years Experience</p>
            <p class="doc_info_spl1 my-2"><i
                class="fas fa-globe-asia mr-2"></i>{{selectedDoctorProfile.doctorLanguagesKnown}}</p>
          </div>
          
        </div>
        
      </div>
      <div class="col-lg-4 mt-3 col-sm-6 p-1 mb-3 text-center">
        <img class="doc-img1" src="assets/{{selectedDoctorProfile.doctorName}}.png" alt="doctorPic">
      </div>
      <div class="tab_container ">
        <input id="tab1" type="radio" name="tabs" checked>
        <label for="tab1"><i class="fa fas fa-clock"></i><span>Book Appointment</span></label>
        <input id="tab3" type="radio" name="tabs">
        <label for="tab3"><i class='fa fa-laptop-medical'></i><span>Video Consultation</span></label>
        <input id="tab2" type="radio" name="tabs">
        <label for="tab2"><i class="fa fa-user-md" aria-hidden="true"></i><span>About</span></label>
        <section id="content1" class="tab-content w-100 ">
          <div class="d-flex">
            <button class="btn  rounded-circle d-flex  align-items-center" (click)="daysBackward()"  >
              <span class="font-weight-bold arrow-btn"><i class="fas fa-angle-left fa-2x"></i></span></button>
          <ul class="nav nav-pills mb-3  mt-2 pl-0 nav-justified w-100" id="pills-tab" role="tablist">
            <li class="nav-item " (click)="selectingTheSlotDay(firstDay,1)">
              <a class="nav-link" [ngClass]="{'active':currentdateIndex == 1}"   data-toggle="pill" href="#slotContent" role="tab" aria-controls="pills-home" aria-selected="true">
                <span class="h5 font-weight-normal m-0 float-left slot_dte">{{firstDay.toString().substr(8,2)}}</span>
                <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;"><span class="d-block mt-0">{{firstDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{firstDay.toString().substr(4,3).toUpperCase()}}</span>
              </span>
              </a>
            </li>
            <li class="nav-item" (click)="selectingTheSlotDay(secondDay,2)">
              <a class="nav-link" [ngClass]="{'active':currentdateIndex == 2}" data-toggle="pill" href="#slotContent" role="tab" aria-controls="pills-profile" aria-selected="false">
                <span class="h5 font-weight-normal m-0 float-left slot_dte" >{{secondDay.toString().substr(8,2)}}</span>
                <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;">
                  <span class="d-block mt-0">{{secondDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{secondDay.toString().substr(4,3).toUpperCase()}}</span>
              </span></a>
            </li>
            <li class="nav-item" (click)="selectingTheSlotDay(thirdDay,3)">
              <a class="nav-link" [ngClass]="{'active':currentdateIndex == 3}" data-toggle="pill" href="#slotContent" role="tab" aria-controls="pills-contact" aria-selected="false">
                <span class="h5 font-weight-normal m-0 float-left slot_dte">{{thirdDay.toString().substr(8,2)}}</span>
                <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;">
                  <span class="d-block mt-0">{{thirdDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{thirdDay.toString().substr(4,3).toUpperCase()}}</span>
              </span></a>
            </li>

          </ul>
            <button class="btn rounded-circle d-flex  align-items-center" (click)="daysForward()" >
              <span class="font-weight-bold arrow-btn" ><i class="fas fa-angle-right fa-2x"></i></span></button>
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd"
                       name="dp" [(ngModel)]="ngDateValue" [minDate]="minimumDate"
                       (dateSelect)="assigningNgbDatetoPresentdate(ngDateValue)" [placement]="placement" [positionTarget]="buttonEl" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                  <img style=" height: 54px;
  width: 58px;
  cursor: pointer;
  border-radius: 0px;" #buttonEl  (click)="d.toggle()"  src="assets/calendar.svg" alt="Calendar">
                </div>
              </div>
            </div>
<div>


    <!--<div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd"
             name="dp" [(ngModel)]="ngDateValue" [minDate]="minimumDate"
             (dateSelect)="assigningNgbDatetoPresentdate(ngDateValue)" ngbDatepicker #d="ngbDatepicker">

        <span class="calendar" (click)="d.toggle()" ><img style="height: 54px;" src="assets/calendar.svg" alt="Calendar"> </span>

    </div>-->

  <!--<input class="form-control" placeholder="yyyy-mm-dd"
                   name="dp" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <span class=" calendar date_nne" ><img class="cal_doc"  (click)="d.toggle()"  src="assets/calendar.svg" alt="Calendar"></span>
            </div>-->
          </div>
          </div>

          <div class="tab-content doct-slots" id="pills-tabContent">
            <div class="tab-pane fade show active" id="slotContent" role="tabpanel" aria-labelledby="pills-home-tab">

              <div *ngIf="singleSesionSlots">

                <div class="row  mt-4 mb-2">
                  <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time " > Available Slots :</div>

                  <div class="col-md-10" style="display: contents;">
                    <button  class="slot-data" *ngIf="singleSesionSlots !== 'No Slots Available'" (click)="doctorInfoEmitter(singleSesionSlots, 2, 0)">
                    {{singleSesionSlots}}
                    </button>
                    <p *ngIf="singleSesionSlots === 'No Slots Available'" class="text-danger text-center">No Slots Available</p>
                  </div>
                </div>
              </div>

              <div *ngIf="!singleSesionSlots">

              <div class="row  mt-4 mb-2">
                 <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5  slot-time"> Morning :</div>

                    <div class="col-md-8 p-0 text-center" >

                      <div *ngIf="morningSlots.length > 0" class="mx-auto">
                         <button (click)="doctorInfoEmitter(timeslot.time, 0, 0)"
                                 *ngFor = "let timeslot of morningSlots" [disabled]="timeslot.isBooked"
                         class="btn  slot-align">
                           <span class="slot-data">{{timeslot.time}}AM</span>
                          </button>
                       </div>
                      <p *ngIf="morningSlots.length === 0" class="text-danger text-center">No Slots Available</p>

                   </div>
                <div class="col-md-1"></div>
              </div>

              <hr>

              <div class="row mt-2 mb-2">
                         <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time" >Afternoon :</div>
                        <div class="col-md-8 p-0 text-center" >

                          <div *ngIf="afternoonSlots.length > 0" class="mx-auto">
                                  <button (click)="doctorInfoEmitter(timeslot.time, 1, 0)"
                                   *ngFor="let timeslot of afternoonSlots" [disabled]="timeslot.isBooked"
                                  class="btn slot-align">
                                  <span  class=" slot-data">{{timeslot.time}}PM</span>
                                  </button>
                          </div>
                          <p *ngIf="afternoonSlots.length === 0" class="text-danger  text-center">No Slots Available</p>
                        </div>
                <div class="col-md-1"></div>
              </div>

                <hr>
              <div class="row mt-2 mb-2">
                <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time">Evening :</div>
                    <div class="col-md-8 p-0 text-center" >
                      <div *ngIf="eveningSlots.length > 0" class="mx-auto">
                                  <button (click)="doctorInfoEmitter(timeSlots.time,1, 0)"
                                  *ngFor="let timeSlots of eveningSlots" [disabled]="timeSlots.isBooked"
                                  class="btn  slot-align"  >
                                  <span class=" slot-data">{{timeSlots.time}}PM</span>
                                </button>
                      </div>
                      <p *ngIf="eveningSlots.length === 0" class="text-danger text-center" >No Slots Available</p>
                    </div>
                <div class="col-md-1"></div>
              </div>

              </div>

                            </div>
                          </div>
                        </section>


                        <section id="content2" class="tab-content">
                          <h5 class="text-dark doc-about">More About : <span class="card-title">{{selectedDoctorProfile.doctorTitle}} {{selectedDoctorProfile.doctorName}}</span></h5>
                          <p class="m-1 doc-about">{{selectedDoctorProfile.doctorAbout}}</p>
          <h5 class="text-dark doc-about">Speciality :</h5>
          <p class="m-1 doc-about">{{selectedDoctorProfile.doctorSpecilization}}</p>
          <h5 class="text-dark doc-about">Education :</h5>
          <p class="m-1 doc-about">{{selectedDoctorProfile.doctorQulalification}}</p>
          <h5 class="text-dark doc-about">Languages :</h5>
          <p class="m-1 doc-about">{{selectedDoctorProfile.doctorLanguagesKnown}}</p>
        </section>

<section id="content3" class="tab-content w-100 px-0">
  <div class="d-flex">
    <button class="btn  rounded-circle d-flex  align-items-center" (click)="daysBackward()"  >
      <span class="font-weight-bold arrow-btn" ><i class="fas fa-angle-left fa-2x"></i></span></button>
    <ul class="nav nav-pills mb-3  mt-2 pl-0 nav-justified w-100" id="onlinepills-tab" role="tablist">
      <li class="nav-item " (click)="selectingTheSlotDay(firstDay,1)">
        <a class="nav-link" [ngClass]="{'active':currentdateIndex == 1}"   data-toggle="pill" href="#OnlineslotContent" role="tab" aria-controls="pills-home" aria-selected="true">
          <span class="h5 font-weight-normal m-0 float-left slot_dte">{{firstDay.toString().substr(8,2)}}</span>
          <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;"><span class="d-block mt-0">{{firstDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{firstDay.toString().substr(4,3).toUpperCase()}}</span>
              </span>
        </a>
      </li>
      <li class="nav-item" (click)="selectingTheSlotDay(secondDay,2)">
        <a class="nav-link" [ngClass]="{'active':currentdateIndex == 2}" data-toggle="pill" href="#OnlineslotContent" role="tab" aria-controls="pills-profile" aria-selected="false">
          <span class="h5 font-weight-normal m-0 float-left slot_dte" >{{secondDay.toString().substr(8,2)}}</span>
          <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;">
                  <span class="d-block mt-0">{{secondDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{secondDay.toString().substr(4,3).toUpperCase()}}</span>
              </span></a>
      </li>
      <li class="nav-item" (click)="selectingTheSlotDay(thirdDay,3)">
        <a class="nav-link" [ngClass]="{'active':currentdateIndex == 3}" data-toggle="pill" href="#OnlineslotContent" role="tab" aria-controls="pills-contact" aria-selected="false">
          <span class="h5 font-weight-normal m-0 float-left slot_dte">{{thirdDay.toString().substr(8,2)}}</span>
          <span class="d-none d-md-inline-block float-left m-0 h6 p-1 " style="font-size: 13px;
              font-weight: bold;">
                  <span class="d-block mt-0">{{thirdDay.toString().substr(0,3).toUpperCase()}}</span>
                <span class="d-block mt-0">{{thirdDay.toString().substr(4,3).toUpperCase()}}</span>
              </span></a>
      </li>

    </ul>
    <button class="btn  rounded-circle d-flex  align-items-center" (click)="daysForward()" >
      <span class="font-weight-bold arrow-btn"><i class="fas fa-angle-right fa-2x"></i></span></button>
<!--<div class="form-group">
    <input  class="form-control" placeholder="yyyy-mm-dd" [minDate]="minimumDate"  [(ngModel)]="ngDateValue" (dateSelect)="assigningNgbDatetoPresentdate(ngDateValue)"
           name="ngDate" ngbDatepicker #dk="ngbDatepicker">
    <div class="input-group-append">
      <span class=" calendar date_nne" (click)="dk.toggle()"><img class="cal_doc"  style="height: 57px;" src="assets/calendar.svg" alt="Calendar"></span>
    </div>
</div>-->
    <div class="form-group">
      <div class="input-group">
        <input  class="form-control" placeholder="yyyy-mm-dd" [minDate]="minimumDate"  [(ngModel)]="ngDateValue" [placement]="placement" [positionTarget]="buttonE" (dateSelect)="assigningNgbDatetoPresentdate(ngDateValue)"
                name="ngDate" ngbDatepicker #dk="ngbDatepicker">
        <div class="input-group-append">
          <img style=" height: 54px;
  width: 58px;
  cursor: pointer;
  border-radius: 0px;" #buttonE (click)="dk.toggle()"  src="assets/calendar.svg" alt="Calendar">
        </div>
      </div>
    </div>
  </div>


  <div class="tab-content doct-slots" id="Onlinepills-tabContent">
    <div class="tab-pane fade show active" id="OnlineslotContent" role="tabpanel" aria-labelledby="pills-home-tab">
<div>
  <div class="row">
    <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time" >Morning :</div>
    <div class="col-md-8 p-0  " >
      <div *ngIf="onlineMorningSlots.length" class="mx-auto">
      <button type="button" class="btn slot-align " (click)="doctorInfoEmitter(onlineSlots.time, 0, 1)"
      *ngFor="let onlineSlots of onlineMorningSlots, let i = index" [disabled]="onlineSlots.isBooked">
      <span class="slot-data" >{{onlineSlots.time}} AM</span> </button>
      </div>
      <p *ngIf="!onlineMorningSlots.length" class="text-center text-danger"> No Slots Available</p>
    </div>
    <div class="col-md-1"></div>
  </div>
<hr>
  <div class="row">
    <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time" >Afternoon :</div>
    <div class="col-md-8 p-0 text-center" >
      <div *ngIf="onlineAfternoonSlots" class="mx-auto">
      <button type="button" class="btn  slot-align " (click)="doctorInfoEmitter(onlineSlots.time, 1, 1)"
       *ngFor="let onlineSlots of onlineAfternoonSlots, let i = index" [disabled]="onlineSlots.isBooked">
        <span class="slot-data">{{onlineSlots.time}} PM</span>
      </button>
      </div>
      <p *ngIf="!onlineAfternoonSlots.length" class="text-center text-danger"> No Slots Available</p>
    </div>
    <div class="col-md-1"></div>
  </div>
<hr>
  <div class="row" >
    <div class="col-md-3 text-secondary d-flex align-items-center justify-content-center h5 slot-time">Evening :</div>
    <div class="col-md-8 p-0 text-center" >
      <div *ngIf="onlineEveningSlots" class="mx-auto c-day-session__slot ">
      <button type="button" class="btn  slot-align " (click)="doctorInfoEmitter(onlineSlots.time, 1, 1)"
       *ngFor="let onlineSlots of onlineEveningSlots, let i = index" [disabled]="onlineSlots.isBooked">
        <span class="slot-data"> {{onlineSlots.time}} PM </span></button>
    </div>
      <p *ngIf="!onlineEveningSlots.length" class="text-center text-danger"ng > No Slots Available</p>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
    </div>
  </div>
  </section>
      </div>
    </div>
  </div>
</div>
