<div class="container-fluid ">
  <!-- <div class="row justify-content-between">
    <div class="col-6">
      <div class=" ic-postion ">
        <img class=" ml-4 icc-icon">

        <span class="ml-2 "></span> 
      </div>
    </div>
    <div class="col-6">
      <div class="ic-postion float-right mt-1">
        <img class="ml-4 ic-hover">
        <span class="ml-3"></span> 
      </div>
    </div>
  </div> -->

  <div class="row justify-content-center mb-5 m-top ml-3">
    <div class="col-md-2"></div>

    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.master_management">
      <div class="bg-clr" routerLink="/master-management-landing">
        <img class="first-icc" src="assets/dashboard/master.svg">
        <div class="name-module">
          <label class="modename">Master Management</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.ward_bed_management">
      <div class="bg-clr" routerLink="/ipAdmission">
        <img class="first-icc" src="assets/dashboard/ward.svg">
        <div class="name-module">
          <label class="modename">Ward&Bed Management</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.doctors_workstation">
      <div class="bg-clr" routerLink="/frontOffice">
        <img class="first-icc" src="assets/dashboard/doctorworkstation.svg">
        <div class="name-module">
          <label class="modename align">Doctor's Workstation</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.equipment_management">
      <div class="bg-clr">
        <img class="first-icc" src="assets/dashboard/equipment.svg">
        <div class="name-module">
          <label class="modename">Equipment Management</label>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row justify-content-center mb-5 ic-top ml-3">
    <div class="col-md-2"></div>
    <!-- <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.inventory_management">
      <div class="bg-clr">
        <img class="first-icc " src="assets/dashboard/inventray.svg">
        <div class="name-module">
          <label class="modename">Inventory Management</label>
        </div>
      </div>
    </div> -->
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.registration_op">
      <div class="bg-clr" routerLink="/registration">
        <img class="first-icc " src="assets/dashboard/regop.svg">
        <div class="name-module">
          <label class="modename">Registration/OP</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.lab">
      <div class="bg-clr" routerLink="/sampleCollection">
        <img class="first-icc " src="assets/dashboard/lab.svg">
        <div class="name-module text-center">
          <label class="modename">Lab</label>
        </div>
      </div>
    </div>

    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.lab">
      <div class="bg-clr" routerLink="/mrd">
        <img class="first-icc " src="assets/dashboard/MRD-icon.png">
        <div class="name-module text-center">
          <label class="modename">MRD</label>
        </div>
      </div>
    </div>


    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.operation_theatre">
      <div class="bg-clr">
        <img class="first-icc " src="assets/dashboard/operation.svg">
        <div class="name-module">
          <label class="modename">Operation Theatre</label>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row justify-content-center mb-5 ic-top ml-3">
   
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.schedule_anagement">
      <div class="bg-clr" routerLink="/specialization">
        <img class="first-icc " src="assets/dashboard/shedule.svg">
        <div class="name-module">
          <label class="modename">Schedule Management</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.billing">
      <div class="bg-clr" routerLink="/billing">
        <img class="first-icc " src="assets/dashboard/billing.svg">
        <div class="name-module">
          <label class="modename">Billing Management</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.dietery_canteen">
      <div class="bg-clr">
        <img class="first-icc " src="assets/dashboard/canteen.svg">
        <div class="name-module">
          <label class="modename">Dietery/Canteen</label>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="rolesInfo?.moduleLevel?.radiology">
      <div class="bg-clr" routerLink="/radiology">
        <img class="first-icc " src="assets/dashboard/others.svg">
        <div class="name-module">
          <label class="modename">Radiology Management</label>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-2">
      <div class="bg-clr" routerLink="/inventory-management">
        <img class="first-icc " src="assets/dashboard/others.svg">
        <div class="name-module">
          <label class="modename">Inventory Management</label>
        </div>
      </div>
    </div> -->
  </div>
</div>