import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MRD_URL} from './mrdConstants'

@Injectable({
    providedIn: 'root',
  })

export class MRDService {
    public headers: any = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
  
    constructor(private http: HttpClient) {}

    public patientsList(data: any): any {
        return this.http.post(MRD_URL.GET_PATIENTS_LIST, data);
    }

    public filesUpload(formData: FormData): any
    {
        return this.http.post(MRD_URL.FILES_UPLOAD, formData)
    }

    public getPatientDocuments(inputRequest)
    {
      return this.http.post(MRD_URL.GET_PATIENT_DOCUMENTS, inputRequest)
    }

}
  