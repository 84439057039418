import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ViewoutpatientComponent } from '../billing-module/viewoutpatient/viewoutpatient.component';
import { RouterModule, Routes } from '@angular/router';
import {BillingModuleModule} from '../billing-module/billing-module.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReportsModule } from '../reports/reports.module';
const billingRoutes: Routes = [
  {path: 'OutPatient', component: ViewoutpatientComponent}
];

@NgModule({
  declarations: [RegistrationFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgbTypeaheadModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        RouterModule.forChild(billingRoutes),
        BillingModuleModule,
        ReportsModule,
        AutocompleteLibModule
    ]
})
export class RegnOpdmanagementModule { }
