import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AppointmentService} from '../appointment.service';
import {DoctorProfile} from '../../shared/appointmentsModels/doctorProfile';
import {AppointmentDoctorSchedule, AppointmentOnlineDoctorSchedule} from '../appointmentconstants';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {GetDoctorSchedule} from '../../shared/appointmentsModels/getDoctorSchedule';


@Component({
  selector: 'app-doctorinfo',
  templateUrl: './doctorinfo.component.html',
  styleUrls: ['./doctorinfo.component.css']
})
export class DoctorinfoComponent implements OnInit, OnChanges {
  toDayDate = new Date();
  checkTodayDate: boolean;
  docInfoDataLoaded = false;
  morningSlots: any[] = [];
  afternoonSlots: any[] = [];
  eveningSlots: any[] = [];
  onlineMorningSlots: any[] = [];
  onlineAfternoonSlots: any[] = [];
  onlineEveningSlots: any[] = [];
  singleSesionSlots: string;
  timeDiff = 86400000;
  dateChanger = 0;
  placement = 'left';
  presentHour = this.toDayDate.getHours();
  presentMinute = this.toDayDate.getMinutes();
  date = this.toDayDate.getTime() + (this.dateChanger * 3 * this.timeDiff);
  ngDateValue: string;
  firstDay: Date;
  secondDay: Date;
  thirdDay: Date;
  selectedDay: Date;
  minimumDate = {year: this.toDayDate.getFullYear(), month: this.toDayDate.getMonth() + 1, day: this.toDayDate.getDate()};
  allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
@Output() doctorEmit = new EventEmitter<string>();
// @Input() receivedDoctorId: string;
@Input() selectedDoctorProfile: SearchDoctors;

  public currentdateIndex = 0;
  constructor(private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    // this.assigningDaynDate();
    // this.selectingTheSlotDay(this.firstDay);
  }
  ngOnChanges(changes: SimpleChanges): any {
    if (this.appointmentService.appPatientForReschedule) {
      this.date = new Date(this.appointmentService.appPatientForReschedule.patientAppointmentDate).getTime();
    }
    this.assigningDaynDate();
    this.selectingTheSlotDay(this.firstDay);
  }
  assigningNgbDatetoPresentdate(ngbDate): any {
    this.date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime();
    this.assigningDaynDate();
    this.selectingTheSlotDay(this.firstDay);
  }
  assigningDaynDate(): any{
    // this.date = new Date().getTime() + (this.dateChanger * 3 * this.timeDiff);
    this.firstDay = new Date(this.date );
    this.secondDay = new Date(this.date + this.timeDiff);
    this.thirdDay = new Date(this.date + (2 * this.timeDiff));
    this.currentdateIndex = 1;
  }

  selectingTheSlotDay(day: any, index?: any): any {
    this.selectedDay = day;
    if (index !== undefined) {
      this.currentdateIndex = index;
    }
    this.appointmentService.appSelectedSlotToDisplay = day.toString().slice(0, 10);
    // After changing time this.appointmentService.appSelectedSlotDate = this.selectedDay.toJSON().substr(0, 10);
    this.appointmentService.appSelectedSlotDate = this.appointmentService.requiredDateFormat(this.selectedDay);
    this.checkingTheDaynSlotsData(this.allDays[day.getDay()]);
  }
  checkingTheDaynSlotsData(inputDay): any {
    const doctorPostObject: GetDoctorSchedule = {
      currentDate: this.appointmentService.appSelectedSlotDate,
      day: inputDay.toLowerCase(),
      hospitalDoctorAvialabiltyID:  this.selectedDoctorProfile.hospitaldocdepId
    };
    // this.checkTodayDate = this.toDayDate.toJSON().substr(0, 10) === this.appointmentService.appSelectedSlotDate;
    this.checkTodayDate = this.appointmentService.requiredDateFormat(this.toDayDate) === this.appointmentService.appSelectedSlotDate;
    this.docInfoDataLoaded = true;
    this.appointmentService.fullDoctorScheduleDetails(AppointmentDoctorSchedule, doctorPostObject)
      .subscribe( (doctorSheduleArr: DoctorProfile[]) => {
        this.docInfoDataLoaded = false;
        this.morningSlots = [];
        this.eveningSlots = [];
        this.afternoonSlots = [];
        this.singleSesionSlots = '';
        doctorSheduleArr.forEach( (doctorSchedule) => {
          if (doctorSchedule.queueType === '1') {
            this.appointmentService.appMaximumLimitConsultation = Number(doctorSchedule.maximumLimitofConsulting);
            const endTime = doctorSchedule.consultingHours.split('-')[1];
            if ((endTime < this.toDayDate.toTimeString().substr(0, 5) &&
              this.appointmentService.appSelectedSlotDate === this.appointmentService.requiredDateFormat(this.toDayDate))
              || (Number(doctorSchedule.maximumLimitofConsulting) < 1) || doctorSchedule[this.allDays[inputDay]] == ""){
              this.singleSesionSlots = 'No Slots Available';
            }
            else {
              this.singleSesionSlots = doctorSchedule.consultingHours;
            }
            this.appointmentService.appQueueType = 1;
            // const time = doctorSchedule.consultingHours.split(':');
            // const startTime = time[0].split(':');
            // const endTime = time[1].split(':');
            // if (Number(startTime[0]) < 12 && Number(endTime[0]) <= 12 ) {
            //   morningOnlySlot = st
            // }
            // this.startTotalMin = Number((startTime[0] * 60)) + Number(startTime[1]);
            // this.endTotalMin = Number((endTime[0] * 60) ) + Number(endTime[1]);
          }
          else if (doctorSchedule['timeSlot' + inputDay]) {
            this.singleSesionSlots = '';
            this.appointmentService.appMaximumLimitConsultation = 0;
            this.appointmentService.appQueueType = 0;
            this.assigningTimeSlots(doctorSchedule['timeSlot' + inputDay]);
          }
        });
      });
    this.gettingOnlineSlots(doctorPostObject, inputDay);
  }
  gettingOnlineSlots(postDataForService, dayForInput): any {
    this.appointmentService.fullDoctorScheduleOnline(AppointmentOnlineDoctorSchedule, postDataForService)
      .subscribe( (onlineSlotData: DoctorProfile[]) => {
        this.onlineMorningSlots = [];
        this.onlineAfternoonSlots = [];
        this.onlineEveningSlots = [];
        onlineSlotData.forEach( eachSlotData => {
          let slots = Object.keys(eachSlotData['timeSlot' + dayForInput]);
          slots.forEach( slot => {
            const hr = slot.split(':')[0];
            const min = slot.split(':')[1];
            let CheckingTime = true;
            if (this.checkTodayDate) {
              CheckingTime = (Number(hr) > this.presentHour)
                ? true : (Number(hr) === this.presentHour && Number(min) >= this.presentMinute) ? true : false;
            }
            if (CheckingTime) {
              let slotObj = {
                'time':this.changingMinutesToTime(hr, min),
                'isBooked':eachSlotData['timeSlot' + dayForInput][slot] == 1?false:true
              }
              if (Number(hr) < 12) {
                this.onlineMorningSlots.push(slotObj);
              } else if (Number(hr) < 17) {
                this.onlineAfternoonSlots.push(slotObj);
              } else {
                this.onlineEveningSlots.push(slotObj);
              }
            }
          });
        });
      });
  }
  daysForward(): any {
    this.dateChanger++;
    // this.assigningDaynDate();
    // this.selectingTheSlotDay(this.selectedDay);
    this.currentdateIndex++;
    if (this.currentdateIndex > 3) {
      this.firstDay = this.secondDay;
      this.secondDay = this.thirdDay;
      const date = this.secondDay.getTime();
      this.thirdDay = new Date(date + (this.timeDiff));
      this.currentdateIndex = 3;
      this.selectingTheSlotDay(this.thirdDay);
    } else {
      if (this.currentdateIndex === 2) {
        this.selectingTheSlotDay(this.secondDay);
      } else if (this.currentdateIndex === 3) {
        this.selectingTheSlotDay(this.thirdDay);
      }
    }
  }
  daysBackward(): any {
    if ((new Date(this.firstDay) >= new Date()) || this.currentdateIndex !== 1) {
        this.currentdateIndex--;
        if (this.currentdateIndex < 1) {
          this.thirdDay = this.secondDay;
          this.secondDay = this.firstDay;
          const date = this.secondDay.getTime();
          this.firstDay = new Date(date - (this.timeDiff));
          this.currentdateIndex = 1;
          this.selectingTheSlotDay(this.firstDay);
        } else {
          if (this.currentdateIndex === 2) {
            this.selectingTheSlotDay(this.secondDay);
          } else if (this.currentdateIndex === 1) {
            this.selectingTheSlotDay(this.firstDay);
          }
        }
    }
  }
  assigningTimeSlots(slotsAvailability): any {
    // const time = slotsAvailability.split('-');
    // const interval = slotTime.split(':');
    // const startTime = time[0].split(':');
    // const endTime = time[1].split(':');
    // this.intervalMin = Number((interval[0] * 60) ) + Number(interval[1]);
    // this.startTotalMin = Number((startTime[0] * 60)) + Number(startTime[1]);
    // this.endTotalMin = Number((endTime[0] * 60) ) + Number(endTime[1]);
    // while ((this.startTotalMin <= (this.endTotalMin - this.intervalMin)) && (this.startTotalMin < 720))   {
    //   this.minutesToSlots();
    //   this.morningSlots.push(this.slot);
    // }
    // while ((this.startTotalMin <= (this.endTotalMin - this.intervalMin)) && (this.startTotalMin < 1020))   {
    //   this.minutesToSlots();
    //   this.afternoonSlots.push(this.slot);
    // }
    // while ((this.startTotalMin <= (this.endTotalMin - this.intervalMin)) && (this.startTotalMin >= 1020))   {
    //   this.minutesToSlots();
    //   this.morningSlots.push(this.slot);
    // }
    let slots = Object.keys(slotsAvailability);
    slots.forEach( eachSlot => {
      const hr = eachSlot.split(':')[0];
      const min = eachSlot.split(':')[1];
      let CheckingTime = true;
      if (this.checkTodayDate) {
        CheckingTime = (Number(hr) > this.presentHour)
          ? true : (Number(hr) === this.presentHour && Number(min) >= this.presentMinute) ? true : false;
      }
      if (CheckingTime) {
        let slotObj = {
          'time':this.changingMinutesToTime(hr, min),
          'isBooked':slotsAvailability[eachSlot] == 1?false:true
        }
        if (Number(hr) < 12) {
          this.morningSlots.push(slotObj);
        } else if (Number(hr) < 17) {
          this.afternoonSlots.push(slotObj);
        } else {
          this.eveningSlots.push(slotObj);
        }
      }
    });
  }
  // minutesToSlots(): any {
  //   this.slot = '';
  //   this.changingMinutesToTime(this.startTotalMin);
  //   this.startTotalMin += Number(this.intervalMin);
    // this.slot += '-';
    // this.changingMinutesToTime(this.startTotalMin);
  // }
  changingMinutesToTime(Hr, Min): string {
    Hr = (Hr > 12) ? Hr - 12 : Hr ;
    Hr = this.twoDigitsCheck(Hr);
    Min = this.twoDigitsCheck(Min);
    return Hr + ':' + Min ;
  }
  twoDigitsCheck(digits): string {
    digits = '' + digits;
    if (digits.length < 2) {
      return '0' + digits;
    }
    else {
      return digits;
    }
  }
  doctorInfoEmitter(timeSlot, dayNumber, onOffAppointments): any{
    if (dayNumber <= 1) {
      const dayDefiner = (dayNumber) ? 'PM' : 'AM';
      const serverTime = timeSlot.split(':');
      this.appointmentService.appSelectedSlot = timeSlot + ' ' + dayDefiner;
      if (dayDefiner === 'PM') {
        serverTime[0] = (Number(serverTime[0]) < 12) ? Number(serverTime[0]) + 12 : serverTime[0];
      }
      this.appointmentService.appSelectedTimeForServer = serverTime.join(':') + ':00';
    }
    else {
      this.appointmentService.appSelectedTimeForServer = timeSlot.split('-')[0] + ':00';
      this.appointmentService.appSelectedSlot = timeSlot;
    }
    this.appointmentService.appOnlineAppointmentType = onOffAppointments;
    this.doctorEmit.emit('formFilling');
  }
}
