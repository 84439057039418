import {Component, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {NgbTypeahead, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {AppointmentService} from '../appointment.service';
import {
  AppointmentDepartmentsList,
  AppointmentSearchDoctor,
  AppointmentSearchHospitals,
  AppointmentSearchLocations
} from '../appointmentconstants';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';
import {SearchHospitals} from '../../shared/appointmentsModels/searchHospitals';
import {SearchBarResults} from '../../shared/appointmentsModels/searchBarResults';
import {SearchLocations} from '../../shared/appointmentsModels/searchLocations';
import {Router} from '@angular/router';
import {SearchDepartments} from '../../shared/appointmentsModels/searchDepartments';
import {TokenStorageService} from '../../shared/loginservices/token-storage.service';


@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {
  locations: string[] = this.appoitntmentService.appLocationsArray || [];
  locationId: string[] = this.appoitntmentService.appLocationsIdArray || [];
  docsSpecialities: string[] = this.appoitntmentService.appDoctorListArray || [];
  doctorId: number[] = this.appoitntmentService.appDoctorIdArray || [];
  areaHospitals: string[] = this.appoitntmentService.appHospitalsArray || [];
  hospitalId: number[] = this.appoitntmentService.appHospitalIdArray || [];
  spcialisationsArray: string[] = this.appoitntmentService.appDepartmentsArray || [];
  location: string;
  docnspecialisation: string;
  hospital: string;
  private roles: string[];
  isLoggedIn = false;
  showAdminBoard = false;
  disableLocation = false;
  disableHospital = false;
  showModeratorBoard = false;
  showUserBoard = false;
  showDoctorBoard = false;
  username: string;
  searchDataLoaded = false;
  departmentIdArray: number[] = this.appoitntmentService.appDepartmentIdArray || [];
  selectedLocationId: string;
  selectedHospitalId: number;
  selectedDoctorOrSpecialisationId: number;
  allHospitalsDataArray: SearchHospitals[] = this.appoitntmentService.appHospitalsDataArray || [] ;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  place$ = new Subject<string>();
  placeClick$ = new Subject<string>();
  specialisationDoc$ = new Subject<string>();
  specialisationDocClick1$ = new Subject<string>();
  allhospitals$ = new Subject<string>();
  hospitalClick$ = new Subject<string>();
  @Output() searchBarResultsEmit = new EventEmitter<SearchBarResults>();
  locationSearch = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.placeClick$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.place$;
    if (this.locations) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.locations
          : this.locations.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }
  hospitalSearch = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.hospitalClick$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.allhospitals$;
    if (this.areaHospitals) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.areaHospitals
          : this.areaHospitals.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }
  docSpecialitySearch = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.specialisationDocClick1$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.specialisationDoc$;
    if (this.docsSpecialities) {
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map(term => (term === '' ? this.docsSpecialities
          : this.docsSpecialities.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      );
    }
  }

  constructor(private appoitntmentService: AppointmentService, private router: Router, private tokenStorageService: TokenStorageService) {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorageService.getUser().roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_FRONTEND');
      this.showUserBoard = this.roles.includes('ROLE_USER');
      this.showDoctorBoard = this.roles.includes('ROLE_DOCTOR');
      this.username = this.tokenStorageService.getUser().username;
    }
  }

  ngOnInit(): any {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_FRONTEND');
      this.showUserBoard = this.roles.includes('ROLE_USER');
      this.showDoctorBoard = this.roles.includes('ROLE_DOCTOR');
      this.username = user.username;
    }
    if (this.appoitntmentService.appSearchFields) {
      this.location = this.appoitntmentService.appSearchFields.locationName;
      this.docnspecialisation = this.appoitntmentService.appSearchFields.doctorName;
      this.hospital = this.appoitntmentService.appSearchFields.hospitalName ;
    }
    this.searchDataLoaded = this.appoitntmentService.dataLoaded;
    this.citiesList();
    if (this.showModeratorBoard) {
      this.location = this.locations[this.locationId.indexOf(this.tokenStorageService.getUser().locationID)];
      this.hospitalList(this.tokenStorageService.getUser().locationID);
      this.hospital = this.areaHospitals[this.hospitalId.indexOf(this.tokenStorageService.getUser().hospitalID)];
      this.doctorList(this.hospital);
    }
  }
  citiesList(): any {
    this.searchDataLoaded = true;
    this.locations = [];
    this.appoitntmentService.getAppointmentSearchLocations(AppointmentSearchLocations)
      .subscribe((data: SearchLocations[]) => {
        this.searchDataLoaded = false;
        data.forEach(item => {
          this.locations.push(item.cityName);
          this.locationId.push(item.cityId);
        });
        this.appoitntmentService.appLocationsIdArray = this.locationId;
        this.appoitntmentService.appLocationsArray = this.locations;
        if (this.tokenStorageService.getUser().locationID){
          this.disableLocation = true;
          this.location = this.locations[this.locationId.indexOf(this.tokenStorageService.getUser().locationID)];
          this.hospitalList(this.tokenStorageService.getUser().locationID);
        }
      });
  }
  hospitalList(items): any {
    this.searchDataLoaded = true;
    if (typeof items === 'object') {
      this.selectedLocationId = this.locationId[this.locations.indexOf(items.item)];
    }
    else {
      this.selectedLocationId = items;
    }
    if (this.selectedLocationId) {
      this.appoitntmentService.getAppointmentSearchHospitals(AppointmentSearchHospitals + this.selectedLocationId)
        .subscribe((data: SearchHospitals[]) => {
          this.searchDataLoaded = false;
          this.allHospitalsDataArray = data;
          this.appoitntmentService.appHospitalsDataArray = data;
          this.areaHospitals = [];
          data.forEach(item => {
            this.areaHospitals.push(item.hospitalName);
            // this.areaHospitals.push(item.hospitalName + ' - ' + item.hospitalLocation);
            this.hospitalId.push(item.hospitalId);
          });
          this.appoitntmentService.appHospitalIdArray = this.hospitalId;
          this.appoitntmentService.appHospitalsArray = this.areaHospitals;
          if (this.tokenStorageService.getUser().hospitalID) {
            this.disableHospital = true;
            this.hospital = this.areaHospitals[this.hospitalId.indexOf(this.tokenStorageService.getUser().hospitalID)];
            this.doctorList(this.hospital);
          }
        });
    }
  }
  doctorList(items): any {
   this.searchDataLoaded = true;
   if (typeof items === 'object') {
     this.appoitntmentService.appSelectedHospital = items.item;
     this.appoitntmentService.appSelectedHospitaldata = this.allHospitalsDataArray.find(requiredHospital => {
       return items.item === requiredHospital.hospitalName;
     });
     this.selectedHospitalId = this.hospitalId[this.areaHospitals.indexOf(items.item)];
   }
   else {
     this.appoitntmentService.appSelectedHospital = items;
     this.appoitntmentService.appSelectedHospitaldata = this.allHospitalsDataArray.find(requiredHospital => {
       return items === requiredHospital.hospitalName;
     });
     this.selectedHospitalId = this.tokenStorageService.getUser().hospitalID;
   }
   this.appoitntmentService.appSelectedHospitalId = this.selectedHospitalId;
   if (this.selectedHospitalId) {
      this.appoitntmentService.getAppointmentSearchDoctors(AppointmentSearchDoctor + this.selectedHospitalId)
        .subscribe((data: SearchDoctors[]) => {
          this.searchDataLoaded = false;
          this.docsSpecialities = [];
          this.appoitntmentService.appallDoctorsList = data;
          data.forEach(item => {
            this.docsSpecialities.push(item.doctorTitle + ' ' + item.doctorName + ' - ' + item.doctorSpecilization);
            this.doctorId.push(item.hospitaldocdepId);
          });
          this.appoitntmentService.appDoctorIdArray = this.doctorId;
          this.spcialisationsArray = [];
          this.departmentIdArray = [];
          this.appoitntmentService.getAppointmentDepartmentList(AppointmentDepartmentsList + this.selectedHospitalId)
            .subscribe((departments: SearchDepartments[]) => {
              departments.forEach(dep => {
                if (this.spcialisationsArray.indexOf(dep.deptName) < 0) {
                this.spcialisationsArray.push(dep.deptName);
                this.departmentIdArray.push(dep.deptId);
              }
              });
              this.docsSpecialities = [...this.docsSpecialities, ...this.spcialisationsArray];
              this.appoitntmentService.appDoctorListArray = this.docsSpecialities;
              this.appoitntmentService.appDepartmentIdArray = this.departmentIdArray;
              this.appoitntmentService.appDepartmentsArray = this.spcialisationsArray;
              if (this.selectedLocationId && this.selectedHospitalId && !this.appoitntmentService.appPatientForReschedule) {
                // const passingSearchInfo = {
                //   locationId: this.selectedLocationId,
                //   hospitalId: this.tokenStorageService.getUser().hospitalID,
                //   doctorOrSpecialisationId: undefined,
                //   doctorNameRSpecialisation: '',
                //   bySpecialisation: undefined,
                //   departmentId: undefined
                // };
                // console.log(passingSearchInfo);
                // this.appoitntmentService.searchResults = passingSearchInfo;
                // this.router.navigate(['/specialization']);
                // this.searchBarResultsEmit.emit(passingSearchInfo);
                this.sendingSearchData('', '', '');
              }
            });
        });
    }
  }

   sendingSearchData(place , hospitalname, doctorName): any {
    this.selectedDoctorOrSpecialisationId = this.doctorId[this.docsSpecialities.indexOf(doctorName.value)];
    const bySpeciality = doctorName.value && doctorName.value.indexOf(' - ') === -1;
    const depId = this.departmentIdArray[this.spcialisationsArray.indexOf(doctorName.value)];
    const passingSearchInfo = {
      locationId: this.selectedLocationId || this.appoitntmentService.searchResults.locationId,
      hospitalId: this.selectedHospitalId || this.appoitntmentService.searchResults.hospitalId,
      doctorOrSpecialisationId: this.selectedDoctorOrSpecialisationId,
      doctorNameRSpecialisation: doctorName.value,
      bySpecialisation: bySpeciality,
      departmentId: (bySpeciality) ? depId : undefined
    };
    this.appoitntmentService.appSearchFields = {locationName: this.location,
       hospitalName: this.hospital, doctorName: this.docnspecialisation};
    this.appoitntmentService.searchResults = passingSearchInfo;
    this.searchBarResultsEmit.emit(passingSearchInfo);
    if (this.location && this.hospital) {
      this.router.navigate(['/specialization']);
    }
  }
}
