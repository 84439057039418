import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { DoctorListComponent } from './doctors/doctor-list/doctor-list.component';
import { BillingRuleComponent } from './billing-rules/billing-rule/billing-rule.component';
import { NgxEditorModule } from 'ngx-editor';
import { MasterManagementLandingComponent } from './master-management-landing/master-management-landing.component';
import { AddReferralComponent } from './add-referral/add-referral.component';

const MasterRouting: Routes = [
  {
    path: 'master-management-landing',
    component: MasterManagementLandingComponent
  }, {
    path: 'reportTemplatelist',
    component: DoctorListComponent
  }
];


@NgModule({
  declarations: [
    DoctorListComponent, 
    BillingRuleComponent, 
    MasterManagementLandingComponent,
    AddReferralComponent],
  imports: [
    SharedModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,  
    NgxEditorModule,  
    RouterModule.forChild(MasterRouting)
  ],
  exports: [RouterModule, AddReferralComponent]
  
})
export class MasterManagementModule { }
