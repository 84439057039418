import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inpatientlist',
  templateUrl: './inpatientlist.component.html',
  styleUrls: ['./inpatientlist.component.css']
})
export class InpatientlistComponent implements OnInit {
  public show = false;
  public buttonName = 'Select';
  constructor() { }

  ngOnInit(): void {
  }
  toggle(): any {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Select';
    }
    else{
      this.buttonName = 'Select';
    }
  }
}
