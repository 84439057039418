import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { RadiologyPatientListComponent } from './radiology-patient-list/radiology-patient-list.component';
import { RadiologySummaryComponent } from './radiology-summary/radiology-summary.component';
import { NgxEditorModule } from 'ngx-editor';
import {AuthGuard} from '../shared/loginservices/auth.guard';

const radiologyRoutes: Routes = [
  { path : 'radiology',component : RadiologyPatientListComponent, canActivate: [AuthGuard]},
  { path : 'lab-summary', component : RadiologySummaryComponent },
 
];

@NgModule({
  declarations: [
   RadiologyPatientListComponent,
   RadiologySummaryComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    MaterialModule,
    NgxEditorModule,

    RouterModule.forChild(radiologyRoutes),
  ]
})


export class RadiologyManagementModule { }
