/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrinterService } from 'ngx-printer';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { BillingService } from '../billing.service';
import { BillingId,
          DoctorServicesList,
          GetDoctorsList,
          PatientDetails,
          PatientRecordsByVisitID,
          PayeeName,
          PaymentModes,
          LoadServices,
          ServiceInfo,
          BillPayment,
          printBill,
          cancelBilling,
          prescriptionService, visitStatus, patientVisitStatus } from '../billingconstants';
import { CommonService } from '../../shared/services/common.service';
@Component({
  selector: 'app-viewoutpatient',
  templateUrl: './viewoutpatient.component.html',
  styleUrls: ['./viewoutpatient.component.css']
})
export class ViewoutpatientComponent implements OnInit, OnChanges {

  @Input() registerVisitId: any;
  @Input() registerDocId: any;

  @ViewChild('myModal01') myModal01;
  @ViewChild('myModal11') myModal11;

  public show = false;
  public buttonName = 'Select';
  public buttoncheck = 'Check out';
  public rolesInfo: any = [];

  public outPatientList: any = [];
  public patientInfo: any = {};
  public doctorsList: any = [];
  public doctorServicesList: any = [];
  public selectedServices: any = [];
  public grandTotal: any;
  public discountAmount: any;
  public discountAmountPercenttage: any;
  public netAmount: any;
  public serviceNetAmount: any;
  public collectedAmount: any = 0;
  public transactionId: any;
  public transactionReferenceId: any = 0;
  public bankName: any;
  public mobileNumber: any;
  public returnAmount: any;
  public selectedPaymentType: any = '';
  public paymentTypes: any = [];
  public selectedDoctorName: any = '';
  public selecteddepartmentID: any = 0;
  public selectedDoctorId: any = 0;
  public showPayment: any = false;
  public searchVisitId: any;
  public searchUMR: any;
  public searchPatientName: any;
  public searchDate: any;
  public billingId: any;
  public userInfo: any = {};
  public paymentModeList: any = [];
  public payeeName: any = '';
  public searchServiceName: any = '';
  public allServices: any = [];
  public selectedAllServices: any = [];
  public searchServiceNameVal = '';
  public searchServiceType = '';
  public printDetailsData: any = {};
  public prescriptionData: any = {};
  public printPrescription = false;
  public billingPaymentSubmitDetails = [];
  public billingCancelDetails = [];
  public billingCancelList = [];
  public billingFinalBillDetailModel = {};
  public billingDoctorInformation = {};
  public hospitalModel = {};
  public patientModel = {};
  public paymentIndex = 0;
  public disableCancel: any = false;
  public visitExpireMessage = 'The Visit for this patient is checked out. Please add a new visit';
  public visitMessage: any = '';
  public visitPatientStatus: any = true;
  public bodyDisplay = 'viewoutPatient';
  public visit = 0;
  public discountAuthorizedBy: any = '';
  public discountRemarks: any = '';
  public totalBillAmount = 0;
  public BillPayType = '';
  public FinalBillId = '';
  public cancelBillPrint = [];
  arrowkeyLocation: any;
  public cardNmbr: any;
  public isCreditVocher : boolean = false;
  cardOption = false;
  walkin = false;
  printedDoctor = false;
  isShown = false;

  constructor(
            private billingService: BillingService,
            private tokenService: TokenStorageService,
            private toastrService: ToastrService,
            private printerService: NgxPrinterService,
            private confirmDialogService: ConfirmDialogService,
            private modalService: NgbModal,
            private commonService: CommonService
            ) {

    this.userInfo = this.tokenService.getUser();
    this.rolesInfo = this.tokenService.getUser().rolesInfo;
    // this.userInfo.hospitalID = 2;
  }

  ngOnInit(): void {
    this.getOutPatientList();
    this.getPaymentModeList();
  }

  ngOnChanges(): void {
    if (this.registerVisitId !== undefined) {
      this.getPatientInfo(null, this.registerVisitId, this.registerDocId);
    }
  }

  getOutPatientList(): any {
    this.outPatientList = [];
  }

  getPaymentModeList(): any {
    this.billingService.getPaymentModes(PaymentModes + this.userInfo.hospitalID).subscribe(response => {
        this.paymentModeList = response;
    });
  }
 enterKeytriggerFunction(event, id, type): any {
   if (event.keyCode === 38 || event.keyCode === 40) {
     return false;
   }
   if (type === 'visitId') {
      this.searchUMR = null;
      this.searchPatientName = null;
      this.searchDate = null;
    }
    else if (type === 'patientId') {
      this.searchVisitId = null;
      this.searchPatientName = null;
      this.searchDate = null;
    } else if (type === 'patientName') {
      this.searchVisitId = null;
      this.searchUMR = null;
      this.searchDate = null;

    } else if (type === 'visitDate') {
      this.searchVisitId = null;
      this.searchUMR = null;
      this.searchPatientName = null;
    }
   if (event.keyCode === 13) {
      this.searchOutpatients(id, type);
    }
 }
  public searchOutpatients(searchId: any, type: any): any {
    this.outPatientList = [];
    const searchObj = {
      patientID: 0,
      visitID: 0,
      patientName: '',
      visitDate: '',
      visitStatus: this.visit
    };
    if (type === 'visitId') {
      searchObj.visitID = searchId;
    }
    if (type === 'patientId') {
      searchObj.patientID = searchId;
    }
    if (type === 'patientName') {
      searchObj.patientName = searchId;
    }
    if (type === 'visitDate') {
      searchObj.visitDate = searchId;
    }

    this.billingService.getOutpatientsByVisitId(PatientRecordsByVisitID , searchObj)
      .subscribe(response => {
        if (response.opInfo.length > 0){
        this.outPatientList = response.opInfo;
        }else{
          this.toastrService.error('No Records Found');
        }
      });
  }

  public checkOutVisitStatus(visitID: any): void {
    const checkOutVisitId = {
      visitID
    };

    this.confirmDialogService.confirmThis(
      'Do you want to Check out ?',
      () => {
        // console.log('Yes');
        this.billingService.setPatientVisitSatus(visitStatus , checkOutVisitId)
        .subscribe(
          (response) => {
            if (response.status){
              let searchTypeValue = ''
              let searchType = ''
              if(this.searchVisitId !== null)
              {
                searchTypeValue = this.searchVisitId
                searchType = 'visitId'
              }
              else if(this.searchUMR !== null)
              {
                searchTypeValue = this.searchUMR
                searchType = 'patientId'
              }
              else if(this.searchPatientName !== null)
              {
                searchTypeValue = this.searchPatientName
                searchType = 'patientName'
              }
              else if(this.searchDate !== null)
              {
                searchTypeValue = this.searchDate
                searchType = 'visitDate'
              }
              this.searchOutpatients(searchTypeValue, searchType);
              this.show = false;
              this.toastrService.success("Patient checkout is successfully completed")
            }
          },
          (err) => {
            console.log("Error", err)
            this.toastrService.error("Something went wrong..!")
          }
        );
      },
      () => {
        // console.log('No');
      }
    );
  }

   /** pre-auth form popup */
  OnpreAuth(preAuthPopup): void {
    this.modalService.open(preAuthPopup, { size: 'xl' });
  }

  onClosePopup(): void {
    this.modalService.dismissAll();
  }

  // public getPatientVisitStatus(visitID:any):any{
  //   this.billingService.getPatientVisitInfo(patientVisitStatus +  visitID)
  //   .subscribe(response => {
  //     this.visitPatientStatus = response.status;
  //     // alert(this.visitPatientStatus);
  //     // return this.visitPatientStatus;
  //   });
  // }


  public getPatientInfo(patientInfo: any, regVisitId?: any, registerDocId?: any): any {
    let visitID: any;
    let isRegVisit = false;
    let patientHospitalID = null;
    if (regVisitId !== undefined && regVisitId !== null) {
      visitID = regVisitId;
      isRegVisit = true;
    } else {
      visitID = patientInfo.visitId;
      patientHospitalID = patientInfo.hospitalID;
    }

    if (patientHospitalID == null || patientHospitalID === this.userInfo.hospitalID)
    {
      this.billingService.getPatientDetails(PatientDetails +  visitID + '/' + this.visit).subscribe(response => {
        this.patientInfo = response;
        this.doctorsList = [];

        if (this.patientInfo.length !== 0)
        {
          this.show = true; // !this.show;
          this.billingService.getDoctorsInfo(GetDoctorsList + visitID).subscribe(serResponse => {
            this.doctorsList = serResponse;
            if (this.doctorsList.length !== 0 && isRegVisit) {
              if(registerDocId) {
                this.doctorsList.forEach(element => {
                  if (element.doctorID == registerDocId) {
                    this.getServiceInfo(element, isRegVisit);
                  }
                });
              } else {
                this.getServiceInfo(this.doctorsList[0], isRegVisit);
              }              
            }
          });

          this.billingService.getBillingId(BillingId + visitID)
          .subscribe(serverResponse => {
            if (serverResponse !== null) {
              this.billingId = serverResponse.visitBillingID;
            }
          });
          this.billingService.getPayeeName(PayeeName + this.userInfo.hospitalID + '/' + this.patientInfo.payeeId)
          .subscribe(serverResponse => {
            if (response !== null) {
              this.payeeName = serverResponse;
            }
          });
        }
        else{
          this.show = false; // !this.show;
          this.visitMessage = this.visitExpireMessage;
        }
      });
    }else{
      this.visitMessage = 'Visit for this UMR is active in ' + patientInfo.hospitalName + '. Please ask the user to checkout to add Visit in your  location';
      this.patientInfo = [];
      this.show = false;
    }
  }

  public getServiceInfo(doctorObj: any, isInisde?: any): any {

    this.billingService.getPatientVisitInfo(patientVisitStatus +  doctorObj.patientVisitID)
    .subscribe(response => {
      if (response.status){
        this.searchServiceData();
        this.searchServiceNameVal = '';
        this.searchServiceType = '';
        this.selectedServices = [];
        this.selectedAllServices = [];
        this.grandTotal = 0;
        this.discountAmount = 0;
        this.discountAmountPercenttage = 0;
        this.netAmount = 0;
        this.selectedDoctorName = doctorObj.dmName;
        this.selectedDoctorId = doctorObj.doctorID;
        this.selecteddepartmentID = doctorObj.departmentID;
        this.showPayment = false;
        this.allServices = [];
        this.searchServiceName = '';

        // commented below code due to bug - triggering bill popup instead of Service popup
        // By Yuva on 18-10-2021
        /*
        if (isInisde) {
          setTimeout(() => {
            const ele = document.getElementById('proceed');
            if (ele !== null) {
              ele.click();
            }
          }, 100);
        }
        */
        this.getDoctorServiceInfo(doctorObj, isInisde);

        this.OnpreAuth(this.myModal01);
      } else {
        this.show = false;
        this.patientInfo = [];
      }
    });
  }


  public getDoctorServiceInfo(doctorobj: any, isInisde: any): any {

    const serviceSearchObj = {
      dmId: doctorobj.doctorID,
      visitID: doctorobj.patientVisitID,
      hospitalID: this.userInfo.hospitalID
    };

    this.doctorServicesList = [];
    this.billingService.getDoctorsServiceList(DoctorServicesList, serviceSearchObj).subscribe(response => {
      this.doctorServicesList = response;
      console.log('doctorlist', this.doctorServicesList);
      if (this.doctorServicesList.length > 0 && isInisde) {
        this.doctorServicesList[0].isSelected = true;
        this.onSelectService(this.doctorServicesList[0]);
      }
    });

  }

  public onSelectService(serviceObj: any): any {
    if (serviceObj.isSelected) {
      if (serviceObj.serviceUnitQty === 0) {
        serviceObj.serviceUnitQty = 1;
      }
      serviceObj.totalPrice = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      serviceObj.serviceNetAmt = serviceObj.serviceUnitQty * serviceObj.servicePrice;
      this.selectedServices.push(serviceObj);
    } else {
      const hasObj = this.selectedServices.filter(obj => obj.serviceName === serviceObj.serviceName);
      if (hasObj.length !== 0) {
        const index = this.selectedServices.indexOf(hasObj[0]);
        this.selectedServices.splice(index, 1);
      }
      if (serviceObj.ismain) {
          const index = this.doctorServicesList.indexOf(serviceObj);
          this.doctorServicesList.splice(index, 1);
      }
      const objIndex = this.selectedAllServices.findIndex((obj => obj.SM_ID === serviceObj.SM_ID) );
      this.selectedAllServices[objIndex].isSelected = false;
    }
    this.calculateTotalAmount();
  }

  public calculateTotalAmount(): any {
    this.grandTotal = 0;
    this.discountAmount = 0;
    this.discountAmountPercenttage = 0;
    this.netAmount = 0;
    this.selectedServices.forEach(element => {
      element.totalPrice = Math.round(element.serviceUnitQty * element.servicePrice);
      this.grandTotal = this.grandTotal + element.totalPrice;
      this.grandTotal =  Number(this.grandTotal.toFixed(2));
      if (element.discount !== undefined && element.discount !== '') {
        const disAmt = ((element.totalPrice / 100) * element.discount);
        this.discountAmount = Number(this.discountAmount + disAmt);
        element.serviceNetAmt = Math.round((element.serviceUnitQty * element.servicePrice) - disAmt);
      } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
        this.discountAmount = Number(this.discountAmount + Number(element.discountRuppes));
        element.serviceNetAmt = Math.round((element.serviceUnitQty * element.servicePrice)) - Number(element.discountRuppes);
      } else{
        element.serviceNetAmt = Math.round((element.serviceUnitQty * element.servicePrice));
      }
    });
    this.discountAmount = Number(this.discountAmount.toFixed(1));
    this.netAmount = this.grandTotal - this.discountAmount;
    this.netAmount = Math.floor((this.netAmount));
    this.discountAmountPercenttage = ((this.grandTotal - this.netAmount) / this.grandTotal) * 100;
    this.discountAmountPercenttage = Number(this.discountAmountPercenttage.toFixed(2));
  }

  public proceedPayment(): any {
    this.showPayment = true;
    this.clearAmounts();
    this.getPaymentModeList();
  }

  public clearAmounts(): any {
    this.selectedPaymentType = '';
    this.collectedAmount = 0;
    this.transactionId = '';
    this.bankName = '';
    this.mobileNumber = '';
    this.cardNmbr = '';
  }

  public cancelPayment(): any {
    this.showPayment = false;
  }

  public getServiceDiscountAmount(element: any): any {
    if (element.discount !== undefined && element.discount !== '') {
      return ((element.servicePrice / 100) * element.discount);
    } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
      return Number(element.discountRuppes);
    } else {
      return 0;
    }
  }

  public getServiceNetAmount(element: any): any {
    if (element.discount !== undefined && element.discount !== '') {
      const disAmt = ((element.totalPrice / 100) * element.discount);
      return (element.serviceUnitQty * element.servicePrice) - disAmt;
    } else if (element.discountRuppes !== undefined && element.discountRuppes !== '') {
      return (element.serviceUnitQty * element.servicePrice) - Number(element.discountRuppes);
    } else{
      return (element.serviceUnitQty * element.servicePrice);
    }
  }


  public saveServicesBillingInfo(): any {
    const saveServiceList = [];
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    let checkServiceType = 1;
    this.selectedServices.forEach(element => {
      const obj = {
        createdDateTime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
        createdId: this.userInfo.username,
        departmentId: this.selecteddepartmentID,
        doctorId: this.selectedDoctorId,
        sbiId: 0,
        serviceAmount: element.servicePrice,
        serviceBilledDate: dateFormat,
        serviceBilledTime: date.toTimeString().substr(0, 8),
        serviceDiscountAmt: this.getServiceDiscountAmount(element),
        serviceDiscountPerc: element.discount !== undefined ? element.discount : 0,
        serviceGrossAmt: element.servicePrice,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        serviceReportTemplate: element.serviceReportTemplate,
        serviceReportType: element.serviceReportType,
        serviceNetAmt: this.getServiceNetAmount(element),
        serviceQuantity: element.serviceUnitQty,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };

      saveServiceList.push(obj);
    });

    const saveServices = [];
    this.selectedServices.forEach(element => {
      const obj = {
        hospitalId: this.userInfo.hospitalID,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        serviceReportTemplate: element.serviceReportTemplate,
        serviceReportType: element.serviceReportType,
        servicePrescribedBy: this.selectedDoctorId,
        serviceQuantity: element.serviceUnitQty,
        serviceRequestDate: dateFormat,
        serviceRequestRemarks: '',
        serviceRequestTime: date.toTimeString().substr(0, 8),
        serviceTypeId: element.serviceTypeId !== undefined ? element.serviceTypeId : element.serviceTypeID,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };
      saveServices.push(obj);

      // Deprecated by Yuva on 27-12-2021
      // if (obj.serviceTypeId == 1) {
      //   checkServiceType =  1;
      // }
    });

    // const paymentObj = this.paymentModeList.filter(obj => obj.paymentModeName === this.selectedPaymentType);
    let cashamount = 0;
    let cardamount = 0;
    let walletamount = 0;
    let paymentModeID = 0;
    this.paymentModeList.forEach(element => {
      if (element.selectedPaymentType) {

        if (element.paymentModeID === '1' || element.paymentModeID === '13') {
         cashamount = cashamount + Number(element.collectedAmount);
        }

        if (element.paymentModeID === '2' || element.paymentModeID === '3') {
          cardamount = cardamount + Number(element.collectedAmount);
        }

        if (element.paymentModeID === '4' || element.paymentModeID === '5' || element.paymentModeID === '6') {
          walletamount = walletamount + Number(element.collectedAmount);
        }
        paymentModeID = element.paymentModeID;

      }

      // if (element.cashAmount !== undefined && element.cashAmount !== '') {
      //   cashamount = cashamount + Number(element.cashAmount);
      // }
      // if (element.cardAmount !== undefined && element.cardAmount !== '') {
      //   cardAmount = cardAmount + Number(element.cardAmount);
      // }
      // if (element.walletAmount !== undefined && element.walletAmount !== '') {
      //   walletAmount = walletAmount + Number(element.walletAmount);
      // }
    });

    const saveBillingJSON = {
      ServiceBillingInfo: saveServiceList,
      ServiceOrderRequestInfo: saveServices,
      amountCollected: this.collectedAmount,
      balanceAmount: this.collectedAmount - this.netAmount,
      bankingAmount: 0,
      bankingDate: dateFormat,
      bankingName: this.bankName,
      cardAmount: cardamount,
      cardNumber: this.cardNmbr,
      cashAmount: cashamount,
      createdDateTime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createdId: this.userInfo.username,
      creditPayeeId: 0,
      grossAmount: this.grandTotal,
      hospitalId: this.userInfo.hospitalID,
      paymentDate: dateFormat,
      paymentID: 0,
      paymentModeType: paymentModeID,
      paymentReceiptNo: 0,
      paymentStatus: '',
      paymentTime: date.toTimeString().substr(0, 8),
      paymentType: '1', // this.isCreditVocher ? '3' : '1', // this.selectedPaymentType,
      transactionId: (cardamount > 0) ? this.transactionId : 0,
      transactionMobileNr: this.mobileNumber,
      transactionReferenceId: (walletamount > 0) ? this.transactionId : 0,
      vBillingId: this.billingId,
      visitId: this.patientInfo.visitId,
      walletsAmount: walletamount,
      finalBillAmount: this.grandTotal,
      finalBillDate: dateFormat,
      finalBillTime: date.toTimeString().substr(0, 8),
      finalDiscountAmount: this.discountAmount,
      finalDiscountPercentage: this.discountAmountPercenttage,
      finalID: 0,
      finalInvoiceNr: this.billingId,
      finalNetAmount: this.netAmount,
      discountAutherizedBy: this.discountAuthorizedBy,
      discountRemarks: this.discountRemarks
    };

    // console.log('saveBillingJSON', saveBillingJSON);

    this.billingService.saveServicesBillingInfo(BillPayment, saveBillingJSON).subscribe(response => {
      this.toastrService.success('Payment done successfully!');
      if (checkServiceType == 1)
      {
        this.doctorsList.forEach(element => {
          if (element.doctorID == this.selectedDoctorId) {
            element.billingStatus = true;
            this.printBillDetails(element);
          }
        });
      }
      document.getElementById('closePopup').click();
      // this.printBillDetails(this.doctorsList);
      this.discountRemarks = '';
      this.discountAuthorizedBy = '';
    });

  }

  public saveServicesInfo(): any {
    const saveServices = [];
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    this.selectedServices.forEach(element => {
      const obj = {
        hospitalId: this.userInfo.hospitalID,
        serviceMasterId: element.serviceMasterID,
        serviceName: element.serviceName,
        servicePrescribedBy: this.selectedDoctorId,
        serviceQuantity: element.serviceUnitQty,
        serviceRequestDate: dateFormat,
        serviceRequestRemarks: '',
        serviceRequestTime: date.toTimeString().substr(0, 8),
        serviceTypeId: element.serviceMasterID,
        vBillingId: this.billingId,
        visitId: this.patientInfo.visitId
      };
      saveServices.push(obj);
    });
    // this.billingService.saveServicesInfo(SaveServicesInfo, saveServices)
    //   .subscribe(response => {
    //     this.saveOpPaymentDetails();
    //   });

  }

  public saveOpPaymentDetails(): any {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    const paymentObj = this.paymentModeList.filter(obj => obj.paymentModeName === this.selectedPaymentType);
    const saveJSON = {
      amountCollected: this.collectedAmount,
      balanceAmount: this.collectedAmount - this.netAmount,
      bankingAmount: 0,
      bankingDate: dateFormat,
      bankingName: this.bankName,
      cardAmount: 0,
      cashAmount: this.collectedAmount,
      createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createId: this.userInfo.username,
      creditPayeeId: 0,
      grossAmount: this.grandTotal,
      hospitalID: this.userInfo.hospitalID,
      paymentDate: dateFormat,
      paymentID: 0,
      paymentModeType: paymentObj[0].paymentModeID,
      paymentReceiptNo: 0,
      paymentStatus: '',
      paymentTime: date.toTimeString().substr(0, 8),
      paymentType: this.selectedPaymentType,
      transactionId: 0,
      transactionMobileNr: this.mobileNumber,
      transactionReferenceId: 0,
      visitBillingID: this.billingId,
      visitID: this.patientInfo.visitId,
      walletsAmount: 0
    };
    // this.billingService.saveOpPaymentDetails(SaveOpPaymentDetails, saveJSON)
    //   .subscribe(response => {
    //     this.saveOpBillDetails();
    //   });
  }

  public saveOpBillDetails(): any {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    const saveJson = {
      createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
      createId: this.userInfo.username,
      finalBillAmount: this.grandTotal,
      finalBillDate: dateFormat,
      finalBillTime: date.toTimeString().substr(0, 8),
      finalDiscountAmount: this.discountAmount,
      finalDiscountPercentage: 0,
      finalID: 0,
      finalInvoiceNr: this.billingId,
      finalNetAmount: this.netAmount,
      paymentType: this.selectedPaymentType,
      visitID: this.patientInfo.visitId
    };
    // this.billingService.saveOpBillDetails(SaveOpBillDetails, saveJson)
    //   .subscribe(response => {
    //     this.toastrService.success('Payement done successfully!');
    //     document.getElementById('closePopup').click();
    //   });
  }

  public searchServiceData(): any {
    // let serviceObj = this.allServices.filter(obj => obj.serviceName === this.searchServiceName);
        const searchJson: any = {
            gender: 'BOTH',
            hospitalId: this.userInfo.hospitalID,
            serviceName: ''
        };
        this.allServices = [];
        this.selectedAllServices = [];
        this.billingService.getAllServices(LoadServices, searchJson)
        .subscribe(response => {
          if (response !== null) {
            this.allServices = response;
            this.selectedAllServices = response;

            this.selectedAllServices.forEach(element => {
              element.isSelected = false;
            });
          }
        });
  }

  public printBillDetails(docInfo: any): any {

    this.billingService.getPatientVisitInfo(patientVisitStatus +  this.patientInfo.visitId).subscribe(response => {

      if (response.status) {
        const searchJson: any = {
          hospitalID: this.userInfo.hospitalID,
          visitID: this.patientInfo.visitId,
          patientID: this.patientInfo.patientId,
        };
        this.printDetailsData = {};
        this.billingFinalBillDetailModel = {};
        this.billingPaymentSubmitDetails = [];
        this.billingCancelDetails = [];
        this.billingCancelList = [];
        this.cancelBillPrint = [];

        this.billingService.getPrintDetails(printBill, searchJson).subscribe(printData => {

          if (printData !== null) {
            this.printDetailsData = printData;
            this.printDetailsData.patientModel.age  = this.gettingAge(this.printDetailsData.patientModel.dateOfBirth);

            if (this.printDetailsData !== undefined &&
              this.printDetailsData.billingPaymentSubmitModel !== undefined &&
              this.printDetailsData.billingPaymentSubmitModel.length !== 0) {

                this.printDetailsData.billingPaymentSubmitModel.forEach((bpsmEle, i) => {

                  if (bpsmEle.ServiceBillingInfoList &&
                    ( bpsmEle.billingDoctorInformation && bpsmEle.billingDoctorInformation.doctorId === docInfo.doctorID ) ) {

                    this.setServicesIndex(i);
                    // this.billingPaymentSubmitDetails = bpsmEle.ServiceBillingInfoList;
                    // this.billingFinalBillDetailModel = bpsmEle.billingFinalBillDetailModel;
                    // this.FinalBillId = bpsmEle.billingFinalBillDetailModel[0].finalID;
                    // this.billingCancelDetails = bpsmEle.ServiceBillingCancelInfoList;

                    // if (this.billingCancelDetails[this.FinalBillId]) {
                    //   this.billingCancelList = this.billingCancelDetails[this.FinalBillId];
                    // }
                    // this.billingPaymentSubmitDetails.forEach(element => {
                    //   if (element.sbiStatus === 'cancelled') {
                    //     this.cancelBillPrint.push(element);
                    //   }
                    // });

                    // this.billingDoctorInformation = {
                    //   doctorName: bpsmEle.billingDoctorInformation.doctorName,
                    //   externalReferal : bpsmEle.billingDoctorInformation.externalReferal
                    // };

                  }

                });

            } else {
              this.billingDoctorInformation = {
                doctorName: docInfo.dmName,
                externalReferal : docInfo.referalDoctorName
              };
            }

            // if(this.printDetailsData.billingPaymentSubmitModel  !== undefined
            //  && this.printDetailsData.billingPaymentSubmitModel.length != 0)
            // {
            //   if(this.printDetailsData.billingPaymentSubmitModel[0].billingDoctorInformation === null)
            //   {
            //     this.billingDoctorInformation = {"doctorName":this.userInfo.hospitalName};
            //   } else {
            //     this.billingDoctorInformation = this.printDetailsData.billingPaymentSubmitModel[0].billingDoctorInformation;
            //   }
            // }else{

            // }

            /* if(this.printDetailsData.billingPaymentSubmitModel[0].billingDoctorInformation.doctorName == "N.Bhuvaneshwer Rao"){
              this.printedDoctor = true;
            } */
            this.getTotalBillAmoutAndType();
            this.OnpreAuth(this.myModal11);
          }
        });
      } else {
        this.show = false;
        this.patientInfo = [];
      }
    });
  }

  public printBillToPrinter(): any {
     const htmlele = document.getElementById('print-section');
     htmlele.style.display = 'block';
    //  let htmlele1 = document.getElementById('cancelHeader');
    //  htmlele1.style.display = 'none';
    //  let htmlele2 = document.getElementById('remarksHeader');
    //  htmlele2.style.display = 'none';
    //  let htmlele3 = document.getElementById('cancelRow');
    //  htmlele3.style.display = 'none';
    //  let htmlele4 = document.getElementById('remarksRow');
    //  htmlele4.style.display = 'none';
     this.printerService.printDiv('print-section');
     setTimeout(() => {
      // const htmlele = document.getElementById('print-section');
      htmlele.style.display = 'none';
    //   let htmlele1 = document.getElementById('cancelHeader');
    //   htmlele1.style.removeProperty("display");
    //   let htmlele2 = document.getElementById('remarksHeader');
    //   htmlele2.style.removeProperty("display");
    //   let htmlele3 = document.getElementById('cancelRow');
    //   htmlele3.style.removeProperty("display");
    //   let htmlele4 = document.getElementById('remarksRow');
    //   htmlele4.style.removeProperty("display");
      }, 1000);

  }


  printComponent(cmpName) {
    const printContents = document.getElementById(cmpName).innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    // document.body.innerHTML = originalContents;
}

  public getGrossAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus !== 'cancelled') {
              amount = amount + element.serviceGrossAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getDiscountAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus !== 'cancelled') {
              amount = amount + element.serviceDiscountAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getNetAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus !== 'cancelled') {
              amount = amount + element.serviceNetAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  public getCancelAmount(): any {
    let amount = 0;
    if (this.billingPaymentSubmitDetails.length !== 0) {
          this.billingPaymentSubmitDetails.forEach(element => {
            if (element.sbiStatus === 'cancelled') {
              amount = amount + element.serviceNetAmt;
            }
          });
    }
    return amount.toFixed(2);
  }

  setServicesIndex(index: any): void {

    this.paymentIndex = index;
    this.cancelBillPrint = [];
    if (index !== undefined) {
      this.FinalBillId = this.printDetailsData.billingPaymentSubmitModel[index].billingFinalBillDetailModel[0].finalID;
      this.billingPaymentSubmitDetails = this.printDetailsData.billingPaymentSubmitModel[index].ServiceBillingInfoList;
      this.billingFinalBillDetailModel = this.printDetailsData.billingPaymentSubmitModel[index].billingFinalBillDetailModel;
      this.billingDoctorInformation = this.printDetailsData.billingPaymentSubmitModel[index].billingDoctorInformation;

      this.billingCancelDetails = this.printDetailsData.billingPaymentSubmitModel[index].ServiceBillingCancelInfoList;

      if (this.billingCancelDetails[this.FinalBillId]) {
        this.billingCancelList = this.billingCancelDetails[this.FinalBillId];
      } else {
        this.billingCancelList = [];
      }


      this.billingPaymentSubmitDetails.forEach(element => {
        // console.log("sbiStatus", element.sbiStatus);
        if (element.sbiStatus === 'cancelled') {
          this.cancelBillPrint.push(element);
        }
      });

      // console.log('cancel for print', this.cancelBillPrint);

      // if (this.billingCancelDetails[this.FinalBillId]) {
      //   this.billingCancelList = this.billingCancelDetails[this.FinalBillId];
      // }
      // this.billingPaymentSubmitDetails.forEach(element => {
      //   if (element.sbiStatus === 'cancelled') {
      //     this.cancelBillPrint.push(element);
      //   }
      // });
    }
    this.getTotalBillAmoutAndType();

  }

  getFinalBillDate(): any{
    let finalDate = this.getDate();
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingFinalBillDetailModel;
      if (model !== undefined) {
          finalDate = model[0].finalBillDate;
      }
    }
    return finalDate;
  }

  getCardAmount(): any {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cardAmount > 0) ? model.cardAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getWalletAmount(): any {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.walletsAmount > 0) ? model.walletsAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getCashAmount(): any {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) 
      //&&   this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel.paymentModeType !== '3'
      {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cashAmount > 0) ? model.cashAmount : 0;
        return amount;
      }
    }
    return amount;
  }
  
  getDueAmount(): any {
    let amount  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined)
      //&&   this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel.paymentModeType === '3' 
      {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        amount = (model.cashAmount > 0) ? model.cashAmount : 0;
        return amount;
      }
    }
    return amount;
  }

  getTransactionNo(): any {
    let transaction  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        transaction = (model.transactionId > 0) ? model.transactionId : 0;
        return transaction;
      }
    }
    return transaction;
  }

  getTransactionReferenceNo(): any {
    let transactionRef  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        transactionRef = (model.transactionReferenceId > 0) ? model.transactionReferenceId : 0;
        return transactionRef;
      }
    }
    return transactionRef;
  }

  getMobileNo(): any {
    let mobileNr  = 0;
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        mobileNr = (model.transactionMobileNr > 0) ? model.transactionMobileNr : 0;
        return mobileNr;
      }
    }
    return mobileNr;
  }

  getBankName(): any {
    let name  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        name = (model.bankingName) ? model.bankingName : '';
        return name;
      }
    }
    return name;
  }

  getCardNumber(): any {
    let cardNo  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        cardNo = (model.cardNumber) ? model.cardNumber : '';
        return cardNo;
      }
    }
    return cardNo;
  }

  getCreatedBy(): any {
    let name  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        name = model.createId;
        return name;
      }
    }
    return name;
  }

  getCreatedDate(): any {
    let date  = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        date = model.createDatetime;
        return date;
      }
    }
    return date;
  }

  getDate(): any {
    const date = new Date();
    const dateFormat = this.requiredDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }

  getDateToPrint(): any {
    const date = new Date();
    const dateFormat = this.changeDateFormat(date);
    return dateFormat + ' ' + date.toTimeString().substr(0, 8);
  }


  isDisabled(): any {
    let disable = false;
    this.isShown = false;
    if (this.billingPaymentSubmitDetails.length !== 0) {
      let hasSel = false;
      this.billingPaymentSubmitDetails.forEach(element => {
        if (element.isSelect && element.sbiStatus !== 'cancelled') {
          if (element.remarks === undefined || element.remarks === '' || element.remarks.length < 10) {
            disable = true;
            this.isShown = true;
          }
          hasSel = true;
        }
      });
      if (hasSel) {
        return disable;
      }
      return true;
    }
    return true;
  }


  public cancelPaymentService(): any {
    this.disableCancel = true;
    if (this.billingPaymentSubmitDetails !== undefined){
      const selectedServices = [];
      this.billingPaymentSubmitDetails.forEach(element => {
        if (element.isSelect) {
          selectedServices.push(element);
        }
      });
      selectedServices.forEach(element => {
        //
      });

      if (selectedServices.length !== 0) {
        const date = new Date();
        const dateFormat = this.requiredDateFormat(date);
        const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
        const cancelJson = {
          BillingPaymentDetail: {
            amountCollected: 0,
            balanceAmount: 0,
            bankingAmount: 0,
            bankingDate: '',
            bankingName: '',
            cardAmount: 0,
            cashAmount: 0,
            createDatetime: dateFormat + ' ' + date.toTimeString().substr(0, 8),
            createId: this.userInfo.username,
            creditPayeeId: 0,
            grossAmount: 0,
            hospitalID: this.userInfo.hospitalID,
            paymentDate: dateFormat + ' ' + date.toTimeString().substr(0, 8),
            paymentID: 0,
            paymentModeType: 'Cash',
            paymentReceiptNo: 0,
            paymentStatus: '',
            paymentTime: date.toTimeString().substr(0, 8),
            paymentType: '2',
            transactionId: this.billingFinalBillDetailModel[0].finalID,
            transactionMobileNr: 0,
            transactionReferenceId: 0,
            visitBillingID: model.visitBillingID,
            visitID: this.billingFinalBillDetailModel[0].visitID,
            walletsAmount: 0
          },
          ServicesCancellationDetail: []
        };

        let amount = 0;
        selectedServices.forEach(element => {
          amount = amount + element.serviceAmount;
          const obj = {
            finalID: this.billingFinalBillDetailModel[0].finalID,
            createDateTime: element.createdDateTime,
            createId: element.createdId,
            deptId: element.departmentId,
            dmId: element.doctorId,
            sbiId: element.sbiId,
            scdId: 0,
            scdRemarks: element.remarks,
            serviceAmount: element.serviceAmount,
            serviceCancelDate: dateFormat,
            serviceCancelTime: date.toTimeString().substr(0, 8),
            serviceDiscountAmount: element.serviceDiscountAmt,
            serviceDiscountedPercentage: element.serviceDiscountPerc,
            serviceGrossAmount: element.serviceGrossAmt,
            serviceName: element.serviceName,
            serviceNetAmount: element.serviceNetAmt,
            serviceUnitQty: element.serviceQuantity,
            smId: element.serviceMasterId,
            visitBillingId: element.vBillingId,
            visitId: element.visitId
          };
          cancelJson.ServicesCancellationDetail.push(obj);

        });
        cancelJson.BillingPaymentDetail.cashAmount = amount;
        this.billingService.getPrintDetails(cancelBilling, cancelJson)
        .subscribe(response => {
          if (response.errorMessage !== undefined && response.errorMessage !== '' &&
          response.errorMessage !== null) {
            this.toastrService.error(response.errorMessage);
            this.disableCancel = false;
          } else {
            this.toastrService.success('Cancel billing successfully!');
            if(response.consultationType == 1)
            {
              this.doctorsList.forEach(element => {
                if(element.doctorID == response.cancelDocId) {
                  element.billingStatus = false;
                  this.printBillDetails(element);
                }
              });
            }
            // selectedServices.forEach(element => {
            //   element.sbiStatus = 'cancelled';
            //   // this.printBillDetails('cancelled'); // parameter not reading in printbill function
            // });
          }
        });

      }

    }
  }

  public servicenameSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    }else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceName.toLowerCase().
      indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceName.toLowerCase().indexOf(this.searchServiceNameVal.toLowerCase()) !== -1));
    }
  }
  onKeydown($event): any{
    return this.arrowkeyLocation++;
  }

  public serviceTypeSearch(): any {
    if (this.searchServiceType === '' && this.searchServiceNameVal === '') {
      this.selectedAllServices = this.allServices;
    } else if (this.searchServiceNameVal !== '' && this.searchServiceType !== ''){
      this.selectedAllServices = this.selectedAllServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    } else {
      this.selectedAllServices = this.allServices.filter(obj => (obj.serviceTypeName.toLowerCase().indexOf(this.searchServiceType.toLowerCase()) !== -1));
    }
  }

  public selectService(): any {
    const serviceObj = this.allServices.filter(obj => obj.serviceName === this.searchServiceName);
    this.selectedAllServices.push(serviceObj[0]);
    this.searchServiceName = '';
    this.allServices = [];
  }

  public addService(service: any): any {
    const serviceJson = {
      hospitalId: this.userInfo.hospitalID,
      serviceMasterId : service.smId,
      visitId: this.patientInfo.visitId
    };
    const hasService = this.doctorServicesList.filter(obj => obj.serviceMasterID === service.smId);
    if (hasService.length === 0) {
      this.billingService.getServiceDetail(ServiceInfo, serviceJson)
        .subscribe(response => {
          if (response !== null) {
            const obj = response[0];
            if (obj !== undefined) {
              obj.ismain = true;
              obj.isSelected = true;
              response[0].serviceTypeId = service.serviceTypeId;
              this.doctorServicesList.push(response[0]);
              this.onSelectService(response[0]);

              service.isSelected = true;
            }
          }
      });
    }
  }

  public isPaymentSelected(): any {
    // console.log(this.paymentModeList);
    // this.isCreditVocher = this.paymentModeList.filter(obj => (obj.paymentModeName === 'Credit Voucher/Due Amount ' && obj.selectedPaymentType))
    const hasObj = this.paymentModeList.filter(obj => (obj.paymentModeName !== 'CASH' && obj.paymentModeName != 'Credit Voucher/Due Amount ' && obj.selectedPaymentType));
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }

  public isWalletSelected(): any {
    const hasObj = this.paymentModeList.filter(obj =>
      (obj.paymentModeName !== 'CASH' && obj.paymentModeName !== 'CREDIT CARD'
      &&  obj.paymentModeName != 'Credit Voucher/Due Amount '
      && obj.paymentModeName !== 'DEBIT CARD' && obj.selectedPaymentType));
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }

  public isCardSelected(): any {
    const hasObj = this.paymentModeList.filter(obj =>
      (obj.paymentModeName !== 'CASH' && obj.paymentModeName !== 'GOOGLE PAY'
      && obj.paymentModeName != 'Credit Voucher/Due Amount '
      && obj.paymentModeName !== 'PHONE PAY' && obj.paymentModeName !== 'UPI' && obj.selectedPaymentType));
    if (hasObj.length !== 0) {
      return true;
    }
    return false;
  }
  public stringNullCheck(e) {
    switch (e) {
      case "":
      case 0:
      case "0":
      case null:
      case false:
      case typeof(e) == "undefined":
        return true;
      default:
        return false;
    }
  }
  public clearAmount(paymentType: any) {
    paymentType.collectedAmount = '';
    this.bankName = '';
    this.transactionId = '';
    this.cardNmbr = '';
    this.mobileNumber = '';
  }

  public checkCollectAmount(): any {
    let collectAmount = 0;
    let hastrans = false;
    this.paymentModeList.forEach(element => {
      if (element.paymentModeID === 1) {
        element.cashAmount = element.collectedAmount;
      } else if ((element.paymentModeID === 2 || element.paymentModeID === 3) && element.collectedAmount !== undefined) {
        element.cardAmount = element.collectedAmount;
        // this.transactionReferenceId = 0;
        hastrans = true;
      }  else if ((element.paymentModeID === 4 || element.paymentModeID === 5 || element.paymentModeID === 6) && element.collectedAmount !== undefined) {
        element.walletAmount = element.collectedAmount;
        this.transactionReferenceId = element.transactionId;
        hastrans = true;
      }
      if (element.selectedPaymentType && element.collectedAmount !== undefined && element.collectedAmount !== '') {
        collectAmount = collectAmount + Number(element.collectedAmount);
      }

    });

    this.paymentModeList.forEach(element => {
      if (collectAmount === this.netAmount && !element.selectedPaymentType) {
        element.isDisable = true;
      } else {
        element.isDisable = false;
      }
    });    

    if (hastrans && this.transactionId !== undefined &&
      this.bankName !== undefined && this.bankName !== ''
      /* this.cardNumber !== undefined && this.cardNumber !== '' */ ) {
      this.collectedAmount = collectAmount;
      if (collectAmount === this.netAmount) {
        return false;
      }
    } else if (hastrans) {
      return true;
    }
    this.collectedAmount = collectAmount;
    // if (collectAmount === this.netAmount) {
    //   return false;
    // }
    // return true;
    
    if((this.isPaymentSelected() && this.stringNullCheck(this.bankName)) || (this.isPaymentSelected() && this.stringNullCheck(this.transactionId))) {
       return true;     
     } else if(this.isWalletSelected() && this.stringNullCheck(this.mobileNumber)){
       return true;
     } else if(this.isCardSelected() && this.stringNullCheck(this.cardNmbr)) {
       return true;
     } else if (collectAmount === this.netAmount){
       return false;
     }
     return true;

  }

  public requiredDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return funYear + '-' + this.checkingTwoDigits(funMonth) + '-' + this.checkingTwoDigits(funDay);
  }

  public changeDateFormat(dateRec: Date): string {
    const funYear = dateRec.getFullYear();
    const funMonth = dateRec.getMonth() + 1;
    const funDay = dateRec.getDate();
    return this.checkingTwoDigits(funDay) + '-' + this.checkingTwoDigits(funMonth) + '-' + funYear ;
  }

  public checkingTwoDigits(info): string {
    const twoDig = info.toString().length < 2 ? '0' + info : '' + info;
    return twoDig;
  }

  numberOnly(event: any, data: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (data !== undefined) {
      const value = data + event.key;
      if (value > 100) {
        return false;
      }
    }
    return true;
  }

  numberOnlyOther(event: any, data: any, total: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (data !== undefined) {
      const value = parseFloat(data + event.key);
      if (value > total) {
        return false;
      }
    }
    return true;
  }

  numberOnlyVal(event: any, data: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public getreturnAmount(): any {
    const amount = this.collectedAmount - this.netAmount;
    return amount.toFixed(2);
  }

  gettingAge(dobValue: any): any {
      if (dobValue !== undefined && dobValue !== '') {
        const fullDate = new Date(dobValue);
        const todayDate = new Date();
        let patientDobYear = todayDate.getFullYear() - fullDate.getFullYear();
        let patientDobMonth = todayDate.getMonth() - fullDate.getMonth();
        let patientDobDate = todayDate.getDate() - fullDate.getDate();

        if (patientDobMonth <= 0) {
          patientDobYear--;
          patientDobMonth = (12 + patientDobMonth);
        }
        if (patientDobDate < 0) {
          patientDobMonth--;
          patientDobDate = 31 + patientDobDate;
          if (patientDobDate === 31) {
            patientDobMonth++;
            patientDobDate = 0;
          }
        }
        if (patientDobMonth === 12) {
        patientDobYear = patientDobYear + 1;
        patientDobMonth = 0;
      }
        const patientAge = patientDobYear + 'Y ' + patientDobMonth + 'M ' + patientDobDate + 'D';
        return patientAge;
    }
      return 0;
  }

  /**
   * print for outpatient prescription
   */
 private prescriptionPrint(patientId, visitId, doctorId): any{
    this.billingService.getPatientVisitInfo(patientVisitStatus +  visitId)
    .subscribe(response => {
      if (response.status){
        const inputRequest: any = {
          hospitalID: this.userInfo.hospitalID,
          patientID: patientId,
          visitID: visitId,
          doctorID: doctorId
        };
        this.billingService.prescriptionDoctorsInfo(inputRequest, prescriptionService)
          .subscribe(response => {
            if (response !== null) {
              this.prescriptionData = response;
              console.log(this.prescriptionData);
              this.prescriptionData.patientModel.age  = this.gettingAge(this.prescriptionData.patientModel.dateOfBirth);
              if (this.prescriptionData.billingDoctorInformation === null) {
                this.prescriptionData.billingDoctorInformation = {doctorName : this.userInfo.hospitalName, billingDoctorInformation: ''};
              }
              this.billingDoctorInformation = this.prescriptionData.billingDoctorInformation;
              if (this.prescriptionData.billingDoctorInformation.externalReferal === 'Walk-In'){
                this.walkin = true;
              }
              if (this.prescriptionData.billingDoctorInformation.doctorName === 'N.Bhuvaneshwer Rao'){
                this.printedDoctor = true;
              }

            }
          });

        this.printPrescription = true;
        setTimeout(() => {
          this.printerService.printDiv('print-prescription-section');
        }, 2000);
        setTimeout(() => {
          this.printPrescription = false;
        }, 3000);
      } else {
        this.show = false;
        this.patientInfo = [];
      }
    });
  }

  getFollowupDate(): any {
    if (this.billingDoctorInformation['freeFollowUpDays'] > 0) {
      const fullDate = new Date(this.prescriptionData.visitDate);
      fullDate.setDate( fullDate.getDate() + ((this.billingDoctorInformation['freeFollowUpDays']) - 1));
      const dateFormat = this.requiredDateFormat(fullDate);
      return dateFormat;
    }
    return '';
  }

  /* getFollowUpDateFiveDays() {
    if(this.billingDoctorInformation['freeFollowUpDays'] > 0) {
      let fullDate = new Date(this.prescriptionData.visitDate);
      fullDate.setDate( fullDate.getDate() + ((this.billingDoctorInformation['freeFollowUpDays'])-3));
      const dateFormat = this.requiredDateFormat(fullDate);
      return dateFormat;
    }
    return '';
  } */

  getTotalBillAmoutAndType(): void{
    const amount = 0;
    this.totalBillAmount = 0 ;
    this.BillPayType = '';
    if (this.printDetailsData.billingPaymentSubmitModel !== undefined &&
      this.printDetailsData.billingPaymentSubmitModel !== null &&
      this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex] !== undefined) {
      const model = this.printDetailsData.billingPaymentSubmitModel[this.paymentIndex].billingPaymentDetailModel;
      if (model !== undefined) {
        if (model.cashAmount > 0)
        {
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.cashAmount);
          if ( this.BillPayType !== ''){
            this.BillPayType = ' AND CASH';
          } else {
            this.BillPayType = 'CASH';
          }
        }

        if (model.walletsAmount > 0)
        {
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.walletsAmount);
          if ( this.BillPayType !== ''){
            this.BillPayType = this.BillPayType + ' AND WALLET' ;
          } else {
            this.BillPayType = 'WALLET';
          }
        }

        if (model.cardAmount > 0)
        {
          this.totalBillAmount = Number(this.totalBillAmount) + Number(model.cardAmount);
          if ( this.BillPayType !== ''){
            this.BillPayType = this.BillPayType + ' AND CARD';
          } else {
            this.BillPayType = 'CARD';
          }
        }
      }
    }
  }
  getAmountInWords(): any {
    return this.commonService.toWords(Math.round(this.totalBillAmount));
  }
}
