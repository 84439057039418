import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiple-fileupload',
  templateUrl: './multiple-fileupload.component.html',
  styleUrls: ['./multiple-fileupload.component.css']
})
export class MultipleFileuploadComponent implements OnInit {

  myFiles: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  getFileDetails(e) {
    //console.log (e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
    console.log(this.myFiles);
  }

  uploadFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.myFiles.length; i++) {
      frmData.append('fileUpload', this.myFiles[i]);
    }

    console.log(this.myFiles);
  }

}
