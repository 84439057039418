import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { DoctorService } from '../doctors/doctorService';

@Component({
  selector: 'app-add-referral',
  templateUrl: './add-referral.component.html',
  styleUrls: ['./add-referral.component.css']
})
export class AddReferralComponent implements OnInit {

  addReferralForm: FormGroup;
  referralTypeList = [
    {referralTypeId:1, referralName:'WALK-IN'},
    {referralTypeId:2, referralName:'DOCTOR'},
    {referralTypeId:3, referralName:'AMBULANCE'}
  ]
  userInfo: any;
  constructor(
    private doctorService: DoctorService,
    private toastrService: ToastrService,
    private tokenstorageService: TokenStorageService,
    private modalService: NgbModal) 
    { 
      this.userInfo = this.tokenstorageService.getUser();
    }

  ngOnInit(): void {

    this.addReferralForm = new FormGroup({
      referralname: new FormControl('', [Validators.required]),
      qualification: new FormControl(''),
      areaname: new FormControl('', [Validators.required]),
      mobileNo: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      referralType: new FormControl('', [Validators.required]),
    });
  }

  get f(): any { return this.addReferralForm.controls; }

  submitData(){
    const input= {
      "referalName": this.addReferralForm.controls.referralname.value,
      "qualification": this.addReferralForm.controls.qualification.value,
      "referalMobileNo": this.addReferralForm.controls.mobileNo.value,
      "referalemail": ' ',
      "referalMobileNo2": '',
      "referaltype": this.addReferralForm.controls.referralType.value,
      "referaladdress": '',
      "referalArea":this.addReferralForm.controls.areaname.value,
      "pincode": '',
      "district": '',
      "state": '',
      "createName":this.userInfo.username
      }
      this.doctorService.submitReferralForm(input).subscribe((response)=>{
        if(response){
          this.toastrService.success(response.message);
          //this.setValuestoEmpty();
          this.popupClose();
        }else{
          this.toastrService.error("Referral Doctor Name is not added");
        }
      });  
    }

    setValuestoEmpty(){
      this.addReferralForm.reset();
    }

    popupClose(){
      this.modalService.dismissAll();
    }
}
