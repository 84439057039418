import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { InpatientService } from '../../viewinpatient-module/inpatient.service';

import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'app-receipt-print',
  templateUrl: './receipt-print.component.html',
  styleUrls: ['./receipt-print.component.css']
})
export class ReceiptPrintComponent implements OnInit, OnChanges {

  @Input() ipno: number;
  @Input() receiptno: number;

  ipReceiptDetailsList;
  ipReceiptAmountInWords;

  public patientInfo: any;
  public ipPatientNO = 0;

  public userInfo: any = {};

  constructor(private inpatientService: InpatientService,
              private tokenService: TokenStorageService,
              private commonService: CommonService) {

    this.userInfo = this.tokenService.getUser();

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    this.printReceipt(this.ipno, this.receiptno);

  }

  printReceipt(ipno, receiptno): void {

    const input = {
      IpNr: ipno,
      HospitalId: this.userInfo.hospitalID,
      ReceiptNo : receiptno
    };
    this.getIpReceiptDetails(input);

  }

  /** get the pdf footer details */
  getIpReceiptDetails(inputRequest): void {

    this.inpatientService.ipReceiptDetails(inputRequest).subscribe((response) => {

      if (response !== null) {
        this.ipReceiptDetailsList = response;
        this.ipReceiptAmountInWords = this.commonService.toWords(
          Math.round(this.ipReceiptDetailsList.ReceiptAmt)
        );
      }

    });

  }

}
