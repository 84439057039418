import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { InpatientService } from '../../viewinpatient-module/inpatient.service';

import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'app-admissionform-print',
  templateUrl: './admissionform-print.component.html',
  styleUrls: ['./admissionform-print.component.css']
})
export class AdmissionformPrintComponent implements OnInit, OnChanges {

  public admissionFormPrintData;

  @Input() ipno: number;
  @Input() umrno: number;

  public userInfo: any = {};

  constructor(private inpatientService: InpatientService,
              private tokenService: TokenStorageService,
              private commonService: CommonService) {

    this.userInfo = this.tokenService.getUser();

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    this.admissionFormPrint();

  }

  admissionFormPrint(): void {

    const inputRequest = {
      sear_hospitalid: this.userInfo.hospitalID,
      sear_ip_no: this.ipno,
      sear_umr_no : this.umrno,
      admissionPrintStatus: 'Y',
      chckoutPrintStatus: 'N'
    };
    this.inpatientService.admissionFormPrint(inputRequest).subscribe((response) => {

      if (response !== null) {
        this.admissionFormPrintData = response;
      }

    });

  }

}
