<div *ngIf="userInfo.hospitalShortName != 'DPMTA' && userInfo.hospitalShortName != 'KPMTA' 
&& userInfo.hospitalShortName != 'CPMTA' && userInfo.hospitalShortName != 'JPMTA'" >
    <div id="print-receipt-section" class="print-form border border-dark" *ngIf="ipReceiptDetailsList !== undefined">            
    
        <div class="row no-gutters heading-block border-bottom border-dark">
            <div class="col-md-6 border-right border-dark">
                <div class="row">
                    <div class="col-md-2 logo">
                        <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
                        <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
                    </div>
                    <div class="col-md-10">
                        <h4 class="main-title mb-0 font-weight-bold">{{ ipReceiptDetailsList?.HospitalName }}</h4>
                        <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ ipReceiptDetailsList?.HospitalTagLine }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: center;">
                        <p>{{ipReceiptDetailsList?.HospitalAddress}}</p>
                        <h3 class="sub-title1 mb-1 font-weight-bold">
                            <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3" style="font-size: 15px;">
                <div class="row">
                    <div class="col-md-3">
                        P. Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                    </div>
                    <div class="col-md-3">
                        Admn No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Recpt No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                    </div>
                    <div class="col-md-3">
                        Recpt Dt
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Age/Sex
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                    </div>
                    <div class="col-md-3">
                        Phone
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.Phone}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row-md-8 p-3" style="font-size: 15px;">
            <div class="row">
                <div class="col-md-2">
                    <span> S/O.D/O.W/O</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
                </div>
                <div class="col-md-2">
                    <span> Ward/Bed</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.WardBed}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Relation</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.relation}}
                </div>
                <div class="col-md-2">
                    <span>Advance NO</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Consultant</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.Consultant}}
                </div>
                <div class="col-md-2">
                    <span> Dept Name</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.DeptName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span>Receipt Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
                </div>
                <div class="col-md-4">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                    </span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.BankName}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
            </div>
            <div>
                <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
                <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
            </div>
        </div>
        <footer class="p-3" style="font-size: 15px;">
            <div class="row">
                <div class="col-md-2">
                    Created By
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
                </div>
                <div class="col-2">
                    Create Dt
                </div>
                <div class="col-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    Print By
                </div>
                <div class="col-md-4">
                    <b>:</b> {{userInfo.username}}
                </div>
                <div class="col-2">
                    Print Dt
                </div>
                <div class="col-4">
                    <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
                <div class="col-md-4 text-center">Email: health@lsch.in, www.lsch.in</div>
                <div class="col-md-4 text-right">(Authorised Signatory)
                </div>
            </div>
        </footer>
    </div>
</div>
<div *ngIf="userInfo.hospitalShortName == 'DPMTA'" style="margin-top: 1.3in;">
    <div id="print-receipt-section" class="print-form border border-dark" *ngIf="ipReceiptDetailsList !== undefined">    
        <div class="row no-gutters heading-block border-bottom border-dark">            
            <div class="col-md-6 border-right border-dark">
                <div class="row">
                    <div class="col-md-2 logo">
                        <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
                        <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
                    </div>
                    <div class="col-md-10">
                        <h4 class="main-title mb-0 font-weight-bold">{{ ipReceiptDetailsList?.HospitalName }}</h4>
                        <span style="font-size: 12px; font-weight: bold; display: inline-block; ">{{ ipReceiptDetailsList?.HospitalTagLine }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <p class="font-weight-bold">{{ipReceiptDetailsList?.HospitalAddress}}</p>
                        <h3 class="sub-title1 mb-1 font-weight-bold">
                            <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3 font-weight-bold" style="font-size: 15px;">
                <div class="row">
                    <div class="col-md-3">
                        P. Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                    </div>
                    <div class="col-md-3">
                        Admn No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Recpt No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                    </div>
                    <div class="col-md-3">
                        Recpt Dt
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Age/Sex
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                    </div>
                    <div class="col-md-3">
                        Phone
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.Phone}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Address
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{ipReceiptDetailsList?.Address}}
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row-md-8 p-3 font-weight-bold" style="font-size: 15px;">
            <div class="row">
                <div class="col-md-2">
                    <span> S/O.D/O.W/O</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
                </div>
                <div class="col-md-2">
                    <span> Ward/Bed</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.WardBed}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Relation</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.relation}}
                </div>
                <div class="col-md-2">
                    <span>Advance NO</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Consultant</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.Consultant}}
                </div>
                <div class="col-md-2">
                    <span> Dept Name</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.DeptName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span>Receipt Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
                </div>
                <div class="col-md-4">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                    </span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.BankName}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
            </div>
            <div>
                <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
                <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
            </div>
        </div>
        <footer class="p-3 font-weight-bold" style="font-size: 15px;">
            <div class="row">
                <div class="col-md-2">
                    Created By
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
                </div>
                <div class="col-md-2">
                    Create Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    Print By
                </div>
                <div class="col-md-4">
                    <b>:</b> {{userInfo.username}}
                </div>
                <div class="col-md-2">
                    Print Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
                <div class="col-md-4 text-center"></div>
                <div class="col-md-4 text-right">(Authorised Signatory)
                </div>
            </div>
        </footer>
    </div>
</div>
<div *ngIf="userInfo.hospitalShortName == 'KPMTA'" style="margin-top: 1.4in;">
    <div id="print-receipt-section" class="print-form border border-dark" *ngIf="ipReceiptDetailsList !== undefined">    
        <div class="row no-gutters heading-block border-bottom border-dark">            
            <div class="col-md-6 border-right border-dark">
                <div class="row">
                    <div class="col-md-2 logo">
                        <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
                        <!-- <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" /> -->
                    </div>
                    <div class="col-md-10">
                        <h4 class="main-title mb-0 font-weight-bold">{{ ipReceiptDetailsList?.HospitalName }}</h4>
                        <span style="font-size: 12px; font-weight: bold; display: inline-block; ">{{ ipReceiptDetailsList?.HospitalTagLine }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <p class="font-weight-bold">{{ipReceiptDetailsList?.HospitalAddress}}</p>
                        <h3 class="sub-title1 mb-1 font-weight-bold">
                            <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3 font-weight-bold">
                <div class="row">
                    <div class="col-md-3">
                        P. Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                    </div>
                    <div class="col-md-3">
                        IP No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Recpt No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                    </div>
                    <div class="col-md-3">
                        Recpt Dt
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Age/Sex
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                    </div>
                    <div class="col-md-3">
                        Phone
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.Phone}}
                    </div>
                </div>                
            </div>
        </div>
        <div class="row-md-8 p-3 font-weight-bold">
            <div class="row">
                <div class="col-md-2">
                    <span> S/O.D/O.W/O</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
                </div>
                <div class="col-md-2">
                    <span> Ward/Bed</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.WardBed}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Relation</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.relation}}
                </div>
                <div class="col-md-2">
                    <span>Advance NO</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Consultant</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.Consultant}}
                </div>
                <div class="col-md-2">
                    <span> Dept Name</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.DeptName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span>Receipt Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
                </div>
                <div class="col-md-4">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                    </span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.BankName}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
            </div>
            <div>
                <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
                <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
            </div>
        </div>
        <footer class="p-3 font-weight-bold">
            <div class="row">
                <div class="col-md-2">
                    Created By
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
                </div>
                <div class="col-md-2">
                    Create Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    Print By
                </div>
                <div class="col-md-3">
                    <b>:</b> {{userInfo.username}}
                </div>
                <div class="col-md-2">
                    Print Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
                <div class="col-md-4 text-center"></div>-->
                <div class="col-md-12 text-right">(Authorised Signatory)
                </div>
            </div>
        </footer>
    </div>
</div>
<div *ngIf="userInfo.hospitalShortName == 'CPMTA'">
    <div id="print-receipt-section" class="print-form border border-dark" *ngIf="ipReceiptDetailsList !== undefined">
        <div class="row no-gutters heading-block border-bottom border-dark">
                <div class="col-md-6 border-right border-dark">
                    <div class="row">
                        <div class="col-md-2 logo">
                            <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
                            <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" />
                        </div>
                        <div class="col-md-10">
                            <h4 class="main-title mb-0 font-weight-bold">{{ ipReceiptDetailsList?.HospitalName }}</h4>
                            <span style="font-size: 12px; font-weight: normal; display: inline-block; ">{{ ipReceiptDetailsList?.HospitalTagLine }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="text-align: center;">
                            <p>{{ipReceiptDetailsList?.HospitalAddress}}</p>
                            <h3 class="sub-title1 mb-1 font-weight-bold">
                                <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-3">
                    <div class="row">
                        <div class="col-md-3">
                            P. Name
                        </div>
                        <div class="col-md-9">
                            <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            UMR No
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                        </div>
                        <div class="col-md-3">
                            Admn No
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            Recpt No
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                        </div>
                        <div class="col-md-3">
                            Recpt Dt
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            Age/Sex
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                        </div>
                        <div class="col-md-3">
                            Phone
                        </div>
                        <div class="col-md-3">
                            <b>:</b> {{ipReceiptDetailsList?.Phone}}
                        </div>
                    </div>
                </div>
        </div>
        <div class="row-md-8 p-3">
                <div class="row">
                    <div class="col-md-2">
                        <span> S/O.D/O.W/O</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
                    </div>
                    <div class="col-md-2">
                        <span> Ward/Bed</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.WardBed}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <span> Relation</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.relation}}
                    </div>
                    <div class="col-md-2">
                        <span>Advance NO</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <span> Consultant</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.Consultant}}
                    </div>
                    <div class="col-md-2">
                        <span> Dept Name</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.DeptName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <span>Receipt Amt</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </div>
                    <div class="col-md-2">
                        <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                        <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
                    </div>
                    <div class="col-md-4">
                        <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                            <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                        </span>
                        <span *ngIf="ipReceiptDetailsList?.WalletMode">
                            <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                        </span>
                    </div>
                </div>
                <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
                    <div class="col-md-2">
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                        <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
                    </div>
                    <div class="col-md-4">
                        <b>:</b>
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                        <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
                    </div>
                    <div class="col-md-2">
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                        <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
                    </div>
                    <div class="col-md-4">
                        <b>:</b>
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                        <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
                    </div>
                </div>
                <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
                    <div class="col-md-2">
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                        <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.BankName}}
                    </div>
                    <div class="col-md-2">
                        <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                        <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </div>
                </div>
                <div>
                    <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
                    <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
                </div>
        </div>
        <footer class="p-3">
                <div class="row">
                    <div class="col-md-2">
                        Created By
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
                    </div>
                    <div class="col-md-2">
                        Create Dt
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        Print By
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{userInfo.username}}
                    </div>
                    <div class="col-md-2">
                        Print Dt
                    </div>
                    <div class="col-md-4">
                        <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-align">***Cheques are subject to realisation</div>                    
                    <div class="col-md-4 text-center"></div>
                    <div class="col-md-4 text-right">(Authorised Signatory)
                    </div>
                </div>
        </footer>
    </div>
</div>
<div *ngIf="userInfo.hospitalShortName == 'JPMTA'" style="margin-top: 1.4in;">
    <div id="print-receipt-section" class="print-form border border-dark" *ngIf="ipReceiptDetailsList !== undefined">    
        <div class="row no-gutters heading-block border-bottom border-dark">            
            <div class="col-md-6 border-right border-dark">
                <div class="row">
                    <div class="col-md-2 logo">
                        <!-- <img src="./assets/hospital-logo.jpg" class="img-thumbnail" /> -->
                        <!-- <img src="./assets/logo/hospital-logo-{{userInfo.hospitalShortName}}-{{userInfo.hospitalID}}.png" class="img-thumbnail" /> -->
                    </div>
                    <div class="col-md-10">
                        <h4 class="main-title mb-0 font-weight-bold">{{ ipReceiptDetailsList?.HospitalName }}</h4>
                        <span style="font-size: 12px; font-weight: bold; display: inline-block; ">{{ ipReceiptDetailsList?.HospitalTagLine }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <p class="font-weight-bold">{{ipReceiptDetailsList?.HospitalAddress}}</p>
                        <h3 class="sub-title1 mb-1 font-weight-bold">
                            <u>{{ipReceiptDetailsList?.ReceiptName}}</u>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-3 font-weight-bold">
                <div class="row">
                    <div class="col-md-3">
                        P. Name
                    </div>
                    <div class="col-md-9">
                        <b>:</b> {{ipReceiptDetailsList?.PatientName}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        UMR No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.UMRNo}}
                    </div>
                    <div class="col-md-3">
                        Admn No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AdmissionNo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Recpt No
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptNo}}
                    </div>
                    <div class="col-md-3">
                        Recpt Dt
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptDate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Age/Sex
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.AgeSex}}
                    </div>
                    <div class="col-md-3">
                        Phone
                    </div>
                    <div class="col-md-3">
                        <b>:</b> {{ipReceiptDetailsList?.Phone}}
                    </div>
                </div>                
            </div>
        </div>
        <div class="row-md-8 p-3 font-weight-bold">
            <div class="row">
                <div class="col-md-2">
                    <span> S/O.D/O.W/O</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.SWDBO}}
                </div>
                <div class="col-md-2">
                    <span> Ward/Bed</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.WardBed}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Relation</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.relation}}
                </div>
                <div class="col-md-2">
                    <span>Advance NO</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.AdvanceNo}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span> Consultant</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.Consultant}}
                </div>
                <div class="col-md-2">
                    <span> Dept Name</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.DeptName}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <span>Receipt Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode"> Cash Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode"> Wallet Amt</span>
                </div>
                <div class="col-md-4">
                    <span *ngIf="!ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.CashAmt}}
                    </span>
                    <span *ngIf="ipReceiptDetailsList?.WalletMode">
                        <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Date</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card Dt</span> Might be required in future-->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ChequeDt}}</span> -->
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque NO</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.CardMode">Card NO</span> Might be required in future -->
                </div>
                <div class="col-md-4">
                    <b>:</b>
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.TransactionId}}</span>
                    <!-- <span *ngIf="ipReceiptDetailsList?.ChequeMode">{{ipReceiptDetailsList?.ReceiptAmt}}</span> -->
                </div>
            </div>
            <div class="row" *ngIf="ipReceiptDetailsList?.ChequeMode || ipReceiptDetailsList?.CardMode">
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Bank</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Bank</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.BankName}}
                </div>
                <div class="col-md-2">
                    <span *ngIf="ipReceiptDetailsList?.ChequeMode">Cheque Amt</span>
                    <span *ngIf="ipReceiptDetailsList?.CardMode">Card Amt</span>
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.ReceiptAmt}}
                </div>
            </div>
            <div>
                <p class="pt-4">{{ipReceiptDetailsList?.ReceivedWithThanksFrom}}</p>
                <p style="text-transform: capitalize;">In Words &nbsp;&nbsp;&nbsp;{{ipReceiptAmountInWords}}</p>
            </div>
        </div>
        <footer class="p-3 font-weight-bold">
            <div class="row">
                <div class="col-md-2">
                    Created By
                </div>
                <div class="col-md-3">
                    <b>:</b> {{ipReceiptDetailsList?.CreateBy}}
                </div>
                <div class="col-md-2">
                    Create Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.CreateDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    Print By
                </div>
                <div class="col-md-3">
                    <b>:</b> {{userInfo.username}}
                </div>
                <div class="col-md-2">
                    Print Dt
                </div>
                <div class="col-md-4">
                    <b>:</b> {{ipReceiptDetailsList?.PrintDt}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 text-align">***Cheques are subject to realisation</div>
                <div class="col-md-4 text-center"></div>
                <div class="col-md-4 text-right">(Authorised Signatory)
                </div>
            </div>
        </footer>
    </div>
</div>

