import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../shared/loginservices/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private roles: string[];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;
  hospitalName: string | null = null;
  accountDropdown: boolean = false;
  constructor(
        private tokenStorageService: TokenStorageService, 
        private router: Router
    ) {

    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorageService.getUser().roles      
    }

  }


  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.tokenStorageService.currentUserHospitalName$
        .subscribe(
          response => {
            this.username = response.userName;
            this.hospitalName = response.hospitalName
          });

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      // this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      // this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
      this.hospitalName = user.hospitalName;
    }
  }

  dropdownToggle() {
    this.accountDropdown = !this.accountDropdown;
  }

  hasUser(): boolean {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorageService.getUser().roles;
      return true;
    }
    return false;
  }
  logout(): void {
    this.tokenStorageService.signOut();
    location.replace("/");
  }

}
