import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patientregistration',
  templateUrl: './patientregistration.component.html',
  styleUrls: ['./patientregistration.component.css']
})
export class PatientregistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

