import { Component, OnInit, ElementRef, ViewChild, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import {AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent} from 'ngx-agora';
import {FormControl} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  @ViewChild('btnpopup', {static: true}) btnpopup: ElementRef;
  @Input() showPopUp: any = false;
  @Output() closePopup = new EventEmitter();
  public show = false;
  public buttonName: any = 'Select';
  title = 'app';
  domain = 'meet.jit.si';
  options: any;
  api: any;
  agoraService: NgxAgoraService;
  localCallId = 'agora_local';

  private localStream: Stream;
  private client: AgoraClient;

  /**
   * App ID used when connecting to the Agora.io servers
   */
  appId: FormControl = new FormControl((environment as any).agora ? (environment as any).agora.appId : '');
  /**
   * Channel (meeting room) within the Agora app to join
   */
  channel = new FormControl('123');
  /**
   * Generated user ID that is attached to the local client when joining a meeting room
   */
  uid: number;

  /**
   * All the IDs of other users that have joined the call
   */
  remoteCalls: string[] = [];
  /**
   * Whether the local client has tuned in to the Agora meeting room
   */
  connected = false;
  /**
   * Whether the local client's A/V stream has been published to the remote meeting room
   */
  published = false;


  constructor(private renderer: Renderer2, agoraService: NgxAgoraService) {
    this.agoraService = agoraService;
    this.uid = Math.floor(Math.random() * 100);
  }
  toggle(): void {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Select';
    }
    else{
      this.buttonName = 'Select';
    }
  }

  ngOnChanges(changes: any) {
    this.showpopup();
  }

  showpopup() {  
    let popEle = document.getElementById('videoPopup');
    popEle.click();
  }

  ngOnInit(): void {
    this.client = this.agoraService.createClient({ mode: 'rtc', codec: 'h264'});
    this.assignClientHandlers();
    // Added in this step to initialize the local A/v stream
    this.localStream = this.agoraService.createStream({streamID: this.uid, audio: true, video: true, screen: false});
    this.assignLocalStreamHandlers();
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
  }
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.client.join('0064cb8692aba194d97b0ae2841a75dd856IAAdLc1huzvVxCcMsbHle81cmHFvd1ZvLpJJHogW5nkoGxWLCzUAAAAAEABsKnYm4vJcXwEAAQDi8lxf', 'igzolthealth', this.uid, onSuccess, onFailure);
  }
  publish(): void{
    this.client.publish(this.localStream, err => console.log('Publish local stream error:' + err));
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });
    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(() => {
        this.localStream.play(this.localCallId);
        if (onSuccess()){
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err));
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      this.published = true;
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  public stopVideo() {
    this.localStream.close();
    this.closePopup.emit(false);
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }
}
