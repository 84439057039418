import { environment } from 'src/environments/environment';

export const restServerUrl = '/MOCK_DATA%20(1).json' ;

export const partialUrl = 'igzolthealth/v1/';
// export const serverUrl = 'http://localhost:8000/igzolthealth/v1';
// export const serverUrl = 'https://devhost.igzolthealth.com/assets';
export const bootstrapError = '/NG_DEFER_BOOTSTRAP!';
export const value = 'value';
export const privileges = 'privileges';
// export const serverUrl = 'http://localhost:4200/assets';

// export const serverUrl = 'https://devhost.igzolthealth.com:8000/igzolthealth/v1';
export const serverUrl = environment.apiUrl + 'v1';

