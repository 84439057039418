import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppointmentService} from '../appointment.service';
import {SearchBarResults} from '../../shared/appointmentsModels/searchBarResults';
import {SearchDoctors} from '../../shared/appointmentsModels/searchDoctors';

@Component({
  selector: 'app-doctorprofiles',
  templateUrl: './doctorprofiles.component.html',
  styleUrls: ['./doctorprofiles.component.css']
})

export class DoctorprofilesComponent implements OnInit, OnChanges{
  list: any;
  searchBarresults: SearchBarResults;
  displpayComponent: string;
  selectedDoctor: SearchDoctors;
  docProfiles: SearchDoctors[] = [];
  constructor(private appointmentService: AppointmentService){
  }

  ngOnChanges(changes: SimpleChanges): any{
    this.searchBarresults = this.appointmentService.searchResults;
  }

  ngOnInit(): void {
    this.selectedDoctor = this.appointmentService.appSelectedDoctor;
    this.searchBarresults = this.appointmentService.searchResults;
    this.gettingTheInitialData();
    if (this.appointmentService.appSelectedDoctor) {
      this.selectedDoctor = this.appointmentService.appSelectedDoctor;
      this.displpayComponent = 'doctorInfo';
    }
  }
  gettingTheInitialData(): any {
    this.list = undefined;
    if (this.searchBarresults) {
      this.docProfiles = this.appointmentService.appallDoctorsList;
      if (this.docProfiles) {
        this.selectedDoctor = this.docProfiles[0];
        this.appointmentService.appSelectedDoctor = this.selectedDoctor;
        this.displpayComponent = 'doctorInfo';
        this.docProfiles.map((data: any, i) => {
          if (i === 0) {
            data.activeClass = true;
          } else {
            data.activeClass = false;
          }
          return data;
        });
      }
      if (this.searchBarresults.doctorNameRSpecialisation) {
        this.ifDoctorSelected();
        if (!this.searchBarresults.bySpecialisation) {
          this.docProfiles = [this.selectedDoctor];
        }
      }
    }
  }
  ifDoctorSelected(): any {
    if (this.searchBarresults.bySpecialisation) {
      this.displpayComponent = undefined;
      this.selectedDoctor = null;
      // console.log(this.appointmentService.searchResults.departmentId);
      this.docProfiles = this.appointmentService.appallDoctorsList.filter( item => {
        // return (item.doctorSpecilization.toLowerCase().indexOf(this.searchBarresults.doctorNameRSpecialisation.toLowerCase()) >= 0);
        return item.departmentId === this.appointmentService.searchResults.departmentId;
      });
      // if (this.docProfiles) {
      //   this.selectedDoctor = this.docProfiles[0];
      //   this.docProfiles.map((data: any, i) => {
      //     if (i === 0) {
      //       data.activeClass = true;
      //     } else {
      //       data.activeClass = false;
      //     }
      //     return data;
      //   });
      // }
    }
    else {
      this.selectedDoctor = this.docProfiles.find(searchigDoctor => {
        return searchigDoctor.hospitaldocdepId === this.searchBarresults.doctorOrSpecialisationId;
      });
      this.appointmentService.appSelectedDoctor = this.selectedDoctor;
      this.displpayComponent = 'doctorInfo';
    }
  }


  receivedSearchBarResults(dataReceived: SearchBarResults): any {
    this.docProfiles = this.appointmentService.appallDoctorsList;
    this.searchBarresults = dataReceived;
    this.gettingTheInitialData();
    // if (this.searchBarresults) {
    //   this.docProfiles = this.appointmentService.appallDoctorsList;
    //   if (this.searchBarresults.doctorNameRSpecialisation) {
    //     this.ifDoctorSelected();
    //     if (!this.searchBarresults.bySpecialisation) {
    //       this.docProfiles = [this.selectedDoctor];
    //     }
    //   }
    // }
    // this.gettingTheDoctorList(this.searchBarresults);
  }
  doctorSelection(doctorIndex: number): any{
    this.docProfiles.map((data: any, i) => {
      if (i === doctorIndex) {
        data.activeClass = true;
      } else {
        data.activeClass = false;
      }
    });
    this.list = doctorIndex;
    this.selectedDoctor = this.docProfiles[doctorIndex];
    this.appointmentService.appSelectedDoctor = this.selectedDoctor;
    this.displpayComponent = 'doctorInfo';
  }

}
