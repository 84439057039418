import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';
import { TokenStorageService } from 'src/app/shared/loginservices/token-storage.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  userName: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

  public form: any = {};
  public userInfo: any = {};
  public errorMessage: string | null = null;
  public changePasswordInfo: IChangePassword | null;
  constructor(
      private accountService:AccountService, 
      private tokenService: TokenStorageService,
      private toastr: ToastrService,
      private router: Router) 
      {

        this.userInfo = this.tokenService.getUser();

      }

  ngOnInit(): void {
  }

  /**
   * @author Sankar
   */
  onSubmit(): void {
    this.errorMessage = null;
    this.changePasswordInfo = {
      userName: this.userInfo.username,
      oldPassword: this.form.oldPassword,
      newPassword: this.form.newPassword,
      confirmPassword: this.form.confirmPassword
    }
    
    this.accountService.changePassword(this.changePasswordInfo)
        .subscribe(data => {
            this.toastr.success('Your password changed successfully!');
            this.router.navigate(['/'])
          },
          err => {
            console.log("asdfasdf", err.error.message)
            this.errorMessage = err.error.message;
          }
        )
  }

}
